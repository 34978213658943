import { FC, useEffect, useRef, useState } from "react"
import { JsonView, defaultStyles } from "react-json-view-lite"
import { Link, useParams } from "react-router-dom"
import { RecordKey, RecordValue, RecordValueWithSensograms, getFullRecord, getRecord, updateRecordBoundaries, updateRecordDescription, updateRecordName, withIdb } from "../idb/idb"
import { FlexCol, FlexRow, Paper } from "../common"
import uPlot from "uplot"
import { SingleSensogramFigure } from "../widgets/recordSensogram"
import { DEFAULT_PLOT_DECIMATED_FPS } from "../constants"
import { Breadcrumb, Button, Col, Input, InputRef, Row, Space, Tooltip, Typography, message as antdMessage } from "antd"
import { SingleSignatureFigure } from "../widgets/recordSignature"
import { BoxPlotOutlined, BoxPlotTwoTone, BugOutlined, BugTwoTone, HomeOutlined, SaveOutlined } from "@ant-design/icons"

enum ZoneSelectorType {
    Baseline,
    Analyte
}

export const RecordPage: FC = () => {
    const { recordId } = useParams<{recordId: string}>()
    const [ record, setRecord ] = useState<RecordValueWithSensograms | null>(null)

    const targetRef = useRef<HTMLDivElement>(null)
    const uplotRef = useRef<uPlot | null>(null)
    const nameInputRef = useRef<InputRef>(null)
    const descriptionInputRef = useRef<InputRef>(null)
    
    const [ zoneSelectorType, setZoneSelectorType ] = useState<ZoneSelectorType>(ZoneSelectorType.Analyte)
    const [ showDebugInfo, setShowDebugInfo ] = useState<boolean>(false)
    const [ shouldAggregate, setShouldAggregate ] = useState<boolean>(true)

    
    useEffect(() => {
        getFullRecord(recordId).then((_record) => {
            setRecord(_record)
        })
    }, [recordId])

    useEffect(() => {
        if (record === null) {
            return
        }
        let X = record.sensogramTimestamps.map((ts) => ts/1000)
        let Ys: number[][] = []
        for (let i = 0; i < 64; i++) {
            let Y = []
            for (let j = 0; j < record.sensogramSeries.length; j++) {
                Y[j] = record.sensogramSeries[j][i]
                
            }
            Ys[i] = Y
        }
        let data: uPlot.AlignedData = [
            X,
            ...Ys
        ]
        let opts: uPlot.Options = {
            width: 400,
            height: 400,
            legend: {
                show: false
            },
            series: [
                {},
                ...Ys.map((_, i) => {
                    return {
                        show: true,
                        stroke: `hsl(${i * 360 / 64}, 100%, 50%)`,
                        label: `S${i}`
                    }
                })
            ]
        }

        if (targetRef.current === null) {
            return
        }
        let uplot = new uPlot(opts, data, targetRef.current)
        uplot.setSize({
            width: targetRef.current.clientWidth,
            height: targetRef.current.clientHeight
        })
        uplotRef.current = uplot
        return () => {
            uplot.destroy()
            uplotRef.current = null
        }

    }, [record])

    if (!record) {
        return null
    }

    // const setBoundaries = async (
    //     startIdx?: number,
    //     endIdx?: number
    // ) => {
    //     console.log('single sensogram figure: set boundaries', startIdx, endIdx, zoneSelectorType)
    //     let _record = {...record}
        
    //     if (zoneSelectorType === ZoneSelectorType.Baseline) {
    //         _record.baselineStart = startIdx
    //         _record.baselineEnd = endIdx
    //         void await updateRecordBoundaries(
    //             record.key,
    //             startIdx,
    //             endIdx,
    //             undefined,
    //             undefined                
    //         )
    //     } else {
    //         _record.analyteStart = startIdx
    //         _record.analyteEnd = endIdx
    //         void await updateRecordBoundaries(
    //             record.key,
    //             undefined,
    //             undefined,
    //             startIdx,
    //             endIdx
    //         )
    //     }
    //     // TODO: rerender by setting the existing record, not a newly-fetched one
    //     setRecord(_record)
    // }

    return <div
    tabIndex={-1}
    onKeyDown={(e) => {
        console.log('single sensogram figure: keydown', e.key)
        let n: number
        switch (e.key) {
            case 'Shift':
                console.log('shift pressed', zoneSelectorType)
                setZoneSelectorType(ZoneSelectorType.Baseline)
                break
        }
    }}
    onKeyUp={(e) => {
        console.log('single sensogram figure: keyup', e.key)
        switch (e.key) {
            case 'Shift':
                setZoneSelectorType(ZoneSelectorType.Analyte)
                break
        }
    }}
    ><Paper>
        <Breadcrumb
            items={[
                {
                    title: <Link to="/home"><HomeOutlined /></Link>,
                },
                {
                    title: <Link to="/records">Records</Link>,
                },
                {
                    title: record.name ? record.name : '—',
                },
            ]}
        />
        <FlexRow style={{
            width: '100%',
            justifyContent: 'end',
            alignItems: 'center',
            gap: 5
        }}>
            <Tooltip overlay="Toggle debug info">
                <Button
                    type="text" 
                    size="large" 
                    icon={
                    showDebugInfo ? <BugTwoTone /> : <BugOutlined />}
                    onClick={() => {
                        setShowDebugInfo(!showDebugInfo)
                    }}
                />
            </Tooltip>
            <Tooltip overlay="Toggle sensor aggregation">
                <Button 
                    type="text"
                    size="large"
                    onClick={() => {
                        setShouldAggregate(!shouldAggregate)
                    }}
                    icon={shouldAggregate ? <BoxPlotTwoTone /> : <BoxPlotOutlined/>}
                />
            </Tooltip>
        </FlexRow>
        <FlexRow style={{
            justifyContent: 'space-between',
            alignItems: 'center',
        }}>
            <FlexCol style={{
                width: '100%'
            }}>
                <b>Name:</b>
                <Space.Compact>
                    <Input
                        defaultValue={record.name}
                        placeholder="Name"
                        ref={nameInputRef}
                    />
                    <Button icon={<SaveOutlined/>} type="primary" onClick={async () => {
                        if (nameInputRef.current === null || nameInputRef.current.input === null) {
                            return
                        }
                        await updateRecordName(record.key, nameInputRef.current.input.value)
                        antdMessage.success('Name updated')
                    }}/>
                </Space.Compact>
            </FlexCol>
            <FlexCol style={{
                width: '100%'
            }}>
                <b>Description:</b>
                <Space.Compact>
                    <Input
                        defaultValue={record.description}
                        placeholder="Description"
                        ref={descriptionInputRef}
                    />
                    <Button icon={<SaveOutlined/>} type="primary" onClick={async () => {
                        if (descriptionInputRef.current === null || descriptionInputRef.current.input === null) {
                            return
                        }
                        await updateRecordDescription(record.key, descriptionInputRef.current.input.value)
                        antdMessage.success('Description updated')
                    }}/>
                </Space.Compact>
            </FlexCol>
        </FlexRow>
        {showDebugInfo &&
            <FlexRow style={{
                width: '100%',
                padding: 10
            }}>
                <JsonView data={record} style={defaultStyles} shouldInitiallyExpand={(level) => {
                    return level < 1
                }}/>
            </FlexRow>
        }
        <Row gutter={[5, 5]}>
            <Col xs={24} lg={16}>
                <div style={{
                    width: '100%',
                    height: 400,
                }}>
                    <SingleSensogramFigure
                        record={record}
                        shouldAggregate={shouldAggregate}
                        setBoundaries={async (startIdx, endIdx) => {
                            console.log('single sensogram figure: set boundaries', startIdx, endIdx, zoneSelectorType)
                            let _record = {...record}
                            
                            if (zoneSelectorType === ZoneSelectorType.Baseline) {
                                _record.baselineStart = startIdx
                                _record.baselineEnd = endIdx
                                void await updateRecordBoundaries(
                                    record.key,
                                    startIdx,
                                    endIdx,
                                    undefined,
                                    undefined                
                                )
                            } else {
                                _record.analyteStart = startIdx
                                _record.analyteEnd = endIdx
                                void await updateRecordBoundaries(
                                    record.key,
                                    undefined,
                                    undefined,
                                    startIdx,
                                    endIdx
                                )
                            }
                            // TODO: rerender by setting the existing record, not a newly-fetched one
                            setRecord(_record)
                        }}
                    />
                </div>
            </Col> 
            <Col xs={24} lg={8}>
                <div style={{
                    width: '100%',
                    height: 400,
                }}>
                    <SingleSignatureFigure
                        record={record}
                        shouldAggregate={shouldAggregate}
                    />
                </div>
            </Col>
        </Row>
        <FlexRow style={{
            justifyContent: 'center',
            alignItems: 'center',
            height: 30
            
        }}>
            Selecting zone: {ZoneSelectorType[zoneSelectorType]}
        </FlexRow>
    </Paper>
    </div>
}