import { Tooltip, Badge, theme } from "antd"
import { FC } from "react"
import { FlexCol, FlexRow } from "../common"
import { Link } from "react-router-dom"
import { useMediaQuery } from "../utils"

export const ConnectionIndicator: FC<{
    name: string,
    connectPath: string,
    isConnected: boolean,
    minimized: boolean,
    queueLength: number
}> = ({
    name,
    connectPath,
    isConnected,
    minimized,
    queueLength
}) => {

    const {
        token: {
            colorSuccessText,
            colorErrorText
        }
    } = theme.useToken()

    const isMobile = useMediaQuery()

    let connectionIndicator = (
        <span style={{
            fontSize: 20,
            cursor: 'pointer',
            color: isConnected ? colorSuccessText : colorErrorText //'#52c41a' : '#ff4d4f'
        }}
        >⬤</span>
    )
    if (!isMobile) {
        connectionIndicator = <Tooltip overlay={`Connected to ${name}`} color='#333'>
            {connectionIndicator}
        </Tooltip>
    }

    let lightColor = "rgba(255, 255, 255, 0.85)"

    if (minimized) {
        return <Link to={connectPath}>
            <div style={{
                textAlign: 'center',
                padding: 10
            }}>
                {connectionIndicator}
            </div>
        </Link>
    } else {
        return (
            <Link to={connectPath}><FlexCol style={{
                gap: 0,
                color: lightColor,
                padding: 5,
                cursor: 'pointer'
            }}>
                <span><b>{name}</b></span>
                <FlexRow style={{alignItems: 'center', gap: 3}}>Connection: {connectionIndicator}</FlexRow>
                {/* <span>Queue: <Badge count={queueLength} color="purple" showZero size="small" /></span> */}
            </FlexCol>
            </Link>
        )
    }
}