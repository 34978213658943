import { Typography } from "antd"
import { FC } from "react"
import { FlexCol } from "../common"

export const WebBleNotSupportedWidget: FC = () => {
    return <FlexCol style={{
        justifyContent: "center",
        alignItems: "center",
        width: '100%',
        height: '100%',
        gap: 0,
    }}>
        <Typography.Title level={3} style={{
            textAlign: 'center'
        }}>Your browser does not support <b>Web BLE</b></Typography.Title>
        <Typography.Text>Please use <b>Google Chrome</b>, <b>Microsoft Edge</b> or <b>Opera Browser</b></Typography.Text>
    </FlexCol>
}