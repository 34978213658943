import React, { createContext, useContext, useReducer } from 'react';
import { thimphuProtocol } from '../../thimphu_protocol';
import { RefDesignProtocol } from '../../ref_design_protocol';
import { FspMetadata } from '../../fsp_metadata';

interface Action {
    type: string;
    payload?: any;
}

interface State {
    thimphuPlatformId?: thimphuProtocol.IPlatformIdResult
    thimphuFspMetadata?: FspMetadata.IMetadataSchema
    thimphuMemInfos?: thimphuProtocol.IMemInfosResult

    refkitVersion?: RefDesignProtocol.IVersionResult
    refkitConfig?: RefDesignProtocol.IConfigData
    refkitCapabilities?: RefDesignProtocol.ICapabilitiesResult
    refkitMemInfosImage?: RefDesignProtocol.IMemoryInfoResult
    refkitMemInfosCoreSensor?: RefDesignProtocol.IMemoryInfoResult

}

interface MetadataContextType extends State {
    setThimphuPlatformId: (platformId: thimphuProtocol.IPlatformIdResult) => void;
    setThimphuFspMetadata: (fspMetadata?: FspMetadata.IMetadataSchema) => void;
    setThimphuMemInfos: (memInfos: thimphuProtocol.IMemInfosResult) => void;

    setRefkitVersion: (version: RefDesignProtocol.IVersionResult) => void;
    setRefkitConfig: (config: RefDesignProtocol.IConfigData) => void;
    setRefkitCapabilities: (capabilities: RefDesignProtocol.ICapabilitiesResult) => void;
    setRefkitMemInfosImage: (memInfos: RefDesignProtocol.IMemoryInfoResult) => void;
    setRefkitMemInfosCoreSensor: (memInfos: RefDesignProtocol.IMemoryInfoResult) => void;
}

const initialState: State = {};

function metadataReducer(state: State, action: Action): State {
    switch (action.type) {
        case 'SET_THIMPHU_PLATFORM_ID':
            return {
                ...state,
                thimphuPlatformId: action.payload,
            };
        case 'SET_THIMPHU_FSP_METADATA':
            return {
                ...state,
                thimphuFspMetadata: action.payload,
            };
        case 'SET_THIMPHU_MEM_INFOS':
            return {
                ...state,
                thimphuMemInfos: action.payload,
            };
        case 'SET_REFKIT_VERSION':
            return {
                ...state,
                refkitVersion: action.payload,
            }
        case 'SET_REFKIT_CONFIG':
            return {
                ...state,
                refkitConfig: action.payload,
            }
        case 'SET_REFKIT_CAPABILITIES':
            return {
                ...state,
                refkitCapabilities: action.payload,
            }
        case 'SET_REFKIT_MEM_INFOS_IMAGE':
            return {
                ...state,
                refkitMemInfosImage: action.payload,
            }
        case 'SET_REFKIT_MEM_INFOS_CORE_SENSOR':
            return {
                ...state,
                refkitMemInfosCoreSensor: action.payload,
            }
    default:
        return state;
    }
}

export const MetadataContext = createContext<MetadataContextType | undefined>(undefined);

export function useMetadataContext(): MetadataContextType {
    const context = useContext(MetadataContext);
    if (!context) {
        throw new Error('useMetadataContext must be used within a MessageProvider');
    }
    return context;
}

export function MetadataProvider({ children }: { children: React.ReactNode }) {
    const [state, dispatch] = useReducer(metadataReducer, initialState);
    
    const setThimphuPlatformId = (platformId: thimphuProtocol.IPlatformIdResult) => {
        dispatch({
            type: 'SET_THIMPHU_PLATFORM_ID',
            payload: platformId,
        });
    };
    const setThimphuFspMetadata = (fspMetadata?: FspMetadata.IMetadataSchema) => {
        dispatch({
            type: 'SET_THIMPHU_FSP_METADATA',
            payload: fspMetadata,
        });
    }
    const setThimphuMemInfos = (memInfos: thimphuProtocol.IMemInfosResult) => {
        dispatch({
            type: 'SET_THIMPHU_MEM_INFOS',
            payload: memInfos,
        });
    }
    
    const setRefkitVersion = (version: RefDesignProtocol.IVersionResult) => {
        dispatch({
            type: 'SET_REFKIT_VERSION',
            payload: version,
        });
    }
    const setRefkitConfig = (config: RefDesignProtocol.IConfigData) => {
        dispatch({
            type: 'SET_REFKIT_CONFIG',
            payload: config,
        });
    }
    const setRefkitCapabilities = (capabilities: RefDesignProtocol.ICapabilitiesResult) => {
        dispatch({
            type: 'SET_REFKIT_CAPABILITIES',
            payload: capabilities,
        });
    }
    const setRefkitMemInfosImage = (memInfos: RefDesignProtocol.IMemoryInfoResult) => {
        dispatch({
            type: 'SET_REFKIT_MEM_INFOS_IMAGE',
            payload: memInfos,
        });
    }
    const setRefkitMemInfosCoreSensor = (memInfos: RefDesignProtocol.IMemoryInfoResult) => {
        dispatch({
            type: 'SET_REFKIT_MEM_INFOS_CORE_SENSOR',
            payload: memInfos,
        });
    }

    const contextValue: MetadataContextType = {
        thimphuPlatformId: state.thimphuPlatformId,
        thimphuFspMetadata: state.thimphuFspMetadata,
        thimphuMemInfos: state.thimphuMemInfos,

        refkitVersion: state.refkitVersion,
        refkitConfig: state.refkitConfig,
        refkitCapabilities: state.refkitCapabilities,
        refkitMemInfosImage: state.refkitMemInfosImage,
        refkitMemInfosCoreSensor: state.refkitMemInfosCoreSensor,

        setThimphuPlatformId,
        setThimphuFspMetadata,
        setThimphuMemInfos,
        
        setRefkitVersion,
        setRefkitConfig,
        setRefkitCapabilities,
        setRefkitMemInfosImage,
        setRefkitMemInfosCoreSensor,
    };

    return <MetadataContext.Provider value={contextValue}>
        {children}
    </MetadataContext.Provider>
}