
export const abs = (a: number[]): number[] => {
    return a.map(e => Math.abs(e))
}

export const sum = (a: number[]): number => {
    return a.reduce((acc, cur) => {
        return acc+=cur
    }, 0)
}

export const mean = (a: number[]): number => {
    return sum(a)/a.length
}

export const variance = (a: number[]): number => {
    const avg = mean(a);
    const squareDiffs = a.map((cur) => Math.pow(cur - avg, 2));
    const avgSquareDiff = mean(squareDiffs);
    return avgSquareDiff;
};

export const standardDeviation = (a: number[]): number => Math.sqrt(variance(a));

export const euclideanDistance = (a: number[], b: number[]): number => {
    return Math.hypot(...a.map((e, i) => e-b[i]))
}

export const transpose = (a: number[][]): number[][] => {
    return a[0].map((col, i) => a.map(row => row[i]))
}

export const argMax = (a: number[]) => {
    return a.map((x, i) => [x, i]).reduce((r, a) => (a[0] > r[0] ? a : r))[1];
}

export const meanAxis = (a: number[][], axis: number): (number[] | number) => {
    switch (axis) {
        case 0:
            return transpose(a).map((e) => mean(e)); // Mean along the cols (vertical) axis, e.g. meanAxis([[1,2,3], [1,2,3], [1,2,3]], null) -> [1, 2, 3]
        case 1:
            return a.map((e) => mean(e)); // Mean along the rows (horizontal) axis, e.g. meanAxis([[1,2,3], [1,2,3], [1,2,3]], null) -> [2, 2, 2]
        case null:
            return mean(a.map((e) => mean(e))); // Mean of the whole matrix, e.g. meanAxis([[1,2,3], [1,2,3], [1,2,3]], null) -> 2
        default:
            throw Error('Axis should be 0, 1 or null');
    }
};

export const shift = (a: number[][], baselineLength: number): number[][] => {
    const shifts = a.slice(0, baselineLength).map((row) => mean(row));
    return a.map((row) => row.map((col, i) => col - shifts[i]));
};

export const getGroupedMap = (all: any[], labels: string[]): Record<string, any[]> => {
    const groupedMap: Record<string, any> = {};
    labels.forEach((label) => (groupedMap[label] = []));
    all.forEach((a, i) => groupedMap[labels[i]].push(a));
    return groupedMap;
};