import React, { createContext, useContext, useReducer } from 'react';
import { thimphuProtocol } from '../../thimphu_protocol';
import { RefDesignProtocol } from '../../ref_design_protocol';
import { CSMMisoFrame, CSMMosiCommand } from '../serial/csmProtocol';

const MAX_MESSAGE_QUEUE_SIZE = 100;

type MessageID = string;

interface ThimphuMessage {
    id: MessageID;
    message: thimphuProtocol.PlatformMsg;
}

interface RefkitMessage {
    id: MessageID;
    ts: number; // Date.now()
    message: RefDesignProtocol.SlaveRoot;
}

interface CSMMisoMessage {
    id: MessageID;
    ts: number; // Date.now()
    message: CSMMisoFrame;
}

interface CSMMosiMessage {
    id: MessageID;
    message: CSMMosiCommand
}

interface State {
    thimphuMessages: ThimphuMessage[];
    thimphuPort?: SerialPort
    thimphuIsConnected: boolean

    refkitMessages: RefkitMessage[];
    refkitPort?: SerialPort
    refkitIsConnected: boolean

    csmMessages: CSMMisoMessage[];
    csmCommands: CSMMosiMessage[];
    csmBleDevice?: BluetoothDevice;
    csmIsConnected: boolean;
}

interface Action {
    type: string;
    payload?: any;
}

interface MessageContextType extends State {

    addThimphuMessage: (message: ThimphuMessage) => void;
    consumeThimphuMessage: (messageId: string) => void;
    clearThimphuMessages: () => void;
    setThimphuPort: (port: SerialPort | undefined) => void;
    setThimphuIsConnected: (isConnected: boolean) => void;

    addRefkitMessage: (message: RefkitMessage) => void;
    consumeRefkitMessage: (messageId: string) => void;
    clearRefkitMessages: () => void;
    setRefkitPort: (port: SerialPort | undefined) => void;
    setRefkitIsConnected: (isConnected: boolean) => void;

    addCSMMessage: (message: CSMMisoMessage) => void;
    consumeCSMMessage: (messageId: string) => void;
    clearCSMMessages: () => void;
    addCSMCommand: (message: CSMMosiMessage) => void;
    consumeCSMCommand: (messageId: string) => void;
    clearCSMCommands: () => void;
    setCSMBleDevice: (device: BluetoothDevice | undefined) => void;
    setCSMIsConnected: (isConnected: boolean) => void;

}

const initialState: State = {
    thimphuMessages: [],
    thimphuIsConnected: false,

    refkitMessages: [],
    refkitIsConnected: false,

    csmMessages: [],
    csmCommands: [],
    csmIsConnected: false,

};

function messageReducer(state: State, action: Action): State {
    switch (action.type) {

    // THIMPHU
    case 'ADD_THIMPHU_MESSAGE':
        let newThimphuMessages = [...state.thimphuMessages, action.payload]
        if (newThimphuMessages.length > MAX_MESSAGE_QUEUE_SIZE) {
            newThimphuMessages.shift()
        }
        return {
        ...state,
        thimphuMessages: newThimphuMessages,
        };
    case 'CONSUME_THIMPHU_MESSAGE':
        const messageId = action.payload;
        const updatedMessages = state.thimphuMessages.filter(
            (message) => message.id !== messageId
        );
        return {
            ...state,
            thimphuMessages: updatedMessages,
        };
    case 'SET_THIMPHU_PORT':
        return {
            ...state,
            thimphuPort: action.payload
        }
    case 'SET_THIMPHU_IS_CONNECTED':
        return {
            ...state,
            thimphuIsConnected: action.payload
        }
    case 'CLEAR_THIMPHU_MESSAGES':
        return {
            ...state,
            thimphuMessages: [],
        };

    // REFKIT
    case 'ADD_REFKIT_MESSAGE':
        let newRefkitMessages = [...state.refkitMessages, action.payload]
        if (newRefkitMessages.length > MAX_MESSAGE_QUEUE_SIZE) {
            newRefkitMessages.shift()
        }
        return {
        ...state,
        refkitMessages: newRefkitMessages,
        }
    case 'CONSUME_REFKIT_MESSAGE':
        const refkitMessageId = action.payload;
        const updatedRefkitMessages = state.refkitMessages.filter(
            (message) => message.id !== refkitMessageId
        );
        return {
            ...state,
            refkitMessages: updatedRefkitMessages,
        };
    case 'SET_REFKIT_PORT':
        return {
            ...state,
            refkitPort: action.payload
        }
    case 'SET_REFKIT_IS_CONNECTED':
        return {
            ...state,
            refkitIsConnected: action.payload
        }
    case 'CLEAR_REFKIT_MESSAGES':
        return {
            ...state,
            refkitMessages: [],
        };
    
    // CSM
    case 'ADD_CSM_MESSAGE':
        let newCsmMessages = [...state.csmMessages, action.payload]
        if (newCsmMessages.length > MAX_MESSAGE_QUEUE_SIZE) {
            newCsmMessages.shift()
        }
        return {
            ...state,
            csmMessages: newCsmMessages,
        };
    case 'CONSUME_CSM_MESSAGE':
        const csmMessageId = action.payload;
        const updatedCSMMessages = state.csmMessages.filter(
            (message) => message.id !== csmMessageId
        );
        return {
            ...state,
            csmMessages: updatedCSMMessages,
        };
    case 'SET_CSM_BLE_DEVICE':
        return {
            ...state,
            csmBleDevice: action.payload
        }
    case 'SET_CSM_IS_CONNECTED':
        return {
            ...state,
            csmIsConnected: action.payload
        }
    case 'CLEAR_CSM_MESSAGES':
        return {
            ...state,
            csmMessages: [],
        };
    case 'ADD_CSM_COMMAND':
        let newCsmCommands = [...state.csmCommands, action.payload]
        if (newCsmCommands.length > MAX_MESSAGE_QUEUE_SIZE) {
            newCsmCommands.shift()
        }
        return {
            ...state,
            csmCommands: newCsmCommands,
        };
    case 'CONSUME_CSM_COMMAND':
        const csmCommandId = action.payload;
        const updatedCSMCommands = state.csmCommands.filter(
            (message) => message.id !== csmCommandId
        );
        return {
            ...state,
            csmCommands: updatedCSMCommands,
        };
    case 'CLEAR_CSM_COMMANDS':
        return {
            ...state,
            csmCommands: [],
        };

    default:
        return state;
    }
}

export const MessageContext = createContext<MessageContextType | undefined>(undefined);

export function useMessageContext(): MessageContextType {
    const context = useContext(MessageContext);
    if (!context) {
        throw new Error('useMessageContext must be used within a MessageProvider');
    }
    return context;
}

export function MessageProvider({ children }: { children: React.ReactNode }) {
    const [state, dispatch] = useReducer(messageReducer, initialState);

    // THIMPHU
    const addThimphuMessage = (message: ThimphuMessage) => {
        dispatch({ type: 'ADD_THIMPHU_MESSAGE', payload: message });
    };
    const consumeThimphuMessage = (messageId: string) => {
        dispatch({ type: 'CONSUME_THIMPHU_MESSAGE', payload: messageId });
    };
    const setThimphuPort = (port: SerialPort | undefined) => {
        dispatch({ type: 'SET_THIMPHU_PORT', payload: port })
    }
    const setThimphuIsConnected = (isConnected: boolean) => {
        dispatch({ type: 'SET_THIMPHU_IS_CONNECTED', payload: isConnected })
    }
    const clearThimphuMessages = () => {
        dispatch({ type: 'CLEAR_THIMPHU_MESSAGES' });
    };

    // REFKIT
    const addRefkitMessage = (message: RefkitMessage) => {
        dispatch({ type: 'ADD_REFKIT_MESSAGE', payload: message });
    };
    const consumeRefkitMessage = (messageId: string) => {
        dispatch({ type: 'CONSUME_REFKIT_MESSAGE', payload: messageId });
    };
    const setRefkitPort = (port: SerialPort | undefined) => {
        dispatch({ type: 'SET_REFKIT_PORT', payload: port })
    }
    const setRefkitIsConnected = (isConnected: boolean) => {
        dispatch({ type: 'SET_REFKIT_IS_CONNECTED', payload: isConnected })
    }
    const clearRefkitMessages = () => {
        dispatch({ type: 'CLEAR_REFKIT_MESSAGES' });
    };

    // CSM
    const addCSMMessage = (message: CSMMisoMessage) => {
        dispatch({ type: 'ADD_CSM_MESSAGE', payload: message });
    };
    const consumeCSMMessage = (messageId: string) => {
        dispatch({ type: 'CONSUME_CSM_MESSAGE', payload: messageId });
    };
    const setCSMBleDevice = (device: BluetoothDevice | undefined) => {
        dispatch({ type: 'SET_CSM_BLE_DEVICE', payload: device })
    }
    const setCSMIsConnected = (isConnected: boolean) => {
        dispatch({ type: 'SET_CSM_IS_CONNECTED', payload: isConnected })
    }
    const clearCSMMessages = () => {
        dispatch({ type: 'CLEAR_CSM_MESSAGES' });
    };
    const addCSMCommand = (message: CSMMosiMessage) => {
        dispatch({ type: 'ADD_CSM_COMMAND', payload: message });
    };
    const consumeCSMCommand = (messageId: string) => {
        dispatch({ type: 'CONSUME_CSM_COMMAND', payload: messageId });
    };
    const clearCSMCommands = () => {
        dispatch({ type: 'CLEAR_CSM_COMMANDS' });
    };

    const contextValue: MessageContextType = {
        
        // THIMPHU
        thimphuMessages: state.thimphuMessages,
        thimphuPort: state.thimphuPort,
        thimphuIsConnected: state.thimphuIsConnected,
        
        addThimphuMessage,
        consumeThimphuMessage,
        setThimphuPort,
        setThimphuIsConnected,
        clearThimphuMessages,
        
        // REFKIT
        refkitMessages: state.refkitMessages,
        refkitPort: state.refkitPort,
        refkitIsConnected: state.refkitIsConnected,

        addRefkitMessage,
        consumeRefkitMessage,
        setRefkitPort,
        setRefkitIsConnected,
        clearRefkitMessages,
        
        // CSM
        csmMessages: state.csmMessages,
        csmCommands: state.csmCommands,
        csmBleDevice: state.csmBleDevice,
        csmIsConnected: state.csmIsConnected,

        addCSMMessage,
        consumeCSMMessage,
        clearCSMMessages,
        addCSMCommand,
        consumeCSMCommand,
        clearCSMCommands,
        setCSMBleDevice,
        setCSMIsConnected,
    };

    return <MessageContext.Provider value={contextValue}>
        {children}
    </MessageContext.Provider>
}