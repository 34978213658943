
export const NEOSE_SERVICE_UUID = 0x181A
export const NEOSE_MOSI_CHARACTERISTIC_UUID = "856d000b-20cc-4bfe-a6c5-9e6fe705d6c0"
export const NEOSE_MISO_CHARACTERISTIC_UUID = "169e9389-17df-44f1-8e56-c5de9992ba47"
export const NEOSE_HRBT_CHARACTERISTIC_UUID = "b04ce747-af00-48c8-8612-a00f06452d3b"

export const navigatorSupportsWebBle = () => {
    return 'bluetooth' in navigator
}

export const getBleDeviceCandidates = async () => {
    if (!navigatorSupportsWebBle()) {
        throw new Error('Web Bluetooth is not supported')
    }
    const devices = await navigator.bluetooth.getDevices()
    let candidateDevices: (BluetoothDevice | null)[] = []
    for (let i = 0; i < devices.length; i++) {
        let d = devices[i]
        if (d.name && d.name.startsWith('Aryballe')) {
            candidateDevices.push(d)
        }
    }
    return devices
}

export const requestBleDevice = async () => {
    if (!navigatorSupportsWebBle()) {
        throw new Error('Web Bluetooth is not supported')
    }
    const device = await navigator.bluetooth.requestDevice({
        filters: [
            { namePrefix: 'Aryballe'}
        ],
        optionalServices: [NEOSE_SERVICE_UUID]
    })
    return device
}

export const getBleService = async (device: BluetoothDevice) => {
    if (device.gatt === null || device.gatt === undefined) {
        throw new Error('connectBle: device.gatt is null or undefined')
    }
    try {
        void await device.watchAdvertisements()
    } catch(e: any) {
        console.log('watchAdvertisements failed', e.message)
    }
    let gatt = await device.gatt.connect()
    let service = await gatt.getPrimaryService(NEOSE_SERVICE_UUID)
    return service
}

export const getHeartbeatCharacteristic = async (service: BluetoothRemoteGATTService) => {
    let characteristic = await service.getCharacteristic(NEOSE_HRBT_CHARACTERISTIC_UUID)
    return characteristic
}

export const getMosiCharacteristic = async (service: BluetoothRemoteGATTService) => {
    let characteristic = await service.getCharacteristic(NEOSE_MOSI_CHARACTERISTIC_UUID)
    return characteristic
}

export const getMisoCharacteristic = async (service: BluetoothRemoteGATTService) => {
    let characteristic = await service.getCharacteristic(NEOSE_MISO_CHARACTERISTIC_UUID)
    return characteristic
}

export const csmMosiWriteToCharacteristic = async (
    characteristic: BluetoothRemoteGATTCharacteristic,
    mosiFrame: Uint8Array
) => {
    try {
        void await characteristic.writeValue(mosiFrame)
    } catch(e: any) {
        console.warn("csmMosiWriteToCharacteristic: ", e)
    }
}
