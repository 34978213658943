import { FC, useEffect, useRef, useState } from "react";
import { useMessageContext } from "../reducers/messageContext";
import { IMAGE_HEIGHT, IMAGE_WIDTH } from "../constants";
import { Button, Progress } from "antd";
import { refkitProtocolReadMemoryImage, refkitProtocolSingleShot } from "../serial/refkit";
import { FlexCol } from "../common";

export const SingleShotImageWidget: FC = () => {

    const { refkitPort, refkitMessages, consumeRefkitMessage } = useMessageContext();
    const [ offset, setOffset ] = useState<number>(0)
    const [ isDone, setIsDone ] = useState<boolean>(true)

    const canvasRef = useRef<HTMLCanvasElement>(null)
    const canvasCtxRef = useRef<CanvasRenderingContext2D | null>(null)

    const imageLength = IMAGE_WIDTH*IMAGE_HEIGHT

    useEffect(() => {
        if (refkitPort === undefined) {
            return
        }
        for (let message of refkitMessages) {
            if (
                message.message.result &&
                message.message.result.singleShot
            ) {
                consumeRefkitMessage(message.id)
                setOffset(0)
                setIsDone(false)
                if (canvasRef.current) {
                    let ctx = canvasRef.current.getContext('2d', {
                        willReadFrequently: true
                    })
                    if (ctx !== null) {
                        ctx.clearRect(0, 0, IMAGE_WIDTH, IMAGE_HEIGHT)
                        canvasCtxRef.current = ctx
                    }
                }
                refkitProtocolReadMemoryImage(refkitPort, 0)
            }
            if (
                !isDone && 
                message.message.result &&
                message.message.result.memoryData
            ) {
                consumeRefkitMessage(message.id)
                let data = message.message.result.memoryData.data
                if (data && data.byteLength > 0) {
                    let newOffset = offset + data.byteLength
                    if (canvasRef.current) {
                        let ctx = canvasCtxRef.current
                        if (ctx === null) {
                            return
                        }
                        let imageData = ctx.getImageData(0, 0, IMAGE_WIDTH, IMAGE_HEIGHT)
                        let clampedData = new Uint8ClampedArray(data.length*4)
                        for (let i = 0; i < data.length; i++) {
                            clampedData.set([data[i], data[i], data[i], 255], i*4)
                        }
                        imageData.data.set(clampedData, offset*4)
                        void ctx.putImageData(imageData, 0, 0)
                    }
                    if (newOffset >= imageLength) {
                        setIsDone(true)
                        console.log('image loaded!')
                        return
                    } else {
                        refkitProtocolReadMemoryImage(refkitPort, newOffset)
                        setOffset(newOffset)
                    }
                }
            }
        }
    }, [refkitMessages])

    let p = Math.round(((offset) / (imageLength-1024) * 100))
    return <FlexCol style={{
        width: '100%',
    }}>
        <Button onClick={async () => {
            if (refkitPort) {
                void await refkitProtocolSingleShot(refkitPort)
            }
        }}>Load</Button>

        <Progress percent={p}  style={{
            width: '100%',
            transitionDuration: '0.05s !important'
        }}/>

        <canvas ref={canvasRef} width={IMAGE_WIDTH} height={IMAGE_HEIGHT} style={{
            width: '100%',
        }}/>
    </FlexCol>
}