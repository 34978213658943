/*eslint-disable block-scoped-var, id-length, no-control-regex, no-magic-numbers, no-prototype-builtins, no-redeclare, no-shadow, no-var, sort-vars*/
import * as $protobuf from "protobufjs/minimal";

// Common aliases
const $Reader = $protobuf.Reader, $Writer = $protobuf.Writer, $util = $protobuf.util;

// Exported root namespace
const $root = $protobuf.roots["default"] || ($protobuf.roots["default"] = {});

export const RefDesignProtocol = $root.RefDesignProtocol = (() => {

    /**
     * Namespace RefDesignProtocol.
     * @exports RefDesignProtocol
     * @namespace
     */
    const RefDesignProtocol = {};

    /**
     * ProtocolVersion enum.
     * @name RefDesignProtocol.ProtocolVersion
     * @enum {number}
     * @property {number} NOT_USED=0 NOT_USED value
     * @property {number} VERSION=7 VERSION value
     */
    RefDesignProtocol.ProtocolVersion = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "NOT_USED"] = 0;
        values[valuesById[7] = "VERSION"] = 7;
        return values;
    })();

    /**
     * ImageEncoding enum.
     * @name RefDesignProtocol.ImageEncoding
     * @enum {number}
     * @property {number} PIXEL_DEPTH_8_BITS=0 PIXEL_DEPTH_8_BITS value
     * @property {number} PIXEL_DEPTH_10_OVER_16_BITS=1 PIXEL_DEPTH_10_OVER_16_BITS value
     */
    RefDesignProtocol.ImageEncoding = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "PIXEL_DEPTH_8_BITS"] = 0;
        values[valuesById[1] = "PIXEL_DEPTH_10_OVER_16_BITS"] = 1;
        return values;
    })();

    RefDesignProtocol.PeakMask = (function() {

        /**
         * Properties of a PeakMask.
         * @memberof RefDesignProtocol
         * @interface IPeakMask
         * @property {number|null} [x] PeakMask x
         * @property {number|null} [y] PeakMask y
         * @property {number|null} [radius] PeakMask radius
         */

        /**
         * Constructs a new PeakMask.
         * @memberof RefDesignProtocol
         * @classdesc Represents a PeakMask.
         * @implements IPeakMask
         * @constructor
         * @param {RefDesignProtocol.IPeakMask=} [properties] Properties to set
         */
        function PeakMask(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * PeakMask x.
         * @member {number} x
         * @memberof RefDesignProtocol.PeakMask
         * @instance
         */
        PeakMask.prototype.x = 0;

        /**
         * PeakMask y.
         * @member {number} y
         * @memberof RefDesignProtocol.PeakMask
         * @instance
         */
        PeakMask.prototype.y = 0;

        /**
         * PeakMask radius.
         * @member {number} radius
         * @memberof RefDesignProtocol.PeakMask
         * @instance
         */
        PeakMask.prototype.radius = 0;

        /**
         * Creates a new PeakMask instance using the specified properties.
         * @function create
         * @memberof RefDesignProtocol.PeakMask
         * @static
         * @param {RefDesignProtocol.IPeakMask=} [properties] Properties to set
         * @returns {RefDesignProtocol.PeakMask} PeakMask instance
         */
        PeakMask.create = function create(properties) {
            return new PeakMask(properties);
        };

        /**
         * Encodes the specified PeakMask message. Does not implicitly {@link RefDesignProtocol.PeakMask.verify|verify} messages.
         * @function encode
         * @memberof RefDesignProtocol.PeakMask
         * @static
         * @param {RefDesignProtocol.IPeakMask} message PeakMask message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        PeakMask.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.x != null && Object.hasOwnProperty.call(message, "x"))
                writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.x);
            if (message.y != null && Object.hasOwnProperty.call(message, "y"))
                writer.uint32(/* id 2, wireType 0 =*/16).uint32(message.y);
            if (message.radius != null && Object.hasOwnProperty.call(message, "radius"))
                writer.uint32(/* id 3, wireType 0 =*/24).uint32(message.radius);
            return writer;
        };

        /**
         * Encodes the specified PeakMask message, length delimited. Does not implicitly {@link RefDesignProtocol.PeakMask.verify|verify} messages.
         * @function encodeDelimited
         * @memberof RefDesignProtocol.PeakMask
         * @static
         * @param {RefDesignProtocol.IPeakMask} message PeakMask message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        PeakMask.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a PeakMask message from the specified reader or buffer.
         * @function decode
         * @memberof RefDesignProtocol.PeakMask
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {RefDesignProtocol.PeakMask} PeakMask
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        PeakMask.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.RefDesignProtocol.PeakMask();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.x = reader.uint32();
                        break;
                    }
                case 2: {
                        message.y = reader.uint32();
                        break;
                    }
                case 3: {
                        message.radius = reader.uint32();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a PeakMask message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof RefDesignProtocol.PeakMask
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {RefDesignProtocol.PeakMask} PeakMask
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        PeakMask.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a PeakMask message.
         * @function verify
         * @memberof RefDesignProtocol.PeakMask
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        PeakMask.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.x != null && message.hasOwnProperty("x"))
                if (!$util.isInteger(message.x))
                    return "x: integer expected";
            if (message.y != null && message.hasOwnProperty("y"))
                if (!$util.isInteger(message.y))
                    return "y: integer expected";
            if (message.radius != null && message.hasOwnProperty("radius"))
                if (!$util.isInteger(message.radius))
                    return "radius: integer expected";
            return null;
        };

        /**
         * Creates a PeakMask message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof RefDesignProtocol.PeakMask
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {RefDesignProtocol.PeakMask} PeakMask
         */
        PeakMask.fromObject = function fromObject(object) {
            if (object instanceof $root.RefDesignProtocol.PeakMask)
                return object;
            let message = new $root.RefDesignProtocol.PeakMask();
            if (object.x != null)
                message.x = object.x >>> 0;
            if (object.y != null)
                message.y = object.y >>> 0;
            if (object.radius != null)
                message.radius = object.radius >>> 0;
            return message;
        };

        /**
         * Creates a plain object from a PeakMask message. Also converts values to other types if specified.
         * @function toObject
         * @memberof RefDesignProtocol.PeakMask
         * @static
         * @param {RefDesignProtocol.PeakMask} message PeakMask
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        PeakMask.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.x = 0;
                object.y = 0;
                object.radius = 0;
            }
            if (message.x != null && message.hasOwnProperty("x"))
                object.x = message.x;
            if (message.y != null && message.hasOwnProperty("y"))
                object.y = message.y;
            if (message.radius != null && message.hasOwnProperty("radius"))
                object.radius = message.radius;
            return object;
        };

        /**
         * Converts this PeakMask to JSON.
         * @function toJSON
         * @memberof RefDesignProtocol.PeakMask
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        PeakMask.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for PeakMask
         * @function getTypeUrl
         * @memberof RefDesignProtocol.PeakMask
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        PeakMask.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/RefDesignProtocol.PeakMask";
        };

        return PeakMask;
    })();

    RefDesignProtocol.PeakPhasisCalib = (function() {

        /**
         * Properties of a PeakPhasisCalib.
         * @memberof RefDesignProtocol
         * @interface IPeakPhasisCalib
         * @property {number|null} [gain] PeakPhasisCalib gain
         * @property {number|null} [offset] PeakPhasisCalib offset
         */

        /**
         * Constructs a new PeakPhasisCalib.
         * @memberof RefDesignProtocol
         * @classdesc Represents a PeakPhasisCalib.
         * @implements IPeakPhasisCalib
         * @constructor
         * @param {RefDesignProtocol.IPeakPhasisCalib=} [properties] Properties to set
         */
        function PeakPhasisCalib(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * PeakPhasisCalib gain.
         * @member {number} gain
         * @memberof RefDesignProtocol.PeakPhasisCalib
         * @instance
         */
        PeakPhasisCalib.prototype.gain = 0;

        /**
         * PeakPhasisCalib offset.
         * @member {number} offset
         * @memberof RefDesignProtocol.PeakPhasisCalib
         * @instance
         */
        PeakPhasisCalib.prototype.offset = 0;

        /**
         * Creates a new PeakPhasisCalib instance using the specified properties.
         * @function create
         * @memberof RefDesignProtocol.PeakPhasisCalib
         * @static
         * @param {RefDesignProtocol.IPeakPhasisCalib=} [properties] Properties to set
         * @returns {RefDesignProtocol.PeakPhasisCalib} PeakPhasisCalib instance
         */
        PeakPhasisCalib.create = function create(properties) {
            return new PeakPhasisCalib(properties);
        };

        /**
         * Encodes the specified PeakPhasisCalib message. Does not implicitly {@link RefDesignProtocol.PeakPhasisCalib.verify|verify} messages.
         * @function encode
         * @memberof RefDesignProtocol.PeakPhasisCalib
         * @static
         * @param {RefDesignProtocol.IPeakPhasisCalib} message PeakPhasisCalib message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        PeakPhasisCalib.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.gain != null && Object.hasOwnProperty.call(message, "gain"))
                writer.uint32(/* id 1, wireType 5 =*/13).float(message.gain);
            if (message.offset != null && Object.hasOwnProperty.call(message, "offset"))
                writer.uint32(/* id 2, wireType 0 =*/16).uint32(message.offset);
            return writer;
        };

        /**
         * Encodes the specified PeakPhasisCalib message, length delimited. Does not implicitly {@link RefDesignProtocol.PeakPhasisCalib.verify|verify} messages.
         * @function encodeDelimited
         * @memberof RefDesignProtocol.PeakPhasisCalib
         * @static
         * @param {RefDesignProtocol.IPeakPhasisCalib} message PeakPhasisCalib message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        PeakPhasisCalib.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a PeakPhasisCalib message from the specified reader or buffer.
         * @function decode
         * @memberof RefDesignProtocol.PeakPhasisCalib
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {RefDesignProtocol.PeakPhasisCalib} PeakPhasisCalib
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        PeakPhasisCalib.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.RefDesignProtocol.PeakPhasisCalib();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.gain = reader.float();
                        break;
                    }
                case 2: {
                        message.offset = reader.uint32();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a PeakPhasisCalib message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof RefDesignProtocol.PeakPhasisCalib
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {RefDesignProtocol.PeakPhasisCalib} PeakPhasisCalib
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        PeakPhasisCalib.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a PeakPhasisCalib message.
         * @function verify
         * @memberof RefDesignProtocol.PeakPhasisCalib
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        PeakPhasisCalib.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.gain != null && message.hasOwnProperty("gain"))
                if (typeof message.gain !== "number")
                    return "gain: number expected";
            if (message.offset != null && message.hasOwnProperty("offset"))
                if (!$util.isInteger(message.offset))
                    return "offset: integer expected";
            return null;
        };

        /**
         * Creates a PeakPhasisCalib message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof RefDesignProtocol.PeakPhasisCalib
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {RefDesignProtocol.PeakPhasisCalib} PeakPhasisCalib
         */
        PeakPhasisCalib.fromObject = function fromObject(object) {
            if (object instanceof $root.RefDesignProtocol.PeakPhasisCalib)
                return object;
            let message = new $root.RefDesignProtocol.PeakPhasisCalib();
            if (object.gain != null)
                message.gain = Number(object.gain);
            if (object.offset != null)
                message.offset = object.offset >>> 0;
            return message;
        };

        /**
         * Creates a plain object from a PeakPhasisCalib message. Also converts values to other types if specified.
         * @function toObject
         * @memberof RefDesignProtocol.PeakPhasisCalib
         * @static
         * @param {RefDesignProtocol.PeakPhasisCalib} message PeakPhasisCalib
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        PeakPhasisCalib.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.gain = 0;
                object.offset = 0;
            }
            if (message.gain != null && message.hasOwnProperty("gain"))
                object.gain = options.json && !isFinite(message.gain) ? String(message.gain) : message.gain;
            if (message.offset != null && message.hasOwnProperty("offset"))
                object.offset = message.offset;
            return object;
        };

        /**
         * Converts this PeakPhasisCalib to JSON.
         * @function toJSON
         * @memberof RefDesignProtocol.PeakPhasisCalib
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        PeakPhasisCalib.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for PeakPhasisCalib
         * @function getTypeUrl
         * @memberof RefDesignProtocol.PeakPhasisCalib
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        PeakPhasisCalib.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/RefDesignProtocol.PeakPhasisCalib";
        };

        return PeakPhasisCalib;
    })();

    /**
     * CameraResolution enum.
     * @name RefDesignProtocol.CameraResolution
     * @enum {number}
     * @property {number} ResolutionUnknown=0 ResolutionUnknown value
     * @property {number} Resolution1280x800=1 Resolution1280x800 value
     * @property {number} Resolution640x400=2 Resolution640x400 value
     */
    RefDesignProtocol.CameraResolution = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "ResolutionUnknown"] = 0;
        values[valuesById[1] = "Resolution1280x800"] = 1;
        values[valuesById[2] = "Resolution640x400"] = 2;
        return values;
    })();

    RefDesignProtocol.ConfigData = (function() {

        /**
         * Properties of a ConfigData.
         * @memberof RefDesignProtocol
         * @interface IConfigData
         * @property {number|null} [cameraExposure] ConfigData cameraExposure
         * @property {number|null} [cameraFrequency] ConfigData cameraFrequency
         * @property {RefDesignProtocol.CameraResolution|null} [cameraResolution] ConfigData cameraResolution
         * @property {number|null} [cameraPixelDepth] ConfigData cameraPixelDepth
         * @property {Array.<RefDesignProtocol.IPeakMask>|null} [peakMasks] ConfigData peakMasks
         * @property {Array.<RefDesignProtocol.IPeakPhasisCalib>|null} [peakPhasisCalib] ConfigData peakPhasisCalib
         */

        /**
         * Constructs a new ConfigData.
         * @memberof RefDesignProtocol
         * @classdesc Represents a ConfigData.
         * @implements IConfigData
         * @constructor
         * @param {RefDesignProtocol.IConfigData=} [properties] Properties to set
         */
        function ConfigData(properties) {
            this.peakMasks = [];
            this.peakPhasisCalib = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ConfigData cameraExposure.
         * @member {number} cameraExposure
         * @memberof RefDesignProtocol.ConfigData
         * @instance
         */
        ConfigData.prototype.cameraExposure = 0;

        /**
         * ConfigData cameraFrequency.
         * @member {number} cameraFrequency
         * @memberof RefDesignProtocol.ConfigData
         * @instance
         */
        ConfigData.prototype.cameraFrequency = 0;

        /**
         * ConfigData cameraResolution.
         * @member {RefDesignProtocol.CameraResolution} cameraResolution
         * @memberof RefDesignProtocol.ConfigData
         * @instance
         */
        ConfigData.prototype.cameraResolution = 0;

        /**
         * ConfigData cameraPixelDepth.
         * @member {number} cameraPixelDepth
         * @memberof RefDesignProtocol.ConfigData
         * @instance
         */
        ConfigData.prototype.cameraPixelDepth = 0;

        /**
         * ConfigData peakMasks.
         * @member {Array.<RefDesignProtocol.IPeakMask>} peakMasks
         * @memberof RefDesignProtocol.ConfigData
         * @instance
         */
        ConfigData.prototype.peakMasks = $util.emptyArray;

        /**
         * ConfigData peakPhasisCalib.
         * @member {Array.<RefDesignProtocol.IPeakPhasisCalib>} peakPhasisCalib
         * @memberof RefDesignProtocol.ConfigData
         * @instance
         */
        ConfigData.prototype.peakPhasisCalib = $util.emptyArray;

        /**
         * Creates a new ConfigData instance using the specified properties.
         * @function create
         * @memberof RefDesignProtocol.ConfigData
         * @static
         * @param {RefDesignProtocol.IConfigData=} [properties] Properties to set
         * @returns {RefDesignProtocol.ConfigData} ConfigData instance
         */
        ConfigData.create = function create(properties) {
            return new ConfigData(properties);
        };

        /**
         * Encodes the specified ConfigData message. Does not implicitly {@link RefDesignProtocol.ConfigData.verify|verify} messages.
         * @function encode
         * @memberof RefDesignProtocol.ConfigData
         * @static
         * @param {RefDesignProtocol.IConfigData} message ConfigData message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ConfigData.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.cameraExposure != null && Object.hasOwnProperty.call(message, "cameraExposure"))
                writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.cameraExposure);
            if (message.cameraFrequency != null && Object.hasOwnProperty.call(message, "cameraFrequency"))
                writer.uint32(/* id 2, wireType 0 =*/16).uint32(message.cameraFrequency);
            if (message.cameraResolution != null && Object.hasOwnProperty.call(message, "cameraResolution"))
                writer.uint32(/* id 3, wireType 0 =*/24).int32(message.cameraResolution);
            if (message.cameraPixelDepth != null && Object.hasOwnProperty.call(message, "cameraPixelDepth"))
                writer.uint32(/* id 4, wireType 0 =*/32).uint32(message.cameraPixelDepth);
            if (message.peakMasks != null && message.peakMasks.length)
                for (let i = 0; i < message.peakMasks.length; ++i)
                    $root.RefDesignProtocol.PeakMask.encode(message.peakMasks[i], writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
            if (message.peakPhasisCalib != null && message.peakPhasisCalib.length)
                for (let i = 0; i < message.peakPhasisCalib.length; ++i)
                    $root.RefDesignProtocol.PeakPhasisCalib.encode(message.peakPhasisCalib[i], writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified ConfigData message, length delimited. Does not implicitly {@link RefDesignProtocol.ConfigData.verify|verify} messages.
         * @function encodeDelimited
         * @memberof RefDesignProtocol.ConfigData
         * @static
         * @param {RefDesignProtocol.IConfigData} message ConfigData message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ConfigData.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a ConfigData message from the specified reader or buffer.
         * @function decode
         * @memberof RefDesignProtocol.ConfigData
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {RefDesignProtocol.ConfigData} ConfigData
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ConfigData.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.RefDesignProtocol.ConfigData();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.cameraExposure = reader.uint32();
                        break;
                    }
                case 2: {
                        message.cameraFrequency = reader.uint32();
                        break;
                    }
                case 3: {
                        message.cameraResolution = reader.int32();
                        break;
                    }
                case 4: {
                        message.cameraPixelDepth = reader.uint32();
                        break;
                    }
                case 5: {
                        if (!(message.peakMasks && message.peakMasks.length))
                            message.peakMasks = [];
                        message.peakMasks.push($root.RefDesignProtocol.PeakMask.decode(reader, reader.uint32()));
                        break;
                    }
                case 6: {
                        if (!(message.peakPhasisCalib && message.peakPhasisCalib.length))
                            message.peakPhasisCalib = [];
                        message.peakPhasisCalib.push($root.RefDesignProtocol.PeakPhasisCalib.decode(reader, reader.uint32()));
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a ConfigData message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof RefDesignProtocol.ConfigData
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {RefDesignProtocol.ConfigData} ConfigData
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ConfigData.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a ConfigData message.
         * @function verify
         * @memberof RefDesignProtocol.ConfigData
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ConfigData.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.cameraExposure != null && message.hasOwnProperty("cameraExposure"))
                if (!$util.isInteger(message.cameraExposure))
                    return "cameraExposure: integer expected";
            if (message.cameraFrequency != null && message.hasOwnProperty("cameraFrequency"))
                if (!$util.isInteger(message.cameraFrequency))
                    return "cameraFrequency: integer expected";
            if (message.cameraResolution != null && message.hasOwnProperty("cameraResolution"))
                switch (message.cameraResolution) {
                default:
                    return "cameraResolution: enum value expected";
                case 0:
                case 1:
                case 2:
                    break;
                }
            if (message.cameraPixelDepth != null && message.hasOwnProperty("cameraPixelDepth"))
                if (!$util.isInteger(message.cameraPixelDepth))
                    return "cameraPixelDepth: integer expected";
            if (message.peakMasks != null && message.hasOwnProperty("peakMasks")) {
                if (!Array.isArray(message.peakMasks))
                    return "peakMasks: array expected";
                for (let i = 0; i < message.peakMasks.length; ++i) {
                    let error = $root.RefDesignProtocol.PeakMask.verify(message.peakMasks[i]);
                    if (error)
                        return "peakMasks." + error;
                }
            }
            if (message.peakPhasisCalib != null && message.hasOwnProperty("peakPhasisCalib")) {
                if (!Array.isArray(message.peakPhasisCalib))
                    return "peakPhasisCalib: array expected";
                for (let i = 0; i < message.peakPhasisCalib.length; ++i) {
                    let error = $root.RefDesignProtocol.PeakPhasisCalib.verify(message.peakPhasisCalib[i]);
                    if (error)
                        return "peakPhasisCalib." + error;
                }
            }
            return null;
        };

        /**
         * Creates a ConfigData message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof RefDesignProtocol.ConfigData
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {RefDesignProtocol.ConfigData} ConfigData
         */
        ConfigData.fromObject = function fromObject(object) {
            if (object instanceof $root.RefDesignProtocol.ConfigData)
                return object;
            let message = new $root.RefDesignProtocol.ConfigData();
            if (object.cameraExposure != null)
                message.cameraExposure = object.cameraExposure >>> 0;
            if (object.cameraFrequency != null)
                message.cameraFrequency = object.cameraFrequency >>> 0;
            switch (object.cameraResolution) {
            default:
                if (typeof object.cameraResolution === "number") {
                    message.cameraResolution = object.cameraResolution;
                    break;
                }
                break;
            case "ResolutionUnknown":
            case 0:
                message.cameraResolution = 0;
                break;
            case "Resolution1280x800":
            case 1:
                message.cameraResolution = 1;
                break;
            case "Resolution640x400":
            case 2:
                message.cameraResolution = 2;
                break;
            }
            if (object.cameraPixelDepth != null)
                message.cameraPixelDepth = object.cameraPixelDepth >>> 0;
            if (object.peakMasks) {
                if (!Array.isArray(object.peakMasks))
                    throw TypeError(".RefDesignProtocol.ConfigData.peakMasks: array expected");
                message.peakMasks = [];
                for (let i = 0; i < object.peakMasks.length; ++i) {
                    if (typeof object.peakMasks[i] !== "object")
                        throw TypeError(".RefDesignProtocol.ConfigData.peakMasks: object expected");
                    message.peakMasks[i] = $root.RefDesignProtocol.PeakMask.fromObject(object.peakMasks[i]);
                }
            }
            if (object.peakPhasisCalib) {
                if (!Array.isArray(object.peakPhasisCalib))
                    throw TypeError(".RefDesignProtocol.ConfigData.peakPhasisCalib: array expected");
                message.peakPhasisCalib = [];
                for (let i = 0; i < object.peakPhasisCalib.length; ++i) {
                    if (typeof object.peakPhasisCalib[i] !== "object")
                        throw TypeError(".RefDesignProtocol.ConfigData.peakPhasisCalib: object expected");
                    message.peakPhasisCalib[i] = $root.RefDesignProtocol.PeakPhasisCalib.fromObject(object.peakPhasisCalib[i]);
                }
            }
            return message;
        };

        /**
         * Creates a plain object from a ConfigData message. Also converts values to other types if specified.
         * @function toObject
         * @memberof RefDesignProtocol.ConfigData
         * @static
         * @param {RefDesignProtocol.ConfigData} message ConfigData
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ConfigData.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults) {
                object.peakMasks = [];
                object.peakPhasisCalib = [];
            }
            if (options.defaults) {
                object.cameraExposure = 0;
                object.cameraFrequency = 0;
                object.cameraResolution = options.enums === String ? "ResolutionUnknown" : 0;
                object.cameraPixelDepth = 0;
            }
            if (message.cameraExposure != null && message.hasOwnProperty("cameraExposure"))
                object.cameraExposure = message.cameraExposure;
            if (message.cameraFrequency != null && message.hasOwnProperty("cameraFrequency"))
                object.cameraFrequency = message.cameraFrequency;
            if (message.cameraResolution != null && message.hasOwnProperty("cameraResolution"))
                object.cameraResolution = options.enums === String ? $root.RefDesignProtocol.CameraResolution[message.cameraResolution] === undefined ? message.cameraResolution : $root.RefDesignProtocol.CameraResolution[message.cameraResolution] : message.cameraResolution;
            if (message.cameraPixelDepth != null && message.hasOwnProperty("cameraPixelDepth"))
                object.cameraPixelDepth = message.cameraPixelDepth;
            if (message.peakMasks && message.peakMasks.length) {
                object.peakMasks = [];
                for (let j = 0; j < message.peakMasks.length; ++j)
                    object.peakMasks[j] = $root.RefDesignProtocol.PeakMask.toObject(message.peakMasks[j], options);
            }
            if (message.peakPhasisCalib && message.peakPhasisCalib.length) {
                object.peakPhasisCalib = [];
                for (let j = 0; j < message.peakPhasisCalib.length; ++j)
                    object.peakPhasisCalib[j] = $root.RefDesignProtocol.PeakPhasisCalib.toObject(message.peakPhasisCalib[j], options);
            }
            return object;
        };

        /**
         * Converts this ConfigData to JSON.
         * @function toJSON
         * @memberof RefDesignProtocol.ConfigData
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ConfigData.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for ConfigData
         * @function getTypeUrl
         * @memberof RefDesignProtocol.ConfigData
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        ConfigData.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/RefDesignProtocol.ConfigData";
        };

        return ConfigData;
    })();

    /**
     * MemoryBank enum.
     * @name RefDesignProtocol.MemoryBank
     * @enum {number}
     * @property {number} MemoryMain=0 MemoryMain value
     * @property {number} MemoryCoreSensor=1 MemoryCoreSensor value
     * @property {number} MemoryImage=2 MemoryImage value
     * @property {number} MemoryAlgo=3 MemoryAlgo value
     * @property {number} MemoryThimphu=4 MemoryThimphu value
     */
    RefDesignProtocol.MemoryBank = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "MemoryMain"] = 0;
        values[valuesById[1] = "MemoryCoreSensor"] = 1;
        values[valuesById[2] = "MemoryImage"] = 2;
        values[valuesById[3] = "MemoryAlgo"] = 3;
        values[valuesById[4] = "MemoryThimphu"] = 4;
        return values;
    })();

    RefDesignProtocol.Hih6120 = (function() {

        /**
         * Properties of a Hih6120.
         * @memberof RefDesignProtocol
         * @interface IHih6120
         * @property {number|null} [humidity] Hih6120 humidity
         * @property {number|null} [temperature] Hih6120 temperature
         */

        /**
         * Constructs a new Hih6120.
         * @memberof RefDesignProtocol
         * @classdesc Represents a Hih6120.
         * @implements IHih6120
         * @constructor
         * @param {RefDesignProtocol.IHih6120=} [properties] Properties to set
         */
        function Hih6120(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Hih6120 humidity.
         * @member {number} humidity
         * @memberof RefDesignProtocol.Hih6120
         * @instance
         */
        Hih6120.prototype.humidity = 0;

        /**
         * Hih6120 temperature.
         * @member {number} temperature
         * @memberof RefDesignProtocol.Hih6120
         * @instance
         */
        Hih6120.prototype.temperature = 0;

        /**
         * Creates a new Hih6120 instance using the specified properties.
         * @function create
         * @memberof RefDesignProtocol.Hih6120
         * @static
         * @param {RefDesignProtocol.IHih6120=} [properties] Properties to set
         * @returns {RefDesignProtocol.Hih6120} Hih6120 instance
         */
        Hih6120.create = function create(properties) {
            return new Hih6120(properties);
        };

        /**
         * Encodes the specified Hih6120 message. Does not implicitly {@link RefDesignProtocol.Hih6120.verify|verify} messages.
         * @function encode
         * @memberof RefDesignProtocol.Hih6120
         * @static
         * @param {RefDesignProtocol.IHih6120} message Hih6120 message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Hih6120.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.humidity != null && Object.hasOwnProperty.call(message, "humidity"))
                writer.uint32(/* id 1, wireType 5 =*/13).float(message.humidity);
            if (message.temperature != null && Object.hasOwnProperty.call(message, "temperature"))
                writer.uint32(/* id 2, wireType 5 =*/21).float(message.temperature);
            return writer;
        };

        /**
         * Encodes the specified Hih6120 message, length delimited. Does not implicitly {@link RefDesignProtocol.Hih6120.verify|verify} messages.
         * @function encodeDelimited
         * @memberof RefDesignProtocol.Hih6120
         * @static
         * @param {RefDesignProtocol.IHih6120} message Hih6120 message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Hih6120.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a Hih6120 message from the specified reader or buffer.
         * @function decode
         * @memberof RefDesignProtocol.Hih6120
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {RefDesignProtocol.Hih6120} Hih6120
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Hih6120.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.RefDesignProtocol.Hih6120();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.humidity = reader.float();
                        break;
                    }
                case 2: {
                        message.temperature = reader.float();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a Hih6120 message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof RefDesignProtocol.Hih6120
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {RefDesignProtocol.Hih6120} Hih6120
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Hih6120.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a Hih6120 message.
         * @function verify
         * @memberof RefDesignProtocol.Hih6120
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        Hih6120.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.humidity != null && message.hasOwnProperty("humidity"))
                if (typeof message.humidity !== "number")
                    return "humidity: number expected";
            if (message.temperature != null && message.hasOwnProperty("temperature"))
                if (typeof message.temperature !== "number")
                    return "temperature: number expected";
            return null;
        };

        /**
         * Creates a Hih6120 message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof RefDesignProtocol.Hih6120
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {RefDesignProtocol.Hih6120} Hih6120
         */
        Hih6120.fromObject = function fromObject(object) {
            if (object instanceof $root.RefDesignProtocol.Hih6120)
                return object;
            let message = new $root.RefDesignProtocol.Hih6120();
            if (object.humidity != null)
                message.humidity = Number(object.humidity);
            if (object.temperature != null)
                message.temperature = Number(object.temperature);
            return message;
        };

        /**
         * Creates a plain object from a Hih6120 message. Also converts values to other types if specified.
         * @function toObject
         * @memberof RefDesignProtocol.Hih6120
         * @static
         * @param {RefDesignProtocol.Hih6120} message Hih6120
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        Hih6120.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.humidity = 0;
                object.temperature = 0;
            }
            if (message.humidity != null && message.hasOwnProperty("humidity"))
                object.humidity = options.json && !isFinite(message.humidity) ? String(message.humidity) : message.humidity;
            if (message.temperature != null && message.hasOwnProperty("temperature"))
                object.temperature = options.json && !isFinite(message.temperature) ? String(message.temperature) : message.temperature;
            return object;
        };

        /**
         * Converts this Hih6120 to JSON.
         * @function toJSON
         * @memberof RefDesignProtocol.Hih6120
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        Hih6120.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for Hih6120
         * @function getTypeUrl
         * @memberof RefDesignProtocol.Hih6120
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        Hih6120.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/RefDesignProtocol.Hih6120";
        };

        return Hih6120;
    })();

    RefDesignProtocol.AlgoOutputData = (function() {

        /**
         * Properties of an AlgoOutputData.
         * @memberof RefDesignProtocol
         * @interface IAlgoOutputData
         * @property {Array.<number>|null} [histogram] AlgoOutputData histogram
         * @property {Array.<number>|null} [peaks] AlgoOutputData peaks
         * @property {Array.<number>|null} [sensorgram] AlgoOutputData sensorgram
         * @property {Array.<number>|null} [iq] AlgoOutputData iq
         */

        /**
         * Constructs a new AlgoOutputData.
         * @memberof RefDesignProtocol
         * @classdesc Represents an AlgoOutputData.
         * @implements IAlgoOutputData
         * @constructor
         * @param {RefDesignProtocol.IAlgoOutputData=} [properties] Properties to set
         */
        function AlgoOutputData(properties) {
            this.histogram = [];
            this.peaks = [];
            this.sensorgram = [];
            this.iq = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * AlgoOutputData histogram.
         * @member {Array.<number>} histogram
         * @memberof RefDesignProtocol.AlgoOutputData
         * @instance
         */
        AlgoOutputData.prototype.histogram = $util.emptyArray;

        /**
         * AlgoOutputData peaks.
         * @member {Array.<number>} peaks
         * @memberof RefDesignProtocol.AlgoOutputData
         * @instance
         */
        AlgoOutputData.prototype.peaks = $util.emptyArray;

        /**
         * AlgoOutputData sensorgram.
         * @member {Array.<number>} sensorgram
         * @memberof RefDesignProtocol.AlgoOutputData
         * @instance
         */
        AlgoOutputData.prototype.sensorgram = $util.emptyArray;

        /**
         * AlgoOutputData iq.
         * @member {Array.<number>} iq
         * @memberof RefDesignProtocol.AlgoOutputData
         * @instance
         */
        AlgoOutputData.prototype.iq = $util.emptyArray;

        /**
         * Creates a new AlgoOutputData instance using the specified properties.
         * @function create
         * @memberof RefDesignProtocol.AlgoOutputData
         * @static
         * @param {RefDesignProtocol.IAlgoOutputData=} [properties] Properties to set
         * @returns {RefDesignProtocol.AlgoOutputData} AlgoOutputData instance
         */
        AlgoOutputData.create = function create(properties) {
            return new AlgoOutputData(properties);
        };

        /**
         * Encodes the specified AlgoOutputData message. Does not implicitly {@link RefDesignProtocol.AlgoOutputData.verify|verify} messages.
         * @function encode
         * @memberof RefDesignProtocol.AlgoOutputData
         * @static
         * @param {RefDesignProtocol.IAlgoOutputData} message AlgoOutputData message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AlgoOutputData.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.histogram != null && message.histogram.length) {
                writer.uint32(/* id 2, wireType 2 =*/18).fork();
                for (let i = 0; i < message.histogram.length; ++i)
                    writer.uint32(message.histogram[i]);
                writer.ldelim();
            }
            if (message.peaks != null && message.peaks.length) {
                writer.uint32(/* id 3, wireType 2 =*/26).fork();
                for (let i = 0; i < message.peaks.length; ++i)
                    writer.float(message.peaks[i]);
                writer.ldelim();
            }
            if (message.sensorgram != null && message.sensorgram.length) {
                writer.uint32(/* id 4, wireType 2 =*/34).fork();
                for (let i = 0; i < message.sensorgram.length; ++i)
                    writer.float(message.sensorgram[i]);
                writer.ldelim();
            }
            if (message.iq != null && message.iq.length) {
                writer.uint32(/* id 5, wireType 2 =*/42).fork();
                for (let i = 0; i < message.iq.length; ++i)
                    writer.float(message.iq[i]);
                writer.ldelim();
            }
            return writer;
        };

        /**
         * Encodes the specified AlgoOutputData message, length delimited. Does not implicitly {@link RefDesignProtocol.AlgoOutputData.verify|verify} messages.
         * @function encodeDelimited
         * @memberof RefDesignProtocol.AlgoOutputData
         * @static
         * @param {RefDesignProtocol.IAlgoOutputData} message AlgoOutputData message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AlgoOutputData.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an AlgoOutputData message from the specified reader or buffer.
         * @function decode
         * @memberof RefDesignProtocol.AlgoOutputData
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {RefDesignProtocol.AlgoOutputData} AlgoOutputData
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AlgoOutputData.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.RefDesignProtocol.AlgoOutputData();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 2: {
                        if (!(message.histogram && message.histogram.length))
                            message.histogram = [];
                        if ((tag & 7) === 2) {
                            let end2 = reader.uint32() + reader.pos;
                            while (reader.pos < end2)
                                message.histogram.push(reader.uint32());
                        } else
                            message.histogram.push(reader.uint32());
                        break;
                    }
                case 3: {
                        if (!(message.peaks && message.peaks.length))
                            message.peaks = [];
                        if ((tag & 7) === 2) {
                            let end2 = reader.uint32() + reader.pos;
                            while (reader.pos < end2)
                                message.peaks.push(reader.float());
                        } else
                            message.peaks.push(reader.float());
                        break;
                    }
                case 4: {
                        if (!(message.sensorgram && message.sensorgram.length))
                            message.sensorgram = [];
                        if ((tag & 7) === 2) {
                            let end2 = reader.uint32() + reader.pos;
                            while (reader.pos < end2)
                                message.sensorgram.push(reader.float());
                        } else
                            message.sensorgram.push(reader.float());
                        break;
                    }
                case 5: {
                        if (!(message.iq && message.iq.length))
                            message.iq = [];
                        if ((tag & 7) === 2) {
                            let end2 = reader.uint32() + reader.pos;
                            while (reader.pos < end2)
                                message.iq.push(reader.float());
                        } else
                            message.iq.push(reader.float());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an AlgoOutputData message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof RefDesignProtocol.AlgoOutputData
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {RefDesignProtocol.AlgoOutputData} AlgoOutputData
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AlgoOutputData.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an AlgoOutputData message.
         * @function verify
         * @memberof RefDesignProtocol.AlgoOutputData
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        AlgoOutputData.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.histogram != null && message.hasOwnProperty("histogram")) {
                if (!Array.isArray(message.histogram))
                    return "histogram: array expected";
                for (let i = 0; i < message.histogram.length; ++i)
                    if (!$util.isInteger(message.histogram[i]))
                        return "histogram: integer[] expected";
            }
            if (message.peaks != null && message.hasOwnProperty("peaks")) {
                if (!Array.isArray(message.peaks))
                    return "peaks: array expected";
                for (let i = 0; i < message.peaks.length; ++i)
                    if (typeof message.peaks[i] !== "number")
                        return "peaks: number[] expected";
            }
            if (message.sensorgram != null && message.hasOwnProperty("sensorgram")) {
                if (!Array.isArray(message.sensorgram))
                    return "sensorgram: array expected";
                for (let i = 0; i < message.sensorgram.length; ++i)
                    if (typeof message.sensorgram[i] !== "number")
                        return "sensorgram: number[] expected";
            }
            if (message.iq != null && message.hasOwnProperty("iq")) {
                if (!Array.isArray(message.iq))
                    return "iq: array expected";
                for (let i = 0; i < message.iq.length; ++i)
                    if (typeof message.iq[i] !== "number")
                        return "iq: number[] expected";
            }
            return null;
        };

        /**
         * Creates an AlgoOutputData message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof RefDesignProtocol.AlgoOutputData
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {RefDesignProtocol.AlgoOutputData} AlgoOutputData
         */
        AlgoOutputData.fromObject = function fromObject(object) {
            if (object instanceof $root.RefDesignProtocol.AlgoOutputData)
                return object;
            let message = new $root.RefDesignProtocol.AlgoOutputData();
            if (object.histogram) {
                if (!Array.isArray(object.histogram))
                    throw TypeError(".RefDesignProtocol.AlgoOutputData.histogram: array expected");
                message.histogram = [];
                for (let i = 0; i < object.histogram.length; ++i)
                    message.histogram[i] = object.histogram[i] >>> 0;
            }
            if (object.peaks) {
                if (!Array.isArray(object.peaks))
                    throw TypeError(".RefDesignProtocol.AlgoOutputData.peaks: array expected");
                message.peaks = [];
                for (let i = 0; i < object.peaks.length; ++i)
                    message.peaks[i] = Number(object.peaks[i]);
            }
            if (object.sensorgram) {
                if (!Array.isArray(object.sensorgram))
                    throw TypeError(".RefDesignProtocol.AlgoOutputData.sensorgram: array expected");
                message.sensorgram = [];
                for (let i = 0; i < object.sensorgram.length; ++i)
                    message.sensorgram[i] = Number(object.sensorgram[i]);
            }
            if (object.iq) {
                if (!Array.isArray(object.iq))
                    throw TypeError(".RefDesignProtocol.AlgoOutputData.iq: array expected");
                message.iq = [];
                for (let i = 0; i < object.iq.length; ++i)
                    message.iq[i] = Number(object.iq[i]);
            }
            return message;
        };

        /**
         * Creates a plain object from an AlgoOutputData message. Also converts values to other types if specified.
         * @function toObject
         * @memberof RefDesignProtocol.AlgoOutputData
         * @static
         * @param {RefDesignProtocol.AlgoOutputData} message AlgoOutputData
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        AlgoOutputData.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults) {
                object.histogram = [];
                object.peaks = [];
                object.sensorgram = [];
                object.iq = [];
            }
            if (message.histogram && message.histogram.length) {
                object.histogram = [];
                for (let j = 0; j < message.histogram.length; ++j)
                    object.histogram[j] = message.histogram[j];
            }
            if (message.peaks && message.peaks.length) {
                object.peaks = [];
                for (let j = 0; j < message.peaks.length; ++j)
                    object.peaks[j] = options.json && !isFinite(message.peaks[j]) ? String(message.peaks[j]) : message.peaks[j];
            }
            if (message.sensorgram && message.sensorgram.length) {
                object.sensorgram = [];
                for (let j = 0; j < message.sensorgram.length; ++j)
                    object.sensorgram[j] = options.json && !isFinite(message.sensorgram[j]) ? String(message.sensorgram[j]) : message.sensorgram[j];
            }
            if (message.iq && message.iq.length) {
                object.iq = [];
                for (let j = 0; j < message.iq.length; ++j)
                    object.iq[j] = options.json && !isFinite(message.iq[j]) ? String(message.iq[j]) : message.iq[j];
            }
            return object;
        };

        /**
         * Converts this AlgoOutputData to JSON.
         * @function toJSON
         * @memberof RefDesignProtocol.AlgoOutputData
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        AlgoOutputData.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for AlgoOutputData
         * @function getTypeUrl
         * @memberof RefDesignProtocol.AlgoOutputData
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        AlgoOutputData.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/RefDesignProtocol.AlgoOutputData";
        };

        return AlgoOutputData;
    })();

    RefDesignProtocol.AlgoOutputFlags = (function() {

        /**
         * Properties of an AlgoOutputFlags.
         * @memberof RefDesignProtocol
         * @interface IAlgoOutputFlags
         * @property {boolean|null} [withHistogram] AlgoOutputFlags withHistogram
         * @property {boolean|null} [withPeaks] AlgoOutputFlags withPeaks
         * @property {boolean|null} [withSensorgram] AlgoOutputFlags withSensorgram
         * @property {boolean|null} [withIq] AlgoOutputFlags withIq
         */

        /**
         * Constructs a new AlgoOutputFlags.
         * @memberof RefDesignProtocol
         * @classdesc Represents an AlgoOutputFlags.
         * @implements IAlgoOutputFlags
         * @constructor
         * @param {RefDesignProtocol.IAlgoOutputFlags=} [properties] Properties to set
         */
        function AlgoOutputFlags(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * AlgoOutputFlags withHistogram.
         * @member {boolean} withHistogram
         * @memberof RefDesignProtocol.AlgoOutputFlags
         * @instance
         */
        AlgoOutputFlags.prototype.withHistogram = false;

        /**
         * AlgoOutputFlags withPeaks.
         * @member {boolean} withPeaks
         * @memberof RefDesignProtocol.AlgoOutputFlags
         * @instance
         */
        AlgoOutputFlags.prototype.withPeaks = false;

        /**
         * AlgoOutputFlags withSensorgram.
         * @member {boolean} withSensorgram
         * @memberof RefDesignProtocol.AlgoOutputFlags
         * @instance
         */
        AlgoOutputFlags.prototype.withSensorgram = false;

        /**
         * AlgoOutputFlags withIq.
         * @member {boolean} withIq
         * @memberof RefDesignProtocol.AlgoOutputFlags
         * @instance
         */
        AlgoOutputFlags.prototype.withIq = false;

        /**
         * Creates a new AlgoOutputFlags instance using the specified properties.
         * @function create
         * @memberof RefDesignProtocol.AlgoOutputFlags
         * @static
         * @param {RefDesignProtocol.IAlgoOutputFlags=} [properties] Properties to set
         * @returns {RefDesignProtocol.AlgoOutputFlags} AlgoOutputFlags instance
         */
        AlgoOutputFlags.create = function create(properties) {
            return new AlgoOutputFlags(properties);
        };

        /**
         * Encodes the specified AlgoOutputFlags message. Does not implicitly {@link RefDesignProtocol.AlgoOutputFlags.verify|verify} messages.
         * @function encode
         * @memberof RefDesignProtocol.AlgoOutputFlags
         * @static
         * @param {RefDesignProtocol.IAlgoOutputFlags} message AlgoOutputFlags message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AlgoOutputFlags.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.withHistogram != null && Object.hasOwnProperty.call(message, "withHistogram"))
                writer.uint32(/* id 1, wireType 0 =*/8).bool(message.withHistogram);
            if (message.withPeaks != null && Object.hasOwnProperty.call(message, "withPeaks"))
                writer.uint32(/* id 2, wireType 0 =*/16).bool(message.withPeaks);
            if (message.withSensorgram != null && Object.hasOwnProperty.call(message, "withSensorgram"))
                writer.uint32(/* id 3, wireType 0 =*/24).bool(message.withSensorgram);
            if (message.withIq != null && Object.hasOwnProperty.call(message, "withIq"))
                writer.uint32(/* id 4, wireType 0 =*/32).bool(message.withIq);
            return writer;
        };

        /**
         * Encodes the specified AlgoOutputFlags message, length delimited. Does not implicitly {@link RefDesignProtocol.AlgoOutputFlags.verify|verify} messages.
         * @function encodeDelimited
         * @memberof RefDesignProtocol.AlgoOutputFlags
         * @static
         * @param {RefDesignProtocol.IAlgoOutputFlags} message AlgoOutputFlags message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AlgoOutputFlags.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an AlgoOutputFlags message from the specified reader or buffer.
         * @function decode
         * @memberof RefDesignProtocol.AlgoOutputFlags
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {RefDesignProtocol.AlgoOutputFlags} AlgoOutputFlags
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AlgoOutputFlags.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.RefDesignProtocol.AlgoOutputFlags();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.withHistogram = reader.bool();
                        break;
                    }
                case 2: {
                        message.withPeaks = reader.bool();
                        break;
                    }
                case 3: {
                        message.withSensorgram = reader.bool();
                        break;
                    }
                case 4: {
                        message.withIq = reader.bool();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an AlgoOutputFlags message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof RefDesignProtocol.AlgoOutputFlags
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {RefDesignProtocol.AlgoOutputFlags} AlgoOutputFlags
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AlgoOutputFlags.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an AlgoOutputFlags message.
         * @function verify
         * @memberof RefDesignProtocol.AlgoOutputFlags
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        AlgoOutputFlags.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.withHistogram != null && message.hasOwnProperty("withHistogram"))
                if (typeof message.withHistogram !== "boolean")
                    return "withHistogram: boolean expected";
            if (message.withPeaks != null && message.hasOwnProperty("withPeaks"))
                if (typeof message.withPeaks !== "boolean")
                    return "withPeaks: boolean expected";
            if (message.withSensorgram != null && message.hasOwnProperty("withSensorgram"))
                if (typeof message.withSensorgram !== "boolean")
                    return "withSensorgram: boolean expected";
            if (message.withIq != null && message.hasOwnProperty("withIq"))
                if (typeof message.withIq !== "boolean")
                    return "withIq: boolean expected";
            return null;
        };

        /**
         * Creates an AlgoOutputFlags message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof RefDesignProtocol.AlgoOutputFlags
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {RefDesignProtocol.AlgoOutputFlags} AlgoOutputFlags
         */
        AlgoOutputFlags.fromObject = function fromObject(object) {
            if (object instanceof $root.RefDesignProtocol.AlgoOutputFlags)
                return object;
            let message = new $root.RefDesignProtocol.AlgoOutputFlags();
            if (object.withHistogram != null)
                message.withHistogram = Boolean(object.withHistogram);
            if (object.withPeaks != null)
                message.withPeaks = Boolean(object.withPeaks);
            if (object.withSensorgram != null)
                message.withSensorgram = Boolean(object.withSensorgram);
            if (object.withIq != null)
                message.withIq = Boolean(object.withIq);
            return message;
        };

        /**
         * Creates a plain object from an AlgoOutputFlags message. Also converts values to other types if specified.
         * @function toObject
         * @memberof RefDesignProtocol.AlgoOutputFlags
         * @static
         * @param {RefDesignProtocol.AlgoOutputFlags} message AlgoOutputFlags
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        AlgoOutputFlags.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.withHistogram = false;
                object.withPeaks = false;
                object.withSensorgram = false;
                object.withIq = false;
            }
            if (message.withHistogram != null && message.hasOwnProperty("withHistogram"))
                object.withHistogram = message.withHistogram;
            if (message.withPeaks != null && message.hasOwnProperty("withPeaks"))
                object.withPeaks = message.withPeaks;
            if (message.withSensorgram != null && message.hasOwnProperty("withSensorgram"))
                object.withSensorgram = message.withSensorgram;
            if (message.withIq != null && message.hasOwnProperty("withIq"))
                object.withIq = message.withIq;
            return object;
        };

        /**
         * Converts this AlgoOutputFlags to JSON.
         * @function toJSON
         * @memberof RefDesignProtocol.AlgoOutputFlags
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        AlgoOutputFlags.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for AlgoOutputFlags
         * @function getTypeUrl
         * @memberof RefDesignProtocol.AlgoOutputFlags
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        AlgoOutputFlags.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/RefDesignProtocol.AlgoOutputFlags";
        };

        return AlgoOutputFlags;
    })();

    RefDesignProtocol.ExtensionValue = (function() {

        /**
         * Properties of an ExtensionValue.
         * @memberof RefDesignProtocol
         * @interface IExtensionValue
         * @property {boolean|null} [boolValue] ExtensionValue boolValue
         * @property {number|Long|null} [intValue] ExtensionValue intValue
         * @property {string|null} [stringValue] ExtensionValue stringValue
         * @property {Uint8Array|null} [binaryValue] ExtensionValue binaryValue
         * @property {number|null} [floatValue] ExtensionValue floatValue
         */

        /**
         * Constructs a new ExtensionValue.
         * @memberof RefDesignProtocol
         * @classdesc Represents an ExtensionValue.
         * @implements IExtensionValue
         * @constructor
         * @param {RefDesignProtocol.IExtensionValue=} [properties] Properties to set
         */
        function ExtensionValue(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ExtensionValue boolValue.
         * @member {boolean|null|undefined} boolValue
         * @memberof RefDesignProtocol.ExtensionValue
         * @instance
         */
        ExtensionValue.prototype.boolValue = null;

        /**
         * ExtensionValue intValue.
         * @member {number|Long|null|undefined} intValue
         * @memberof RefDesignProtocol.ExtensionValue
         * @instance
         */
        ExtensionValue.prototype.intValue = null;

        /**
         * ExtensionValue stringValue.
         * @member {string|null|undefined} stringValue
         * @memberof RefDesignProtocol.ExtensionValue
         * @instance
         */
        ExtensionValue.prototype.stringValue = null;

        /**
         * ExtensionValue binaryValue.
         * @member {Uint8Array|null|undefined} binaryValue
         * @memberof RefDesignProtocol.ExtensionValue
         * @instance
         */
        ExtensionValue.prototype.binaryValue = null;

        /**
         * ExtensionValue floatValue.
         * @member {number|null|undefined} floatValue
         * @memberof RefDesignProtocol.ExtensionValue
         * @instance
         */
        ExtensionValue.prototype.floatValue = null;

        // OneOf field names bound to virtual getters and setters
        let $oneOfFields;

        /**
         * ExtensionValue type.
         * @member {"boolValue"|"intValue"|"stringValue"|"binaryValue"|"floatValue"|undefined} type
         * @memberof RefDesignProtocol.ExtensionValue
         * @instance
         */
        Object.defineProperty(ExtensionValue.prototype, "type", {
            get: $util.oneOfGetter($oneOfFields = ["boolValue", "intValue", "stringValue", "binaryValue", "floatValue"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Creates a new ExtensionValue instance using the specified properties.
         * @function create
         * @memberof RefDesignProtocol.ExtensionValue
         * @static
         * @param {RefDesignProtocol.IExtensionValue=} [properties] Properties to set
         * @returns {RefDesignProtocol.ExtensionValue} ExtensionValue instance
         */
        ExtensionValue.create = function create(properties) {
            return new ExtensionValue(properties);
        };

        /**
         * Encodes the specified ExtensionValue message. Does not implicitly {@link RefDesignProtocol.ExtensionValue.verify|verify} messages.
         * @function encode
         * @memberof RefDesignProtocol.ExtensionValue
         * @static
         * @param {RefDesignProtocol.IExtensionValue} message ExtensionValue message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ExtensionValue.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.boolValue != null && Object.hasOwnProperty.call(message, "boolValue"))
                writer.uint32(/* id 1, wireType 0 =*/8).bool(message.boolValue);
            if (message.intValue != null && Object.hasOwnProperty.call(message, "intValue"))
                writer.uint32(/* id 2, wireType 0 =*/16).uint64(message.intValue);
            if (message.stringValue != null && Object.hasOwnProperty.call(message, "stringValue"))
                writer.uint32(/* id 4, wireType 2 =*/34).string(message.stringValue);
            if (message.binaryValue != null && Object.hasOwnProperty.call(message, "binaryValue"))
                writer.uint32(/* id 5, wireType 2 =*/42).bytes(message.binaryValue);
            if (message.floatValue != null && Object.hasOwnProperty.call(message, "floatValue"))
                writer.uint32(/* id 6, wireType 5 =*/53).float(message.floatValue);
            return writer;
        };

        /**
         * Encodes the specified ExtensionValue message, length delimited. Does not implicitly {@link RefDesignProtocol.ExtensionValue.verify|verify} messages.
         * @function encodeDelimited
         * @memberof RefDesignProtocol.ExtensionValue
         * @static
         * @param {RefDesignProtocol.IExtensionValue} message ExtensionValue message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ExtensionValue.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an ExtensionValue message from the specified reader or buffer.
         * @function decode
         * @memberof RefDesignProtocol.ExtensionValue
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {RefDesignProtocol.ExtensionValue} ExtensionValue
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ExtensionValue.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.RefDesignProtocol.ExtensionValue();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.boolValue = reader.bool();
                        break;
                    }
                case 2: {
                        message.intValue = reader.uint64();
                        break;
                    }
                case 4: {
                        message.stringValue = reader.string();
                        break;
                    }
                case 5: {
                        message.binaryValue = reader.bytes();
                        break;
                    }
                case 6: {
                        message.floatValue = reader.float();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an ExtensionValue message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof RefDesignProtocol.ExtensionValue
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {RefDesignProtocol.ExtensionValue} ExtensionValue
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ExtensionValue.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an ExtensionValue message.
         * @function verify
         * @memberof RefDesignProtocol.ExtensionValue
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ExtensionValue.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            let properties = {};
            if (message.boolValue != null && message.hasOwnProperty("boolValue")) {
                properties.type = 1;
                if (typeof message.boolValue !== "boolean")
                    return "boolValue: boolean expected";
            }
            if (message.intValue != null && message.hasOwnProperty("intValue")) {
                if (properties.type === 1)
                    return "type: multiple values";
                properties.type = 1;
                if (!$util.isInteger(message.intValue) && !(message.intValue && $util.isInteger(message.intValue.low) && $util.isInteger(message.intValue.high)))
                    return "intValue: integer|Long expected";
            }
            if (message.stringValue != null && message.hasOwnProperty("stringValue")) {
                if (properties.type === 1)
                    return "type: multiple values";
                properties.type = 1;
                if (!$util.isString(message.stringValue))
                    return "stringValue: string expected";
            }
            if (message.binaryValue != null && message.hasOwnProperty("binaryValue")) {
                if (properties.type === 1)
                    return "type: multiple values";
                properties.type = 1;
                if (!(message.binaryValue && typeof message.binaryValue.length === "number" || $util.isString(message.binaryValue)))
                    return "binaryValue: buffer expected";
            }
            if (message.floatValue != null && message.hasOwnProperty("floatValue")) {
                if (properties.type === 1)
                    return "type: multiple values";
                properties.type = 1;
                if (typeof message.floatValue !== "number")
                    return "floatValue: number expected";
            }
            return null;
        };

        /**
         * Creates an ExtensionValue message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof RefDesignProtocol.ExtensionValue
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {RefDesignProtocol.ExtensionValue} ExtensionValue
         */
        ExtensionValue.fromObject = function fromObject(object) {
            if (object instanceof $root.RefDesignProtocol.ExtensionValue)
                return object;
            let message = new $root.RefDesignProtocol.ExtensionValue();
            if (object.boolValue != null)
                message.boolValue = Boolean(object.boolValue);
            if (object.intValue != null)
                if ($util.Long)
                    (message.intValue = $util.Long.fromValue(object.intValue)).unsigned = true;
                else if (typeof object.intValue === "string")
                    message.intValue = parseInt(object.intValue, 10);
                else if (typeof object.intValue === "number")
                    message.intValue = object.intValue;
                else if (typeof object.intValue === "object")
                    message.intValue = new $util.LongBits(object.intValue.low >>> 0, object.intValue.high >>> 0).toNumber(true);
            if (object.stringValue != null)
                message.stringValue = String(object.stringValue);
            if (object.binaryValue != null)
                if (typeof object.binaryValue === "string")
                    $util.base64.decode(object.binaryValue, message.binaryValue = $util.newBuffer($util.base64.length(object.binaryValue)), 0);
                else if (object.binaryValue.length >= 0)
                    message.binaryValue = object.binaryValue;
            if (object.floatValue != null)
                message.floatValue = Number(object.floatValue);
            return message;
        };

        /**
         * Creates a plain object from an ExtensionValue message. Also converts values to other types if specified.
         * @function toObject
         * @memberof RefDesignProtocol.ExtensionValue
         * @static
         * @param {RefDesignProtocol.ExtensionValue} message ExtensionValue
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ExtensionValue.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (message.boolValue != null && message.hasOwnProperty("boolValue")) {
                object.boolValue = message.boolValue;
                if (options.oneofs)
                    object.type = "boolValue";
            }
            if (message.intValue != null && message.hasOwnProperty("intValue")) {
                if (typeof message.intValue === "number")
                    object.intValue = options.longs === String ? String(message.intValue) : message.intValue;
                else
                    object.intValue = options.longs === String ? $util.Long.prototype.toString.call(message.intValue) : options.longs === Number ? new $util.LongBits(message.intValue.low >>> 0, message.intValue.high >>> 0).toNumber(true) : message.intValue;
                if (options.oneofs)
                    object.type = "intValue";
            }
            if (message.stringValue != null && message.hasOwnProperty("stringValue")) {
                object.stringValue = message.stringValue;
                if (options.oneofs)
                    object.type = "stringValue";
            }
            if (message.binaryValue != null && message.hasOwnProperty("binaryValue")) {
                object.binaryValue = options.bytes === String ? $util.base64.encode(message.binaryValue, 0, message.binaryValue.length) : options.bytes === Array ? Array.prototype.slice.call(message.binaryValue) : message.binaryValue;
                if (options.oneofs)
                    object.type = "binaryValue";
            }
            if (message.floatValue != null && message.hasOwnProperty("floatValue")) {
                object.floatValue = options.json && !isFinite(message.floatValue) ? String(message.floatValue) : message.floatValue;
                if (options.oneofs)
                    object.type = "floatValue";
            }
            return object;
        };

        /**
         * Converts this ExtensionValue to JSON.
         * @function toJSON
         * @memberof RefDesignProtocol.ExtensionValue
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ExtensionValue.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for ExtensionValue
         * @function getTypeUrl
         * @memberof RefDesignProtocol.ExtensionValue
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        ExtensionValue.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/RefDesignProtocol.ExtensionValue";
        };

        return ExtensionValue;
    })();

    RefDesignProtocol.ExtensionEvent = (function() {

        /**
         * Properties of an ExtensionEvent.
         * @memberof RefDesignProtocol
         * @interface IExtensionEvent
         * @property {string|null} [key] ExtensionEvent key
         * @property {RefDesignProtocol.IExtensionValue|null} [value] ExtensionEvent value
         */

        /**
         * Constructs a new ExtensionEvent.
         * @memberof RefDesignProtocol
         * @classdesc Represents an ExtensionEvent.
         * @implements IExtensionEvent
         * @constructor
         * @param {RefDesignProtocol.IExtensionEvent=} [properties] Properties to set
         */
        function ExtensionEvent(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ExtensionEvent key.
         * @member {string} key
         * @memberof RefDesignProtocol.ExtensionEvent
         * @instance
         */
        ExtensionEvent.prototype.key = "";

        /**
         * ExtensionEvent value.
         * @member {RefDesignProtocol.IExtensionValue|null|undefined} value
         * @memberof RefDesignProtocol.ExtensionEvent
         * @instance
         */
        ExtensionEvent.prototype.value = null;

        /**
         * Creates a new ExtensionEvent instance using the specified properties.
         * @function create
         * @memberof RefDesignProtocol.ExtensionEvent
         * @static
         * @param {RefDesignProtocol.IExtensionEvent=} [properties] Properties to set
         * @returns {RefDesignProtocol.ExtensionEvent} ExtensionEvent instance
         */
        ExtensionEvent.create = function create(properties) {
            return new ExtensionEvent(properties);
        };

        /**
         * Encodes the specified ExtensionEvent message. Does not implicitly {@link RefDesignProtocol.ExtensionEvent.verify|verify} messages.
         * @function encode
         * @memberof RefDesignProtocol.ExtensionEvent
         * @static
         * @param {RefDesignProtocol.IExtensionEvent} message ExtensionEvent message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ExtensionEvent.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.key != null && Object.hasOwnProperty.call(message, "key"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.key);
            if (message.value != null && Object.hasOwnProperty.call(message, "value"))
                $root.RefDesignProtocol.ExtensionValue.encode(message.value, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified ExtensionEvent message, length delimited. Does not implicitly {@link RefDesignProtocol.ExtensionEvent.verify|verify} messages.
         * @function encodeDelimited
         * @memberof RefDesignProtocol.ExtensionEvent
         * @static
         * @param {RefDesignProtocol.IExtensionEvent} message ExtensionEvent message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ExtensionEvent.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an ExtensionEvent message from the specified reader or buffer.
         * @function decode
         * @memberof RefDesignProtocol.ExtensionEvent
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {RefDesignProtocol.ExtensionEvent} ExtensionEvent
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ExtensionEvent.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.RefDesignProtocol.ExtensionEvent();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.key = reader.string();
                        break;
                    }
                case 2: {
                        message.value = $root.RefDesignProtocol.ExtensionValue.decode(reader, reader.uint32());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an ExtensionEvent message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof RefDesignProtocol.ExtensionEvent
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {RefDesignProtocol.ExtensionEvent} ExtensionEvent
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ExtensionEvent.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an ExtensionEvent message.
         * @function verify
         * @memberof RefDesignProtocol.ExtensionEvent
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ExtensionEvent.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.key != null && message.hasOwnProperty("key"))
                if (!$util.isString(message.key))
                    return "key: string expected";
            if (message.value != null && message.hasOwnProperty("value")) {
                let error = $root.RefDesignProtocol.ExtensionValue.verify(message.value);
                if (error)
                    return "value." + error;
            }
            return null;
        };

        /**
         * Creates an ExtensionEvent message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof RefDesignProtocol.ExtensionEvent
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {RefDesignProtocol.ExtensionEvent} ExtensionEvent
         */
        ExtensionEvent.fromObject = function fromObject(object) {
            if (object instanceof $root.RefDesignProtocol.ExtensionEvent)
                return object;
            let message = new $root.RefDesignProtocol.ExtensionEvent();
            if (object.key != null)
                message.key = String(object.key);
            if (object.value != null) {
                if (typeof object.value !== "object")
                    throw TypeError(".RefDesignProtocol.ExtensionEvent.value: object expected");
                message.value = $root.RefDesignProtocol.ExtensionValue.fromObject(object.value);
            }
            return message;
        };

        /**
         * Creates a plain object from an ExtensionEvent message. Also converts values to other types if specified.
         * @function toObject
         * @memberof RefDesignProtocol.ExtensionEvent
         * @static
         * @param {RefDesignProtocol.ExtensionEvent} message ExtensionEvent
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ExtensionEvent.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.key = "";
                object.value = null;
            }
            if (message.key != null && message.hasOwnProperty("key"))
                object.key = message.key;
            if (message.value != null && message.hasOwnProperty("value"))
                object.value = $root.RefDesignProtocol.ExtensionValue.toObject(message.value, options);
            return object;
        };

        /**
         * Converts this ExtensionEvent to JSON.
         * @function toJSON
         * @memberof RefDesignProtocol.ExtensionEvent
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ExtensionEvent.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for ExtensionEvent
         * @function getTypeUrl
         * @memberof RefDesignProtocol.ExtensionEvent
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        ExtensionEvent.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/RefDesignProtocol.ExtensionEvent";
        };

        return ExtensionEvent;
    })();

    RefDesignProtocol.GetVersionCommand = (function() {

        /**
         * Properties of a GetVersionCommand.
         * @memberof RefDesignProtocol
         * @interface IGetVersionCommand
         */

        /**
         * Constructs a new GetVersionCommand.
         * @memberof RefDesignProtocol
         * @classdesc Represents a GetVersionCommand.
         * @implements IGetVersionCommand
         * @constructor
         * @param {RefDesignProtocol.IGetVersionCommand=} [properties] Properties to set
         */
        function GetVersionCommand(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Creates a new GetVersionCommand instance using the specified properties.
         * @function create
         * @memberof RefDesignProtocol.GetVersionCommand
         * @static
         * @param {RefDesignProtocol.IGetVersionCommand=} [properties] Properties to set
         * @returns {RefDesignProtocol.GetVersionCommand} GetVersionCommand instance
         */
        GetVersionCommand.create = function create(properties) {
            return new GetVersionCommand(properties);
        };

        /**
         * Encodes the specified GetVersionCommand message. Does not implicitly {@link RefDesignProtocol.GetVersionCommand.verify|verify} messages.
         * @function encode
         * @memberof RefDesignProtocol.GetVersionCommand
         * @static
         * @param {RefDesignProtocol.IGetVersionCommand} message GetVersionCommand message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GetVersionCommand.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            return writer;
        };

        /**
         * Encodes the specified GetVersionCommand message, length delimited. Does not implicitly {@link RefDesignProtocol.GetVersionCommand.verify|verify} messages.
         * @function encodeDelimited
         * @memberof RefDesignProtocol.GetVersionCommand
         * @static
         * @param {RefDesignProtocol.IGetVersionCommand} message GetVersionCommand message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GetVersionCommand.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a GetVersionCommand message from the specified reader or buffer.
         * @function decode
         * @memberof RefDesignProtocol.GetVersionCommand
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {RefDesignProtocol.GetVersionCommand} GetVersionCommand
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GetVersionCommand.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.RefDesignProtocol.GetVersionCommand();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a GetVersionCommand message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof RefDesignProtocol.GetVersionCommand
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {RefDesignProtocol.GetVersionCommand} GetVersionCommand
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GetVersionCommand.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a GetVersionCommand message.
         * @function verify
         * @memberof RefDesignProtocol.GetVersionCommand
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        GetVersionCommand.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            return null;
        };

        /**
         * Creates a GetVersionCommand message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof RefDesignProtocol.GetVersionCommand
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {RefDesignProtocol.GetVersionCommand} GetVersionCommand
         */
        GetVersionCommand.fromObject = function fromObject(object) {
            if (object instanceof $root.RefDesignProtocol.GetVersionCommand)
                return object;
            return new $root.RefDesignProtocol.GetVersionCommand();
        };

        /**
         * Creates a plain object from a GetVersionCommand message. Also converts values to other types if specified.
         * @function toObject
         * @memberof RefDesignProtocol.GetVersionCommand
         * @static
         * @param {RefDesignProtocol.GetVersionCommand} message GetVersionCommand
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        GetVersionCommand.toObject = function toObject() {
            return {};
        };

        /**
         * Converts this GetVersionCommand to JSON.
         * @function toJSON
         * @memberof RefDesignProtocol.GetVersionCommand
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        GetVersionCommand.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for GetVersionCommand
         * @function getTypeUrl
         * @memberof RefDesignProtocol.GetVersionCommand
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        GetVersionCommand.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/RefDesignProtocol.GetVersionCommand";
        };

        return GetVersionCommand;
    })();

    RefDesignProtocol.VersionResult = (function() {

        /**
         * Properties of a VersionResult.
         * @memberof RefDesignProtocol
         * @interface IVersionResult
         * @property {string|null} [deviceType] VersionResult deviceType
         * @property {RefDesignProtocol.ProtocolVersion|null} [protocolVersion] VersionResult protocolVersion
         * @property {string|null} [hwVersion] VersionResult hwVersion
         * @property {string|null} [fwVersion] VersionResult fwVersion
         * @property {string|null} [uuid] VersionResult uuid
         */

        /**
         * Constructs a new VersionResult.
         * @memberof RefDesignProtocol
         * @classdesc Represents a VersionResult.
         * @implements IVersionResult
         * @constructor
         * @param {RefDesignProtocol.IVersionResult=} [properties] Properties to set
         */
        function VersionResult(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * VersionResult deviceType.
         * @member {string} deviceType
         * @memberof RefDesignProtocol.VersionResult
         * @instance
         */
        VersionResult.prototype.deviceType = "";

        /**
         * VersionResult protocolVersion.
         * @member {RefDesignProtocol.ProtocolVersion} protocolVersion
         * @memberof RefDesignProtocol.VersionResult
         * @instance
         */
        VersionResult.prototype.protocolVersion = 0;

        /**
         * VersionResult hwVersion.
         * @member {string} hwVersion
         * @memberof RefDesignProtocol.VersionResult
         * @instance
         */
        VersionResult.prototype.hwVersion = "";

        /**
         * VersionResult fwVersion.
         * @member {string} fwVersion
         * @memberof RefDesignProtocol.VersionResult
         * @instance
         */
        VersionResult.prototype.fwVersion = "";

        /**
         * VersionResult uuid.
         * @member {string} uuid
         * @memberof RefDesignProtocol.VersionResult
         * @instance
         */
        VersionResult.prototype.uuid = "";

        /**
         * Creates a new VersionResult instance using the specified properties.
         * @function create
         * @memberof RefDesignProtocol.VersionResult
         * @static
         * @param {RefDesignProtocol.IVersionResult=} [properties] Properties to set
         * @returns {RefDesignProtocol.VersionResult} VersionResult instance
         */
        VersionResult.create = function create(properties) {
            return new VersionResult(properties);
        };

        /**
         * Encodes the specified VersionResult message. Does not implicitly {@link RefDesignProtocol.VersionResult.verify|verify} messages.
         * @function encode
         * @memberof RefDesignProtocol.VersionResult
         * @static
         * @param {RefDesignProtocol.IVersionResult} message VersionResult message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        VersionResult.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.deviceType != null && Object.hasOwnProperty.call(message, "deviceType"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.deviceType);
            if (message.protocolVersion != null && Object.hasOwnProperty.call(message, "protocolVersion"))
                writer.uint32(/* id 2, wireType 0 =*/16).int32(message.protocolVersion);
            if (message.hwVersion != null && Object.hasOwnProperty.call(message, "hwVersion"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.hwVersion);
            if (message.fwVersion != null && Object.hasOwnProperty.call(message, "fwVersion"))
                writer.uint32(/* id 4, wireType 2 =*/34).string(message.fwVersion);
            if (message.uuid != null && Object.hasOwnProperty.call(message, "uuid"))
                writer.uint32(/* id 5, wireType 2 =*/42).string(message.uuid);
            return writer;
        };

        /**
         * Encodes the specified VersionResult message, length delimited. Does not implicitly {@link RefDesignProtocol.VersionResult.verify|verify} messages.
         * @function encodeDelimited
         * @memberof RefDesignProtocol.VersionResult
         * @static
         * @param {RefDesignProtocol.IVersionResult} message VersionResult message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        VersionResult.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a VersionResult message from the specified reader or buffer.
         * @function decode
         * @memberof RefDesignProtocol.VersionResult
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {RefDesignProtocol.VersionResult} VersionResult
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        VersionResult.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.RefDesignProtocol.VersionResult();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.deviceType = reader.string();
                        break;
                    }
                case 2: {
                        message.protocolVersion = reader.int32();
                        break;
                    }
                case 3: {
                        message.hwVersion = reader.string();
                        break;
                    }
                case 4: {
                        message.fwVersion = reader.string();
                        break;
                    }
                case 5: {
                        message.uuid = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a VersionResult message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof RefDesignProtocol.VersionResult
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {RefDesignProtocol.VersionResult} VersionResult
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        VersionResult.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a VersionResult message.
         * @function verify
         * @memberof RefDesignProtocol.VersionResult
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        VersionResult.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.deviceType != null && message.hasOwnProperty("deviceType"))
                if (!$util.isString(message.deviceType))
                    return "deviceType: string expected";
            if (message.protocolVersion != null && message.hasOwnProperty("protocolVersion"))
                switch (message.protocolVersion) {
                default:
                    return "protocolVersion: enum value expected";
                case 0:
                case 7:
                    break;
                }
            if (message.hwVersion != null && message.hasOwnProperty("hwVersion"))
                if (!$util.isString(message.hwVersion))
                    return "hwVersion: string expected";
            if (message.fwVersion != null && message.hasOwnProperty("fwVersion"))
                if (!$util.isString(message.fwVersion))
                    return "fwVersion: string expected";
            if (message.uuid != null && message.hasOwnProperty("uuid"))
                if (!$util.isString(message.uuid))
                    return "uuid: string expected";
            return null;
        };

        /**
         * Creates a VersionResult message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof RefDesignProtocol.VersionResult
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {RefDesignProtocol.VersionResult} VersionResult
         */
        VersionResult.fromObject = function fromObject(object) {
            if (object instanceof $root.RefDesignProtocol.VersionResult)
                return object;
            let message = new $root.RefDesignProtocol.VersionResult();
            if (object.deviceType != null)
                message.deviceType = String(object.deviceType);
            switch (object.protocolVersion) {
            default:
                if (typeof object.protocolVersion === "number") {
                    message.protocolVersion = object.protocolVersion;
                    break;
                }
                break;
            case "NOT_USED":
            case 0:
                message.protocolVersion = 0;
                break;
            case "VERSION":
            case 7:
                message.protocolVersion = 7;
                break;
            }
            if (object.hwVersion != null)
                message.hwVersion = String(object.hwVersion);
            if (object.fwVersion != null)
                message.fwVersion = String(object.fwVersion);
            if (object.uuid != null)
                message.uuid = String(object.uuid);
            return message;
        };

        /**
         * Creates a plain object from a VersionResult message. Also converts values to other types if specified.
         * @function toObject
         * @memberof RefDesignProtocol.VersionResult
         * @static
         * @param {RefDesignProtocol.VersionResult} message VersionResult
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        VersionResult.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.deviceType = "";
                object.protocolVersion = options.enums === String ? "NOT_USED" : 0;
                object.hwVersion = "";
                object.fwVersion = "";
                object.uuid = "";
            }
            if (message.deviceType != null && message.hasOwnProperty("deviceType"))
                object.deviceType = message.deviceType;
            if (message.protocolVersion != null && message.hasOwnProperty("protocolVersion"))
                object.protocolVersion = options.enums === String ? $root.RefDesignProtocol.ProtocolVersion[message.protocolVersion] === undefined ? message.protocolVersion : $root.RefDesignProtocol.ProtocolVersion[message.protocolVersion] : message.protocolVersion;
            if (message.hwVersion != null && message.hasOwnProperty("hwVersion"))
                object.hwVersion = message.hwVersion;
            if (message.fwVersion != null && message.hasOwnProperty("fwVersion"))
                object.fwVersion = message.fwVersion;
            if (message.uuid != null && message.hasOwnProperty("uuid"))
                object.uuid = message.uuid;
            return object;
        };

        /**
         * Converts this VersionResult to JSON.
         * @function toJSON
         * @memberof RefDesignProtocol.VersionResult
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        VersionResult.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for VersionResult
         * @function getTypeUrl
         * @memberof RefDesignProtocol.VersionResult
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        VersionResult.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/RefDesignProtocol.VersionResult";
        };

        return VersionResult;
    })();

    RefDesignProtocol.ConfigureCommand = (function() {

        /**
         * Properties of a ConfigureCommand.
         * @memberof RefDesignProtocol
         * @interface IConfigureCommand
         * @property {RefDesignProtocol.IConfigData|null} [config] ConfigureCommand config
         */

        /**
         * Constructs a new ConfigureCommand.
         * @memberof RefDesignProtocol
         * @classdesc Represents a ConfigureCommand.
         * @implements IConfigureCommand
         * @constructor
         * @param {RefDesignProtocol.IConfigureCommand=} [properties] Properties to set
         */
        function ConfigureCommand(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ConfigureCommand config.
         * @member {RefDesignProtocol.IConfigData|null|undefined} config
         * @memberof RefDesignProtocol.ConfigureCommand
         * @instance
         */
        ConfigureCommand.prototype.config = null;

        /**
         * Creates a new ConfigureCommand instance using the specified properties.
         * @function create
         * @memberof RefDesignProtocol.ConfigureCommand
         * @static
         * @param {RefDesignProtocol.IConfigureCommand=} [properties] Properties to set
         * @returns {RefDesignProtocol.ConfigureCommand} ConfigureCommand instance
         */
        ConfigureCommand.create = function create(properties) {
            return new ConfigureCommand(properties);
        };

        /**
         * Encodes the specified ConfigureCommand message. Does not implicitly {@link RefDesignProtocol.ConfigureCommand.verify|verify} messages.
         * @function encode
         * @memberof RefDesignProtocol.ConfigureCommand
         * @static
         * @param {RefDesignProtocol.IConfigureCommand} message ConfigureCommand message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ConfigureCommand.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.config != null && Object.hasOwnProperty.call(message, "config"))
                $root.RefDesignProtocol.ConfigData.encode(message.config, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified ConfigureCommand message, length delimited. Does not implicitly {@link RefDesignProtocol.ConfigureCommand.verify|verify} messages.
         * @function encodeDelimited
         * @memberof RefDesignProtocol.ConfigureCommand
         * @static
         * @param {RefDesignProtocol.IConfigureCommand} message ConfigureCommand message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ConfigureCommand.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a ConfigureCommand message from the specified reader or buffer.
         * @function decode
         * @memberof RefDesignProtocol.ConfigureCommand
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {RefDesignProtocol.ConfigureCommand} ConfigureCommand
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ConfigureCommand.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.RefDesignProtocol.ConfigureCommand();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.config = $root.RefDesignProtocol.ConfigData.decode(reader, reader.uint32());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a ConfigureCommand message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof RefDesignProtocol.ConfigureCommand
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {RefDesignProtocol.ConfigureCommand} ConfigureCommand
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ConfigureCommand.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a ConfigureCommand message.
         * @function verify
         * @memberof RefDesignProtocol.ConfigureCommand
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ConfigureCommand.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.config != null && message.hasOwnProperty("config")) {
                let error = $root.RefDesignProtocol.ConfigData.verify(message.config);
                if (error)
                    return "config." + error;
            }
            return null;
        };

        /**
         * Creates a ConfigureCommand message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof RefDesignProtocol.ConfigureCommand
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {RefDesignProtocol.ConfigureCommand} ConfigureCommand
         */
        ConfigureCommand.fromObject = function fromObject(object) {
            if (object instanceof $root.RefDesignProtocol.ConfigureCommand)
                return object;
            let message = new $root.RefDesignProtocol.ConfigureCommand();
            if (object.config != null) {
                if (typeof object.config !== "object")
                    throw TypeError(".RefDesignProtocol.ConfigureCommand.config: object expected");
                message.config = $root.RefDesignProtocol.ConfigData.fromObject(object.config);
            }
            return message;
        };

        /**
         * Creates a plain object from a ConfigureCommand message. Also converts values to other types if specified.
         * @function toObject
         * @memberof RefDesignProtocol.ConfigureCommand
         * @static
         * @param {RefDesignProtocol.ConfigureCommand} message ConfigureCommand
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ConfigureCommand.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                object.config = null;
            if (message.config != null && message.hasOwnProperty("config"))
                object.config = $root.RefDesignProtocol.ConfigData.toObject(message.config, options);
            return object;
        };

        /**
         * Converts this ConfigureCommand to JSON.
         * @function toJSON
         * @memberof RefDesignProtocol.ConfigureCommand
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ConfigureCommand.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for ConfigureCommand
         * @function getTypeUrl
         * @memberof RefDesignProtocol.ConfigureCommand
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        ConfigureCommand.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/RefDesignProtocol.ConfigureCommand";
        };

        return ConfigureCommand;
    })();

    RefDesignProtocol.GetConfigCommand = (function() {

        /**
         * Properties of a GetConfigCommand.
         * @memberof RefDesignProtocol
         * @interface IGetConfigCommand
         */

        /**
         * Constructs a new GetConfigCommand.
         * @memberof RefDesignProtocol
         * @classdesc Represents a GetConfigCommand.
         * @implements IGetConfigCommand
         * @constructor
         * @param {RefDesignProtocol.IGetConfigCommand=} [properties] Properties to set
         */
        function GetConfigCommand(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Creates a new GetConfigCommand instance using the specified properties.
         * @function create
         * @memberof RefDesignProtocol.GetConfigCommand
         * @static
         * @param {RefDesignProtocol.IGetConfigCommand=} [properties] Properties to set
         * @returns {RefDesignProtocol.GetConfigCommand} GetConfigCommand instance
         */
        GetConfigCommand.create = function create(properties) {
            return new GetConfigCommand(properties);
        };

        /**
         * Encodes the specified GetConfigCommand message. Does not implicitly {@link RefDesignProtocol.GetConfigCommand.verify|verify} messages.
         * @function encode
         * @memberof RefDesignProtocol.GetConfigCommand
         * @static
         * @param {RefDesignProtocol.IGetConfigCommand} message GetConfigCommand message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GetConfigCommand.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            return writer;
        };

        /**
         * Encodes the specified GetConfigCommand message, length delimited. Does not implicitly {@link RefDesignProtocol.GetConfigCommand.verify|verify} messages.
         * @function encodeDelimited
         * @memberof RefDesignProtocol.GetConfigCommand
         * @static
         * @param {RefDesignProtocol.IGetConfigCommand} message GetConfigCommand message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GetConfigCommand.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a GetConfigCommand message from the specified reader or buffer.
         * @function decode
         * @memberof RefDesignProtocol.GetConfigCommand
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {RefDesignProtocol.GetConfigCommand} GetConfigCommand
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GetConfigCommand.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.RefDesignProtocol.GetConfigCommand();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a GetConfigCommand message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof RefDesignProtocol.GetConfigCommand
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {RefDesignProtocol.GetConfigCommand} GetConfigCommand
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GetConfigCommand.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a GetConfigCommand message.
         * @function verify
         * @memberof RefDesignProtocol.GetConfigCommand
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        GetConfigCommand.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            return null;
        };

        /**
         * Creates a GetConfigCommand message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof RefDesignProtocol.GetConfigCommand
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {RefDesignProtocol.GetConfigCommand} GetConfigCommand
         */
        GetConfigCommand.fromObject = function fromObject(object) {
            if (object instanceof $root.RefDesignProtocol.GetConfigCommand)
                return object;
            return new $root.RefDesignProtocol.GetConfigCommand();
        };

        /**
         * Creates a plain object from a GetConfigCommand message. Also converts values to other types if specified.
         * @function toObject
         * @memberof RefDesignProtocol.GetConfigCommand
         * @static
         * @param {RefDesignProtocol.GetConfigCommand} message GetConfigCommand
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        GetConfigCommand.toObject = function toObject() {
            return {};
        };

        /**
         * Converts this GetConfigCommand to JSON.
         * @function toJSON
         * @memberof RefDesignProtocol.GetConfigCommand
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        GetConfigCommand.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for GetConfigCommand
         * @function getTypeUrl
         * @memberof RefDesignProtocol.GetConfigCommand
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        GetConfigCommand.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/RefDesignProtocol.GetConfigCommand";
        };

        return GetConfigCommand;
    })();

    RefDesignProtocol.ConfigResult = (function() {

        /**
         * Properties of a ConfigResult.
         * @memberof RefDesignProtocol
         * @interface IConfigResult
         * @property {RefDesignProtocol.IConfigData|null} [config] ConfigResult config
         */

        /**
         * Constructs a new ConfigResult.
         * @memberof RefDesignProtocol
         * @classdesc Represents a ConfigResult.
         * @implements IConfigResult
         * @constructor
         * @param {RefDesignProtocol.IConfigResult=} [properties] Properties to set
         */
        function ConfigResult(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ConfigResult config.
         * @member {RefDesignProtocol.IConfigData|null|undefined} config
         * @memberof RefDesignProtocol.ConfigResult
         * @instance
         */
        ConfigResult.prototype.config = null;

        /**
         * Creates a new ConfigResult instance using the specified properties.
         * @function create
         * @memberof RefDesignProtocol.ConfigResult
         * @static
         * @param {RefDesignProtocol.IConfigResult=} [properties] Properties to set
         * @returns {RefDesignProtocol.ConfigResult} ConfigResult instance
         */
        ConfigResult.create = function create(properties) {
            return new ConfigResult(properties);
        };

        /**
         * Encodes the specified ConfigResult message. Does not implicitly {@link RefDesignProtocol.ConfigResult.verify|verify} messages.
         * @function encode
         * @memberof RefDesignProtocol.ConfigResult
         * @static
         * @param {RefDesignProtocol.IConfigResult} message ConfigResult message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ConfigResult.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.config != null && Object.hasOwnProperty.call(message, "config"))
                $root.RefDesignProtocol.ConfigData.encode(message.config, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified ConfigResult message, length delimited. Does not implicitly {@link RefDesignProtocol.ConfigResult.verify|verify} messages.
         * @function encodeDelimited
         * @memberof RefDesignProtocol.ConfigResult
         * @static
         * @param {RefDesignProtocol.IConfigResult} message ConfigResult message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ConfigResult.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a ConfigResult message from the specified reader or buffer.
         * @function decode
         * @memberof RefDesignProtocol.ConfigResult
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {RefDesignProtocol.ConfigResult} ConfigResult
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ConfigResult.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.RefDesignProtocol.ConfigResult();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.config = $root.RefDesignProtocol.ConfigData.decode(reader, reader.uint32());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a ConfigResult message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof RefDesignProtocol.ConfigResult
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {RefDesignProtocol.ConfigResult} ConfigResult
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ConfigResult.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a ConfigResult message.
         * @function verify
         * @memberof RefDesignProtocol.ConfigResult
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ConfigResult.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.config != null && message.hasOwnProperty("config")) {
                let error = $root.RefDesignProtocol.ConfigData.verify(message.config);
                if (error)
                    return "config." + error;
            }
            return null;
        };

        /**
         * Creates a ConfigResult message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof RefDesignProtocol.ConfigResult
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {RefDesignProtocol.ConfigResult} ConfigResult
         */
        ConfigResult.fromObject = function fromObject(object) {
            if (object instanceof $root.RefDesignProtocol.ConfigResult)
                return object;
            let message = new $root.RefDesignProtocol.ConfigResult();
            if (object.config != null) {
                if (typeof object.config !== "object")
                    throw TypeError(".RefDesignProtocol.ConfigResult.config: object expected");
                message.config = $root.RefDesignProtocol.ConfigData.fromObject(object.config);
            }
            return message;
        };

        /**
         * Creates a plain object from a ConfigResult message. Also converts values to other types if specified.
         * @function toObject
         * @memberof RefDesignProtocol.ConfigResult
         * @static
         * @param {RefDesignProtocol.ConfigResult} message ConfigResult
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ConfigResult.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                object.config = null;
            if (message.config != null && message.hasOwnProperty("config"))
                object.config = $root.RefDesignProtocol.ConfigData.toObject(message.config, options);
            return object;
        };

        /**
         * Converts this ConfigResult to JSON.
         * @function toJSON
         * @memberof RefDesignProtocol.ConfigResult
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ConfigResult.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for ConfigResult
         * @function getTypeUrl
         * @memberof RefDesignProtocol.ConfigResult
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        ConfigResult.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/RefDesignProtocol.ConfigResult";
        };

        return ConfigResult;
    })();

    RefDesignProtocol.StoreConfigCommand = (function() {

        /**
         * Properties of a StoreConfigCommand.
         * @memberof RefDesignProtocol
         * @interface IStoreConfigCommand
         */

        /**
         * Constructs a new StoreConfigCommand.
         * @memberof RefDesignProtocol
         * @classdesc Represents a StoreConfigCommand.
         * @implements IStoreConfigCommand
         * @constructor
         * @param {RefDesignProtocol.IStoreConfigCommand=} [properties] Properties to set
         */
        function StoreConfigCommand(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Creates a new StoreConfigCommand instance using the specified properties.
         * @function create
         * @memberof RefDesignProtocol.StoreConfigCommand
         * @static
         * @param {RefDesignProtocol.IStoreConfigCommand=} [properties] Properties to set
         * @returns {RefDesignProtocol.StoreConfigCommand} StoreConfigCommand instance
         */
        StoreConfigCommand.create = function create(properties) {
            return new StoreConfigCommand(properties);
        };

        /**
         * Encodes the specified StoreConfigCommand message. Does not implicitly {@link RefDesignProtocol.StoreConfigCommand.verify|verify} messages.
         * @function encode
         * @memberof RefDesignProtocol.StoreConfigCommand
         * @static
         * @param {RefDesignProtocol.IStoreConfigCommand} message StoreConfigCommand message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        StoreConfigCommand.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            return writer;
        };

        /**
         * Encodes the specified StoreConfigCommand message, length delimited. Does not implicitly {@link RefDesignProtocol.StoreConfigCommand.verify|verify} messages.
         * @function encodeDelimited
         * @memberof RefDesignProtocol.StoreConfigCommand
         * @static
         * @param {RefDesignProtocol.IStoreConfigCommand} message StoreConfigCommand message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        StoreConfigCommand.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a StoreConfigCommand message from the specified reader or buffer.
         * @function decode
         * @memberof RefDesignProtocol.StoreConfigCommand
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {RefDesignProtocol.StoreConfigCommand} StoreConfigCommand
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        StoreConfigCommand.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.RefDesignProtocol.StoreConfigCommand();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a StoreConfigCommand message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof RefDesignProtocol.StoreConfigCommand
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {RefDesignProtocol.StoreConfigCommand} StoreConfigCommand
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        StoreConfigCommand.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a StoreConfigCommand message.
         * @function verify
         * @memberof RefDesignProtocol.StoreConfigCommand
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        StoreConfigCommand.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            return null;
        };

        /**
         * Creates a StoreConfigCommand message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof RefDesignProtocol.StoreConfigCommand
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {RefDesignProtocol.StoreConfigCommand} StoreConfigCommand
         */
        StoreConfigCommand.fromObject = function fromObject(object) {
            if (object instanceof $root.RefDesignProtocol.StoreConfigCommand)
                return object;
            return new $root.RefDesignProtocol.StoreConfigCommand();
        };

        /**
         * Creates a plain object from a StoreConfigCommand message. Also converts values to other types if specified.
         * @function toObject
         * @memberof RefDesignProtocol.StoreConfigCommand
         * @static
         * @param {RefDesignProtocol.StoreConfigCommand} message StoreConfigCommand
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        StoreConfigCommand.toObject = function toObject() {
            return {};
        };

        /**
         * Converts this StoreConfigCommand to JSON.
         * @function toJSON
         * @memberof RefDesignProtocol.StoreConfigCommand
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        StoreConfigCommand.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for StoreConfigCommand
         * @function getTypeUrl
         * @memberof RefDesignProtocol.StoreConfigCommand
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        StoreConfigCommand.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/RefDesignProtocol.StoreConfigCommand";
        };

        return StoreConfigCommand;
    })();

    RefDesignProtocol.GetCapabilitiesCommand = (function() {

        /**
         * Properties of a GetCapabilitiesCommand.
         * @memberof RefDesignProtocol
         * @interface IGetCapabilitiesCommand
         */

        /**
         * Constructs a new GetCapabilitiesCommand.
         * @memberof RefDesignProtocol
         * @classdesc Represents a GetCapabilitiesCommand.
         * @implements IGetCapabilitiesCommand
         * @constructor
         * @param {RefDesignProtocol.IGetCapabilitiesCommand=} [properties] Properties to set
         */
        function GetCapabilitiesCommand(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Creates a new GetCapabilitiesCommand instance using the specified properties.
         * @function create
         * @memberof RefDesignProtocol.GetCapabilitiesCommand
         * @static
         * @param {RefDesignProtocol.IGetCapabilitiesCommand=} [properties] Properties to set
         * @returns {RefDesignProtocol.GetCapabilitiesCommand} GetCapabilitiesCommand instance
         */
        GetCapabilitiesCommand.create = function create(properties) {
            return new GetCapabilitiesCommand(properties);
        };

        /**
         * Encodes the specified GetCapabilitiesCommand message. Does not implicitly {@link RefDesignProtocol.GetCapabilitiesCommand.verify|verify} messages.
         * @function encode
         * @memberof RefDesignProtocol.GetCapabilitiesCommand
         * @static
         * @param {RefDesignProtocol.IGetCapabilitiesCommand} message GetCapabilitiesCommand message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GetCapabilitiesCommand.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            return writer;
        };

        /**
         * Encodes the specified GetCapabilitiesCommand message, length delimited. Does not implicitly {@link RefDesignProtocol.GetCapabilitiesCommand.verify|verify} messages.
         * @function encodeDelimited
         * @memberof RefDesignProtocol.GetCapabilitiesCommand
         * @static
         * @param {RefDesignProtocol.IGetCapabilitiesCommand} message GetCapabilitiesCommand message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GetCapabilitiesCommand.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a GetCapabilitiesCommand message from the specified reader or buffer.
         * @function decode
         * @memberof RefDesignProtocol.GetCapabilitiesCommand
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {RefDesignProtocol.GetCapabilitiesCommand} GetCapabilitiesCommand
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GetCapabilitiesCommand.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.RefDesignProtocol.GetCapabilitiesCommand();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a GetCapabilitiesCommand message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof RefDesignProtocol.GetCapabilitiesCommand
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {RefDesignProtocol.GetCapabilitiesCommand} GetCapabilitiesCommand
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GetCapabilitiesCommand.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a GetCapabilitiesCommand message.
         * @function verify
         * @memberof RefDesignProtocol.GetCapabilitiesCommand
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        GetCapabilitiesCommand.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            return null;
        };

        /**
         * Creates a GetCapabilitiesCommand message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof RefDesignProtocol.GetCapabilitiesCommand
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {RefDesignProtocol.GetCapabilitiesCommand} GetCapabilitiesCommand
         */
        GetCapabilitiesCommand.fromObject = function fromObject(object) {
            if (object instanceof $root.RefDesignProtocol.GetCapabilitiesCommand)
                return object;
            return new $root.RefDesignProtocol.GetCapabilitiesCommand();
        };

        /**
         * Creates a plain object from a GetCapabilitiesCommand message. Also converts values to other types if specified.
         * @function toObject
         * @memberof RefDesignProtocol.GetCapabilitiesCommand
         * @static
         * @param {RefDesignProtocol.GetCapabilitiesCommand} message GetCapabilitiesCommand
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        GetCapabilitiesCommand.toObject = function toObject() {
            return {};
        };

        /**
         * Converts this GetCapabilitiesCommand to JSON.
         * @function toJSON
         * @memberof RefDesignProtocol.GetCapabilitiesCommand
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        GetCapabilitiesCommand.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for GetCapabilitiesCommand
         * @function getTypeUrl
         * @memberof RefDesignProtocol.GetCapabilitiesCommand
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        GetCapabilitiesCommand.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/RefDesignProtocol.GetCapabilitiesCommand";
        };

        return GetCapabilitiesCommand;
    })();

    RefDesignProtocol.CapabilitiesResult = (function() {

        /**
         * Properties of a CapabilitiesResult.
         * @memberof RefDesignProtocol
         * @interface ICapabilitiesResult
         * @property {boolean|null} [hasCoreSensor] CapabilitiesResult hasCoreSensor
         * @property {boolean|null} [hasCamera] CapabilitiesResult hasCamera
         */

        /**
         * Constructs a new CapabilitiesResult.
         * @memberof RefDesignProtocol
         * @classdesc Represents a CapabilitiesResult.
         * @implements ICapabilitiesResult
         * @constructor
         * @param {RefDesignProtocol.ICapabilitiesResult=} [properties] Properties to set
         */
        function CapabilitiesResult(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * CapabilitiesResult hasCoreSensor.
         * @member {boolean} hasCoreSensor
         * @memberof RefDesignProtocol.CapabilitiesResult
         * @instance
         */
        CapabilitiesResult.prototype.hasCoreSensor = false;

        /**
         * CapabilitiesResult hasCamera.
         * @member {boolean} hasCamera
         * @memberof RefDesignProtocol.CapabilitiesResult
         * @instance
         */
        CapabilitiesResult.prototype.hasCamera = false;

        /**
         * Creates a new CapabilitiesResult instance using the specified properties.
         * @function create
         * @memberof RefDesignProtocol.CapabilitiesResult
         * @static
         * @param {RefDesignProtocol.ICapabilitiesResult=} [properties] Properties to set
         * @returns {RefDesignProtocol.CapabilitiesResult} CapabilitiesResult instance
         */
        CapabilitiesResult.create = function create(properties) {
            return new CapabilitiesResult(properties);
        };

        /**
         * Encodes the specified CapabilitiesResult message. Does not implicitly {@link RefDesignProtocol.CapabilitiesResult.verify|verify} messages.
         * @function encode
         * @memberof RefDesignProtocol.CapabilitiesResult
         * @static
         * @param {RefDesignProtocol.ICapabilitiesResult} message CapabilitiesResult message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CapabilitiesResult.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.hasCoreSensor != null && Object.hasOwnProperty.call(message, "hasCoreSensor"))
                writer.uint32(/* id 1, wireType 0 =*/8).bool(message.hasCoreSensor);
            if (message.hasCamera != null && Object.hasOwnProperty.call(message, "hasCamera"))
                writer.uint32(/* id 2, wireType 0 =*/16).bool(message.hasCamera);
            return writer;
        };

        /**
         * Encodes the specified CapabilitiesResult message, length delimited. Does not implicitly {@link RefDesignProtocol.CapabilitiesResult.verify|verify} messages.
         * @function encodeDelimited
         * @memberof RefDesignProtocol.CapabilitiesResult
         * @static
         * @param {RefDesignProtocol.ICapabilitiesResult} message CapabilitiesResult message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CapabilitiesResult.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a CapabilitiesResult message from the specified reader or buffer.
         * @function decode
         * @memberof RefDesignProtocol.CapabilitiesResult
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {RefDesignProtocol.CapabilitiesResult} CapabilitiesResult
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CapabilitiesResult.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.RefDesignProtocol.CapabilitiesResult();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.hasCoreSensor = reader.bool();
                        break;
                    }
                case 2: {
                        message.hasCamera = reader.bool();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a CapabilitiesResult message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof RefDesignProtocol.CapabilitiesResult
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {RefDesignProtocol.CapabilitiesResult} CapabilitiesResult
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CapabilitiesResult.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a CapabilitiesResult message.
         * @function verify
         * @memberof RefDesignProtocol.CapabilitiesResult
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        CapabilitiesResult.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.hasCoreSensor != null && message.hasOwnProperty("hasCoreSensor"))
                if (typeof message.hasCoreSensor !== "boolean")
                    return "hasCoreSensor: boolean expected";
            if (message.hasCamera != null && message.hasOwnProperty("hasCamera"))
                if (typeof message.hasCamera !== "boolean")
                    return "hasCamera: boolean expected";
            return null;
        };

        /**
         * Creates a CapabilitiesResult message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof RefDesignProtocol.CapabilitiesResult
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {RefDesignProtocol.CapabilitiesResult} CapabilitiesResult
         */
        CapabilitiesResult.fromObject = function fromObject(object) {
            if (object instanceof $root.RefDesignProtocol.CapabilitiesResult)
                return object;
            let message = new $root.RefDesignProtocol.CapabilitiesResult();
            if (object.hasCoreSensor != null)
                message.hasCoreSensor = Boolean(object.hasCoreSensor);
            if (object.hasCamera != null)
                message.hasCamera = Boolean(object.hasCamera);
            return message;
        };

        /**
         * Creates a plain object from a CapabilitiesResult message. Also converts values to other types if specified.
         * @function toObject
         * @memberof RefDesignProtocol.CapabilitiesResult
         * @static
         * @param {RefDesignProtocol.CapabilitiesResult} message CapabilitiesResult
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        CapabilitiesResult.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.hasCoreSensor = false;
                object.hasCamera = false;
            }
            if (message.hasCoreSensor != null && message.hasOwnProperty("hasCoreSensor"))
                object.hasCoreSensor = message.hasCoreSensor;
            if (message.hasCamera != null && message.hasOwnProperty("hasCamera"))
                object.hasCamera = message.hasCamera;
            return object;
        };

        /**
         * Converts this CapabilitiesResult to JSON.
         * @function toJSON
         * @memberof RefDesignProtocol.CapabilitiesResult
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        CapabilitiesResult.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for CapabilitiesResult
         * @function getTypeUrl
         * @memberof RefDesignProtocol.CapabilitiesResult
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        CapabilitiesResult.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/RefDesignProtocol.CapabilitiesResult";
        };

        return CapabilitiesResult;
    })();

    RefDesignProtocol.SetVcselCommand = (function() {

        /**
         * Properties of a SetVcselCommand.
         * @memberof RefDesignProtocol
         * @interface ISetVcselCommand
         * @property {boolean|null} [state] SetVcselCommand state
         */

        /**
         * Constructs a new SetVcselCommand.
         * @memberof RefDesignProtocol
         * @classdesc Represents a SetVcselCommand.
         * @implements ISetVcselCommand
         * @constructor
         * @param {RefDesignProtocol.ISetVcselCommand=} [properties] Properties to set
         */
        function SetVcselCommand(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * SetVcselCommand state.
         * @member {boolean} state
         * @memberof RefDesignProtocol.SetVcselCommand
         * @instance
         */
        SetVcselCommand.prototype.state = false;

        /**
         * Creates a new SetVcselCommand instance using the specified properties.
         * @function create
         * @memberof RefDesignProtocol.SetVcselCommand
         * @static
         * @param {RefDesignProtocol.ISetVcselCommand=} [properties] Properties to set
         * @returns {RefDesignProtocol.SetVcselCommand} SetVcselCommand instance
         */
        SetVcselCommand.create = function create(properties) {
            return new SetVcselCommand(properties);
        };

        /**
         * Encodes the specified SetVcselCommand message. Does not implicitly {@link RefDesignProtocol.SetVcselCommand.verify|verify} messages.
         * @function encode
         * @memberof RefDesignProtocol.SetVcselCommand
         * @static
         * @param {RefDesignProtocol.ISetVcselCommand} message SetVcselCommand message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SetVcselCommand.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.state != null && Object.hasOwnProperty.call(message, "state"))
                writer.uint32(/* id 1, wireType 0 =*/8).bool(message.state);
            return writer;
        };

        /**
         * Encodes the specified SetVcselCommand message, length delimited. Does not implicitly {@link RefDesignProtocol.SetVcselCommand.verify|verify} messages.
         * @function encodeDelimited
         * @memberof RefDesignProtocol.SetVcselCommand
         * @static
         * @param {RefDesignProtocol.ISetVcselCommand} message SetVcselCommand message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SetVcselCommand.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a SetVcselCommand message from the specified reader or buffer.
         * @function decode
         * @memberof RefDesignProtocol.SetVcselCommand
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {RefDesignProtocol.SetVcselCommand} SetVcselCommand
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SetVcselCommand.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.RefDesignProtocol.SetVcselCommand();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.state = reader.bool();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a SetVcselCommand message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof RefDesignProtocol.SetVcselCommand
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {RefDesignProtocol.SetVcselCommand} SetVcselCommand
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SetVcselCommand.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a SetVcselCommand message.
         * @function verify
         * @memberof RefDesignProtocol.SetVcselCommand
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        SetVcselCommand.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.state != null && message.hasOwnProperty("state"))
                if (typeof message.state !== "boolean")
                    return "state: boolean expected";
            return null;
        };

        /**
         * Creates a SetVcselCommand message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof RefDesignProtocol.SetVcselCommand
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {RefDesignProtocol.SetVcselCommand} SetVcselCommand
         */
        SetVcselCommand.fromObject = function fromObject(object) {
            if (object instanceof $root.RefDesignProtocol.SetVcselCommand)
                return object;
            let message = new $root.RefDesignProtocol.SetVcselCommand();
            if (object.state != null)
                message.state = Boolean(object.state);
            return message;
        };

        /**
         * Creates a plain object from a SetVcselCommand message. Also converts values to other types if specified.
         * @function toObject
         * @memberof RefDesignProtocol.SetVcselCommand
         * @static
         * @param {RefDesignProtocol.SetVcselCommand} message SetVcselCommand
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        SetVcselCommand.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                object.state = false;
            if (message.state != null && message.hasOwnProperty("state"))
                object.state = message.state;
            return object;
        };

        /**
         * Converts this SetVcselCommand to JSON.
         * @function toJSON
         * @memberof RefDesignProtocol.SetVcselCommand
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        SetVcselCommand.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for SetVcselCommand
         * @function getTypeUrl
         * @memberof RefDesignProtocol.SetVcselCommand
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        SetVcselCommand.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/RefDesignProtocol.SetVcselCommand";
        };

        return SetVcselCommand;
    })();

    RefDesignProtocol.SetValveCommand = (function() {

        /**
         * Properties of a SetValveCommand.
         * @memberof RefDesignProtocol
         * @interface ISetValveCommand
         * @property {number|null} [id] SetValveCommand id
         * @property {boolean|null} [state] SetValveCommand state
         */

        /**
         * Constructs a new SetValveCommand.
         * @memberof RefDesignProtocol
         * @classdesc Represents a SetValveCommand.
         * @implements ISetValveCommand
         * @constructor
         * @param {RefDesignProtocol.ISetValveCommand=} [properties] Properties to set
         */
        function SetValveCommand(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * SetValveCommand id.
         * @member {number} id
         * @memberof RefDesignProtocol.SetValveCommand
         * @instance
         */
        SetValveCommand.prototype.id = 0;

        /**
         * SetValveCommand state.
         * @member {boolean} state
         * @memberof RefDesignProtocol.SetValveCommand
         * @instance
         */
        SetValveCommand.prototype.state = false;

        /**
         * Creates a new SetValveCommand instance using the specified properties.
         * @function create
         * @memberof RefDesignProtocol.SetValveCommand
         * @static
         * @param {RefDesignProtocol.ISetValveCommand=} [properties] Properties to set
         * @returns {RefDesignProtocol.SetValveCommand} SetValveCommand instance
         */
        SetValveCommand.create = function create(properties) {
            return new SetValveCommand(properties);
        };

        /**
         * Encodes the specified SetValveCommand message. Does not implicitly {@link RefDesignProtocol.SetValveCommand.verify|verify} messages.
         * @function encode
         * @memberof RefDesignProtocol.SetValveCommand
         * @static
         * @param {RefDesignProtocol.ISetValveCommand} message SetValveCommand message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SetValveCommand.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.id);
            if (message.state != null && Object.hasOwnProperty.call(message, "state"))
                writer.uint32(/* id 2, wireType 0 =*/16).bool(message.state);
            return writer;
        };

        /**
         * Encodes the specified SetValveCommand message, length delimited. Does not implicitly {@link RefDesignProtocol.SetValveCommand.verify|verify} messages.
         * @function encodeDelimited
         * @memberof RefDesignProtocol.SetValveCommand
         * @static
         * @param {RefDesignProtocol.ISetValveCommand} message SetValveCommand message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SetValveCommand.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a SetValveCommand message from the specified reader or buffer.
         * @function decode
         * @memberof RefDesignProtocol.SetValveCommand
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {RefDesignProtocol.SetValveCommand} SetValveCommand
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SetValveCommand.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.RefDesignProtocol.SetValveCommand();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.id = reader.uint32();
                        break;
                    }
                case 2: {
                        message.state = reader.bool();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a SetValveCommand message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof RefDesignProtocol.SetValveCommand
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {RefDesignProtocol.SetValveCommand} SetValveCommand
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SetValveCommand.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a SetValveCommand message.
         * @function verify
         * @memberof RefDesignProtocol.SetValveCommand
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        SetValveCommand.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.id != null && message.hasOwnProperty("id"))
                if (!$util.isInteger(message.id))
                    return "id: integer expected";
            if (message.state != null && message.hasOwnProperty("state"))
                if (typeof message.state !== "boolean")
                    return "state: boolean expected";
            return null;
        };

        /**
         * Creates a SetValveCommand message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof RefDesignProtocol.SetValveCommand
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {RefDesignProtocol.SetValveCommand} SetValveCommand
         */
        SetValveCommand.fromObject = function fromObject(object) {
            if (object instanceof $root.RefDesignProtocol.SetValveCommand)
                return object;
            let message = new $root.RefDesignProtocol.SetValveCommand();
            if (object.id != null)
                message.id = object.id >>> 0;
            if (object.state != null)
                message.state = Boolean(object.state);
            return message;
        };

        /**
         * Creates a plain object from a SetValveCommand message. Also converts values to other types if specified.
         * @function toObject
         * @memberof RefDesignProtocol.SetValveCommand
         * @static
         * @param {RefDesignProtocol.SetValveCommand} message SetValveCommand
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        SetValveCommand.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.id = 0;
                object.state = false;
            }
            if (message.id != null && message.hasOwnProperty("id"))
                object.id = message.id;
            if (message.state != null && message.hasOwnProperty("state"))
                object.state = message.state;
            return object;
        };

        /**
         * Converts this SetValveCommand to JSON.
         * @function toJSON
         * @memberof RefDesignProtocol.SetValveCommand
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        SetValveCommand.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for SetValveCommand
         * @function getTypeUrl
         * @memberof RefDesignProtocol.SetValveCommand
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        SetValveCommand.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/RefDesignProtocol.SetValveCommand";
        };

        return SetValveCommand;
    })();

    RefDesignProtocol.SetPumpCommand = (function() {

        /**
         * Properties of a SetPumpCommand.
         * @memberof RefDesignProtocol
         * @interface ISetPumpCommand
         * @property {number|null} [power] SetPumpCommand power
         */

        /**
         * Constructs a new SetPumpCommand.
         * @memberof RefDesignProtocol
         * @classdesc Represents a SetPumpCommand.
         * @implements ISetPumpCommand
         * @constructor
         * @param {RefDesignProtocol.ISetPumpCommand=} [properties] Properties to set
         */
        function SetPumpCommand(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * SetPumpCommand power.
         * @member {number} power
         * @memberof RefDesignProtocol.SetPumpCommand
         * @instance
         */
        SetPumpCommand.prototype.power = 0;

        /**
         * Creates a new SetPumpCommand instance using the specified properties.
         * @function create
         * @memberof RefDesignProtocol.SetPumpCommand
         * @static
         * @param {RefDesignProtocol.ISetPumpCommand=} [properties] Properties to set
         * @returns {RefDesignProtocol.SetPumpCommand} SetPumpCommand instance
         */
        SetPumpCommand.create = function create(properties) {
            return new SetPumpCommand(properties);
        };

        /**
         * Encodes the specified SetPumpCommand message. Does not implicitly {@link RefDesignProtocol.SetPumpCommand.verify|verify} messages.
         * @function encode
         * @memberof RefDesignProtocol.SetPumpCommand
         * @static
         * @param {RefDesignProtocol.ISetPumpCommand} message SetPumpCommand message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SetPumpCommand.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.power != null && Object.hasOwnProperty.call(message, "power"))
                writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.power);
            return writer;
        };

        /**
         * Encodes the specified SetPumpCommand message, length delimited. Does not implicitly {@link RefDesignProtocol.SetPumpCommand.verify|verify} messages.
         * @function encodeDelimited
         * @memberof RefDesignProtocol.SetPumpCommand
         * @static
         * @param {RefDesignProtocol.ISetPumpCommand} message SetPumpCommand message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SetPumpCommand.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a SetPumpCommand message from the specified reader or buffer.
         * @function decode
         * @memberof RefDesignProtocol.SetPumpCommand
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {RefDesignProtocol.SetPumpCommand} SetPumpCommand
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SetPumpCommand.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.RefDesignProtocol.SetPumpCommand();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.power = reader.uint32();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a SetPumpCommand message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof RefDesignProtocol.SetPumpCommand
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {RefDesignProtocol.SetPumpCommand} SetPumpCommand
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SetPumpCommand.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a SetPumpCommand message.
         * @function verify
         * @memberof RefDesignProtocol.SetPumpCommand
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        SetPumpCommand.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.power != null && message.hasOwnProperty("power"))
                if (!$util.isInteger(message.power))
                    return "power: integer expected";
            return null;
        };

        /**
         * Creates a SetPumpCommand message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof RefDesignProtocol.SetPumpCommand
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {RefDesignProtocol.SetPumpCommand} SetPumpCommand
         */
        SetPumpCommand.fromObject = function fromObject(object) {
            if (object instanceof $root.RefDesignProtocol.SetPumpCommand)
                return object;
            let message = new $root.RefDesignProtocol.SetPumpCommand();
            if (object.power != null)
                message.power = object.power >>> 0;
            return message;
        };

        /**
         * Creates a plain object from a SetPumpCommand message. Also converts values to other types if specified.
         * @function toObject
         * @memberof RefDesignProtocol.SetPumpCommand
         * @static
         * @param {RefDesignProtocol.SetPumpCommand} message SetPumpCommand
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        SetPumpCommand.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                object.power = 0;
            if (message.power != null && message.hasOwnProperty("power"))
                object.power = message.power;
            return object;
        };

        /**
         * Converts this SetPumpCommand to JSON.
         * @function toJSON
         * @memberof RefDesignProtocol.SetPumpCommand
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        SetPumpCommand.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for SetPumpCommand
         * @function getTypeUrl
         * @memberof RefDesignProtocol.SetPumpCommand
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        SetPumpCommand.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/RefDesignProtocol.SetPumpCommand";
        };

        return SetPumpCommand;
    })();

    RefDesignProtocol.SetHihCommand = (function() {

        /**
         * Properties of a SetHihCommand.
         * @memberof RefDesignProtocol
         * @interface ISetHihCommand
         * @property {boolean|null} [state] SetHihCommand state
         */

        /**
         * Constructs a new SetHihCommand.
         * @memberof RefDesignProtocol
         * @classdesc Represents a SetHihCommand.
         * @implements ISetHihCommand
         * @constructor
         * @param {RefDesignProtocol.ISetHihCommand=} [properties] Properties to set
         */
        function SetHihCommand(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * SetHihCommand state.
         * @member {boolean} state
         * @memberof RefDesignProtocol.SetHihCommand
         * @instance
         */
        SetHihCommand.prototype.state = false;

        /**
         * Creates a new SetHihCommand instance using the specified properties.
         * @function create
         * @memberof RefDesignProtocol.SetHihCommand
         * @static
         * @param {RefDesignProtocol.ISetHihCommand=} [properties] Properties to set
         * @returns {RefDesignProtocol.SetHihCommand} SetHihCommand instance
         */
        SetHihCommand.create = function create(properties) {
            return new SetHihCommand(properties);
        };

        /**
         * Encodes the specified SetHihCommand message. Does not implicitly {@link RefDesignProtocol.SetHihCommand.verify|verify} messages.
         * @function encode
         * @memberof RefDesignProtocol.SetHihCommand
         * @static
         * @param {RefDesignProtocol.ISetHihCommand} message SetHihCommand message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SetHihCommand.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.state != null && Object.hasOwnProperty.call(message, "state"))
                writer.uint32(/* id 1, wireType 0 =*/8).bool(message.state);
            return writer;
        };

        /**
         * Encodes the specified SetHihCommand message, length delimited. Does not implicitly {@link RefDesignProtocol.SetHihCommand.verify|verify} messages.
         * @function encodeDelimited
         * @memberof RefDesignProtocol.SetHihCommand
         * @static
         * @param {RefDesignProtocol.ISetHihCommand} message SetHihCommand message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SetHihCommand.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a SetHihCommand message from the specified reader or buffer.
         * @function decode
         * @memberof RefDesignProtocol.SetHihCommand
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {RefDesignProtocol.SetHihCommand} SetHihCommand
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SetHihCommand.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.RefDesignProtocol.SetHihCommand();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.state = reader.bool();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a SetHihCommand message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof RefDesignProtocol.SetHihCommand
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {RefDesignProtocol.SetHihCommand} SetHihCommand
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SetHihCommand.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a SetHihCommand message.
         * @function verify
         * @memberof RefDesignProtocol.SetHihCommand
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        SetHihCommand.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.state != null && message.hasOwnProperty("state"))
                if (typeof message.state !== "boolean")
                    return "state: boolean expected";
            return null;
        };

        /**
         * Creates a SetHihCommand message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof RefDesignProtocol.SetHihCommand
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {RefDesignProtocol.SetHihCommand} SetHihCommand
         */
        SetHihCommand.fromObject = function fromObject(object) {
            if (object instanceof $root.RefDesignProtocol.SetHihCommand)
                return object;
            let message = new $root.RefDesignProtocol.SetHihCommand();
            if (object.state != null)
                message.state = Boolean(object.state);
            return message;
        };

        /**
         * Creates a plain object from a SetHihCommand message. Also converts values to other types if specified.
         * @function toObject
         * @memberof RefDesignProtocol.SetHihCommand
         * @static
         * @param {RefDesignProtocol.SetHihCommand} message SetHihCommand
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        SetHihCommand.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                object.state = false;
            if (message.state != null && message.hasOwnProperty("state"))
                object.state = message.state;
            return object;
        };

        /**
         * Converts this SetHihCommand to JSON.
         * @function toJSON
         * @memberof RefDesignProtocol.SetHihCommand
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        SetHihCommand.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for SetHihCommand
         * @function getTypeUrl
         * @memberof RefDesignProtocol.SetHihCommand
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        SetHihCommand.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/RefDesignProtocol.SetHihCommand";
        };

        return SetHihCommand;
    })();

    RefDesignProtocol.StartCommand = (function() {

        /**
         * Properties of a StartCommand.
         * @memberof RefDesignProtocol
         * @interface IStartCommand
         * @property {RefDesignProtocol.IAlgoOutputFlags|null} [withAlgo] StartCommand withAlgo
         */

        /**
         * Constructs a new StartCommand.
         * @memberof RefDesignProtocol
         * @classdesc Represents a StartCommand.
         * @implements IStartCommand
         * @constructor
         * @param {RefDesignProtocol.IStartCommand=} [properties] Properties to set
         */
        function StartCommand(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * StartCommand withAlgo.
         * @member {RefDesignProtocol.IAlgoOutputFlags|null|undefined} withAlgo
         * @memberof RefDesignProtocol.StartCommand
         * @instance
         */
        StartCommand.prototype.withAlgo = null;

        /**
         * Creates a new StartCommand instance using the specified properties.
         * @function create
         * @memberof RefDesignProtocol.StartCommand
         * @static
         * @param {RefDesignProtocol.IStartCommand=} [properties] Properties to set
         * @returns {RefDesignProtocol.StartCommand} StartCommand instance
         */
        StartCommand.create = function create(properties) {
            return new StartCommand(properties);
        };

        /**
         * Encodes the specified StartCommand message. Does not implicitly {@link RefDesignProtocol.StartCommand.verify|verify} messages.
         * @function encode
         * @memberof RefDesignProtocol.StartCommand
         * @static
         * @param {RefDesignProtocol.IStartCommand} message StartCommand message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        StartCommand.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.withAlgo != null && Object.hasOwnProperty.call(message, "withAlgo"))
                $root.RefDesignProtocol.AlgoOutputFlags.encode(message.withAlgo, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified StartCommand message, length delimited. Does not implicitly {@link RefDesignProtocol.StartCommand.verify|verify} messages.
         * @function encodeDelimited
         * @memberof RefDesignProtocol.StartCommand
         * @static
         * @param {RefDesignProtocol.IStartCommand} message StartCommand message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        StartCommand.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a StartCommand message from the specified reader or buffer.
         * @function decode
         * @memberof RefDesignProtocol.StartCommand
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {RefDesignProtocol.StartCommand} StartCommand
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        StartCommand.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.RefDesignProtocol.StartCommand();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.withAlgo = $root.RefDesignProtocol.AlgoOutputFlags.decode(reader, reader.uint32());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a StartCommand message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof RefDesignProtocol.StartCommand
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {RefDesignProtocol.StartCommand} StartCommand
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        StartCommand.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a StartCommand message.
         * @function verify
         * @memberof RefDesignProtocol.StartCommand
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        StartCommand.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.withAlgo != null && message.hasOwnProperty("withAlgo")) {
                let error = $root.RefDesignProtocol.AlgoOutputFlags.verify(message.withAlgo);
                if (error)
                    return "withAlgo." + error;
            }
            return null;
        };

        /**
         * Creates a StartCommand message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof RefDesignProtocol.StartCommand
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {RefDesignProtocol.StartCommand} StartCommand
         */
        StartCommand.fromObject = function fromObject(object) {
            if (object instanceof $root.RefDesignProtocol.StartCommand)
                return object;
            let message = new $root.RefDesignProtocol.StartCommand();
            if (object.withAlgo != null) {
                if (typeof object.withAlgo !== "object")
                    throw TypeError(".RefDesignProtocol.StartCommand.withAlgo: object expected");
                message.withAlgo = $root.RefDesignProtocol.AlgoOutputFlags.fromObject(object.withAlgo);
            }
            return message;
        };

        /**
         * Creates a plain object from a StartCommand message. Also converts values to other types if specified.
         * @function toObject
         * @memberof RefDesignProtocol.StartCommand
         * @static
         * @param {RefDesignProtocol.StartCommand} message StartCommand
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        StartCommand.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                object.withAlgo = null;
            if (message.withAlgo != null && message.hasOwnProperty("withAlgo"))
                object.withAlgo = $root.RefDesignProtocol.AlgoOutputFlags.toObject(message.withAlgo, options);
            return object;
        };

        /**
         * Converts this StartCommand to JSON.
         * @function toJSON
         * @memberof RefDesignProtocol.StartCommand
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        StartCommand.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for StartCommand
         * @function getTypeUrl
         * @memberof RefDesignProtocol.StartCommand
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        StartCommand.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/RefDesignProtocol.StartCommand";
        };

        return StartCommand;
    })();

    RefDesignProtocol.StopCommand = (function() {

        /**
         * Properties of a StopCommand.
         * @memberof RefDesignProtocol
         * @interface IStopCommand
         */

        /**
         * Constructs a new StopCommand.
         * @memberof RefDesignProtocol
         * @classdesc Represents a StopCommand.
         * @implements IStopCommand
         * @constructor
         * @param {RefDesignProtocol.IStopCommand=} [properties] Properties to set
         */
        function StopCommand(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Creates a new StopCommand instance using the specified properties.
         * @function create
         * @memberof RefDesignProtocol.StopCommand
         * @static
         * @param {RefDesignProtocol.IStopCommand=} [properties] Properties to set
         * @returns {RefDesignProtocol.StopCommand} StopCommand instance
         */
        StopCommand.create = function create(properties) {
            return new StopCommand(properties);
        };

        /**
         * Encodes the specified StopCommand message. Does not implicitly {@link RefDesignProtocol.StopCommand.verify|verify} messages.
         * @function encode
         * @memberof RefDesignProtocol.StopCommand
         * @static
         * @param {RefDesignProtocol.IStopCommand} message StopCommand message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        StopCommand.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            return writer;
        };

        /**
         * Encodes the specified StopCommand message, length delimited. Does not implicitly {@link RefDesignProtocol.StopCommand.verify|verify} messages.
         * @function encodeDelimited
         * @memberof RefDesignProtocol.StopCommand
         * @static
         * @param {RefDesignProtocol.IStopCommand} message StopCommand message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        StopCommand.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a StopCommand message from the specified reader or buffer.
         * @function decode
         * @memberof RefDesignProtocol.StopCommand
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {RefDesignProtocol.StopCommand} StopCommand
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        StopCommand.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.RefDesignProtocol.StopCommand();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a StopCommand message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof RefDesignProtocol.StopCommand
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {RefDesignProtocol.StopCommand} StopCommand
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        StopCommand.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a StopCommand message.
         * @function verify
         * @memberof RefDesignProtocol.StopCommand
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        StopCommand.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            return null;
        };

        /**
         * Creates a StopCommand message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof RefDesignProtocol.StopCommand
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {RefDesignProtocol.StopCommand} StopCommand
         */
        StopCommand.fromObject = function fromObject(object) {
            if (object instanceof $root.RefDesignProtocol.StopCommand)
                return object;
            return new $root.RefDesignProtocol.StopCommand();
        };

        /**
         * Creates a plain object from a StopCommand message. Also converts values to other types if specified.
         * @function toObject
         * @memberof RefDesignProtocol.StopCommand
         * @static
         * @param {RefDesignProtocol.StopCommand} message StopCommand
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        StopCommand.toObject = function toObject() {
            return {};
        };

        /**
         * Converts this StopCommand to JSON.
         * @function toJSON
         * @memberof RefDesignProtocol.StopCommand
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        StopCommand.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for StopCommand
         * @function getTypeUrl
         * @memberof RefDesignProtocol.StopCommand
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        StopCommand.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/RefDesignProtocol.StopCommand";
        };

        return StopCommand;
    })();

    RefDesignProtocol.SingleShotCommand = (function() {

        /**
         * Properties of a SingleShotCommand.
         * @memberof RefDesignProtocol
         * @interface ISingleShotCommand
         * @property {RefDesignProtocol.IAlgoOutputFlags|null} [withAlgo] SingleShotCommand withAlgo
         * @property {RefDesignProtocol.ImageEncoding|null} [outputImageEncoding] SingleShotCommand outputImageEncoding
         */

        /**
         * Constructs a new SingleShotCommand.
         * @memberof RefDesignProtocol
         * @classdesc Represents a SingleShotCommand.
         * @implements ISingleShotCommand
         * @constructor
         * @param {RefDesignProtocol.ISingleShotCommand=} [properties] Properties to set
         */
        function SingleShotCommand(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * SingleShotCommand withAlgo.
         * @member {RefDesignProtocol.IAlgoOutputFlags|null|undefined} withAlgo
         * @memberof RefDesignProtocol.SingleShotCommand
         * @instance
         */
        SingleShotCommand.prototype.withAlgo = null;

        /**
         * SingleShotCommand outputImageEncoding.
         * @member {RefDesignProtocol.ImageEncoding} outputImageEncoding
         * @memberof RefDesignProtocol.SingleShotCommand
         * @instance
         */
        SingleShotCommand.prototype.outputImageEncoding = 0;

        /**
         * Creates a new SingleShotCommand instance using the specified properties.
         * @function create
         * @memberof RefDesignProtocol.SingleShotCommand
         * @static
         * @param {RefDesignProtocol.ISingleShotCommand=} [properties] Properties to set
         * @returns {RefDesignProtocol.SingleShotCommand} SingleShotCommand instance
         */
        SingleShotCommand.create = function create(properties) {
            return new SingleShotCommand(properties);
        };

        /**
         * Encodes the specified SingleShotCommand message. Does not implicitly {@link RefDesignProtocol.SingleShotCommand.verify|verify} messages.
         * @function encode
         * @memberof RefDesignProtocol.SingleShotCommand
         * @static
         * @param {RefDesignProtocol.ISingleShotCommand} message SingleShotCommand message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SingleShotCommand.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.withAlgo != null && Object.hasOwnProperty.call(message, "withAlgo"))
                $root.RefDesignProtocol.AlgoOutputFlags.encode(message.withAlgo, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.outputImageEncoding != null && Object.hasOwnProperty.call(message, "outputImageEncoding"))
                writer.uint32(/* id 2, wireType 0 =*/16).int32(message.outputImageEncoding);
            return writer;
        };

        /**
         * Encodes the specified SingleShotCommand message, length delimited. Does not implicitly {@link RefDesignProtocol.SingleShotCommand.verify|verify} messages.
         * @function encodeDelimited
         * @memberof RefDesignProtocol.SingleShotCommand
         * @static
         * @param {RefDesignProtocol.ISingleShotCommand} message SingleShotCommand message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SingleShotCommand.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a SingleShotCommand message from the specified reader or buffer.
         * @function decode
         * @memberof RefDesignProtocol.SingleShotCommand
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {RefDesignProtocol.SingleShotCommand} SingleShotCommand
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SingleShotCommand.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.RefDesignProtocol.SingleShotCommand();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.withAlgo = $root.RefDesignProtocol.AlgoOutputFlags.decode(reader, reader.uint32());
                        break;
                    }
                case 2: {
                        message.outputImageEncoding = reader.int32();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a SingleShotCommand message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof RefDesignProtocol.SingleShotCommand
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {RefDesignProtocol.SingleShotCommand} SingleShotCommand
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SingleShotCommand.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a SingleShotCommand message.
         * @function verify
         * @memberof RefDesignProtocol.SingleShotCommand
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        SingleShotCommand.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.withAlgo != null && message.hasOwnProperty("withAlgo")) {
                let error = $root.RefDesignProtocol.AlgoOutputFlags.verify(message.withAlgo);
                if (error)
                    return "withAlgo." + error;
            }
            if (message.outputImageEncoding != null && message.hasOwnProperty("outputImageEncoding"))
                switch (message.outputImageEncoding) {
                default:
                    return "outputImageEncoding: enum value expected";
                case 0:
                case 1:
                    break;
                }
            return null;
        };

        /**
         * Creates a SingleShotCommand message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof RefDesignProtocol.SingleShotCommand
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {RefDesignProtocol.SingleShotCommand} SingleShotCommand
         */
        SingleShotCommand.fromObject = function fromObject(object) {
            if (object instanceof $root.RefDesignProtocol.SingleShotCommand)
                return object;
            let message = new $root.RefDesignProtocol.SingleShotCommand();
            if (object.withAlgo != null) {
                if (typeof object.withAlgo !== "object")
                    throw TypeError(".RefDesignProtocol.SingleShotCommand.withAlgo: object expected");
                message.withAlgo = $root.RefDesignProtocol.AlgoOutputFlags.fromObject(object.withAlgo);
            }
            switch (object.outputImageEncoding) {
            default:
                if (typeof object.outputImageEncoding === "number") {
                    message.outputImageEncoding = object.outputImageEncoding;
                    break;
                }
                break;
            case "PIXEL_DEPTH_8_BITS":
            case 0:
                message.outputImageEncoding = 0;
                break;
            case "PIXEL_DEPTH_10_OVER_16_BITS":
            case 1:
                message.outputImageEncoding = 1;
                break;
            }
            return message;
        };

        /**
         * Creates a plain object from a SingleShotCommand message. Also converts values to other types if specified.
         * @function toObject
         * @memberof RefDesignProtocol.SingleShotCommand
         * @static
         * @param {RefDesignProtocol.SingleShotCommand} message SingleShotCommand
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        SingleShotCommand.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.withAlgo = null;
                object.outputImageEncoding = options.enums === String ? "PIXEL_DEPTH_8_BITS" : 0;
            }
            if (message.withAlgo != null && message.hasOwnProperty("withAlgo"))
                object.withAlgo = $root.RefDesignProtocol.AlgoOutputFlags.toObject(message.withAlgo, options);
            if (message.outputImageEncoding != null && message.hasOwnProperty("outputImageEncoding"))
                object.outputImageEncoding = options.enums === String ? $root.RefDesignProtocol.ImageEncoding[message.outputImageEncoding] === undefined ? message.outputImageEncoding : $root.RefDesignProtocol.ImageEncoding[message.outputImageEncoding] : message.outputImageEncoding;
            return object;
        };

        /**
         * Converts this SingleShotCommand to JSON.
         * @function toJSON
         * @memberof RefDesignProtocol.SingleShotCommand
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        SingleShotCommand.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for SingleShotCommand
         * @function getTypeUrl
         * @memberof RefDesignProtocol.SingleShotCommand
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        SingleShotCommand.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/RefDesignProtocol.SingleShotCommand";
        };

        return SingleShotCommand;
    })();

    RefDesignProtocol.SingleShotResult = (function() {

        /**
         * Properties of a SingleShotResult.
         * @memberof RefDesignProtocol
         * @interface ISingleShotResult
         * @property {number|null} [imageSize] SingleShotResult imageSize
         * @property {RefDesignProtocol.IAlgoOutputData|null} [algo] SingleShotResult algo
         */

        /**
         * Constructs a new SingleShotResult.
         * @memberof RefDesignProtocol
         * @classdesc Represents a SingleShotResult.
         * @implements ISingleShotResult
         * @constructor
         * @param {RefDesignProtocol.ISingleShotResult=} [properties] Properties to set
         */
        function SingleShotResult(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * SingleShotResult imageSize.
         * @member {number} imageSize
         * @memberof RefDesignProtocol.SingleShotResult
         * @instance
         */
        SingleShotResult.prototype.imageSize = 0;

        /**
         * SingleShotResult algo.
         * @member {RefDesignProtocol.IAlgoOutputData|null|undefined} algo
         * @memberof RefDesignProtocol.SingleShotResult
         * @instance
         */
        SingleShotResult.prototype.algo = null;

        /**
         * Creates a new SingleShotResult instance using the specified properties.
         * @function create
         * @memberof RefDesignProtocol.SingleShotResult
         * @static
         * @param {RefDesignProtocol.ISingleShotResult=} [properties] Properties to set
         * @returns {RefDesignProtocol.SingleShotResult} SingleShotResult instance
         */
        SingleShotResult.create = function create(properties) {
            return new SingleShotResult(properties);
        };

        /**
         * Encodes the specified SingleShotResult message. Does not implicitly {@link RefDesignProtocol.SingleShotResult.verify|verify} messages.
         * @function encode
         * @memberof RefDesignProtocol.SingleShotResult
         * @static
         * @param {RefDesignProtocol.ISingleShotResult} message SingleShotResult message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SingleShotResult.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.imageSize != null && Object.hasOwnProperty.call(message, "imageSize"))
                writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.imageSize);
            if (message.algo != null && Object.hasOwnProperty.call(message, "algo"))
                $root.RefDesignProtocol.AlgoOutputData.encode(message.algo, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified SingleShotResult message, length delimited. Does not implicitly {@link RefDesignProtocol.SingleShotResult.verify|verify} messages.
         * @function encodeDelimited
         * @memberof RefDesignProtocol.SingleShotResult
         * @static
         * @param {RefDesignProtocol.ISingleShotResult} message SingleShotResult message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SingleShotResult.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a SingleShotResult message from the specified reader or buffer.
         * @function decode
         * @memberof RefDesignProtocol.SingleShotResult
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {RefDesignProtocol.SingleShotResult} SingleShotResult
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SingleShotResult.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.RefDesignProtocol.SingleShotResult();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.imageSize = reader.uint32();
                        break;
                    }
                case 2: {
                        message.algo = $root.RefDesignProtocol.AlgoOutputData.decode(reader, reader.uint32());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a SingleShotResult message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof RefDesignProtocol.SingleShotResult
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {RefDesignProtocol.SingleShotResult} SingleShotResult
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SingleShotResult.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a SingleShotResult message.
         * @function verify
         * @memberof RefDesignProtocol.SingleShotResult
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        SingleShotResult.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.imageSize != null && message.hasOwnProperty("imageSize"))
                if (!$util.isInteger(message.imageSize))
                    return "imageSize: integer expected";
            if (message.algo != null && message.hasOwnProperty("algo")) {
                let error = $root.RefDesignProtocol.AlgoOutputData.verify(message.algo);
                if (error)
                    return "algo." + error;
            }
            return null;
        };

        /**
         * Creates a SingleShotResult message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof RefDesignProtocol.SingleShotResult
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {RefDesignProtocol.SingleShotResult} SingleShotResult
         */
        SingleShotResult.fromObject = function fromObject(object) {
            if (object instanceof $root.RefDesignProtocol.SingleShotResult)
                return object;
            let message = new $root.RefDesignProtocol.SingleShotResult();
            if (object.imageSize != null)
                message.imageSize = object.imageSize >>> 0;
            if (object.algo != null) {
                if (typeof object.algo !== "object")
                    throw TypeError(".RefDesignProtocol.SingleShotResult.algo: object expected");
                message.algo = $root.RefDesignProtocol.AlgoOutputData.fromObject(object.algo);
            }
            return message;
        };

        /**
         * Creates a plain object from a SingleShotResult message. Also converts values to other types if specified.
         * @function toObject
         * @memberof RefDesignProtocol.SingleShotResult
         * @static
         * @param {RefDesignProtocol.SingleShotResult} message SingleShotResult
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        SingleShotResult.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.imageSize = 0;
                object.algo = null;
            }
            if (message.imageSize != null && message.hasOwnProperty("imageSize"))
                object.imageSize = message.imageSize;
            if (message.algo != null && message.hasOwnProperty("algo"))
                object.algo = $root.RefDesignProtocol.AlgoOutputData.toObject(message.algo, options);
            return object;
        };

        /**
         * Converts this SingleShotResult to JSON.
         * @function toJSON
         * @memberof RefDesignProtocol.SingleShotResult
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        SingleShotResult.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for SingleShotResult
         * @function getTypeUrl
         * @memberof RefDesignProtocol.SingleShotResult
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        SingleShotResult.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/RefDesignProtocol.SingleShotResult";
        };

        return SingleShotResult;
    })();

    RefDesignProtocol.CalibExposureCommand = (function() {

        /**
         * Properties of a CalibExposureCommand.
         * @memberof RefDesignProtocol
         * @interface ICalibExposureCommand
         */

        /**
         * Constructs a new CalibExposureCommand.
         * @memberof RefDesignProtocol
         * @classdesc Represents a CalibExposureCommand.
         * @implements ICalibExposureCommand
         * @constructor
         * @param {RefDesignProtocol.ICalibExposureCommand=} [properties] Properties to set
         */
        function CalibExposureCommand(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Creates a new CalibExposureCommand instance using the specified properties.
         * @function create
         * @memberof RefDesignProtocol.CalibExposureCommand
         * @static
         * @param {RefDesignProtocol.ICalibExposureCommand=} [properties] Properties to set
         * @returns {RefDesignProtocol.CalibExposureCommand} CalibExposureCommand instance
         */
        CalibExposureCommand.create = function create(properties) {
            return new CalibExposureCommand(properties);
        };

        /**
         * Encodes the specified CalibExposureCommand message. Does not implicitly {@link RefDesignProtocol.CalibExposureCommand.verify|verify} messages.
         * @function encode
         * @memberof RefDesignProtocol.CalibExposureCommand
         * @static
         * @param {RefDesignProtocol.ICalibExposureCommand} message CalibExposureCommand message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CalibExposureCommand.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            return writer;
        };

        /**
         * Encodes the specified CalibExposureCommand message, length delimited. Does not implicitly {@link RefDesignProtocol.CalibExposureCommand.verify|verify} messages.
         * @function encodeDelimited
         * @memberof RefDesignProtocol.CalibExposureCommand
         * @static
         * @param {RefDesignProtocol.ICalibExposureCommand} message CalibExposureCommand message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CalibExposureCommand.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a CalibExposureCommand message from the specified reader or buffer.
         * @function decode
         * @memberof RefDesignProtocol.CalibExposureCommand
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {RefDesignProtocol.CalibExposureCommand} CalibExposureCommand
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CalibExposureCommand.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.RefDesignProtocol.CalibExposureCommand();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a CalibExposureCommand message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof RefDesignProtocol.CalibExposureCommand
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {RefDesignProtocol.CalibExposureCommand} CalibExposureCommand
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CalibExposureCommand.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a CalibExposureCommand message.
         * @function verify
         * @memberof RefDesignProtocol.CalibExposureCommand
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        CalibExposureCommand.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            return null;
        };

        /**
         * Creates a CalibExposureCommand message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof RefDesignProtocol.CalibExposureCommand
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {RefDesignProtocol.CalibExposureCommand} CalibExposureCommand
         */
        CalibExposureCommand.fromObject = function fromObject(object) {
            if (object instanceof $root.RefDesignProtocol.CalibExposureCommand)
                return object;
            return new $root.RefDesignProtocol.CalibExposureCommand();
        };

        /**
         * Creates a plain object from a CalibExposureCommand message. Also converts values to other types if specified.
         * @function toObject
         * @memberof RefDesignProtocol.CalibExposureCommand
         * @static
         * @param {RefDesignProtocol.CalibExposureCommand} message CalibExposureCommand
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        CalibExposureCommand.toObject = function toObject() {
            return {};
        };

        /**
         * Converts this CalibExposureCommand to JSON.
         * @function toJSON
         * @memberof RefDesignProtocol.CalibExposureCommand
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        CalibExposureCommand.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for CalibExposureCommand
         * @function getTypeUrl
         * @memberof RefDesignProtocol.CalibExposureCommand
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        CalibExposureCommand.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/RefDesignProtocol.CalibExposureCommand";
        };

        return CalibExposureCommand;
    })();

    RefDesignProtocol.CalibExposureResult = (function() {

        /**
         * Properties of a CalibExposureResult.
         * @memberof RefDesignProtocol
         * @interface ICalibExposureResult
         * @property {boolean|null} [success] CalibExposureResult success
         * @property {string|null} [reason] CalibExposureResult reason
         * @property {number|null} [expoFound] CalibExposureResult expoFound
         */

        /**
         * Constructs a new CalibExposureResult.
         * @memberof RefDesignProtocol
         * @classdesc Represents a CalibExposureResult.
         * @implements ICalibExposureResult
         * @constructor
         * @param {RefDesignProtocol.ICalibExposureResult=} [properties] Properties to set
         */
        function CalibExposureResult(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * CalibExposureResult success.
         * @member {boolean} success
         * @memberof RefDesignProtocol.CalibExposureResult
         * @instance
         */
        CalibExposureResult.prototype.success = false;

        /**
         * CalibExposureResult reason.
         * @member {string} reason
         * @memberof RefDesignProtocol.CalibExposureResult
         * @instance
         */
        CalibExposureResult.prototype.reason = "";

        /**
         * CalibExposureResult expoFound.
         * @member {number} expoFound
         * @memberof RefDesignProtocol.CalibExposureResult
         * @instance
         */
        CalibExposureResult.prototype.expoFound = 0;

        /**
         * Creates a new CalibExposureResult instance using the specified properties.
         * @function create
         * @memberof RefDesignProtocol.CalibExposureResult
         * @static
         * @param {RefDesignProtocol.ICalibExposureResult=} [properties] Properties to set
         * @returns {RefDesignProtocol.CalibExposureResult} CalibExposureResult instance
         */
        CalibExposureResult.create = function create(properties) {
            return new CalibExposureResult(properties);
        };

        /**
         * Encodes the specified CalibExposureResult message. Does not implicitly {@link RefDesignProtocol.CalibExposureResult.verify|verify} messages.
         * @function encode
         * @memberof RefDesignProtocol.CalibExposureResult
         * @static
         * @param {RefDesignProtocol.ICalibExposureResult} message CalibExposureResult message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CalibExposureResult.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.success != null && Object.hasOwnProperty.call(message, "success"))
                writer.uint32(/* id 1, wireType 0 =*/8).bool(message.success);
            if (message.reason != null && Object.hasOwnProperty.call(message, "reason"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.reason);
            if (message.expoFound != null && Object.hasOwnProperty.call(message, "expoFound"))
                writer.uint32(/* id 3, wireType 0 =*/24).uint32(message.expoFound);
            return writer;
        };

        /**
         * Encodes the specified CalibExposureResult message, length delimited. Does not implicitly {@link RefDesignProtocol.CalibExposureResult.verify|verify} messages.
         * @function encodeDelimited
         * @memberof RefDesignProtocol.CalibExposureResult
         * @static
         * @param {RefDesignProtocol.ICalibExposureResult} message CalibExposureResult message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CalibExposureResult.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a CalibExposureResult message from the specified reader or buffer.
         * @function decode
         * @memberof RefDesignProtocol.CalibExposureResult
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {RefDesignProtocol.CalibExposureResult} CalibExposureResult
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CalibExposureResult.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.RefDesignProtocol.CalibExposureResult();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.success = reader.bool();
                        break;
                    }
                case 2: {
                        message.reason = reader.string();
                        break;
                    }
                case 3: {
                        message.expoFound = reader.uint32();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a CalibExposureResult message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof RefDesignProtocol.CalibExposureResult
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {RefDesignProtocol.CalibExposureResult} CalibExposureResult
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CalibExposureResult.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a CalibExposureResult message.
         * @function verify
         * @memberof RefDesignProtocol.CalibExposureResult
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        CalibExposureResult.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.success != null && message.hasOwnProperty("success"))
                if (typeof message.success !== "boolean")
                    return "success: boolean expected";
            if (message.reason != null && message.hasOwnProperty("reason"))
                if (!$util.isString(message.reason))
                    return "reason: string expected";
            if (message.expoFound != null && message.hasOwnProperty("expoFound"))
                if (!$util.isInteger(message.expoFound))
                    return "expoFound: integer expected";
            return null;
        };

        /**
         * Creates a CalibExposureResult message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof RefDesignProtocol.CalibExposureResult
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {RefDesignProtocol.CalibExposureResult} CalibExposureResult
         */
        CalibExposureResult.fromObject = function fromObject(object) {
            if (object instanceof $root.RefDesignProtocol.CalibExposureResult)
                return object;
            let message = new $root.RefDesignProtocol.CalibExposureResult();
            if (object.success != null)
                message.success = Boolean(object.success);
            if (object.reason != null)
                message.reason = String(object.reason);
            if (object.expoFound != null)
                message.expoFound = object.expoFound >>> 0;
            return message;
        };

        /**
         * Creates a plain object from a CalibExposureResult message. Also converts values to other types if specified.
         * @function toObject
         * @memberof RefDesignProtocol.CalibExposureResult
         * @static
         * @param {RefDesignProtocol.CalibExposureResult} message CalibExposureResult
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        CalibExposureResult.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.success = false;
                object.reason = "";
                object.expoFound = 0;
            }
            if (message.success != null && message.hasOwnProperty("success"))
                object.success = message.success;
            if (message.reason != null && message.hasOwnProperty("reason"))
                object.reason = message.reason;
            if (message.expoFound != null && message.hasOwnProperty("expoFound"))
                object.expoFound = message.expoFound;
            return object;
        };

        /**
         * Converts this CalibExposureResult to JSON.
         * @function toJSON
         * @memberof RefDesignProtocol.CalibExposureResult
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        CalibExposureResult.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for CalibExposureResult
         * @function getTypeUrl
         * @memberof RefDesignProtocol.CalibExposureResult
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        CalibExposureResult.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/RefDesignProtocol.CalibExposureResult";
        };

        return CalibExposureResult;
    })();

    RefDesignProtocol.CalibPeakMaskCommand = (function() {

        /**
         * Properties of a CalibPeakMaskCommand.
         * @memberof RefDesignProtocol
         * @interface ICalibPeakMaskCommand
         */

        /**
         * Constructs a new CalibPeakMaskCommand.
         * @memberof RefDesignProtocol
         * @classdesc Represents a CalibPeakMaskCommand.
         * @implements ICalibPeakMaskCommand
         * @constructor
         * @param {RefDesignProtocol.ICalibPeakMaskCommand=} [properties] Properties to set
         */
        function CalibPeakMaskCommand(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Creates a new CalibPeakMaskCommand instance using the specified properties.
         * @function create
         * @memberof RefDesignProtocol.CalibPeakMaskCommand
         * @static
         * @param {RefDesignProtocol.ICalibPeakMaskCommand=} [properties] Properties to set
         * @returns {RefDesignProtocol.CalibPeakMaskCommand} CalibPeakMaskCommand instance
         */
        CalibPeakMaskCommand.create = function create(properties) {
            return new CalibPeakMaskCommand(properties);
        };

        /**
         * Encodes the specified CalibPeakMaskCommand message. Does not implicitly {@link RefDesignProtocol.CalibPeakMaskCommand.verify|verify} messages.
         * @function encode
         * @memberof RefDesignProtocol.CalibPeakMaskCommand
         * @static
         * @param {RefDesignProtocol.ICalibPeakMaskCommand} message CalibPeakMaskCommand message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CalibPeakMaskCommand.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            return writer;
        };

        /**
         * Encodes the specified CalibPeakMaskCommand message, length delimited. Does not implicitly {@link RefDesignProtocol.CalibPeakMaskCommand.verify|verify} messages.
         * @function encodeDelimited
         * @memberof RefDesignProtocol.CalibPeakMaskCommand
         * @static
         * @param {RefDesignProtocol.ICalibPeakMaskCommand} message CalibPeakMaskCommand message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CalibPeakMaskCommand.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a CalibPeakMaskCommand message from the specified reader or buffer.
         * @function decode
         * @memberof RefDesignProtocol.CalibPeakMaskCommand
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {RefDesignProtocol.CalibPeakMaskCommand} CalibPeakMaskCommand
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CalibPeakMaskCommand.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.RefDesignProtocol.CalibPeakMaskCommand();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a CalibPeakMaskCommand message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof RefDesignProtocol.CalibPeakMaskCommand
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {RefDesignProtocol.CalibPeakMaskCommand} CalibPeakMaskCommand
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CalibPeakMaskCommand.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a CalibPeakMaskCommand message.
         * @function verify
         * @memberof RefDesignProtocol.CalibPeakMaskCommand
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        CalibPeakMaskCommand.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            return null;
        };

        /**
         * Creates a CalibPeakMaskCommand message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof RefDesignProtocol.CalibPeakMaskCommand
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {RefDesignProtocol.CalibPeakMaskCommand} CalibPeakMaskCommand
         */
        CalibPeakMaskCommand.fromObject = function fromObject(object) {
            if (object instanceof $root.RefDesignProtocol.CalibPeakMaskCommand)
                return object;
            return new $root.RefDesignProtocol.CalibPeakMaskCommand();
        };

        /**
         * Creates a plain object from a CalibPeakMaskCommand message. Also converts values to other types if specified.
         * @function toObject
         * @memberof RefDesignProtocol.CalibPeakMaskCommand
         * @static
         * @param {RefDesignProtocol.CalibPeakMaskCommand} message CalibPeakMaskCommand
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        CalibPeakMaskCommand.toObject = function toObject() {
            return {};
        };

        /**
         * Converts this CalibPeakMaskCommand to JSON.
         * @function toJSON
         * @memberof RefDesignProtocol.CalibPeakMaskCommand
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        CalibPeakMaskCommand.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for CalibPeakMaskCommand
         * @function getTypeUrl
         * @memberof RefDesignProtocol.CalibPeakMaskCommand
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        CalibPeakMaskCommand.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/RefDesignProtocol.CalibPeakMaskCommand";
        };

        return CalibPeakMaskCommand;
    })();

    RefDesignProtocol.CalibPeakMaskResult = (function() {

        /**
         * Properties of a CalibPeakMaskResult.
         * @memberof RefDesignProtocol
         * @interface ICalibPeakMaskResult
         * @property {boolean|null} [success] CalibPeakMaskResult success
         * @property {string|null} [reason] CalibPeakMaskResult reason
         * @property {number|null} [peakFound] CalibPeakMaskResult peakFound
         */

        /**
         * Constructs a new CalibPeakMaskResult.
         * @memberof RefDesignProtocol
         * @classdesc Represents a CalibPeakMaskResult.
         * @implements ICalibPeakMaskResult
         * @constructor
         * @param {RefDesignProtocol.ICalibPeakMaskResult=} [properties] Properties to set
         */
        function CalibPeakMaskResult(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * CalibPeakMaskResult success.
         * @member {boolean} success
         * @memberof RefDesignProtocol.CalibPeakMaskResult
         * @instance
         */
        CalibPeakMaskResult.prototype.success = false;

        /**
         * CalibPeakMaskResult reason.
         * @member {string} reason
         * @memberof RefDesignProtocol.CalibPeakMaskResult
         * @instance
         */
        CalibPeakMaskResult.prototype.reason = "";

        /**
         * CalibPeakMaskResult peakFound.
         * @member {number} peakFound
         * @memberof RefDesignProtocol.CalibPeakMaskResult
         * @instance
         */
        CalibPeakMaskResult.prototype.peakFound = 0;

        /**
         * Creates a new CalibPeakMaskResult instance using the specified properties.
         * @function create
         * @memberof RefDesignProtocol.CalibPeakMaskResult
         * @static
         * @param {RefDesignProtocol.ICalibPeakMaskResult=} [properties] Properties to set
         * @returns {RefDesignProtocol.CalibPeakMaskResult} CalibPeakMaskResult instance
         */
        CalibPeakMaskResult.create = function create(properties) {
            return new CalibPeakMaskResult(properties);
        };

        /**
         * Encodes the specified CalibPeakMaskResult message. Does not implicitly {@link RefDesignProtocol.CalibPeakMaskResult.verify|verify} messages.
         * @function encode
         * @memberof RefDesignProtocol.CalibPeakMaskResult
         * @static
         * @param {RefDesignProtocol.ICalibPeakMaskResult} message CalibPeakMaskResult message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CalibPeakMaskResult.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.success != null && Object.hasOwnProperty.call(message, "success"))
                writer.uint32(/* id 1, wireType 0 =*/8).bool(message.success);
            if (message.reason != null && Object.hasOwnProperty.call(message, "reason"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.reason);
            if (message.peakFound != null && Object.hasOwnProperty.call(message, "peakFound"))
                writer.uint32(/* id 3, wireType 0 =*/24).uint32(message.peakFound);
            return writer;
        };

        /**
         * Encodes the specified CalibPeakMaskResult message, length delimited. Does not implicitly {@link RefDesignProtocol.CalibPeakMaskResult.verify|verify} messages.
         * @function encodeDelimited
         * @memberof RefDesignProtocol.CalibPeakMaskResult
         * @static
         * @param {RefDesignProtocol.ICalibPeakMaskResult} message CalibPeakMaskResult message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CalibPeakMaskResult.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a CalibPeakMaskResult message from the specified reader or buffer.
         * @function decode
         * @memberof RefDesignProtocol.CalibPeakMaskResult
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {RefDesignProtocol.CalibPeakMaskResult} CalibPeakMaskResult
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CalibPeakMaskResult.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.RefDesignProtocol.CalibPeakMaskResult();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.success = reader.bool();
                        break;
                    }
                case 2: {
                        message.reason = reader.string();
                        break;
                    }
                case 3: {
                        message.peakFound = reader.uint32();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a CalibPeakMaskResult message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof RefDesignProtocol.CalibPeakMaskResult
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {RefDesignProtocol.CalibPeakMaskResult} CalibPeakMaskResult
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CalibPeakMaskResult.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a CalibPeakMaskResult message.
         * @function verify
         * @memberof RefDesignProtocol.CalibPeakMaskResult
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        CalibPeakMaskResult.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.success != null && message.hasOwnProperty("success"))
                if (typeof message.success !== "boolean")
                    return "success: boolean expected";
            if (message.reason != null && message.hasOwnProperty("reason"))
                if (!$util.isString(message.reason))
                    return "reason: string expected";
            if (message.peakFound != null && message.hasOwnProperty("peakFound"))
                if (!$util.isInteger(message.peakFound))
                    return "peakFound: integer expected";
            return null;
        };

        /**
         * Creates a CalibPeakMaskResult message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof RefDesignProtocol.CalibPeakMaskResult
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {RefDesignProtocol.CalibPeakMaskResult} CalibPeakMaskResult
         */
        CalibPeakMaskResult.fromObject = function fromObject(object) {
            if (object instanceof $root.RefDesignProtocol.CalibPeakMaskResult)
                return object;
            let message = new $root.RefDesignProtocol.CalibPeakMaskResult();
            if (object.success != null)
                message.success = Boolean(object.success);
            if (object.reason != null)
                message.reason = String(object.reason);
            if (object.peakFound != null)
                message.peakFound = object.peakFound >>> 0;
            return message;
        };

        /**
         * Creates a plain object from a CalibPeakMaskResult message. Also converts values to other types if specified.
         * @function toObject
         * @memberof RefDesignProtocol.CalibPeakMaskResult
         * @static
         * @param {RefDesignProtocol.CalibPeakMaskResult} message CalibPeakMaskResult
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        CalibPeakMaskResult.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.success = false;
                object.reason = "";
                object.peakFound = 0;
            }
            if (message.success != null && message.hasOwnProperty("success"))
                object.success = message.success;
            if (message.reason != null && message.hasOwnProperty("reason"))
                object.reason = message.reason;
            if (message.peakFound != null && message.hasOwnProperty("peakFound"))
                object.peakFound = message.peakFound;
            return object;
        };

        /**
         * Converts this CalibPeakMaskResult to JSON.
         * @function toJSON
         * @memberof RefDesignProtocol.CalibPeakMaskResult
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        CalibPeakMaskResult.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for CalibPeakMaskResult
         * @function getTypeUrl
         * @memberof RefDesignProtocol.CalibPeakMaskResult
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        CalibPeakMaskResult.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/RefDesignProtocol.CalibPeakMaskResult";
        };

        return CalibPeakMaskResult;
    })();

    RefDesignProtocol.ResetMinMaxPeaksCommand = (function() {

        /**
         * Properties of a ResetMinMaxPeaksCommand.
         * @memberof RefDesignProtocol
         * @interface IResetMinMaxPeaksCommand
         */

        /**
         * Constructs a new ResetMinMaxPeaksCommand.
         * @memberof RefDesignProtocol
         * @classdesc Represents a ResetMinMaxPeaksCommand.
         * @implements IResetMinMaxPeaksCommand
         * @constructor
         * @param {RefDesignProtocol.IResetMinMaxPeaksCommand=} [properties] Properties to set
         */
        function ResetMinMaxPeaksCommand(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Creates a new ResetMinMaxPeaksCommand instance using the specified properties.
         * @function create
         * @memberof RefDesignProtocol.ResetMinMaxPeaksCommand
         * @static
         * @param {RefDesignProtocol.IResetMinMaxPeaksCommand=} [properties] Properties to set
         * @returns {RefDesignProtocol.ResetMinMaxPeaksCommand} ResetMinMaxPeaksCommand instance
         */
        ResetMinMaxPeaksCommand.create = function create(properties) {
            return new ResetMinMaxPeaksCommand(properties);
        };

        /**
         * Encodes the specified ResetMinMaxPeaksCommand message. Does not implicitly {@link RefDesignProtocol.ResetMinMaxPeaksCommand.verify|verify} messages.
         * @function encode
         * @memberof RefDesignProtocol.ResetMinMaxPeaksCommand
         * @static
         * @param {RefDesignProtocol.IResetMinMaxPeaksCommand} message ResetMinMaxPeaksCommand message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ResetMinMaxPeaksCommand.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            return writer;
        };

        /**
         * Encodes the specified ResetMinMaxPeaksCommand message, length delimited. Does not implicitly {@link RefDesignProtocol.ResetMinMaxPeaksCommand.verify|verify} messages.
         * @function encodeDelimited
         * @memberof RefDesignProtocol.ResetMinMaxPeaksCommand
         * @static
         * @param {RefDesignProtocol.IResetMinMaxPeaksCommand} message ResetMinMaxPeaksCommand message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ResetMinMaxPeaksCommand.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a ResetMinMaxPeaksCommand message from the specified reader or buffer.
         * @function decode
         * @memberof RefDesignProtocol.ResetMinMaxPeaksCommand
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {RefDesignProtocol.ResetMinMaxPeaksCommand} ResetMinMaxPeaksCommand
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ResetMinMaxPeaksCommand.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.RefDesignProtocol.ResetMinMaxPeaksCommand();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a ResetMinMaxPeaksCommand message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof RefDesignProtocol.ResetMinMaxPeaksCommand
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {RefDesignProtocol.ResetMinMaxPeaksCommand} ResetMinMaxPeaksCommand
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ResetMinMaxPeaksCommand.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a ResetMinMaxPeaksCommand message.
         * @function verify
         * @memberof RefDesignProtocol.ResetMinMaxPeaksCommand
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ResetMinMaxPeaksCommand.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            return null;
        };

        /**
         * Creates a ResetMinMaxPeaksCommand message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof RefDesignProtocol.ResetMinMaxPeaksCommand
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {RefDesignProtocol.ResetMinMaxPeaksCommand} ResetMinMaxPeaksCommand
         */
        ResetMinMaxPeaksCommand.fromObject = function fromObject(object) {
            if (object instanceof $root.RefDesignProtocol.ResetMinMaxPeaksCommand)
                return object;
            return new $root.RefDesignProtocol.ResetMinMaxPeaksCommand();
        };

        /**
         * Creates a plain object from a ResetMinMaxPeaksCommand message. Also converts values to other types if specified.
         * @function toObject
         * @memberof RefDesignProtocol.ResetMinMaxPeaksCommand
         * @static
         * @param {RefDesignProtocol.ResetMinMaxPeaksCommand} message ResetMinMaxPeaksCommand
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ResetMinMaxPeaksCommand.toObject = function toObject() {
            return {};
        };

        /**
         * Converts this ResetMinMaxPeaksCommand to JSON.
         * @function toJSON
         * @memberof RefDesignProtocol.ResetMinMaxPeaksCommand
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ResetMinMaxPeaksCommand.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for ResetMinMaxPeaksCommand
         * @function getTypeUrl
         * @memberof RefDesignProtocol.ResetMinMaxPeaksCommand
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        ResetMinMaxPeaksCommand.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/RefDesignProtocol.ResetMinMaxPeaksCommand";
        };

        return ResetMinMaxPeaksCommand;
    })();

    RefDesignProtocol.GetMinMaxPeaksCommand = (function() {

        /**
         * Properties of a GetMinMaxPeaksCommand.
         * @memberof RefDesignProtocol
         * @interface IGetMinMaxPeaksCommand
         */

        /**
         * Constructs a new GetMinMaxPeaksCommand.
         * @memberof RefDesignProtocol
         * @classdesc Represents a GetMinMaxPeaksCommand.
         * @implements IGetMinMaxPeaksCommand
         * @constructor
         * @param {RefDesignProtocol.IGetMinMaxPeaksCommand=} [properties] Properties to set
         */
        function GetMinMaxPeaksCommand(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Creates a new GetMinMaxPeaksCommand instance using the specified properties.
         * @function create
         * @memberof RefDesignProtocol.GetMinMaxPeaksCommand
         * @static
         * @param {RefDesignProtocol.IGetMinMaxPeaksCommand=} [properties] Properties to set
         * @returns {RefDesignProtocol.GetMinMaxPeaksCommand} GetMinMaxPeaksCommand instance
         */
        GetMinMaxPeaksCommand.create = function create(properties) {
            return new GetMinMaxPeaksCommand(properties);
        };

        /**
         * Encodes the specified GetMinMaxPeaksCommand message. Does not implicitly {@link RefDesignProtocol.GetMinMaxPeaksCommand.verify|verify} messages.
         * @function encode
         * @memberof RefDesignProtocol.GetMinMaxPeaksCommand
         * @static
         * @param {RefDesignProtocol.IGetMinMaxPeaksCommand} message GetMinMaxPeaksCommand message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GetMinMaxPeaksCommand.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            return writer;
        };

        /**
         * Encodes the specified GetMinMaxPeaksCommand message, length delimited. Does not implicitly {@link RefDesignProtocol.GetMinMaxPeaksCommand.verify|verify} messages.
         * @function encodeDelimited
         * @memberof RefDesignProtocol.GetMinMaxPeaksCommand
         * @static
         * @param {RefDesignProtocol.IGetMinMaxPeaksCommand} message GetMinMaxPeaksCommand message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GetMinMaxPeaksCommand.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a GetMinMaxPeaksCommand message from the specified reader or buffer.
         * @function decode
         * @memberof RefDesignProtocol.GetMinMaxPeaksCommand
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {RefDesignProtocol.GetMinMaxPeaksCommand} GetMinMaxPeaksCommand
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GetMinMaxPeaksCommand.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.RefDesignProtocol.GetMinMaxPeaksCommand();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a GetMinMaxPeaksCommand message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof RefDesignProtocol.GetMinMaxPeaksCommand
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {RefDesignProtocol.GetMinMaxPeaksCommand} GetMinMaxPeaksCommand
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GetMinMaxPeaksCommand.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a GetMinMaxPeaksCommand message.
         * @function verify
         * @memberof RefDesignProtocol.GetMinMaxPeaksCommand
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        GetMinMaxPeaksCommand.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            return null;
        };

        /**
         * Creates a GetMinMaxPeaksCommand message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof RefDesignProtocol.GetMinMaxPeaksCommand
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {RefDesignProtocol.GetMinMaxPeaksCommand} GetMinMaxPeaksCommand
         */
        GetMinMaxPeaksCommand.fromObject = function fromObject(object) {
            if (object instanceof $root.RefDesignProtocol.GetMinMaxPeaksCommand)
                return object;
            return new $root.RefDesignProtocol.GetMinMaxPeaksCommand();
        };

        /**
         * Creates a plain object from a GetMinMaxPeaksCommand message. Also converts values to other types if specified.
         * @function toObject
         * @memberof RefDesignProtocol.GetMinMaxPeaksCommand
         * @static
         * @param {RefDesignProtocol.GetMinMaxPeaksCommand} message GetMinMaxPeaksCommand
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        GetMinMaxPeaksCommand.toObject = function toObject() {
            return {};
        };

        /**
         * Converts this GetMinMaxPeaksCommand to JSON.
         * @function toJSON
         * @memberof RefDesignProtocol.GetMinMaxPeaksCommand
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        GetMinMaxPeaksCommand.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for GetMinMaxPeaksCommand
         * @function getTypeUrl
         * @memberof RefDesignProtocol.GetMinMaxPeaksCommand
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        GetMinMaxPeaksCommand.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/RefDesignProtocol.GetMinMaxPeaksCommand";
        };

        return GetMinMaxPeaksCommand;
    })();

    RefDesignProtocol.MinMaxPeaksResult = (function() {

        /**
         * Properties of a MinMaxPeaksResult.
         * @memberof RefDesignProtocol
         * @interface IMinMaxPeaksResult
         * @property {Array.<number>|null} [minPeaks] MinMaxPeaksResult minPeaks
         * @property {Array.<number>|null} [maxPeaks] MinMaxPeaksResult maxPeaks
         */

        /**
         * Constructs a new MinMaxPeaksResult.
         * @memberof RefDesignProtocol
         * @classdesc Represents a MinMaxPeaksResult.
         * @implements IMinMaxPeaksResult
         * @constructor
         * @param {RefDesignProtocol.IMinMaxPeaksResult=} [properties] Properties to set
         */
        function MinMaxPeaksResult(properties) {
            this.minPeaks = [];
            this.maxPeaks = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * MinMaxPeaksResult minPeaks.
         * @member {Array.<number>} minPeaks
         * @memberof RefDesignProtocol.MinMaxPeaksResult
         * @instance
         */
        MinMaxPeaksResult.prototype.minPeaks = $util.emptyArray;

        /**
         * MinMaxPeaksResult maxPeaks.
         * @member {Array.<number>} maxPeaks
         * @memberof RefDesignProtocol.MinMaxPeaksResult
         * @instance
         */
        MinMaxPeaksResult.prototype.maxPeaks = $util.emptyArray;

        /**
         * Creates a new MinMaxPeaksResult instance using the specified properties.
         * @function create
         * @memberof RefDesignProtocol.MinMaxPeaksResult
         * @static
         * @param {RefDesignProtocol.IMinMaxPeaksResult=} [properties] Properties to set
         * @returns {RefDesignProtocol.MinMaxPeaksResult} MinMaxPeaksResult instance
         */
        MinMaxPeaksResult.create = function create(properties) {
            return new MinMaxPeaksResult(properties);
        };

        /**
         * Encodes the specified MinMaxPeaksResult message. Does not implicitly {@link RefDesignProtocol.MinMaxPeaksResult.verify|verify} messages.
         * @function encode
         * @memberof RefDesignProtocol.MinMaxPeaksResult
         * @static
         * @param {RefDesignProtocol.IMinMaxPeaksResult} message MinMaxPeaksResult message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        MinMaxPeaksResult.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.minPeaks != null && message.minPeaks.length) {
                writer.uint32(/* id 1, wireType 2 =*/10).fork();
                for (let i = 0; i < message.minPeaks.length; ++i)
                    writer.float(message.minPeaks[i]);
                writer.ldelim();
            }
            if (message.maxPeaks != null && message.maxPeaks.length) {
                writer.uint32(/* id 2, wireType 2 =*/18).fork();
                for (let i = 0; i < message.maxPeaks.length; ++i)
                    writer.float(message.maxPeaks[i]);
                writer.ldelim();
            }
            return writer;
        };

        /**
         * Encodes the specified MinMaxPeaksResult message, length delimited. Does not implicitly {@link RefDesignProtocol.MinMaxPeaksResult.verify|verify} messages.
         * @function encodeDelimited
         * @memberof RefDesignProtocol.MinMaxPeaksResult
         * @static
         * @param {RefDesignProtocol.IMinMaxPeaksResult} message MinMaxPeaksResult message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        MinMaxPeaksResult.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a MinMaxPeaksResult message from the specified reader or buffer.
         * @function decode
         * @memberof RefDesignProtocol.MinMaxPeaksResult
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {RefDesignProtocol.MinMaxPeaksResult} MinMaxPeaksResult
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        MinMaxPeaksResult.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.RefDesignProtocol.MinMaxPeaksResult();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        if (!(message.minPeaks && message.minPeaks.length))
                            message.minPeaks = [];
                        if ((tag & 7) === 2) {
                            let end2 = reader.uint32() + reader.pos;
                            while (reader.pos < end2)
                                message.minPeaks.push(reader.float());
                        } else
                            message.minPeaks.push(reader.float());
                        break;
                    }
                case 2: {
                        if (!(message.maxPeaks && message.maxPeaks.length))
                            message.maxPeaks = [];
                        if ((tag & 7) === 2) {
                            let end2 = reader.uint32() + reader.pos;
                            while (reader.pos < end2)
                                message.maxPeaks.push(reader.float());
                        } else
                            message.maxPeaks.push(reader.float());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a MinMaxPeaksResult message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof RefDesignProtocol.MinMaxPeaksResult
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {RefDesignProtocol.MinMaxPeaksResult} MinMaxPeaksResult
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        MinMaxPeaksResult.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a MinMaxPeaksResult message.
         * @function verify
         * @memberof RefDesignProtocol.MinMaxPeaksResult
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        MinMaxPeaksResult.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.minPeaks != null && message.hasOwnProperty("minPeaks")) {
                if (!Array.isArray(message.minPeaks))
                    return "minPeaks: array expected";
                for (let i = 0; i < message.minPeaks.length; ++i)
                    if (typeof message.minPeaks[i] !== "number")
                        return "minPeaks: number[] expected";
            }
            if (message.maxPeaks != null && message.hasOwnProperty("maxPeaks")) {
                if (!Array.isArray(message.maxPeaks))
                    return "maxPeaks: array expected";
                for (let i = 0; i < message.maxPeaks.length; ++i)
                    if (typeof message.maxPeaks[i] !== "number")
                        return "maxPeaks: number[] expected";
            }
            return null;
        };

        /**
         * Creates a MinMaxPeaksResult message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof RefDesignProtocol.MinMaxPeaksResult
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {RefDesignProtocol.MinMaxPeaksResult} MinMaxPeaksResult
         */
        MinMaxPeaksResult.fromObject = function fromObject(object) {
            if (object instanceof $root.RefDesignProtocol.MinMaxPeaksResult)
                return object;
            let message = new $root.RefDesignProtocol.MinMaxPeaksResult();
            if (object.minPeaks) {
                if (!Array.isArray(object.minPeaks))
                    throw TypeError(".RefDesignProtocol.MinMaxPeaksResult.minPeaks: array expected");
                message.minPeaks = [];
                for (let i = 0; i < object.minPeaks.length; ++i)
                    message.minPeaks[i] = Number(object.minPeaks[i]);
            }
            if (object.maxPeaks) {
                if (!Array.isArray(object.maxPeaks))
                    throw TypeError(".RefDesignProtocol.MinMaxPeaksResult.maxPeaks: array expected");
                message.maxPeaks = [];
                for (let i = 0; i < object.maxPeaks.length; ++i)
                    message.maxPeaks[i] = Number(object.maxPeaks[i]);
            }
            return message;
        };

        /**
         * Creates a plain object from a MinMaxPeaksResult message. Also converts values to other types if specified.
         * @function toObject
         * @memberof RefDesignProtocol.MinMaxPeaksResult
         * @static
         * @param {RefDesignProtocol.MinMaxPeaksResult} message MinMaxPeaksResult
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        MinMaxPeaksResult.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults) {
                object.minPeaks = [];
                object.maxPeaks = [];
            }
            if (message.minPeaks && message.minPeaks.length) {
                object.minPeaks = [];
                for (let j = 0; j < message.minPeaks.length; ++j)
                    object.minPeaks[j] = options.json && !isFinite(message.minPeaks[j]) ? String(message.minPeaks[j]) : message.minPeaks[j];
            }
            if (message.maxPeaks && message.maxPeaks.length) {
                object.maxPeaks = [];
                for (let j = 0; j < message.maxPeaks.length; ++j)
                    object.maxPeaks[j] = options.json && !isFinite(message.maxPeaks[j]) ? String(message.maxPeaks[j]) : message.maxPeaks[j];
            }
            return object;
        };

        /**
         * Converts this MinMaxPeaksResult to JSON.
         * @function toJSON
         * @memberof RefDesignProtocol.MinMaxPeaksResult
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        MinMaxPeaksResult.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for MinMaxPeaksResult
         * @function getTypeUrl
         * @memberof RefDesignProtocol.MinMaxPeaksResult
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        MinMaxPeaksResult.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/RefDesignProtocol.MinMaxPeaksResult";
        };

        return MinMaxPeaksResult;
    })();

    RefDesignProtocol.ResetMziStaticContrastCommand = (function() {

        /**
         * Properties of a ResetMziStaticContrastCommand.
         * @memberof RefDesignProtocol
         * @interface IResetMziStaticContrastCommand
         */

        /**
         * Constructs a new ResetMziStaticContrastCommand.
         * @memberof RefDesignProtocol
         * @classdesc Represents a ResetMziStaticContrastCommand.
         * @implements IResetMziStaticContrastCommand
         * @constructor
         * @param {RefDesignProtocol.IResetMziStaticContrastCommand=} [properties] Properties to set
         */
        function ResetMziStaticContrastCommand(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Creates a new ResetMziStaticContrastCommand instance using the specified properties.
         * @function create
         * @memberof RefDesignProtocol.ResetMziStaticContrastCommand
         * @static
         * @param {RefDesignProtocol.IResetMziStaticContrastCommand=} [properties] Properties to set
         * @returns {RefDesignProtocol.ResetMziStaticContrastCommand} ResetMziStaticContrastCommand instance
         */
        ResetMziStaticContrastCommand.create = function create(properties) {
            return new ResetMziStaticContrastCommand(properties);
        };

        /**
         * Encodes the specified ResetMziStaticContrastCommand message. Does not implicitly {@link RefDesignProtocol.ResetMziStaticContrastCommand.verify|verify} messages.
         * @function encode
         * @memberof RefDesignProtocol.ResetMziStaticContrastCommand
         * @static
         * @param {RefDesignProtocol.IResetMziStaticContrastCommand} message ResetMziStaticContrastCommand message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ResetMziStaticContrastCommand.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            return writer;
        };

        /**
         * Encodes the specified ResetMziStaticContrastCommand message, length delimited. Does not implicitly {@link RefDesignProtocol.ResetMziStaticContrastCommand.verify|verify} messages.
         * @function encodeDelimited
         * @memberof RefDesignProtocol.ResetMziStaticContrastCommand
         * @static
         * @param {RefDesignProtocol.IResetMziStaticContrastCommand} message ResetMziStaticContrastCommand message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ResetMziStaticContrastCommand.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a ResetMziStaticContrastCommand message from the specified reader or buffer.
         * @function decode
         * @memberof RefDesignProtocol.ResetMziStaticContrastCommand
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {RefDesignProtocol.ResetMziStaticContrastCommand} ResetMziStaticContrastCommand
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ResetMziStaticContrastCommand.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.RefDesignProtocol.ResetMziStaticContrastCommand();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a ResetMziStaticContrastCommand message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof RefDesignProtocol.ResetMziStaticContrastCommand
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {RefDesignProtocol.ResetMziStaticContrastCommand} ResetMziStaticContrastCommand
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ResetMziStaticContrastCommand.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a ResetMziStaticContrastCommand message.
         * @function verify
         * @memberof RefDesignProtocol.ResetMziStaticContrastCommand
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ResetMziStaticContrastCommand.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            return null;
        };

        /**
         * Creates a ResetMziStaticContrastCommand message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof RefDesignProtocol.ResetMziStaticContrastCommand
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {RefDesignProtocol.ResetMziStaticContrastCommand} ResetMziStaticContrastCommand
         */
        ResetMziStaticContrastCommand.fromObject = function fromObject(object) {
            if (object instanceof $root.RefDesignProtocol.ResetMziStaticContrastCommand)
                return object;
            return new $root.RefDesignProtocol.ResetMziStaticContrastCommand();
        };

        /**
         * Creates a plain object from a ResetMziStaticContrastCommand message. Also converts values to other types if specified.
         * @function toObject
         * @memberof RefDesignProtocol.ResetMziStaticContrastCommand
         * @static
         * @param {RefDesignProtocol.ResetMziStaticContrastCommand} message ResetMziStaticContrastCommand
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ResetMziStaticContrastCommand.toObject = function toObject() {
            return {};
        };

        /**
         * Converts this ResetMziStaticContrastCommand to JSON.
         * @function toJSON
         * @memberof RefDesignProtocol.ResetMziStaticContrastCommand
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ResetMziStaticContrastCommand.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for ResetMziStaticContrastCommand
         * @function getTypeUrl
         * @memberof RefDesignProtocol.ResetMziStaticContrastCommand
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        ResetMziStaticContrastCommand.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/RefDesignProtocol.ResetMziStaticContrastCommand";
        };

        return ResetMziStaticContrastCommand;
    })();

    RefDesignProtocol.GetMziStaticContrastCommand = (function() {

        /**
         * Properties of a GetMziStaticContrastCommand.
         * @memberof RefDesignProtocol
         * @interface IGetMziStaticContrastCommand
         */

        /**
         * Constructs a new GetMziStaticContrastCommand.
         * @memberof RefDesignProtocol
         * @classdesc Represents a GetMziStaticContrastCommand.
         * @implements IGetMziStaticContrastCommand
         * @constructor
         * @param {RefDesignProtocol.IGetMziStaticContrastCommand=} [properties] Properties to set
         */
        function GetMziStaticContrastCommand(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Creates a new GetMziStaticContrastCommand instance using the specified properties.
         * @function create
         * @memberof RefDesignProtocol.GetMziStaticContrastCommand
         * @static
         * @param {RefDesignProtocol.IGetMziStaticContrastCommand=} [properties] Properties to set
         * @returns {RefDesignProtocol.GetMziStaticContrastCommand} GetMziStaticContrastCommand instance
         */
        GetMziStaticContrastCommand.create = function create(properties) {
            return new GetMziStaticContrastCommand(properties);
        };

        /**
         * Encodes the specified GetMziStaticContrastCommand message. Does not implicitly {@link RefDesignProtocol.GetMziStaticContrastCommand.verify|verify} messages.
         * @function encode
         * @memberof RefDesignProtocol.GetMziStaticContrastCommand
         * @static
         * @param {RefDesignProtocol.IGetMziStaticContrastCommand} message GetMziStaticContrastCommand message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GetMziStaticContrastCommand.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            return writer;
        };

        /**
         * Encodes the specified GetMziStaticContrastCommand message, length delimited. Does not implicitly {@link RefDesignProtocol.GetMziStaticContrastCommand.verify|verify} messages.
         * @function encodeDelimited
         * @memberof RefDesignProtocol.GetMziStaticContrastCommand
         * @static
         * @param {RefDesignProtocol.IGetMziStaticContrastCommand} message GetMziStaticContrastCommand message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GetMziStaticContrastCommand.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a GetMziStaticContrastCommand message from the specified reader or buffer.
         * @function decode
         * @memberof RefDesignProtocol.GetMziStaticContrastCommand
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {RefDesignProtocol.GetMziStaticContrastCommand} GetMziStaticContrastCommand
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GetMziStaticContrastCommand.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.RefDesignProtocol.GetMziStaticContrastCommand();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a GetMziStaticContrastCommand message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof RefDesignProtocol.GetMziStaticContrastCommand
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {RefDesignProtocol.GetMziStaticContrastCommand} GetMziStaticContrastCommand
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GetMziStaticContrastCommand.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a GetMziStaticContrastCommand message.
         * @function verify
         * @memberof RefDesignProtocol.GetMziStaticContrastCommand
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        GetMziStaticContrastCommand.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            return null;
        };

        /**
         * Creates a GetMziStaticContrastCommand message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof RefDesignProtocol.GetMziStaticContrastCommand
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {RefDesignProtocol.GetMziStaticContrastCommand} GetMziStaticContrastCommand
         */
        GetMziStaticContrastCommand.fromObject = function fromObject(object) {
            if (object instanceof $root.RefDesignProtocol.GetMziStaticContrastCommand)
                return object;
            return new $root.RefDesignProtocol.GetMziStaticContrastCommand();
        };

        /**
         * Creates a plain object from a GetMziStaticContrastCommand message. Also converts values to other types if specified.
         * @function toObject
         * @memberof RefDesignProtocol.GetMziStaticContrastCommand
         * @static
         * @param {RefDesignProtocol.GetMziStaticContrastCommand} message GetMziStaticContrastCommand
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        GetMziStaticContrastCommand.toObject = function toObject() {
            return {};
        };

        /**
         * Converts this GetMziStaticContrastCommand to JSON.
         * @function toJSON
         * @memberof RefDesignProtocol.GetMziStaticContrastCommand
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        GetMziStaticContrastCommand.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for GetMziStaticContrastCommand
         * @function getTypeUrl
         * @memberof RefDesignProtocol.GetMziStaticContrastCommand
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        GetMziStaticContrastCommand.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/RefDesignProtocol.GetMziStaticContrastCommand";
        };

        return GetMziStaticContrastCommand;
    })();

    RefDesignProtocol.MziStaticContrastResult = (function() {

        /**
         * Properties of a MziStaticContrastResult.
         * @memberof RefDesignProtocol
         * @interface IMziStaticContrastResult
         * @property {Array.<number>|null} [mziContrastStatic] MziStaticContrastResult mziContrastStatic
         */

        /**
         * Constructs a new MziStaticContrastResult.
         * @memberof RefDesignProtocol
         * @classdesc Represents a MziStaticContrastResult.
         * @implements IMziStaticContrastResult
         * @constructor
         * @param {RefDesignProtocol.IMziStaticContrastResult=} [properties] Properties to set
         */
        function MziStaticContrastResult(properties) {
            this.mziContrastStatic = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * MziStaticContrastResult mziContrastStatic.
         * @member {Array.<number>} mziContrastStatic
         * @memberof RefDesignProtocol.MziStaticContrastResult
         * @instance
         */
        MziStaticContrastResult.prototype.mziContrastStatic = $util.emptyArray;

        /**
         * Creates a new MziStaticContrastResult instance using the specified properties.
         * @function create
         * @memberof RefDesignProtocol.MziStaticContrastResult
         * @static
         * @param {RefDesignProtocol.IMziStaticContrastResult=} [properties] Properties to set
         * @returns {RefDesignProtocol.MziStaticContrastResult} MziStaticContrastResult instance
         */
        MziStaticContrastResult.create = function create(properties) {
            return new MziStaticContrastResult(properties);
        };

        /**
         * Encodes the specified MziStaticContrastResult message. Does not implicitly {@link RefDesignProtocol.MziStaticContrastResult.verify|verify} messages.
         * @function encode
         * @memberof RefDesignProtocol.MziStaticContrastResult
         * @static
         * @param {RefDesignProtocol.IMziStaticContrastResult} message MziStaticContrastResult message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        MziStaticContrastResult.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.mziContrastStatic != null && message.mziContrastStatic.length) {
                writer.uint32(/* id 1, wireType 2 =*/10).fork();
                for (let i = 0; i < message.mziContrastStatic.length; ++i)
                    writer.float(message.mziContrastStatic[i]);
                writer.ldelim();
            }
            return writer;
        };

        /**
         * Encodes the specified MziStaticContrastResult message, length delimited. Does not implicitly {@link RefDesignProtocol.MziStaticContrastResult.verify|verify} messages.
         * @function encodeDelimited
         * @memberof RefDesignProtocol.MziStaticContrastResult
         * @static
         * @param {RefDesignProtocol.IMziStaticContrastResult} message MziStaticContrastResult message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        MziStaticContrastResult.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a MziStaticContrastResult message from the specified reader or buffer.
         * @function decode
         * @memberof RefDesignProtocol.MziStaticContrastResult
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {RefDesignProtocol.MziStaticContrastResult} MziStaticContrastResult
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        MziStaticContrastResult.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.RefDesignProtocol.MziStaticContrastResult();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        if (!(message.mziContrastStatic && message.mziContrastStatic.length))
                            message.mziContrastStatic = [];
                        if ((tag & 7) === 2) {
                            let end2 = reader.uint32() + reader.pos;
                            while (reader.pos < end2)
                                message.mziContrastStatic.push(reader.float());
                        } else
                            message.mziContrastStatic.push(reader.float());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a MziStaticContrastResult message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof RefDesignProtocol.MziStaticContrastResult
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {RefDesignProtocol.MziStaticContrastResult} MziStaticContrastResult
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        MziStaticContrastResult.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a MziStaticContrastResult message.
         * @function verify
         * @memberof RefDesignProtocol.MziStaticContrastResult
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        MziStaticContrastResult.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.mziContrastStatic != null && message.hasOwnProperty("mziContrastStatic")) {
                if (!Array.isArray(message.mziContrastStatic))
                    return "mziContrastStatic: array expected";
                for (let i = 0; i < message.mziContrastStatic.length; ++i)
                    if (typeof message.mziContrastStatic[i] !== "number")
                        return "mziContrastStatic: number[] expected";
            }
            return null;
        };

        /**
         * Creates a MziStaticContrastResult message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof RefDesignProtocol.MziStaticContrastResult
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {RefDesignProtocol.MziStaticContrastResult} MziStaticContrastResult
         */
        MziStaticContrastResult.fromObject = function fromObject(object) {
            if (object instanceof $root.RefDesignProtocol.MziStaticContrastResult)
                return object;
            let message = new $root.RefDesignProtocol.MziStaticContrastResult();
            if (object.mziContrastStatic) {
                if (!Array.isArray(object.mziContrastStatic))
                    throw TypeError(".RefDesignProtocol.MziStaticContrastResult.mziContrastStatic: array expected");
                message.mziContrastStatic = [];
                for (let i = 0; i < object.mziContrastStatic.length; ++i)
                    message.mziContrastStatic[i] = Number(object.mziContrastStatic[i]);
            }
            return message;
        };

        /**
         * Creates a plain object from a MziStaticContrastResult message. Also converts values to other types if specified.
         * @function toObject
         * @memberof RefDesignProtocol.MziStaticContrastResult
         * @static
         * @param {RefDesignProtocol.MziStaticContrastResult} message MziStaticContrastResult
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        MziStaticContrastResult.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults)
                object.mziContrastStatic = [];
            if (message.mziContrastStatic && message.mziContrastStatic.length) {
                object.mziContrastStatic = [];
                for (let j = 0; j < message.mziContrastStatic.length; ++j)
                    object.mziContrastStatic[j] = options.json && !isFinite(message.mziContrastStatic[j]) ? String(message.mziContrastStatic[j]) : message.mziContrastStatic[j];
            }
            return object;
        };

        /**
         * Converts this MziStaticContrastResult to JSON.
         * @function toJSON
         * @memberof RefDesignProtocol.MziStaticContrastResult
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        MziStaticContrastResult.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for MziStaticContrastResult
         * @function getTypeUrl
         * @memberof RefDesignProtocol.MziStaticContrastResult
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        MziStaticContrastResult.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/RefDesignProtocol.MziStaticContrastResult";
        };

        return MziStaticContrastResult;
    })();

    RefDesignProtocol.GetMemoryInfoCommand = (function() {

        /**
         * Properties of a GetMemoryInfoCommand.
         * @memberof RefDesignProtocol
         * @interface IGetMemoryInfoCommand
         * @property {RefDesignProtocol.MemoryBank|null} [bank] GetMemoryInfoCommand bank
         */

        /**
         * Constructs a new GetMemoryInfoCommand.
         * @memberof RefDesignProtocol
         * @classdesc Represents a GetMemoryInfoCommand.
         * @implements IGetMemoryInfoCommand
         * @constructor
         * @param {RefDesignProtocol.IGetMemoryInfoCommand=} [properties] Properties to set
         */
        function GetMemoryInfoCommand(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * GetMemoryInfoCommand bank.
         * @member {RefDesignProtocol.MemoryBank} bank
         * @memberof RefDesignProtocol.GetMemoryInfoCommand
         * @instance
         */
        GetMemoryInfoCommand.prototype.bank = 0;

        /**
         * Creates a new GetMemoryInfoCommand instance using the specified properties.
         * @function create
         * @memberof RefDesignProtocol.GetMemoryInfoCommand
         * @static
         * @param {RefDesignProtocol.IGetMemoryInfoCommand=} [properties] Properties to set
         * @returns {RefDesignProtocol.GetMemoryInfoCommand} GetMemoryInfoCommand instance
         */
        GetMemoryInfoCommand.create = function create(properties) {
            return new GetMemoryInfoCommand(properties);
        };

        /**
         * Encodes the specified GetMemoryInfoCommand message. Does not implicitly {@link RefDesignProtocol.GetMemoryInfoCommand.verify|verify} messages.
         * @function encode
         * @memberof RefDesignProtocol.GetMemoryInfoCommand
         * @static
         * @param {RefDesignProtocol.IGetMemoryInfoCommand} message GetMemoryInfoCommand message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GetMemoryInfoCommand.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.bank != null && Object.hasOwnProperty.call(message, "bank"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.bank);
            return writer;
        };

        /**
         * Encodes the specified GetMemoryInfoCommand message, length delimited. Does not implicitly {@link RefDesignProtocol.GetMemoryInfoCommand.verify|verify} messages.
         * @function encodeDelimited
         * @memberof RefDesignProtocol.GetMemoryInfoCommand
         * @static
         * @param {RefDesignProtocol.IGetMemoryInfoCommand} message GetMemoryInfoCommand message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GetMemoryInfoCommand.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a GetMemoryInfoCommand message from the specified reader or buffer.
         * @function decode
         * @memberof RefDesignProtocol.GetMemoryInfoCommand
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {RefDesignProtocol.GetMemoryInfoCommand} GetMemoryInfoCommand
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GetMemoryInfoCommand.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.RefDesignProtocol.GetMemoryInfoCommand();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.bank = reader.int32();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a GetMemoryInfoCommand message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof RefDesignProtocol.GetMemoryInfoCommand
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {RefDesignProtocol.GetMemoryInfoCommand} GetMemoryInfoCommand
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GetMemoryInfoCommand.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a GetMemoryInfoCommand message.
         * @function verify
         * @memberof RefDesignProtocol.GetMemoryInfoCommand
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        GetMemoryInfoCommand.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.bank != null && message.hasOwnProperty("bank"))
                switch (message.bank) {
                default:
                    return "bank: enum value expected";
                case 0:
                case 1:
                case 2:
                case 3:
                case 4:
                    break;
                }
            return null;
        };

        /**
         * Creates a GetMemoryInfoCommand message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof RefDesignProtocol.GetMemoryInfoCommand
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {RefDesignProtocol.GetMemoryInfoCommand} GetMemoryInfoCommand
         */
        GetMemoryInfoCommand.fromObject = function fromObject(object) {
            if (object instanceof $root.RefDesignProtocol.GetMemoryInfoCommand)
                return object;
            let message = new $root.RefDesignProtocol.GetMemoryInfoCommand();
            switch (object.bank) {
            default:
                if (typeof object.bank === "number") {
                    message.bank = object.bank;
                    break;
                }
                break;
            case "MemoryMain":
            case 0:
                message.bank = 0;
                break;
            case "MemoryCoreSensor":
            case 1:
                message.bank = 1;
                break;
            case "MemoryImage":
            case 2:
                message.bank = 2;
                break;
            case "MemoryAlgo":
            case 3:
                message.bank = 3;
                break;
            case "MemoryThimphu":
            case 4:
                message.bank = 4;
                break;
            }
            return message;
        };

        /**
         * Creates a plain object from a GetMemoryInfoCommand message. Also converts values to other types if specified.
         * @function toObject
         * @memberof RefDesignProtocol.GetMemoryInfoCommand
         * @static
         * @param {RefDesignProtocol.GetMemoryInfoCommand} message GetMemoryInfoCommand
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        GetMemoryInfoCommand.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                object.bank = options.enums === String ? "MemoryMain" : 0;
            if (message.bank != null && message.hasOwnProperty("bank"))
                object.bank = options.enums === String ? $root.RefDesignProtocol.MemoryBank[message.bank] === undefined ? message.bank : $root.RefDesignProtocol.MemoryBank[message.bank] : message.bank;
            return object;
        };

        /**
         * Converts this GetMemoryInfoCommand to JSON.
         * @function toJSON
         * @memberof RefDesignProtocol.GetMemoryInfoCommand
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        GetMemoryInfoCommand.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for GetMemoryInfoCommand
         * @function getTypeUrl
         * @memberof RefDesignProtocol.GetMemoryInfoCommand
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        GetMemoryInfoCommand.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/RefDesignProtocol.GetMemoryInfoCommand";
        };

        return GetMemoryInfoCommand;
    })();

    RefDesignProtocol.MemoryInfoResult = (function() {

        /**
         * Properties of a MemoryInfoResult.
         * @memberof RefDesignProtocol
         * @interface IMemoryInfoResult
         * @property {number|null} [size] MemoryInfoResult size
         * @property {number|null} [readBufferSize] MemoryInfoResult readBufferSize
         * @property {number|null} [writeBufferSize] MemoryInfoResult writeBufferSize
         */

        /**
         * Constructs a new MemoryInfoResult.
         * @memberof RefDesignProtocol
         * @classdesc Represents a MemoryInfoResult.
         * @implements IMemoryInfoResult
         * @constructor
         * @param {RefDesignProtocol.IMemoryInfoResult=} [properties] Properties to set
         */
        function MemoryInfoResult(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * MemoryInfoResult size.
         * @member {number} size
         * @memberof RefDesignProtocol.MemoryInfoResult
         * @instance
         */
        MemoryInfoResult.prototype.size = 0;

        /**
         * MemoryInfoResult readBufferSize.
         * @member {number} readBufferSize
         * @memberof RefDesignProtocol.MemoryInfoResult
         * @instance
         */
        MemoryInfoResult.prototype.readBufferSize = 0;

        /**
         * MemoryInfoResult writeBufferSize.
         * @member {number} writeBufferSize
         * @memberof RefDesignProtocol.MemoryInfoResult
         * @instance
         */
        MemoryInfoResult.prototype.writeBufferSize = 0;

        /**
         * Creates a new MemoryInfoResult instance using the specified properties.
         * @function create
         * @memberof RefDesignProtocol.MemoryInfoResult
         * @static
         * @param {RefDesignProtocol.IMemoryInfoResult=} [properties] Properties to set
         * @returns {RefDesignProtocol.MemoryInfoResult} MemoryInfoResult instance
         */
        MemoryInfoResult.create = function create(properties) {
            return new MemoryInfoResult(properties);
        };

        /**
         * Encodes the specified MemoryInfoResult message. Does not implicitly {@link RefDesignProtocol.MemoryInfoResult.verify|verify} messages.
         * @function encode
         * @memberof RefDesignProtocol.MemoryInfoResult
         * @static
         * @param {RefDesignProtocol.IMemoryInfoResult} message MemoryInfoResult message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        MemoryInfoResult.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.size != null && Object.hasOwnProperty.call(message, "size"))
                writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.size);
            if (message.readBufferSize != null && Object.hasOwnProperty.call(message, "readBufferSize"))
                writer.uint32(/* id 2, wireType 0 =*/16).uint32(message.readBufferSize);
            if (message.writeBufferSize != null && Object.hasOwnProperty.call(message, "writeBufferSize"))
                writer.uint32(/* id 3, wireType 0 =*/24).uint32(message.writeBufferSize);
            return writer;
        };

        /**
         * Encodes the specified MemoryInfoResult message, length delimited. Does not implicitly {@link RefDesignProtocol.MemoryInfoResult.verify|verify} messages.
         * @function encodeDelimited
         * @memberof RefDesignProtocol.MemoryInfoResult
         * @static
         * @param {RefDesignProtocol.IMemoryInfoResult} message MemoryInfoResult message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        MemoryInfoResult.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a MemoryInfoResult message from the specified reader or buffer.
         * @function decode
         * @memberof RefDesignProtocol.MemoryInfoResult
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {RefDesignProtocol.MemoryInfoResult} MemoryInfoResult
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        MemoryInfoResult.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.RefDesignProtocol.MemoryInfoResult();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.size = reader.uint32();
                        break;
                    }
                case 2: {
                        message.readBufferSize = reader.uint32();
                        break;
                    }
                case 3: {
                        message.writeBufferSize = reader.uint32();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a MemoryInfoResult message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof RefDesignProtocol.MemoryInfoResult
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {RefDesignProtocol.MemoryInfoResult} MemoryInfoResult
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        MemoryInfoResult.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a MemoryInfoResult message.
         * @function verify
         * @memberof RefDesignProtocol.MemoryInfoResult
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        MemoryInfoResult.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.size != null && message.hasOwnProperty("size"))
                if (!$util.isInteger(message.size))
                    return "size: integer expected";
            if (message.readBufferSize != null && message.hasOwnProperty("readBufferSize"))
                if (!$util.isInteger(message.readBufferSize))
                    return "readBufferSize: integer expected";
            if (message.writeBufferSize != null && message.hasOwnProperty("writeBufferSize"))
                if (!$util.isInteger(message.writeBufferSize))
                    return "writeBufferSize: integer expected";
            return null;
        };

        /**
         * Creates a MemoryInfoResult message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof RefDesignProtocol.MemoryInfoResult
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {RefDesignProtocol.MemoryInfoResult} MemoryInfoResult
         */
        MemoryInfoResult.fromObject = function fromObject(object) {
            if (object instanceof $root.RefDesignProtocol.MemoryInfoResult)
                return object;
            let message = new $root.RefDesignProtocol.MemoryInfoResult();
            if (object.size != null)
                message.size = object.size >>> 0;
            if (object.readBufferSize != null)
                message.readBufferSize = object.readBufferSize >>> 0;
            if (object.writeBufferSize != null)
                message.writeBufferSize = object.writeBufferSize >>> 0;
            return message;
        };

        /**
         * Creates a plain object from a MemoryInfoResult message. Also converts values to other types if specified.
         * @function toObject
         * @memberof RefDesignProtocol.MemoryInfoResult
         * @static
         * @param {RefDesignProtocol.MemoryInfoResult} message MemoryInfoResult
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        MemoryInfoResult.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.size = 0;
                object.readBufferSize = 0;
                object.writeBufferSize = 0;
            }
            if (message.size != null && message.hasOwnProperty("size"))
                object.size = message.size;
            if (message.readBufferSize != null && message.hasOwnProperty("readBufferSize"))
                object.readBufferSize = message.readBufferSize;
            if (message.writeBufferSize != null && message.hasOwnProperty("writeBufferSize"))
                object.writeBufferSize = message.writeBufferSize;
            return object;
        };

        /**
         * Converts this MemoryInfoResult to JSON.
         * @function toJSON
         * @memberof RefDesignProtocol.MemoryInfoResult
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        MemoryInfoResult.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for MemoryInfoResult
         * @function getTypeUrl
         * @memberof RefDesignProtocol.MemoryInfoResult
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        MemoryInfoResult.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/RefDesignProtocol.MemoryInfoResult";
        };

        return MemoryInfoResult;
    })();

    RefDesignProtocol.ReadMemoryCommand = (function() {

        /**
         * Properties of a ReadMemoryCommand.
         * @memberof RefDesignProtocol
         * @interface IReadMemoryCommand
         * @property {RefDesignProtocol.MemoryBank|null} [bank] ReadMemoryCommand bank
         * @property {number|null} [offset] ReadMemoryCommand offset
         * @property {number|null} [size] ReadMemoryCommand size
         */

        /**
         * Constructs a new ReadMemoryCommand.
         * @memberof RefDesignProtocol
         * @classdesc Represents a ReadMemoryCommand.
         * @implements IReadMemoryCommand
         * @constructor
         * @param {RefDesignProtocol.IReadMemoryCommand=} [properties] Properties to set
         */
        function ReadMemoryCommand(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ReadMemoryCommand bank.
         * @member {RefDesignProtocol.MemoryBank} bank
         * @memberof RefDesignProtocol.ReadMemoryCommand
         * @instance
         */
        ReadMemoryCommand.prototype.bank = 0;

        /**
         * ReadMemoryCommand offset.
         * @member {number} offset
         * @memberof RefDesignProtocol.ReadMemoryCommand
         * @instance
         */
        ReadMemoryCommand.prototype.offset = 0;

        /**
         * ReadMemoryCommand size.
         * @member {number} size
         * @memberof RefDesignProtocol.ReadMemoryCommand
         * @instance
         */
        ReadMemoryCommand.prototype.size = 0;

        /**
         * Creates a new ReadMemoryCommand instance using the specified properties.
         * @function create
         * @memberof RefDesignProtocol.ReadMemoryCommand
         * @static
         * @param {RefDesignProtocol.IReadMemoryCommand=} [properties] Properties to set
         * @returns {RefDesignProtocol.ReadMemoryCommand} ReadMemoryCommand instance
         */
        ReadMemoryCommand.create = function create(properties) {
            return new ReadMemoryCommand(properties);
        };

        /**
         * Encodes the specified ReadMemoryCommand message. Does not implicitly {@link RefDesignProtocol.ReadMemoryCommand.verify|verify} messages.
         * @function encode
         * @memberof RefDesignProtocol.ReadMemoryCommand
         * @static
         * @param {RefDesignProtocol.IReadMemoryCommand} message ReadMemoryCommand message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ReadMemoryCommand.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.bank != null && Object.hasOwnProperty.call(message, "bank"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.bank);
            if (message.offset != null && Object.hasOwnProperty.call(message, "offset"))
                writer.uint32(/* id 2, wireType 0 =*/16).uint32(message.offset);
            if (message.size != null && Object.hasOwnProperty.call(message, "size"))
                writer.uint32(/* id 3, wireType 0 =*/24).uint32(message.size);
            return writer;
        };

        /**
         * Encodes the specified ReadMemoryCommand message, length delimited. Does not implicitly {@link RefDesignProtocol.ReadMemoryCommand.verify|verify} messages.
         * @function encodeDelimited
         * @memberof RefDesignProtocol.ReadMemoryCommand
         * @static
         * @param {RefDesignProtocol.IReadMemoryCommand} message ReadMemoryCommand message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ReadMemoryCommand.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a ReadMemoryCommand message from the specified reader or buffer.
         * @function decode
         * @memberof RefDesignProtocol.ReadMemoryCommand
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {RefDesignProtocol.ReadMemoryCommand} ReadMemoryCommand
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ReadMemoryCommand.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.RefDesignProtocol.ReadMemoryCommand();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.bank = reader.int32();
                        break;
                    }
                case 2: {
                        message.offset = reader.uint32();
                        break;
                    }
                case 3: {
                        message.size = reader.uint32();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a ReadMemoryCommand message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof RefDesignProtocol.ReadMemoryCommand
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {RefDesignProtocol.ReadMemoryCommand} ReadMemoryCommand
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ReadMemoryCommand.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a ReadMemoryCommand message.
         * @function verify
         * @memberof RefDesignProtocol.ReadMemoryCommand
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ReadMemoryCommand.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.bank != null && message.hasOwnProperty("bank"))
                switch (message.bank) {
                default:
                    return "bank: enum value expected";
                case 0:
                case 1:
                case 2:
                case 3:
                case 4:
                    break;
                }
            if (message.offset != null && message.hasOwnProperty("offset"))
                if (!$util.isInteger(message.offset))
                    return "offset: integer expected";
            if (message.size != null && message.hasOwnProperty("size"))
                if (!$util.isInteger(message.size))
                    return "size: integer expected";
            return null;
        };

        /**
         * Creates a ReadMemoryCommand message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof RefDesignProtocol.ReadMemoryCommand
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {RefDesignProtocol.ReadMemoryCommand} ReadMemoryCommand
         */
        ReadMemoryCommand.fromObject = function fromObject(object) {
            if (object instanceof $root.RefDesignProtocol.ReadMemoryCommand)
                return object;
            let message = new $root.RefDesignProtocol.ReadMemoryCommand();
            switch (object.bank) {
            default:
                if (typeof object.bank === "number") {
                    message.bank = object.bank;
                    break;
                }
                break;
            case "MemoryMain":
            case 0:
                message.bank = 0;
                break;
            case "MemoryCoreSensor":
            case 1:
                message.bank = 1;
                break;
            case "MemoryImage":
            case 2:
                message.bank = 2;
                break;
            case "MemoryAlgo":
            case 3:
                message.bank = 3;
                break;
            case "MemoryThimphu":
            case 4:
                message.bank = 4;
                break;
            }
            if (object.offset != null)
                message.offset = object.offset >>> 0;
            if (object.size != null)
                message.size = object.size >>> 0;
            return message;
        };

        /**
         * Creates a plain object from a ReadMemoryCommand message. Also converts values to other types if specified.
         * @function toObject
         * @memberof RefDesignProtocol.ReadMemoryCommand
         * @static
         * @param {RefDesignProtocol.ReadMemoryCommand} message ReadMemoryCommand
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ReadMemoryCommand.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.bank = options.enums === String ? "MemoryMain" : 0;
                object.offset = 0;
                object.size = 0;
            }
            if (message.bank != null && message.hasOwnProperty("bank"))
                object.bank = options.enums === String ? $root.RefDesignProtocol.MemoryBank[message.bank] === undefined ? message.bank : $root.RefDesignProtocol.MemoryBank[message.bank] : message.bank;
            if (message.offset != null && message.hasOwnProperty("offset"))
                object.offset = message.offset;
            if (message.size != null && message.hasOwnProperty("size"))
                object.size = message.size;
            return object;
        };

        /**
         * Converts this ReadMemoryCommand to JSON.
         * @function toJSON
         * @memberof RefDesignProtocol.ReadMemoryCommand
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ReadMemoryCommand.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for ReadMemoryCommand
         * @function getTypeUrl
         * @memberof RefDesignProtocol.ReadMemoryCommand
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        ReadMemoryCommand.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/RefDesignProtocol.ReadMemoryCommand";
        };

        return ReadMemoryCommand;
    })();

    RefDesignProtocol.ReadMemoryResult = (function() {

        /**
         * Properties of a ReadMemoryResult.
         * @memberof RefDesignProtocol
         * @interface IReadMemoryResult
         * @property {Uint8Array|null} [data] ReadMemoryResult data
         */

        /**
         * Constructs a new ReadMemoryResult.
         * @memberof RefDesignProtocol
         * @classdesc Represents a ReadMemoryResult.
         * @implements IReadMemoryResult
         * @constructor
         * @param {RefDesignProtocol.IReadMemoryResult=} [properties] Properties to set
         */
        function ReadMemoryResult(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ReadMemoryResult data.
         * @member {Uint8Array} data
         * @memberof RefDesignProtocol.ReadMemoryResult
         * @instance
         */
        ReadMemoryResult.prototype.data = $util.newBuffer([]);

        /**
         * Creates a new ReadMemoryResult instance using the specified properties.
         * @function create
         * @memberof RefDesignProtocol.ReadMemoryResult
         * @static
         * @param {RefDesignProtocol.IReadMemoryResult=} [properties] Properties to set
         * @returns {RefDesignProtocol.ReadMemoryResult} ReadMemoryResult instance
         */
        ReadMemoryResult.create = function create(properties) {
            return new ReadMemoryResult(properties);
        };

        /**
         * Encodes the specified ReadMemoryResult message. Does not implicitly {@link RefDesignProtocol.ReadMemoryResult.verify|verify} messages.
         * @function encode
         * @memberof RefDesignProtocol.ReadMemoryResult
         * @static
         * @param {RefDesignProtocol.IReadMemoryResult} message ReadMemoryResult message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ReadMemoryResult.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.data != null && Object.hasOwnProperty.call(message, "data"))
                writer.uint32(/* id 1, wireType 2 =*/10).bytes(message.data);
            return writer;
        };

        /**
         * Encodes the specified ReadMemoryResult message, length delimited. Does not implicitly {@link RefDesignProtocol.ReadMemoryResult.verify|verify} messages.
         * @function encodeDelimited
         * @memberof RefDesignProtocol.ReadMemoryResult
         * @static
         * @param {RefDesignProtocol.IReadMemoryResult} message ReadMemoryResult message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ReadMemoryResult.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a ReadMemoryResult message from the specified reader or buffer.
         * @function decode
         * @memberof RefDesignProtocol.ReadMemoryResult
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {RefDesignProtocol.ReadMemoryResult} ReadMemoryResult
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ReadMemoryResult.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.RefDesignProtocol.ReadMemoryResult();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.data = reader.bytes();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a ReadMemoryResult message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof RefDesignProtocol.ReadMemoryResult
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {RefDesignProtocol.ReadMemoryResult} ReadMemoryResult
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ReadMemoryResult.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a ReadMemoryResult message.
         * @function verify
         * @memberof RefDesignProtocol.ReadMemoryResult
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ReadMemoryResult.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.data != null && message.hasOwnProperty("data"))
                if (!(message.data && typeof message.data.length === "number" || $util.isString(message.data)))
                    return "data: buffer expected";
            return null;
        };

        /**
         * Creates a ReadMemoryResult message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof RefDesignProtocol.ReadMemoryResult
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {RefDesignProtocol.ReadMemoryResult} ReadMemoryResult
         */
        ReadMemoryResult.fromObject = function fromObject(object) {
            if (object instanceof $root.RefDesignProtocol.ReadMemoryResult)
                return object;
            let message = new $root.RefDesignProtocol.ReadMemoryResult();
            if (object.data != null)
                if (typeof object.data === "string")
                    $util.base64.decode(object.data, message.data = $util.newBuffer($util.base64.length(object.data)), 0);
                else if (object.data.length >= 0)
                    message.data = object.data;
            return message;
        };

        /**
         * Creates a plain object from a ReadMemoryResult message. Also converts values to other types if specified.
         * @function toObject
         * @memberof RefDesignProtocol.ReadMemoryResult
         * @static
         * @param {RefDesignProtocol.ReadMemoryResult} message ReadMemoryResult
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ReadMemoryResult.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                if (options.bytes === String)
                    object.data = "";
                else {
                    object.data = [];
                    if (options.bytes !== Array)
                        object.data = $util.newBuffer(object.data);
                }
            if (message.data != null && message.hasOwnProperty("data"))
                object.data = options.bytes === String ? $util.base64.encode(message.data, 0, message.data.length) : options.bytes === Array ? Array.prototype.slice.call(message.data) : message.data;
            return object;
        };

        /**
         * Converts this ReadMemoryResult to JSON.
         * @function toJSON
         * @memberof RefDesignProtocol.ReadMemoryResult
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ReadMemoryResult.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for ReadMemoryResult
         * @function getTypeUrl
         * @memberof RefDesignProtocol.ReadMemoryResult
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        ReadMemoryResult.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/RefDesignProtocol.ReadMemoryResult";
        };

        return ReadMemoryResult;
    })();

    RefDesignProtocol.WriteMemoryCommand = (function() {

        /**
         * Properties of a WriteMemoryCommand.
         * @memberof RefDesignProtocol
         * @interface IWriteMemoryCommand
         * @property {RefDesignProtocol.MemoryBank|null} [bank] WriteMemoryCommand bank
         * @property {number|null} [offset] WriteMemoryCommand offset
         * @property {Uint8Array|null} [data] WriteMemoryCommand data
         */

        /**
         * Constructs a new WriteMemoryCommand.
         * @memberof RefDesignProtocol
         * @classdesc Represents a WriteMemoryCommand.
         * @implements IWriteMemoryCommand
         * @constructor
         * @param {RefDesignProtocol.IWriteMemoryCommand=} [properties] Properties to set
         */
        function WriteMemoryCommand(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * WriteMemoryCommand bank.
         * @member {RefDesignProtocol.MemoryBank} bank
         * @memberof RefDesignProtocol.WriteMemoryCommand
         * @instance
         */
        WriteMemoryCommand.prototype.bank = 0;

        /**
         * WriteMemoryCommand offset.
         * @member {number} offset
         * @memberof RefDesignProtocol.WriteMemoryCommand
         * @instance
         */
        WriteMemoryCommand.prototype.offset = 0;

        /**
         * WriteMemoryCommand data.
         * @member {Uint8Array} data
         * @memberof RefDesignProtocol.WriteMemoryCommand
         * @instance
         */
        WriteMemoryCommand.prototype.data = $util.newBuffer([]);

        /**
         * Creates a new WriteMemoryCommand instance using the specified properties.
         * @function create
         * @memberof RefDesignProtocol.WriteMemoryCommand
         * @static
         * @param {RefDesignProtocol.IWriteMemoryCommand=} [properties] Properties to set
         * @returns {RefDesignProtocol.WriteMemoryCommand} WriteMemoryCommand instance
         */
        WriteMemoryCommand.create = function create(properties) {
            return new WriteMemoryCommand(properties);
        };

        /**
         * Encodes the specified WriteMemoryCommand message. Does not implicitly {@link RefDesignProtocol.WriteMemoryCommand.verify|verify} messages.
         * @function encode
         * @memberof RefDesignProtocol.WriteMemoryCommand
         * @static
         * @param {RefDesignProtocol.IWriteMemoryCommand} message WriteMemoryCommand message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        WriteMemoryCommand.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.bank != null && Object.hasOwnProperty.call(message, "bank"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.bank);
            if (message.offset != null && Object.hasOwnProperty.call(message, "offset"))
                writer.uint32(/* id 2, wireType 0 =*/16).uint32(message.offset);
            if (message.data != null && Object.hasOwnProperty.call(message, "data"))
                writer.uint32(/* id 3, wireType 2 =*/26).bytes(message.data);
            return writer;
        };

        /**
         * Encodes the specified WriteMemoryCommand message, length delimited. Does not implicitly {@link RefDesignProtocol.WriteMemoryCommand.verify|verify} messages.
         * @function encodeDelimited
         * @memberof RefDesignProtocol.WriteMemoryCommand
         * @static
         * @param {RefDesignProtocol.IWriteMemoryCommand} message WriteMemoryCommand message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        WriteMemoryCommand.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a WriteMemoryCommand message from the specified reader or buffer.
         * @function decode
         * @memberof RefDesignProtocol.WriteMemoryCommand
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {RefDesignProtocol.WriteMemoryCommand} WriteMemoryCommand
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        WriteMemoryCommand.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.RefDesignProtocol.WriteMemoryCommand();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.bank = reader.int32();
                        break;
                    }
                case 2: {
                        message.offset = reader.uint32();
                        break;
                    }
                case 3: {
                        message.data = reader.bytes();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a WriteMemoryCommand message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof RefDesignProtocol.WriteMemoryCommand
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {RefDesignProtocol.WriteMemoryCommand} WriteMemoryCommand
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        WriteMemoryCommand.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a WriteMemoryCommand message.
         * @function verify
         * @memberof RefDesignProtocol.WriteMemoryCommand
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        WriteMemoryCommand.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.bank != null && message.hasOwnProperty("bank"))
                switch (message.bank) {
                default:
                    return "bank: enum value expected";
                case 0:
                case 1:
                case 2:
                case 3:
                case 4:
                    break;
                }
            if (message.offset != null && message.hasOwnProperty("offset"))
                if (!$util.isInteger(message.offset))
                    return "offset: integer expected";
            if (message.data != null && message.hasOwnProperty("data"))
                if (!(message.data && typeof message.data.length === "number" || $util.isString(message.data)))
                    return "data: buffer expected";
            return null;
        };

        /**
         * Creates a WriteMemoryCommand message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof RefDesignProtocol.WriteMemoryCommand
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {RefDesignProtocol.WriteMemoryCommand} WriteMemoryCommand
         */
        WriteMemoryCommand.fromObject = function fromObject(object) {
            if (object instanceof $root.RefDesignProtocol.WriteMemoryCommand)
                return object;
            let message = new $root.RefDesignProtocol.WriteMemoryCommand();
            switch (object.bank) {
            default:
                if (typeof object.bank === "number") {
                    message.bank = object.bank;
                    break;
                }
                break;
            case "MemoryMain":
            case 0:
                message.bank = 0;
                break;
            case "MemoryCoreSensor":
            case 1:
                message.bank = 1;
                break;
            case "MemoryImage":
            case 2:
                message.bank = 2;
                break;
            case "MemoryAlgo":
            case 3:
                message.bank = 3;
                break;
            case "MemoryThimphu":
            case 4:
                message.bank = 4;
                break;
            }
            if (object.offset != null)
                message.offset = object.offset >>> 0;
            if (object.data != null)
                if (typeof object.data === "string")
                    $util.base64.decode(object.data, message.data = $util.newBuffer($util.base64.length(object.data)), 0);
                else if (object.data.length >= 0)
                    message.data = object.data;
            return message;
        };

        /**
         * Creates a plain object from a WriteMemoryCommand message. Also converts values to other types if specified.
         * @function toObject
         * @memberof RefDesignProtocol.WriteMemoryCommand
         * @static
         * @param {RefDesignProtocol.WriteMemoryCommand} message WriteMemoryCommand
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        WriteMemoryCommand.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.bank = options.enums === String ? "MemoryMain" : 0;
                object.offset = 0;
                if (options.bytes === String)
                    object.data = "";
                else {
                    object.data = [];
                    if (options.bytes !== Array)
                        object.data = $util.newBuffer(object.data);
                }
            }
            if (message.bank != null && message.hasOwnProperty("bank"))
                object.bank = options.enums === String ? $root.RefDesignProtocol.MemoryBank[message.bank] === undefined ? message.bank : $root.RefDesignProtocol.MemoryBank[message.bank] : message.bank;
            if (message.offset != null && message.hasOwnProperty("offset"))
                object.offset = message.offset;
            if (message.data != null && message.hasOwnProperty("data"))
                object.data = options.bytes === String ? $util.base64.encode(message.data, 0, message.data.length) : options.bytes === Array ? Array.prototype.slice.call(message.data) : message.data;
            return object;
        };

        /**
         * Converts this WriteMemoryCommand to JSON.
         * @function toJSON
         * @memberof RefDesignProtocol.WriteMemoryCommand
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        WriteMemoryCommand.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for WriteMemoryCommand
         * @function getTypeUrl
         * @memberof RefDesignProtocol.WriteMemoryCommand
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        WriteMemoryCommand.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/RefDesignProtocol.WriteMemoryCommand";
        };

        return WriteMemoryCommand;
    })();

    RefDesignProtocol.ResetCommand = (function() {

        /**
         * Properties of a ResetCommand.
         * @memberof RefDesignProtocol
         * @interface IResetCommand
         * @property {boolean|null} [reboot] ResetCommand reboot
         * @property {boolean|null} [upgrade] ResetCommand upgrade
         */

        /**
         * Constructs a new ResetCommand.
         * @memberof RefDesignProtocol
         * @classdesc Represents a ResetCommand.
         * @implements IResetCommand
         * @constructor
         * @param {RefDesignProtocol.IResetCommand=} [properties] Properties to set
         */
        function ResetCommand(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ResetCommand reboot.
         * @member {boolean} reboot
         * @memberof RefDesignProtocol.ResetCommand
         * @instance
         */
        ResetCommand.prototype.reboot = false;

        /**
         * ResetCommand upgrade.
         * @member {boolean} upgrade
         * @memberof RefDesignProtocol.ResetCommand
         * @instance
         */
        ResetCommand.prototype.upgrade = false;

        /**
         * Creates a new ResetCommand instance using the specified properties.
         * @function create
         * @memberof RefDesignProtocol.ResetCommand
         * @static
         * @param {RefDesignProtocol.IResetCommand=} [properties] Properties to set
         * @returns {RefDesignProtocol.ResetCommand} ResetCommand instance
         */
        ResetCommand.create = function create(properties) {
            return new ResetCommand(properties);
        };

        /**
         * Encodes the specified ResetCommand message. Does not implicitly {@link RefDesignProtocol.ResetCommand.verify|verify} messages.
         * @function encode
         * @memberof RefDesignProtocol.ResetCommand
         * @static
         * @param {RefDesignProtocol.IResetCommand} message ResetCommand message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ResetCommand.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.reboot != null && Object.hasOwnProperty.call(message, "reboot"))
                writer.uint32(/* id 1, wireType 0 =*/8).bool(message.reboot);
            if (message.upgrade != null && Object.hasOwnProperty.call(message, "upgrade"))
                writer.uint32(/* id 2, wireType 0 =*/16).bool(message.upgrade);
            return writer;
        };

        /**
         * Encodes the specified ResetCommand message, length delimited. Does not implicitly {@link RefDesignProtocol.ResetCommand.verify|verify} messages.
         * @function encodeDelimited
         * @memberof RefDesignProtocol.ResetCommand
         * @static
         * @param {RefDesignProtocol.IResetCommand} message ResetCommand message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ResetCommand.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a ResetCommand message from the specified reader or buffer.
         * @function decode
         * @memberof RefDesignProtocol.ResetCommand
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {RefDesignProtocol.ResetCommand} ResetCommand
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ResetCommand.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.RefDesignProtocol.ResetCommand();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.reboot = reader.bool();
                        break;
                    }
                case 2: {
                        message.upgrade = reader.bool();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a ResetCommand message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof RefDesignProtocol.ResetCommand
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {RefDesignProtocol.ResetCommand} ResetCommand
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ResetCommand.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a ResetCommand message.
         * @function verify
         * @memberof RefDesignProtocol.ResetCommand
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ResetCommand.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.reboot != null && message.hasOwnProperty("reboot"))
                if (typeof message.reboot !== "boolean")
                    return "reboot: boolean expected";
            if (message.upgrade != null && message.hasOwnProperty("upgrade"))
                if (typeof message.upgrade !== "boolean")
                    return "upgrade: boolean expected";
            return null;
        };

        /**
         * Creates a ResetCommand message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof RefDesignProtocol.ResetCommand
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {RefDesignProtocol.ResetCommand} ResetCommand
         */
        ResetCommand.fromObject = function fromObject(object) {
            if (object instanceof $root.RefDesignProtocol.ResetCommand)
                return object;
            let message = new $root.RefDesignProtocol.ResetCommand();
            if (object.reboot != null)
                message.reboot = Boolean(object.reboot);
            if (object.upgrade != null)
                message.upgrade = Boolean(object.upgrade);
            return message;
        };

        /**
         * Creates a plain object from a ResetCommand message. Also converts values to other types if specified.
         * @function toObject
         * @memberof RefDesignProtocol.ResetCommand
         * @static
         * @param {RefDesignProtocol.ResetCommand} message ResetCommand
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ResetCommand.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.reboot = false;
                object.upgrade = false;
            }
            if (message.reboot != null && message.hasOwnProperty("reboot"))
                object.reboot = message.reboot;
            if (message.upgrade != null && message.hasOwnProperty("upgrade"))
                object.upgrade = message.upgrade;
            return object;
        };

        /**
         * Converts this ResetCommand to JSON.
         * @function toJSON
         * @memberof RefDesignProtocol.ResetCommand
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ResetCommand.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for ResetCommand
         * @function getTypeUrl
         * @memberof RefDesignProtocol.ResetCommand
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        ResetCommand.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/RefDesignProtocol.ResetCommand";
        };

        return ResetCommand;
    })();

    RefDesignProtocol.SetExtensionCommand = (function() {

        /**
         * Properties of a SetExtensionCommand.
         * @memberof RefDesignProtocol
         * @interface ISetExtensionCommand
         * @property {string|null} [key] SetExtensionCommand key
         * @property {RefDesignProtocol.IExtensionValue|null} [value] SetExtensionCommand value
         */

        /**
         * Constructs a new SetExtensionCommand.
         * @memberof RefDesignProtocol
         * @classdesc Represents a SetExtensionCommand.
         * @implements ISetExtensionCommand
         * @constructor
         * @param {RefDesignProtocol.ISetExtensionCommand=} [properties] Properties to set
         */
        function SetExtensionCommand(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * SetExtensionCommand key.
         * @member {string} key
         * @memberof RefDesignProtocol.SetExtensionCommand
         * @instance
         */
        SetExtensionCommand.prototype.key = "";

        /**
         * SetExtensionCommand value.
         * @member {RefDesignProtocol.IExtensionValue|null|undefined} value
         * @memberof RefDesignProtocol.SetExtensionCommand
         * @instance
         */
        SetExtensionCommand.prototype.value = null;

        /**
         * Creates a new SetExtensionCommand instance using the specified properties.
         * @function create
         * @memberof RefDesignProtocol.SetExtensionCommand
         * @static
         * @param {RefDesignProtocol.ISetExtensionCommand=} [properties] Properties to set
         * @returns {RefDesignProtocol.SetExtensionCommand} SetExtensionCommand instance
         */
        SetExtensionCommand.create = function create(properties) {
            return new SetExtensionCommand(properties);
        };

        /**
         * Encodes the specified SetExtensionCommand message. Does not implicitly {@link RefDesignProtocol.SetExtensionCommand.verify|verify} messages.
         * @function encode
         * @memberof RefDesignProtocol.SetExtensionCommand
         * @static
         * @param {RefDesignProtocol.ISetExtensionCommand} message SetExtensionCommand message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SetExtensionCommand.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.key != null && Object.hasOwnProperty.call(message, "key"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.key);
            if (message.value != null && Object.hasOwnProperty.call(message, "value"))
                $root.RefDesignProtocol.ExtensionValue.encode(message.value, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified SetExtensionCommand message, length delimited. Does not implicitly {@link RefDesignProtocol.SetExtensionCommand.verify|verify} messages.
         * @function encodeDelimited
         * @memberof RefDesignProtocol.SetExtensionCommand
         * @static
         * @param {RefDesignProtocol.ISetExtensionCommand} message SetExtensionCommand message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SetExtensionCommand.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a SetExtensionCommand message from the specified reader or buffer.
         * @function decode
         * @memberof RefDesignProtocol.SetExtensionCommand
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {RefDesignProtocol.SetExtensionCommand} SetExtensionCommand
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SetExtensionCommand.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.RefDesignProtocol.SetExtensionCommand();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.key = reader.string();
                        break;
                    }
                case 2: {
                        message.value = $root.RefDesignProtocol.ExtensionValue.decode(reader, reader.uint32());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a SetExtensionCommand message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof RefDesignProtocol.SetExtensionCommand
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {RefDesignProtocol.SetExtensionCommand} SetExtensionCommand
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SetExtensionCommand.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a SetExtensionCommand message.
         * @function verify
         * @memberof RefDesignProtocol.SetExtensionCommand
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        SetExtensionCommand.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.key != null && message.hasOwnProperty("key"))
                if (!$util.isString(message.key))
                    return "key: string expected";
            if (message.value != null && message.hasOwnProperty("value")) {
                let error = $root.RefDesignProtocol.ExtensionValue.verify(message.value);
                if (error)
                    return "value." + error;
            }
            return null;
        };

        /**
         * Creates a SetExtensionCommand message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof RefDesignProtocol.SetExtensionCommand
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {RefDesignProtocol.SetExtensionCommand} SetExtensionCommand
         */
        SetExtensionCommand.fromObject = function fromObject(object) {
            if (object instanceof $root.RefDesignProtocol.SetExtensionCommand)
                return object;
            let message = new $root.RefDesignProtocol.SetExtensionCommand();
            if (object.key != null)
                message.key = String(object.key);
            if (object.value != null) {
                if (typeof object.value !== "object")
                    throw TypeError(".RefDesignProtocol.SetExtensionCommand.value: object expected");
                message.value = $root.RefDesignProtocol.ExtensionValue.fromObject(object.value);
            }
            return message;
        };

        /**
         * Creates a plain object from a SetExtensionCommand message. Also converts values to other types if specified.
         * @function toObject
         * @memberof RefDesignProtocol.SetExtensionCommand
         * @static
         * @param {RefDesignProtocol.SetExtensionCommand} message SetExtensionCommand
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        SetExtensionCommand.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.key = "";
                object.value = null;
            }
            if (message.key != null && message.hasOwnProperty("key"))
                object.key = message.key;
            if (message.value != null && message.hasOwnProperty("value"))
                object.value = $root.RefDesignProtocol.ExtensionValue.toObject(message.value, options);
            return object;
        };

        /**
         * Converts this SetExtensionCommand to JSON.
         * @function toJSON
         * @memberof RefDesignProtocol.SetExtensionCommand
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        SetExtensionCommand.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for SetExtensionCommand
         * @function getTypeUrl
         * @memberof RefDesignProtocol.SetExtensionCommand
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        SetExtensionCommand.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/RefDesignProtocol.SetExtensionCommand";
        };

        return SetExtensionCommand;
    })();

    RefDesignProtocol.GetExtensionCommand = (function() {

        /**
         * Properties of a GetExtensionCommand.
         * @memberof RefDesignProtocol
         * @interface IGetExtensionCommand
         * @property {string|null} [key] GetExtensionCommand key
         */

        /**
         * Constructs a new GetExtensionCommand.
         * @memberof RefDesignProtocol
         * @classdesc Represents a GetExtensionCommand.
         * @implements IGetExtensionCommand
         * @constructor
         * @param {RefDesignProtocol.IGetExtensionCommand=} [properties] Properties to set
         */
        function GetExtensionCommand(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * GetExtensionCommand key.
         * @member {string} key
         * @memberof RefDesignProtocol.GetExtensionCommand
         * @instance
         */
        GetExtensionCommand.prototype.key = "";

        /**
         * Creates a new GetExtensionCommand instance using the specified properties.
         * @function create
         * @memberof RefDesignProtocol.GetExtensionCommand
         * @static
         * @param {RefDesignProtocol.IGetExtensionCommand=} [properties] Properties to set
         * @returns {RefDesignProtocol.GetExtensionCommand} GetExtensionCommand instance
         */
        GetExtensionCommand.create = function create(properties) {
            return new GetExtensionCommand(properties);
        };

        /**
         * Encodes the specified GetExtensionCommand message. Does not implicitly {@link RefDesignProtocol.GetExtensionCommand.verify|verify} messages.
         * @function encode
         * @memberof RefDesignProtocol.GetExtensionCommand
         * @static
         * @param {RefDesignProtocol.IGetExtensionCommand} message GetExtensionCommand message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GetExtensionCommand.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.key != null && Object.hasOwnProperty.call(message, "key"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.key);
            return writer;
        };

        /**
         * Encodes the specified GetExtensionCommand message, length delimited. Does not implicitly {@link RefDesignProtocol.GetExtensionCommand.verify|verify} messages.
         * @function encodeDelimited
         * @memberof RefDesignProtocol.GetExtensionCommand
         * @static
         * @param {RefDesignProtocol.IGetExtensionCommand} message GetExtensionCommand message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GetExtensionCommand.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a GetExtensionCommand message from the specified reader or buffer.
         * @function decode
         * @memberof RefDesignProtocol.GetExtensionCommand
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {RefDesignProtocol.GetExtensionCommand} GetExtensionCommand
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GetExtensionCommand.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.RefDesignProtocol.GetExtensionCommand();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.key = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a GetExtensionCommand message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof RefDesignProtocol.GetExtensionCommand
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {RefDesignProtocol.GetExtensionCommand} GetExtensionCommand
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GetExtensionCommand.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a GetExtensionCommand message.
         * @function verify
         * @memberof RefDesignProtocol.GetExtensionCommand
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        GetExtensionCommand.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.key != null && message.hasOwnProperty("key"))
                if (!$util.isString(message.key))
                    return "key: string expected";
            return null;
        };

        /**
         * Creates a GetExtensionCommand message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof RefDesignProtocol.GetExtensionCommand
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {RefDesignProtocol.GetExtensionCommand} GetExtensionCommand
         */
        GetExtensionCommand.fromObject = function fromObject(object) {
            if (object instanceof $root.RefDesignProtocol.GetExtensionCommand)
                return object;
            let message = new $root.RefDesignProtocol.GetExtensionCommand();
            if (object.key != null)
                message.key = String(object.key);
            return message;
        };

        /**
         * Creates a plain object from a GetExtensionCommand message. Also converts values to other types if specified.
         * @function toObject
         * @memberof RefDesignProtocol.GetExtensionCommand
         * @static
         * @param {RefDesignProtocol.GetExtensionCommand} message GetExtensionCommand
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        GetExtensionCommand.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                object.key = "";
            if (message.key != null && message.hasOwnProperty("key"))
                object.key = message.key;
            return object;
        };

        /**
         * Converts this GetExtensionCommand to JSON.
         * @function toJSON
         * @memberof RefDesignProtocol.GetExtensionCommand
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        GetExtensionCommand.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for GetExtensionCommand
         * @function getTypeUrl
         * @memberof RefDesignProtocol.GetExtensionCommand
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        GetExtensionCommand.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/RefDesignProtocol.GetExtensionCommand";
        };

        return GetExtensionCommand;
    })();

    RefDesignProtocol.ExtensionResult = (function() {

        /**
         * Properties of an ExtensionResult.
         * @memberof RefDesignProtocol
         * @interface IExtensionResult
         * @property {RefDesignProtocol.IExtensionValue|null} [value] ExtensionResult value
         */

        /**
         * Constructs a new ExtensionResult.
         * @memberof RefDesignProtocol
         * @classdesc Represents an ExtensionResult.
         * @implements IExtensionResult
         * @constructor
         * @param {RefDesignProtocol.IExtensionResult=} [properties] Properties to set
         */
        function ExtensionResult(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ExtensionResult value.
         * @member {RefDesignProtocol.IExtensionValue|null|undefined} value
         * @memberof RefDesignProtocol.ExtensionResult
         * @instance
         */
        ExtensionResult.prototype.value = null;

        /**
         * Creates a new ExtensionResult instance using the specified properties.
         * @function create
         * @memberof RefDesignProtocol.ExtensionResult
         * @static
         * @param {RefDesignProtocol.IExtensionResult=} [properties] Properties to set
         * @returns {RefDesignProtocol.ExtensionResult} ExtensionResult instance
         */
        ExtensionResult.create = function create(properties) {
            return new ExtensionResult(properties);
        };

        /**
         * Encodes the specified ExtensionResult message. Does not implicitly {@link RefDesignProtocol.ExtensionResult.verify|verify} messages.
         * @function encode
         * @memberof RefDesignProtocol.ExtensionResult
         * @static
         * @param {RefDesignProtocol.IExtensionResult} message ExtensionResult message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ExtensionResult.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.value != null && Object.hasOwnProperty.call(message, "value"))
                $root.RefDesignProtocol.ExtensionValue.encode(message.value, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified ExtensionResult message, length delimited. Does not implicitly {@link RefDesignProtocol.ExtensionResult.verify|verify} messages.
         * @function encodeDelimited
         * @memberof RefDesignProtocol.ExtensionResult
         * @static
         * @param {RefDesignProtocol.IExtensionResult} message ExtensionResult message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ExtensionResult.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an ExtensionResult message from the specified reader or buffer.
         * @function decode
         * @memberof RefDesignProtocol.ExtensionResult
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {RefDesignProtocol.ExtensionResult} ExtensionResult
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ExtensionResult.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.RefDesignProtocol.ExtensionResult();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.value = $root.RefDesignProtocol.ExtensionValue.decode(reader, reader.uint32());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an ExtensionResult message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof RefDesignProtocol.ExtensionResult
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {RefDesignProtocol.ExtensionResult} ExtensionResult
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ExtensionResult.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an ExtensionResult message.
         * @function verify
         * @memberof RefDesignProtocol.ExtensionResult
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ExtensionResult.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.value != null && message.hasOwnProperty("value")) {
                let error = $root.RefDesignProtocol.ExtensionValue.verify(message.value);
                if (error)
                    return "value." + error;
            }
            return null;
        };

        /**
         * Creates an ExtensionResult message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof RefDesignProtocol.ExtensionResult
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {RefDesignProtocol.ExtensionResult} ExtensionResult
         */
        ExtensionResult.fromObject = function fromObject(object) {
            if (object instanceof $root.RefDesignProtocol.ExtensionResult)
                return object;
            let message = new $root.RefDesignProtocol.ExtensionResult();
            if (object.value != null) {
                if (typeof object.value !== "object")
                    throw TypeError(".RefDesignProtocol.ExtensionResult.value: object expected");
                message.value = $root.RefDesignProtocol.ExtensionValue.fromObject(object.value);
            }
            return message;
        };

        /**
         * Creates a plain object from an ExtensionResult message. Also converts values to other types if specified.
         * @function toObject
         * @memberof RefDesignProtocol.ExtensionResult
         * @static
         * @param {RefDesignProtocol.ExtensionResult} message ExtensionResult
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ExtensionResult.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                object.value = null;
            if (message.value != null && message.hasOwnProperty("value"))
                object.value = $root.RefDesignProtocol.ExtensionValue.toObject(message.value, options);
            return object;
        };

        /**
         * Converts this ExtensionResult to JSON.
         * @function toJSON
         * @memberof RefDesignProtocol.ExtensionResult
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ExtensionResult.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for ExtensionResult
         * @function getTypeUrl
         * @memberof RefDesignProtocol.ExtensionResult
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        ExtensionResult.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/RefDesignProtocol.ExtensionResult";
        };

        return ExtensionResult;
    })();

    RefDesignProtocol.MasterRoot = (function() {

        /**
         * Properties of a MasterRoot.
         * @memberof RefDesignProtocol
         * @interface IMasterRoot
         * @property {number|null} [msgId] MasterRoot msgId
         * @property {RefDesignProtocol.IGetVersionCommand|null} [version] MasterRoot version
         * @property {RefDesignProtocol.IGetConfigCommand|null} [config] MasterRoot config
         * @property {RefDesignProtocol.IGetCapabilitiesCommand|null} [capabilities] MasterRoot capabilities
         * @property {RefDesignProtocol.IConfigureCommand|null} [configure] MasterRoot configure
         * @property {RefDesignProtocol.IStoreConfigCommand|null} [storeConfig] MasterRoot storeConfig
         * @property {RefDesignProtocol.IStartCommand|null} [start] MasterRoot start
         * @property {RefDesignProtocol.IStopCommand|null} [stop] MasterRoot stop
         * @property {RefDesignProtocol.ISingleShotCommand|null} [singleShot] MasterRoot singleShot
         * @property {RefDesignProtocol.ISetVcselCommand|null} [vcsel] MasterRoot vcsel
         * @property {RefDesignProtocol.IGetMemoryInfoCommand|null} [memInfo] MasterRoot memInfo
         * @property {RefDesignProtocol.IReadMemoryCommand|null} [memRead] MasterRoot memRead
         * @property {RefDesignProtocol.IWriteMemoryCommand|null} [memWrite] MasterRoot memWrite
         * @property {RefDesignProtocol.IResetCommand|null} [reset] MasterRoot reset
         * @property {RefDesignProtocol.ISetExtensionCommand|null} [setExtension] MasterRoot setExtension
         * @property {RefDesignProtocol.IGetExtensionCommand|null} [getExtension] MasterRoot getExtension
         * @property {RefDesignProtocol.ISetValveCommand|null} [valve] MasterRoot valve
         * @property {RefDesignProtocol.ISetPumpCommand|null} [pump] MasterRoot pump
         * @property {RefDesignProtocol.ISetHihCommand|null} [hih] MasterRoot hih
         * @property {RefDesignProtocol.ICalibExposureCommand|null} [calibExpo] MasterRoot calibExpo
         * @property {RefDesignProtocol.ICalibPeakMaskCommand|null} [calibPeakMask] MasterRoot calibPeakMask
         * @property {RefDesignProtocol.IResetMinMaxPeaksCommand|null} [resetMinMaxPeak] MasterRoot resetMinMaxPeak
         * @property {RefDesignProtocol.IGetMinMaxPeaksCommand|null} [getMinMaxPeaks] MasterRoot getMinMaxPeaks
         * @property {RefDesignProtocol.IResetMziStaticContrastCommand|null} [resetMziStaticContrast] MasterRoot resetMziStaticContrast
         * @property {RefDesignProtocol.IGetMziStaticContrastCommand|null} [getMziStaticContrast] MasterRoot getMziStaticContrast
         */

        /**
         * Constructs a new MasterRoot.
         * @memberof RefDesignProtocol
         * @classdesc Represents a MasterRoot.
         * @implements IMasterRoot
         * @constructor
         * @param {RefDesignProtocol.IMasterRoot=} [properties] Properties to set
         */
        function MasterRoot(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * MasterRoot msgId.
         * @member {number} msgId
         * @memberof RefDesignProtocol.MasterRoot
         * @instance
         */
        MasterRoot.prototype.msgId = 0;

        /**
         * MasterRoot version.
         * @member {RefDesignProtocol.IGetVersionCommand|null|undefined} version
         * @memberof RefDesignProtocol.MasterRoot
         * @instance
         */
        MasterRoot.prototype.version = null;

        /**
         * MasterRoot config.
         * @member {RefDesignProtocol.IGetConfigCommand|null|undefined} config
         * @memberof RefDesignProtocol.MasterRoot
         * @instance
         */
        MasterRoot.prototype.config = null;

        /**
         * MasterRoot capabilities.
         * @member {RefDesignProtocol.IGetCapabilitiesCommand|null|undefined} capabilities
         * @memberof RefDesignProtocol.MasterRoot
         * @instance
         */
        MasterRoot.prototype.capabilities = null;

        /**
         * MasterRoot configure.
         * @member {RefDesignProtocol.IConfigureCommand|null|undefined} configure
         * @memberof RefDesignProtocol.MasterRoot
         * @instance
         */
        MasterRoot.prototype.configure = null;

        /**
         * MasterRoot storeConfig.
         * @member {RefDesignProtocol.IStoreConfigCommand|null|undefined} storeConfig
         * @memberof RefDesignProtocol.MasterRoot
         * @instance
         */
        MasterRoot.prototype.storeConfig = null;

        /**
         * MasterRoot start.
         * @member {RefDesignProtocol.IStartCommand|null|undefined} start
         * @memberof RefDesignProtocol.MasterRoot
         * @instance
         */
        MasterRoot.prototype.start = null;

        /**
         * MasterRoot stop.
         * @member {RefDesignProtocol.IStopCommand|null|undefined} stop
         * @memberof RefDesignProtocol.MasterRoot
         * @instance
         */
        MasterRoot.prototype.stop = null;

        /**
         * MasterRoot singleShot.
         * @member {RefDesignProtocol.ISingleShotCommand|null|undefined} singleShot
         * @memberof RefDesignProtocol.MasterRoot
         * @instance
         */
        MasterRoot.prototype.singleShot = null;

        /**
         * MasterRoot vcsel.
         * @member {RefDesignProtocol.ISetVcselCommand|null|undefined} vcsel
         * @memberof RefDesignProtocol.MasterRoot
         * @instance
         */
        MasterRoot.prototype.vcsel = null;

        /**
         * MasterRoot memInfo.
         * @member {RefDesignProtocol.IGetMemoryInfoCommand|null|undefined} memInfo
         * @memberof RefDesignProtocol.MasterRoot
         * @instance
         */
        MasterRoot.prototype.memInfo = null;

        /**
         * MasterRoot memRead.
         * @member {RefDesignProtocol.IReadMemoryCommand|null|undefined} memRead
         * @memberof RefDesignProtocol.MasterRoot
         * @instance
         */
        MasterRoot.prototype.memRead = null;

        /**
         * MasterRoot memWrite.
         * @member {RefDesignProtocol.IWriteMemoryCommand|null|undefined} memWrite
         * @memberof RefDesignProtocol.MasterRoot
         * @instance
         */
        MasterRoot.prototype.memWrite = null;

        /**
         * MasterRoot reset.
         * @member {RefDesignProtocol.IResetCommand|null|undefined} reset
         * @memberof RefDesignProtocol.MasterRoot
         * @instance
         */
        MasterRoot.prototype.reset = null;

        /**
         * MasterRoot setExtension.
         * @member {RefDesignProtocol.ISetExtensionCommand|null|undefined} setExtension
         * @memberof RefDesignProtocol.MasterRoot
         * @instance
         */
        MasterRoot.prototype.setExtension = null;

        /**
         * MasterRoot getExtension.
         * @member {RefDesignProtocol.IGetExtensionCommand|null|undefined} getExtension
         * @memberof RefDesignProtocol.MasterRoot
         * @instance
         */
        MasterRoot.prototype.getExtension = null;

        /**
         * MasterRoot valve.
         * @member {RefDesignProtocol.ISetValveCommand|null|undefined} valve
         * @memberof RefDesignProtocol.MasterRoot
         * @instance
         */
        MasterRoot.prototype.valve = null;

        /**
         * MasterRoot pump.
         * @member {RefDesignProtocol.ISetPumpCommand|null|undefined} pump
         * @memberof RefDesignProtocol.MasterRoot
         * @instance
         */
        MasterRoot.prototype.pump = null;

        /**
         * MasterRoot hih.
         * @member {RefDesignProtocol.ISetHihCommand|null|undefined} hih
         * @memberof RefDesignProtocol.MasterRoot
         * @instance
         */
        MasterRoot.prototype.hih = null;

        /**
         * MasterRoot calibExpo.
         * @member {RefDesignProtocol.ICalibExposureCommand|null|undefined} calibExpo
         * @memberof RefDesignProtocol.MasterRoot
         * @instance
         */
        MasterRoot.prototype.calibExpo = null;

        /**
         * MasterRoot calibPeakMask.
         * @member {RefDesignProtocol.ICalibPeakMaskCommand|null|undefined} calibPeakMask
         * @memberof RefDesignProtocol.MasterRoot
         * @instance
         */
        MasterRoot.prototype.calibPeakMask = null;

        /**
         * MasterRoot resetMinMaxPeak.
         * @member {RefDesignProtocol.IResetMinMaxPeaksCommand|null|undefined} resetMinMaxPeak
         * @memberof RefDesignProtocol.MasterRoot
         * @instance
         */
        MasterRoot.prototype.resetMinMaxPeak = null;

        /**
         * MasterRoot getMinMaxPeaks.
         * @member {RefDesignProtocol.IGetMinMaxPeaksCommand|null|undefined} getMinMaxPeaks
         * @memberof RefDesignProtocol.MasterRoot
         * @instance
         */
        MasterRoot.prototype.getMinMaxPeaks = null;

        /**
         * MasterRoot resetMziStaticContrast.
         * @member {RefDesignProtocol.IResetMziStaticContrastCommand|null|undefined} resetMziStaticContrast
         * @memberof RefDesignProtocol.MasterRoot
         * @instance
         */
        MasterRoot.prototype.resetMziStaticContrast = null;

        /**
         * MasterRoot getMziStaticContrast.
         * @member {RefDesignProtocol.IGetMziStaticContrastCommand|null|undefined} getMziStaticContrast
         * @memberof RefDesignProtocol.MasterRoot
         * @instance
         */
        MasterRoot.prototype.getMziStaticContrast = null;

        // OneOf field names bound to virtual getters and setters
        let $oneOfFields;

        /**
         * MasterRoot payload.
         * @member {"version"|"config"|"capabilities"|"configure"|"storeConfig"|"start"|"stop"|"singleShot"|"vcsel"|"memInfo"|"memRead"|"memWrite"|"reset"|"setExtension"|"getExtension"|"valve"|"pump"|"hih"|"calibExpo"|"calibPeakMask"|"resetMinMaxPeak"|"getMinMaxPeaks"|"resetMziStaticContrast"|"getMziStaticContrast"|undefined} payload
         * @memberof RefDesignProtocol.MasterRoot
         * @instance
         */
        Object.defineProperty(MasterRoot.prototype, "payload", {
            get: $util.oneOfGetter($oneOfFields = ["version", "config", "capabilities", "configure", "storeConfig", "start", "stop", "singleShot", "vcsel", "memInfo", "memRead", "memWrite", "reset", "setExtension", "getExtension", "valve", "pump", "hih", "calibExpo", "calibPeakMask", "resetMinMaxPeak", "getMinMaxPeaks", "resetMziStaticContrast", "getMziStaticContrast"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Creates a new MasterRoot instance using the specified properties.
         * @function create
         * @memberof RefDesignProtocol.MasterRoot
         * @static
         * @param {RefDesignProtocol.IMasterRoot=} [properties] Properties to set
         * @returns {RefDesignProtocol.MasterRoot} MasterRoot instance
         */
        MasterRoot.create = function create(properties) {
            return new MasterRoot(properties);
        };

        /**
         * Encodes the specified MasterRoot message. Does not implicitly {@link RefDesignProtocol.MasterRoot.verify|verify} messages.
         * @function encode
         * @memberof RefDesignProtocol.MasterRoot
         * @static
         * @param {RefDesignProtocol.IMasterRoot} message MasterRoot message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        MasterRoot.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.msgId != null && Object.hasOwnProperty.call(message, "msgId"))
                writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.msgId);
            if (message.version != null && Object.hasOwnProperty.call(message, "version"))
                $root.RefDesignProtocol.GetVersionCommand.encode(message.version, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            if (message.config != null && Object.hasOwnProperty.call(message, "config"))
                $root.RefDesignProtocol.GetConfigCommand.encode(message.config, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            if (message.capabilities != null && Object.hasOwnProperty.call(message, "capabilities"))
                $root.RefDesignProtocol.GetCapabilitiesCommand.encode(message.capabilities, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
            if (message.configure != null && Object.hasOwnProperty.call(message, "configure"))
                $root.RefDesignProtocol.ConfigureCommand.encode(message.configure, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
            if (message.storeConfig != null && Object.hasOwnProperty.call(message, "storeConfig"))
                $root.RefDesignProtocol.StoreConfigCommand.encode(message.storeConfig, writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
            if (message.start != null && Object.hasOwnProperty.call(message, "start"))
                $root.RefDesignProtocol.StartCommand.encode(message.start, writer.uint32(/* id 7, wireType 2 =*/58).fork()).ldelim();
            if (message.stop != null && Object.hasOwnProperty.call(message, "stop"))
                $root.RefDesignProtocol.StopCommand.encode(message.stop, writer.uint32(/* id 8, wireType 2 =*/66).fork()).ldelim();
            if (message.singleShot != null && Object.hasOwnProperty.call(message, "singleShot"))
                $root.RefDesignProtocol.SingleShotCommand.encode(message.singleShot, writer.uint32(/* id 9, wireType 2 =*/74).fork()).ldelim();
            if (message.vcsel != null && Object.hasOwnProperty.call(message, "vcsel"))
                $root.RefDesignProtocol.SetVcselCommand.encode(message.vcsel, writer.uint32(/* id 10, wireType 2 =*/82).fork()).ldelim();
            if (message.memInfo != null && Object.hasOwnProperty.call(message, "memInfo"))
                $root.RefDesignProtocol.GetMemoryInfoCommand.encode(message.memInfo, writer.uint32(/* id 11, wireType 2 =*/90).fork()).ldelim();
            if (message.memRead != null && Object.hasOwnProperty.call(message, "memRead"))
                $root.RefDesignProtocol.ReadMemoryCommand.encode(message.memRead, writer.uint32(/* id 12, wireType 2 =*/98).fork()).ldelim();
            if (message.memWrite != null && Object.hasOwnProperty.call(message, "memWrite"))
                $root.RefDesignProtocol.WriteMemoryCommand.encode(message.memWrite, writer.uint32(/* id 13, wireType 2 =*/106).fork()).ldelim();
            if (message.reset != null && Object.hasOwnProperty.call(message, "reset"))
                $root.RefDesignProtocol.ResetCommand.encode(message.reset, writer.uint32(/* id 14, wireType 2 =*/114).fork()).ldelim();
            if (message.setExtension != null && Object.hasOwnProperty.call(message, "setExtension"))
                $root.RefDesignProtocol.SetExtensionCommand.encode(message.setExtension, writer.uint32(/* id 15, wireType 2 =*/122).fork()).ldelim();
            if (message.getExtension != null && Object.hasOwnProperty.call(message, "getExtension"))
                $root.RefDesignProtocol.GetExtensionCommand.encode(message.getExtension, writer.uint32(/* id 16, wireType 2 =*/130).fork()).ldelim();
            if (message.valve != null && Object.hasOwnProperty.call(message, "valve"))
                $root.RefDesignProtocol.SetValveCommand.encode(message.valve, writer.uint32(/* id 17, wireType 2 =*/138).fork()).ldelim();
            if (message.pump != null && Object.hasOwnProperty.call(message, "pump"))
                $root.RefDesignProtocol.SetPumpCommand.encode(message.pump, writer.uint32(/* id 18, wireType 2 =*/146).fork()).ldelim();
            if (message.hih != null && Object.hasOwnProperty.call(message, "hih"))
                $root.RefDesignProtocol.SetHihCommand.encode(message.hih, writer.uint32(/* id 19, wireType 2 =*/154).fork()).ldelim();
            if (message.calibExpo != null && Object.hasOwnProperty.call(message, "calibExpo"))
                $root.RefDesignProtocol.CalibExposureCommand.encode(message.calibExpo, writer.uint32(/* id 20, wireType 2 =*/162).fork()).ldelim();
            if (message.calibPeakMask != null && Object.hasOwnProperty.call(message, "calibPeakMask"))
                $root.RefDesignProtocol.CalibPeakMaskCommand.encode(message.calibPeakMask, writer.uint32(/* id 21, wireType 2 =*/170).fork()).ldelim();
            if (message.resetMinMaxPeak != null && Object.hasOwnProperty.call(message, "resetMinMaxPeak"))
                $root.RefDesignProtocol.ResetMinMaxPeaksCommand.encode(message.resetMinMaxPeak, writer.uint32(/* id 22, wireType 2 =*/178).fork()).ldelim();
            if (message.getMinMaxPeaks != null && Object.hasOwnProperty.call(message, "getMinMaxPeaks"))
                $root.RefDesignProtocol.GetMinMaxPeaksCommand.encode(message.getMinMaxPeaks, writer.uint32(/* id 23, wireType 2 =*/186).fork()).ldelim();
            if (message.resetMziStaticContrast != null && Object.hasOwnProperty.call(message, "resetMziStaticContrast"))
                $root.RefDesignProtocol.ResetMziStaticContrastCommand.encode(message.resetMziStaticContrast, writer.uint32(/* id 24, wireType 2 =*/194).fork()).ldelim();
            if (message.getMziStaticContrast != null && Object.hasOwnProperty.call(message, "getMziStaticContrast"))
                $root.RefDesignProtocol.GetMziStaticContrastCommand.encode(message.getMziStaticContrast, writer.uint32(/* id 25, wireType 2 =*/202).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified MasterRoot message, length delimited. Does not implicitly {@link RefDesignProtocol.MasterRoot.verify|verify} messages.
         * @function encodeDelimited
         * @memberof RefDesignProtocol.MasterRoot
         * @static
         * @param {RefDesignProtocol.IMasterRoot} message MasterRoot message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        MasterRoot.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a MasterRoot message from the specified reader or buffer.
         * @function decode
         * @memberof RefDesignProtocol.MasterRoot
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {RefDesignProtocol.MasterRoot} MasterRoot
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        MasterRoot.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.RefDesignProtocol.MasterRoot();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.msgId = reader.uint32();
                        break;
                    }
                case 2: {
                        message.version = $root.RefDesignProtocol.GetVersionCommand.decode(reader, reader.uint32());
                        break;
                    }
                case 3: {
                        message.config = $root.RefDesignProtocol.GetConfigCommand.decode(reader, reader.uint32());
                        break;
                    }
                case 4: {
                        message.capabilities = $root.RefDesignProtocol.GetCapabilitiesCommand.decode(reader, reader.uint32());
                        break;
                    }
                case 5: {
                        message.configure = $root.RefDesignProtocol.ConfigureCommand.decode(reader, reader.uint32());
                        break;
                    }
                case 6: {
                        message.storeConfig = $root.RefDesignProtocol.StoreConfigCommand.decode(reader, reader.uint32());
                        break;
                    }
                case 7: {
                        message.start = $root.RefDesignProtocol.StartCommand.decode(reader, reader.uint32());
                        break;
                    }
                case 8: {
                        message.stop = $root.RefDesignProtocol.StopCommand.decode(reader, reader.uint32());
                        break;
                    }
                case 9: {
                        message.singleShot = $root.RefDesignProtocol.SingleShotCommand.decode(reader, reader.uint32());
                        break;
                    }
                case 10: {
                        message.vcsel = $root.RefDesignProtocol.SetVcselCommand.decode(reader, reader.uint32());
                        break;
                    }
                case 11: {
                        message.memInfo = $root.RefDesignProtocol.GetMemoryInfoCommand.decode(reader, reader.uint32());
                        break;
                    }
                case 12: {
                        message.memRead = $root.RefDesignProtocol.ReadMemoryCommand.decode(reader, reader.uint32());
                        break;
                    }
                case 13: {
                        message.memWrite = $root.RefDesignProtocol.WriteMemoryCommand.decode(reader, reader.uint32());
                        break;
                    }
                case 14: {
                        message.reset = $root.RefDesignProtocol.ResetCommand.decode(reader, reader.uint32());
                        break;
                    }
                case 15: {
                        message.setExtension = $root.RefDesignProtocol.SetExtensionCommand.decode(reader, reader.uint32());
                        break;
                    }
                case 16: {
                        message.getExtension = $root.RefDesignProtocol.GetExtensionCommand.decode(reader, reader.uint32());
                        break;
                    }
                case 17: {
                        message.valve = $root.RefDesignProtocol.SetValveCommand.decode(reader, reader.uint32());
                        break;
                    }
                case 18: {
                        message.pump = $root.RefDesignProtocol.SetPumpCommand.decode(reader, reader.uint32());
                        break;
                    }
                case 19: {
                        message.hih = $root.RefDesignProtocol.SetHihCommand.decode(reader, reader.uint32());
                        break;
                    }
                case 20: {
                        message.calibExpo = $root.RefDesignProtocol.CalibExposureCommand.decode(reader, reader.uint32());
                        break;
                    }
                case 21: {
                        message.calibPeakMask = $root.RefDesignProtocol.CalibPeakMaskCommand.decode(reader, reader.uint32());
                        break;
                    }
                case 22: {
                        message.resetMinMaxPeak = $root.RefDesignProtocol.ResetMinMaxPeaksCommand.decode(reader, reader.uint32());
                        break;
                    }
                case 23: {
                        message.getMinMaxPeaks = $root.RefDesignProtocol.GetMinMaxPeaksCommand.decode(reader, reader.uint32());
                        break;
                    }
                case 24: {
                        message.resetMziStaticContrast = $root.RefDesignProtocol.ResetMziStaticContrastCommand.decode(reader, reader.uint32());
                        break;
                    }
                case 25: {
                        message.getMziStaticContrast = $root.RefDesignProtocol.GetMziStaticContrastCommand.decode(reader, reader.uint32());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a MasterRoot message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof RefDesignProtocol.MasterRoot
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {RefDesignProtocol.MasterRoot} MasterRoot
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        MasterRoot.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a MasterRoot message.
         * @function verify
         * @memberof RefDesignProtocol.MasterRoot
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        MasterRoot.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            let properties = {};
            if (message.msgId != null && message.hasOwnProperty("msgId"))
                if (!$util.isInteger(message.msgId))
                    return "msgId: integer expected";
            if (message.version != null && message.hasOwnProperty("version")) {
                properties.payload = 1;
                {
                    let error = $root.RefDesignProtocol.GetVersionCommand.verify(message.version);
                    if (error)
                        return "version." + error;
                }
            }
            if (message.config != null && message.hasOwnProperty("config")) {
                if (properties.payload === 1)
                    return "payload: multiple values";
                properties.payload = 1;
                {
                    let error = $root.RefDesignProtocol.GetConfigCommand.verify(message.config);
                    if (error)
                        return "config." + error;
                }
            }
            if (message.capabilities != null && message.hasOwnProperty("capabilities")) {
                if (properties.payload === 1)
                    return "payload: multiple values";
                properties.payload = 1;
                {
                    let error = $root.RefDesignProtocol.GetCapabilitiesCommand.verify(message.capabilities);
                    if (error)
                        return "capabilities." + error;
                }
            }
            if (message.configure != null && message.hasOwnProperty("configure")) {
                if (properties.payload === 1)
                    return "payload: multiple values";
                properties.payload = 1;
                {
                    let error = $root.RefDesignProtocol.ConfigureCommand.verify(message.configure);
                    if (error)
                        return "configure." + error;
                }
            }
            if (message.storeConfig != null && message.hasOwnProperty("storeConfig")) {
                if (properties.payload === 1)
                    return "payload: multiple values";
                properties.payload = 1;
                {
                    let error = $root.RefDesignProtocol.StoreConfigCommand.verify(message.storeConfig);
                    if (error)
                        return "storeConfig." + error;
                }
            }
            if (message.start != null && message.hasOwnProperty("start")) {
                if (properties.payload === 1)
                    return "payload: multiple values";
                properties.payload = 1;
                {
                    let error = $root.RefDesignProtocol.StartCommand.verify(message.start);
                    if (error)
                        return "start." + error;
                }
            }
            if (message.stop != null && message.hasOwnProperty("stop")) {
                if (properties.payload === 1)
                    return "payload: multiple values";
                properties.payload = 1;
                {
                    let error = $root.RefDesignProtocol.StopCommand.verify(message.stop);
                    if (error)
                        return "stop." + error;
                }
            }
            if (message.singleShot != null && message.hasOwnProperty("singleShot")) {
                if (properties.payload === 1)
                    return "payload: multiple values";
                properties.payload = 1;
                {
                    let error = $root.RefDesignProtocol.SingleShotCommand.verify(message.singleShot);
                    if (error)
                        return "singleShot." + error;
                }
            }
            if (message.vcsel != null && message.hasOwnProperty("vcsel")) {
                if (properties.payload === 1)
                    return "payload: multiple values";
                properties.payload = 1;
                {
                    let error = $root.RefDesignProtocol.SetVcselCommand.verify(message.vcsel);
                    if (error)
                        return "vcsel." + error;
                }
            }
            if (message.memInfo != null && message.hasOwnProperty("memInfo")) {
                if (properties.payload === 1)
                    return "payload: multiple values";
                properties.payload = 1;
                {
                    let error = $root.RefDesignProtocol.GetMemoryInfoCommand.verify(message.memInfo);
                    if (error)
                        return "memInfo." + error;
                }
            }
            if (message.memRead != null && message.hasOwnProperty("memRead")) {
                if (properties.payload === 1)
                    return "payload: multiple values";
                properties.payload = 1;
                {
                    let error = $root.RefDesignProtocol.ReadMemoryCommand.verify(message.memRead);
                    if (error)
                        return "memRead." + error;
                }
            }
            if (message.memWrite != null && message.hasOwnProperty("memWrite")) {
                if (properties.payload === 1)
                    return "payload: multiple values";
                properties.payload = 1;
                {
                    let error = $root.RefDesignProtocol.WriteMemoryCommand.verify(message.memWrite);
                    if (error)
                        return "memWrite." + error;
                }
            }
            if (message.reset != null && message.hasOwnProperty("reset")) {
                if (properties.payload === 1)
                    return "payload: multiple values";
                properties.payload = 1;
                {
                    let error = $root.RefDesignProtocol.ResetCommand.verify(message.reset);
                    if (error)
                        return "reset." + error;
                }
            }
            if (message.setExtension != null && message.hasOwnProperty("setExtension")) {
                if (properties.payload === 1)
                    return "payload: multiple values";
                properties.payload = 1;
                {
                    let error = $root.RefDesignProtocol.SetExtensionCommand.verify(message.setExtension);
                    if (error)
                        return "setExtension." + error;
                }
            }
            if (message.getExtension != null && message.hasOwnProperty("getExtension")) {
                if (properties.payload === 1)
                    return "payload: multiple values";
                properties.payload = 1;
                {
                    let error = $root.RefDesignProtocol.GetExtensionCommand.verify(message.getExtension);
                    if (error)
                        return "getExtension." + error;
                }
            }
            if (message.valve != null && message.hasOwnProperty("valve")) {
                if (properties.payload === 1)
                    return "payload: multiple values";
                properties.payload = 1;
                {
                    let error = $root.RefDesignProtocol.SetValveCommand.verify(message.valve);
                    if (error)
                        return "valve." + error;
                }
            }
            if (message.pump != null && message.hasOwnProperty("pump")) {
                if (properties.payload === 1)
                    return "payload: multiple values";
                properties.payload = 1;
                {
                    let error = $root.RefDesignProtocol.SetPumpCommand.verify(message.pump);
                    if (error)
                        return "pump." + error;
                }
            }
            if (message.hih != null && message.hasOwnProperty("hih")) {
                if (properties.payload === 1)
                    return "payload: multiple values";
                properties.payload = 1;
                {
                    let error = $root.RefDesignProtocol.SetHihCommand.verify(message.hih);
                    if (error)
                        return "hih." + error;
                }
            }
            if (message.calibExpo != null && message.hasOwnProperty("calibExpo")) {
                if (properties.payload === 1)
                    return "payload: multiple values";
                properties.payload = 1;
                {
                    let error = $root.RefDesignProtocol.CalibExposureCommand.verify(message.calibExpo);
                    if (error)
                        return "calibExpo." + error;
                }
            }
            if (message.calibPeakMask != null && message.hasOwnProperty("calibPeakMask")) {
                if (properties.payload === 1)
                    return "payload: multiple values";
                properties.payload = 1;
                {
                    let error = $root.RefDesignProtocol.CalibPeakMaskCommand.verify(message.calibPeakMask);
                    if (error)
                        return "calibPeakMask." + error;
                }
            }
            if (message.resetMinMaxPeak != null && message.hasOwnProperty("resetMinMaxPeak")) {
                if (properties.payload === 1)
                    return "payload: multiple values";
                properties.payload = 1;
                {
                    let error = $root.RefDesignProtocol.ResetMinMaxPeaksCommand.verify(message.resetMinMaxPeak);
                    if (error)
                        return "resetMinMaxPeak." + error;
                }
            }
            if (message.getMinMaxPeaks != null && message.hasOwnProperty("getMinMaxPeaks")) {
                if (properties.payload === 1)
                    return "payload: multiple values";
                properties.payload = 1;
                {
                    let error = $root.RefDesignProtocol.GetMinMaxPeaksCommand.verify(message.getMinMaxPeaks);
                    if (error)
                        return "getMinMaxPeaks." + error;
                }
            }
            if (message.resetMziStaticContrast != null && message.hasOwnProperty("resetMziStaticContrast")) {
                if (properties.payload === 1)
                    return "payload: multiple values";
                properties.payload = 1;
                {
                    let error = $root.RefDesignProtocol.ResetMziStaticContrastCommand.verify(message.resetMziStaticContrast);
                    if (error)
                        return "resetMziStaticContrast." + error;
                }
            }
            if (message.getMziStaticContrast != null && message.hasOwnProperty("getMziStaticContrast")) {
                if (properties.payload === 1)
                    return "payload: multiple values";
                properties.payload = 1;
                {
                    let error = $root.RefDesignProtocol.GetMziStaticContrastCommand.verify(message.getMziStaticContrast);
                    if (error)
                        return "getMziStaticContrast." + error;
                }
            }
            return null;
        };

        /**
         * Creates a MasterRoot message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof RefDesignProtocol.MasterRoot
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {RefDesignProtocol.MasterRoot} MasterRoot
         */
        MasterRoot.fromObject = function fromObject(object) {
            if (object instanceof $root.RefDesignProtocol.MasterRoot)
                return object;
            let message = new $root.RefDesignProtocol.MasterRoot();
            if (object.msgId != null)
                message.msgId = object.msgId >>> 0;
            if (object.version != null) {
                if (typeof object.version !== "object")
                    throw TypeError(".RefDesignProtocol.MasterRoot.version: object expected");
                message.version = $root.RefDesignProtocol.GetVersionCommand.fromObject(object.version);
            }
            if (object.config != null) {
                if (typeof object.config !== "object")
                    throw TypeError(".RefDesignProtocol.MasterRoot.config: object expected");
                message.config = $root.RefDesignProtocol.GetConfigCommand.fromObject(object.config);
            }
            if (object.capabilities != null) {
                if (typeof object.capabilities !== "object")
                    throw TypeError(".RefDesignProtocol.MasterRoot.capabilities: object expected");
                message.capabilities = $root.RefDesignProtocol.GetCapabilitiesCommand.fromObject(object.capabilities);
            }
            if (object.configure != null) {
                if (typeof object.configure !== "object")
                    throw TypeError(".RefDesignProtocol.MasterRoot.configure: object expected");
                message.configure = $root.RefDesignProtocol.ConfigureCommand.fromObject(object.configure);
            }
            if (object.storeConfig != null) {
                if (typeof object.storeConfig !== "object")
                    throw TypeError(".RefDesignProtocol.MasterRoot.storeConfig: object expected");
                message.storeConfig = $root.RefDesignProtocol.StoreConfigCommand.fromObject(object.storeConfig);
            }
            if (object.start != null) {
                if (typeof object.start !== "object")
                    throw TypeError(".RefDesignProtocol.MasterRoot.start: object expected");
                message.start = $root.RefDesignProtocol.StartCommand.fromObject(object.start);
            }
            if (object.stop != null) {
                if (typeof object.stop !== "object")
                    throw TypeError(".RefDesignProtocol.MasterRoot.stop: object expected");
                message.stop = $root.RefDesignProtocol.StopCommand.fromObject(object.stop);
            }
            if (object.singleShot != null) {
                if (typeof object.singleShot !== "object")
                    throw TypeError(".RefDesignProtocol.MasterRoot.singleShot: object expected");
                message.singleShot = $root.RefDesignProtocol.SingleShotCommand.fromObject(object.singleShot);
            }
            if (object.vcsel != null) {
                if (typeof object.vcsel !== "object")
                    throw TypeError(".RefDesignProtocol.MasterRoot.vcsel: object expected");
                message.vcsel = $root.RefDesignProtocol.SetVcselCommand.fromObject(object.vcsel);
            }
            if (object.memInfo != null) {
                if (typeof object.memInfo !== "object")
                    throw TypeError(".RefDesignProtocol.MasterRoot.memInfo: object expected");
                message.memInfo = $root.RefDesignProtocol.GetMemoryInfoCommand.fromObject(object.memInfo);
            }
            if (object.memRead != null) {
                if (typeof object.memRead !== "object")
                    throw TypeError(".RefDesignProtocol.MasterRoot.memRead: object expected");
                message.memRead = $root.RefDesignProtocol.ReadMemoryCommand.fromObject(object.memRead);
            }
            if (object.memWrite != null) {
                if (typeof object.memWrite !== "object")
                    throw TypeError(".RefDesignProtocol.MasterRoot.memWrite: object expected");
                message.memWrite = $root.RefDesignProtocol.WriteMemoryCommand.fromObject(object.memWrite);
            }
            if (object.reset != null) {
                if (typeof object.reset !== "object")
                    throw TypeError(".RefDesignProtocol.MasterRoot.reset: object expected");
                message.reset = $root.RefDesignProtocol.ResetCommand.fromObject(object.reset);
            }
            if (object.setExtension != null) {
                if (typeof object.setExtension !== "object")
                    throw TypeError(".RefDesignProtocol.MasterRoot.setExtension: object expected");
                message.setExtension = $root.RefDesignProtocol.SetExtensionCommand.fromObject(object.setExtension);
            }
            if (object.getExtension != null) {
                if (typeof object.getExtension !== "object")
                    throw TypeError(".RefDesignProtocol.MasterRoot.getExtension: object expected");
                message.getExtension = $root.RefDesignProtocol.GetExtensionCommand.fromObject(object.getExtension);
            }
            if (object.valve != null) {
                if (typeof object.valve !== "object")
                    throw TypeError(".RefDesignProtocol.MasterRoot.valve: object expected");
                message.valve = $root.RefDesignProtocol.SetValveCommand.fromObject(object.valve);
            }
            if (object.pump != null) {
                if (typeof object.pump !== "object")
                    throw TypeError(".RefDesignProtocol.MasterRoot.pump: object expected");
                message.pump = $root.RefDesignProtocol.SetPumpCommand.fromObject(object.pump);
            }
            if (object.hih != null) {
                if (typeof object.hih !== "object")
                    throw TypeError(".RefDesignProtocol.MasterRoot.hih: object expected");
                message.hih = $root.RefDesignProtocol.SetHihCommand.fromObject(object.hih);
            }
            if (object.calibExpo != null) {
                if (typeof object.calibExpo !== "object")
                    throw TypeError(".RefDesignProtocol.MasterRoot.calibExpo: object expected");
                message.calibExpo = $root.RefDesignProtocol.CalibExposureCommand.fromObject(object.calibExpo);
            }
            if (object.calibPeakMask != null) {
                if (typeof object.calibPeakMask !== "object")
                    throw TypeError(".RefDesignProtocol.MasterRoot.calibPeakMask: object expected");
                message.calibPeakMask = $root.RefDesignProtocol.CalibPeakMaskCommand.fromObject(object.calibPeakMask);
            }
            if (object.resetMinMaxPeak != null) {
                if (typeof object.resetMinMaxPeak !== "object")
                    throw TypeError(".RefDesignProtocol.MasterRoot.resetMinMaxPeak: object expected");
                message.resetMinMaxPeak = $root.RefDesignProtocol.ResetMinMaxPeaksCommand.fromObject(object.resetMinMaxPeak);
            }
            if (object.getMinMaxPeaks != null) {
                if (typeof object.getMinMaxPeaks !== "object")
                    throw TypeError(".RefDesignProtocol.MasterRoot.getMinMaxPeaks: object expected");
                message.getMinMaxPeaks = $root.RefDesignProtocol.GetMinMaxPeaksCommand.fromObject(object.getMinMaxPeaks);
            }
            if (object.resetMziStaticContrast != null) {
                if (typeof object.resetMziStaticContrast !== "object")
                    throw TypeError(".RefDesignProtocol.MasterRoot.resetMziStaticContrast: object expected");
                message.resetMziStaticContrast = $root.RefDesignProtocol.ResetMziStaticContrastCommand.fromObject(object.resetMziStaticContrast);
            }
            if (object.getMziStaticContrast != null) {
                if (typeof object.getMziStaticContrast !== "object")
                    throw TypeError(".RefDesignProtocol.MasterRoot.getMziStaticContrast: object expected");
                message.getMziStaticContrast = $root.RefDesignProtocol.GetMziStaticContrastCommand.fromObject(object.getMziStaticContrast);
            }
            return message;
        };

        /**
         * Creates a plain object from a MasterRoot message. Also converts values to other types if specified.
         * @function toObject
         * @memberof RefDesignProtocol.MasterRoot
         * @static
         * @param {RefDesignProtocol.MasterRoot} message MasterRoot
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        MasterRoot.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                object.msgId = 0;
            if (message.msgId != null && message.hasOwnProperty("msgId"))
                object.msgId = message.msgId;
            if (message.version != null && message.hasOwnProperty("version")) {
                object.version = $root.RefDesignProtocol.GetVersionCommand.toObject(message.version, options);
                if (options.oneofs)
                    object.payload = "version";
            }
            if (message.config != null && message.hasOwnProperty("config")) {
                object.config = $root.RefDesignProtocol.GetConfigCommand.toObject(message.config, options);
                if (options.oneofs)
                    object.payload = "config";
            }
            if (message.capabilities != null && message.hasOwnProperty("capabilities")) {
                object.capabilities = $root.RefDesignProtocol.GetCapabilitiesCommand.toObject(message.capabilities, options);
                if (options.oneofs)
                    object.payload = "capabilities";
            }
            if (message.configure != null && message.hasOwnProperty("configure")) {
                object.configure = $root.RefDesignProtocol.ConfigureCommand.toObject(message.configure, options);
                if (options.oneofs)
                    object.payload = "configure";
            }
            if (message.storeConfig != null && message.hasOwnProperty("storeConfig")) {
                object.storeConfig = $root.RefDesignProtocol.StoreConfigCommand.toObject(message.storeConfig, options);
                if (options.oneofs)
                    object.payload = "storeConfig";
            }
            if (message.start != null && message.hasOwnProperty("start")) {
                object.start = $root.RefDesignProtocol.StartCommand.toObject(message.start, options);
                if (options.oneofs)
                    object.payload = "start";
            }
            if (message.stop != null && message.hasOwnProperty("stop")) {
                object.stop = $root.RefDesignProtocol.StopCommand.toObject(message.stop, options);
                if (options.oneofs)
                    object.payload = "stop";
            }
            if (message.singleShot != null && message.hasOwnProperty("singleShot")) {
                object.singleShot = $root.RefDesignProtocol.SingleShotCommand.toObject(message.singleShot, options);
                if (options.oneofs)
                    object.payload = "singleShot";
            }
            if (message.vcsel != null && message.hasOwnProperty("vcsel")) {
                object.vcsel = $root.RefDesignProtocol.SetVcselCommand.toObject(message.vcsel, options);
                if (options.oneofs)
                    object.payload = "vcsel";
            }
            if (message.memInfo != null && message.hasOwnProperty("memInfo")) {
                object.memInfo = $root.RefDesignProtocol.GetMemoryInfoCommand.toObject(message.memInfo, options);
                if (options.oneofs)
                    object.payload = "memInfo";
            }
            if (message.memRead != null && message.hasOwnProperty("memRead")) {
                object.memRead = $root.RefDesignProtocol.ReadMemoryCommand.toObject(message.memRead, options);
                if (options.oneofs)
                    object.payload = "memRead";
            }
            if (message.memWrite != null && message.hasOwnProperty("memWrite")) {
                object.memWrite = $root.RefDesignProtocol.WriteMemoryCommand.toObject(message.memWrite, options);
                if (options.oneofs)
                    object.payload = "memWrite";
            }
            if (message.reset != null && message.hasOwnProperty("reset")) {
                object.reset = $root.RefDesignProtocol.ResetCommand.toObject(message.reset, options);
                if (options.oneofs)
                    object.payload = "reset";
            }
            if (message.setExtension != null && message.hasOwnProperty("setExtension")) {
                object.setExtension = $root.RefDesignProtocol.SetExtensionCommand.toObject(message.setExtension, options);
                if (options.oneofs)
                    object.payload = "setExtension";
            }
            if (message.getExtension != null && message.hasOwnProperty("getExtension")) {
                object.getExtension = $root.RefDesignProtocol.GetExtensionCommand.toObject(message.getExtension, options);
                if (options.oneofs)
                    object.payload = "getExtension";
            }
            if (message.valve != null && message.hasOwnProperty("valve")) {
                object.valve = $root.RefDesignProtocol.SetValveCommand.toObject(message.valve, options);
                if (options.oneofs)
                    object.payload = "valve";
            }
            if (message.pump != null && message.hasOwnProperty("pump")) {
                object.pump = $root.RefDesignProtocol.SetPumpCommand.toObject(message.pump, options);
                if (options.oneofs)
                    object.payload = "pump";
            }
            if (message.hih != null && message.hasOwnProperty("hih")) {
                object.hih = $root.RefDesignProtocol.SetHihCommand.toObject(message.hih, options);
                if (options.oneofs)
                    object.payload = "hih";
            }
            if (message.calibExpo != null && message.hasOwnProperty("calibExpo")) {
                object.calibExpo = $root.RefDesignProtocol.CalibExposureCommand.toObject(message.calibExpo, options);
                if (options.oneofs)
                    object.payload = "calibExpo";
            }
            if (message.calibPeakMask != null && message.hasOwnProperty("calibPeakMask")) {
                object.calibPeakMask = $root.RefDesignProtocol.CalibPeakMaskCommand.toObject(message.calibPeakMask, options);
                if (options.oneofs)
                    object.payload = "calibPeakMask";
            }
            if (message.resetMinMaxPeak != null && message.hasOwnProperty("resetMinMaxPeak")) {
                object.resetMinMaxPeak = $root.RefDesignProtocol.ResetMinMaxPeaksCommand.toObject(message.resetMinMaxPeak, options);
                if (options.oneofs)
                    object.payload = "resetMinMaxPeak";
            }
            if (message.getMinMaxPeaks != null && message.hasOwnProperty("getMinMaxPeaks")) {
                object.getMinMaxPeaks = $root.RefDesignProtocol.GetMinMaxPeaksCommand.toObject(message.getMinMaxPeaks, options);
                if (options.oneofs)
                    object.payload = "getMinMaxPeaks";
            }
            if (message.resetMziStaticContrast != null && message.hasOwnProperty("resetMziStaticContrast")) {
                object.resetMziStaticContrast = $root.RefDesignProtocol.ResetMziStaticContrastCommand.toObject(message.resetMziStaticContrast, options);
                if (options.oneofs)
                    object.payload = "resetMziStaticContrast";
            }
            if (message.getMziStaticContrast != null && message.hasOwnProperty("getMziStaticContrast")) {
                object.getMziStaticContrast = $root.RefDesignProtocol.GetMziStaticContrastCommand.toObject(message.getMziStaticContrast, options);
                if (options.oneofs)
                    object.payload = "getMziStaticContrast";
            }
            return object;
        };

        /**
         * Converts this MasterRoot to JSON.
         * @function toJSON
         * @memberof RefDesignProtocol.MasterRoot
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        MasterRoot.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for MasterRoot
         * @function getTypeUrl
         * @memberof RefDesignProtocol.MasterRoot
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        MasterRoot.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/RefDesignProtocol.MasterRoot";
        };

        return MasterRoot;
    })();

    RefDesignProtocol.SlaveRoot = (function() {

        /**
         * Properties of a SlaveRoot.
         * @memberof RefDesignProtocol
         * @interface ISlaveRoot
         * @property {number|null} [msgId] SlaveRoot msgId
         * @property {RefDesignProtocol.IEventChannel|null} [event] SlaveRoot event
         * @property {RefDesignProtocol.IResultChannel|null} [result] SlaveRoot result
         */

        /**
         * Constructs a new SlaveRoot.
         * @memberof RefDesignProtocol
         * @classdesc Represents a SlaveRoot.
         * @implements ISlaveRoot
         * @constructor
         * @param {RefDesignProtocol.ISlaveRoot=} [properties] Properties to set
         */
        function SlaveRoot(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * SlaveRoot msgId.
         * @member {number} msgId
         * @memberof RefDesignProtocol.SlaveRoot
         * @instance
         */
        SlaveRoot.prototype.msgId = 0;

        /**
         * SlaveRoot event.
         * @member {RefDesignProtocol.IEventChannel|null|undefined} event
         * @memberof RefDesignProtocol.SlaveRoot
         * @instance
         */
        SlaveRoot.prototype.event = null;

        /**
         * SlaveRoot result.
         * @member {RefDesignProtocol.IResultChannel|null|undefined} result
         * @memberof RefDesignProtocol.SlaveRoot
         * @instance
         */
        SlaveRoot.prototype.result = null;

        // OneOf field names bound to virtual getters and setters
        let $oneOfFields;

        /**
         * SlaveRoot channel.
         * @member {"event"|"result"|undefined} channel
         * @memberof RefDesignProtocol.SlaveRoot
         * @instance
         */
        Object.defineProperty(SlaveRoot.prototype, "channel", {
            get: $util.oneOfGetter($oneOfFields = ["event", "result"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Creates a new SlaveRoot instance using the specified properties.
         * @function create
         * @memberof RefDesignProtocol.SlaveRoot
         * @static
         * @param {RefDesignProtocol.ISlaveRoot=} [properties] Properties to set
         * @returns {RefDesignProtocol.SlaveRoot} SlaveRoot instance
         */
        SlaveRoot.create = function create(properties) {
            return new SlaveRoot(properties);
        };

        /**
         * Encodes the specified SlaveRoot message. Does not implicitly {@link RefDesignProtocol.SlaveRoot.verify|verify} messages.
         * @function encode
         * @memberof RefDesignProtocol.SlaveRoot
         * @static
         * @param {RefDesignProtocol.ISlaveRoot} message SlaveRoot message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SlaveRoot.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.msgId != null && Object.hasOwnProperty.call(message, "msgId"))
                writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.msgId);
            if (message.event != null && Object.hasOwnProperty.call(message, "event"))
                $root.RefDesignProtocol.EventChannel.encode(message.event, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            if (message.result != null && Object.hasOwnProperty.call(message, "result"))
                $root.RefDesignProtocol.ResultChannel.encode(message.result, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified SlaveRoot message, length delimited. Does not implicitly {@link RefDesignProtocol.SlaveRoot.verify|verify} messages.
         * @function encodeDelimited
         * @memberof RefDesignProtocol.SlaveRoot
         * @static
         * @param {RefDesignProtocol.ISlaveRoot} message SlaveRoot message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SlaveRoot.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a SlaveRoot message from the specified reader or buffer.
         * @function decode
         * @memberof RefDesignProtocol.SlaveRoot
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {RefDesignProtocol.SlaveRoot} SlaveRoot
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SlaveRoot.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.RefDesignProtocol.SlaveRoot();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.msgId = reader.uint32();
                        break;
                    }
                case 2: {
                        message.event = $root.RefDesignProtocol.EventChannel.decode(reader, reader.uint32());
                        break;
                    }
                case 3: {
                        message.result = $root.RefDesignProtocol.ResultChannel.decode(reader, reader.uint32());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a SlaveRoot message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof RefDesignProtocol.SlaveRoot
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {RefDesignProtocol.SlaveRoot} SlaveRoot
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SlaveRoot.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a SlaveRoot message.
         * @function verify
         * @memberof RefDesignProtocol.SlaveRoot
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        SlaveRoot.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            let properties = {};
            if (message.msgId != null && message.hasOwnProperty("msgId"))
                if (!$util.isInteger(message.msgId))
                    return "msgId: integer expected";
            if (message.event != null && message.hasOwnProperty("event")) {
                properties.channel = 1;
                {
                    let error = $root.RefDesignProtocol.EventChannel.verify(message.event);
                    if (error)
                        return "event." + error;
                }
            }
            if (message.result != null && message.hasOwnProperty("result")) {
                if (properties.channel === 1)
                    return "channel: multiple values";
                properties.channel = 1;
                {
                    let error = $root.RefDesignProtocol.ResultChannel.verify(message.result);
                    if (error)
                        return "result." + error;
                }
            }
            return null;
        };

        /**
         * Creates a SlaveRoot message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof RefDesignProtocol.SlaveRoot
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {RefDesignProtocol.SlaveRoot} SlaveRoot
         */
        SlaveRoot.fromObject = function fromObject(object) {
            if (object instanceof $root.RefDesignProtocol.SlaveRoot)
                return object;
            let message = new $root.RefDesignProtocol.SlaveRoot();
            if (object.msgId != null)
                message.msgId = object.msgId >>> 0;
            if (object.event != null) {
                if (typeof object.event !== "object")
                    throw TypeError(".RefDesignProtocol.SlaveRoot.event: object expected");
                message.event = $root.RefDesignProtocol.EventChannel.fromObject(object.event);
            }
            if (object.result != null) {
                if (typeof object.result !== "object")
                    throw TypeError(".RefDesignProtocol.SlaveRoot.result: object expected");
                message.result = $root.RefDesignProtocol.ResultChannel.fromObject(object.result);
            }
            return message;
        };

        /**
         * Creates a plain object from a SlaveRoot message. Also converts values to other types if specified.
         * @function toObject
         * @memberof RefDesignProtocol.SlaveRoot
         * @static
         * @param {RefDesignProtocol.SlaveRoot} message SlaveRoot
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        SlaveRoot.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                object.msgId = 0;
            if (message.msgId != null && message.hasOwnProperty("msgId"))
                object.msgId = message.msgId;
            if (message.event != null && message.hasOwnProperty("event")) {
                object.event = $root.RefDesignProtocol.EventChannel.toObject(message.event, options);
                if (options.oneofs)
                    object.channel = "event";
            }
            if (message.result != null && message.hasOwnProperty("result")) {
                object.result = $root.RefDesignProtocol.ResultChannel.toObject(message.result, options);
                if (options.oneofs)
                    object.channel = "result";
            }
            return object;
        };

        /**
         * Converts this SlaveRoot to JSON.
         * @function toJSON
         * @memberof RefDesignProtocol.SlaveRoot
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        SlaveRoot.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for SlaveRoot
         * @function getTypeUrl
         * @memberof RefDesignProtocol.SlaveRoot
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        SlaveRoot.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/RefDesignProtocol.SlaveRoot";
        };

        return SlaveRoot;
    })();

    RefDesignProtocol.EventChannel = (function() {

        /**
         * Properties of an EventChannel.
         * @memberof RefDesignProtocol
         * @interface IEventChannel
         * @property {number|Long|null} [tick] EventChannel tick
         * @property {RefDesignProtocol.IAlgoOutputData|null} [algo] EventChannel algo
         * @property {RefDesignProtocol.IHih6120|null} [hih6120] EventChannel hih6120
         * @property {RefDesignProtocol.IExtensionEvent|null} [extension] EventChannel extension
         */

        /**
         * Constructs a new EventChannel.
         * @memberof RefDesignProtocol
         * @classdesc Represents an EventChannel.
         * @implements IEventChannel
         * @constructor
         * @param {RefDesignProtocol.IEventChannel=} [properties] Properties to set
         */
        function EventChannel(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * EventChannel tick.
         * @member {number|Long} tick
         * @memberof RefDesignProtocol.EventChannel
         * @instance
         */
        EventChannel.prototype.tick = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * EventChannel algo.
         * @member {RefDesignProtocol.IAlgoOutputData|null|undefined} algo
         * @memberof RefDesignProtocol.EventChannel
         * @instance
         */
        EventChannel.prototype.algo = null;

        /**
         * EventChannel hih6120.
         * @member {RefDesignProtocol.IHih6120|null|undefined} hih6120
         * @memberof RefDesignProtocol.EventChannel
         * @instance
         */
        EventChannel.prototype.hih6120 = null;

        /**
         * EventChannel extension.
         * @member {RefDesignProtocol.IExtensionEvent|null|undefined} extension
         * @memberof RefDesignProtocol.EventChannel
         * @instance
         */
        EventChannel.prototype.extension = null;

        // OneOf field names bound to virtual getters and setters
        let $oneOfFields;

        /**
         * EventChannel payload.
         * @member {"algo"|"hih6120"|"extension"|undefined} payload
         * @memberof RefDesignProtocol.EventChannel
         * @instance
         */
        Object.defineProperty(EventChannel.prototype, "payload", {
            get: $util.oneOfGetter($oneOfFields = ["algo", "hih6120", "extension"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Creates a new EventChannel instance using the specified properties.
         * @function create
         * @memberof RefDesignProtocol.EventChannel
         * @static
         * @param {RefDesignProtocol.IEventChannel=} [properties] Properties to set
         * @returns {RefDesignProtocol.EventChannel} EventChannel instance
         */
        EventChannel.create = function create(properties) {
            return new EventChannel(properties);
        };

        /**
         * Encodes the specified EventChannel message. Does not implicitly {@link RefDesignProtocol.EventChannel.verify|verify} messages.
         * @function encode
         * @memberof RefDesignProtocol.EventChannel
         * @static
         * @param {RefDesignProtocol.IEventChannel} message EventChannel message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        EventChannel.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.tick != null && Object.hasOwnProperty.call(message, "tick"))
                writer.uint32(/* id 1, wireType 0 =*/8).uint64(message.tick);
            if (message.algo != null && Object.hasOwnProperty.call(message, "algo"))
                $root.RefDesignProtocol.AlgoOutputData.encode(message.algo, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            if (message.hih6120 != null && Object.hasOwnProperty.call(message, "hih6120"))
                $root.RefDesignProtocol.Hih6120.encode(message.hih6120, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            if (message.extension != null && Object.hasOwnProperty.call(message, "extension"))
                $root.RefDesignProtocol.ExtensionEvent.encode(message.extension, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified EventChannel message, length delimited. Does not implicitly {@link RefDesignProtocol.EventChannel.verify|verify} messages.
         * @function encodeDelimited
         * @memberof RefDesignProtocol.EventChannel
         * @static
         * @param {RefDesignProtocol.IEventChannel} message EventChannel message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        EventChannel.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an EventChannel message from the specified reader or buffer.
         * @function decode
         * @memberof RefDesignProtocol.EventChannel
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {RefDesignProtocol.EventChannel} EventChannel
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        EventChannel.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.RefDesignProtocol.EventChannel();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.tick = reader.uint64();
                        break;
                    }
                case 2: {
                        message.algo = $root.RefDesignProtocol.AlgoOutputData.decode(reader, reader.uint32());
                        break;
                    }
                case 3: {
                        message.hih6120 = $root.RefDesignProtocol.Hih6120.decode(reader, reader.uint32());
                        break;
                    }
                case 4: {
                        message.extension = $root.RefDesignProtocol.ExtensionEvent.decode(reader, reader.uint32());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an EventChannel message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof RefDesignProtocol.EventChannel
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {RefDesignProtocol.EventChannel} EventChannel
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        EventChannel.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an EventChannel message.
         * @function verify
         * @memberof RefDesignProtocol.EventChannel
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        EventChannel.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            let properties = {};
            if (message.tick != null && message.hasOwnProperty("tick"))
                if (!$util.isInteger(message.tick) && !(message.tick && $util.isInteger(message.tick.low) && $util.isInteger(message.tick.high)))
                    return "tick: integer|Long expected";
            if (message.algo != null && message.hasOwnProperty("algo")) {
                properties.payload = 1;
                {
                    let error = $root.RefDesignProtocol.AlgoOutputData.verify(message.algo);
                    if (error)
                        return "algo." + error;
                }
            }
            if (message.hih6120 != null && message.hasOwnProperty("hih6120")) {
                if (properties.payload === 1)
                    return "payload: multiple values";
                properties.payload = 1;
                {
                    let error = $root.RefDesignProtocol.Hih6120.verify(message.hih6120);
                    if (error)
                        return "hih6120." + error;
                }
            }
            if (message.extension != null && message.hasOwnProperty("extension")) {
                if (properties.payload === 1)
                    return "payload: multiple values";
                properties.payload = 1;
                {
                    let error = $root.RefDesignProtocol.ExtensionEvent.verify(message.extension);
                    if (error)
                        return "extension." + error;
                }
            }
            return null;
        };

        /**
         * Creates an EventChannel message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof RefDesignProtocol.EventChannel
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {RefDesignProtocol.EventChannel} EventChannel
         */
        EventChannel.fromObject = function fromObject(object) {
            if (object instanceof $root.RefDesignProtocol.EventChannel)
                return object;
            let message = new $root.RefDesignProtocol.EventChannel();
            if (object.tick != null)
                if ($util.Long)
                    (message.tick = $util.Long.fromValue(object.tick)).unsigned = true;
                else if (typeof object.tick === "string")
                    message.tick = parseInt(object.tick, 10);
                else if (typeof object.tick === "number")
                    message.tick = object.tick;
                else if (typeof object.tick === "object")
                    message.tick = new $util.LongBits(object.tick.low >>> 0, object.tick.high >>> 0).toNumber(true);
            if (object.algo != null) {
                if (typeof object.algo !== "object")
                    throw TypeError(".RefDesignProtocol.EventChannel.algo: object expected");
                message.algo = $root.RefDesignProtocol.AlgoOutputData.fromObject(object.algo);
            }
            if (object.hih6120 != null) {
                if (typeof object.hih6120 !== "object")
                    throw TypeError(".RefDesignProtocol.EventChannel.hih6120: object expected");
                message.hih6120 = $root.RefDesignProtocol.Hih6120.fromObject(object.hih6120);
            }
            if (object.extension != null) {
                if (typeof object.extension !== "object")
                    throw TypeError(".RefDesignProtocol.EventChannel.extension: object expected");
                message.extension = $root.RefDesignProtocol.ExtensionEvent.fromObject(object.extension);
            }
            return message;
        };

        /**
         * Creates a plain object from an EventChannel message. Also converts values to other types if specified.
         * @function toObject
         * @memberof RefDesignProtocol.EventChannel
         * @static
         * @param {RefDesignProtocol.EventChannel} message EventChannel
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        EventChannel.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                if ($util.Long) {
                    let long = new $util.Long(0, 0, true);
                    object.tick = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.tick = options.longs === String ? "0" : 0;
            if (message.tick != null && message.hasOwnProperty("tick"))
                if (typeof message.tick === "number")
                    object.tick = options.longs === String ? String(message.tick) : message.tick;
                else
                    object.tick = options.longs === String ? $util.Long.prototype.toString.call(message.tick) : options.longs === Number ? new $util.LongBits(message.tick.low >>> 0, message.tick.high >>> 0).toNumber(true) : message.tick;
            if (message.algo != null && message.hasOwnProperty("algo")) {
                object.algo = $root.RefDesignProtocol.AlgoOutputData.toObject(message.algo, options);
                if (options.oneofs)
                    object.payload = "algo";
            }
            if (message.hih6120 != null && message.hasOwnProperty("hih6120")) {
                object.hih6120 = $root.RefDesignProtocol.Hih6120.toObject(message.hih6120, options);
                if (options.oneofs)
                    object.payload = "hih6120";
            }
            if (message.extension != null && message.hasOwnProperty("extension")) {
                object.extension = $root.RefDesignProtocol.ExtensionEvent.toObject(message.extension, options);
                if (options.oneofs)
                    object.payload = "extension";
            }
            return object;
        };

        /**
         * Converts this EventChannel to JSON.
         * @function toJSON
         * @memberof RefDesignProtocol.EventChannel
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        EventChannel.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for EventChannel
         * @function getTypeUrl
         * @memberof RefDesignProtocol.EventChannel
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        EventChannel.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/RefDesignProtocol.EventChannel";
        };

        return EventChannel;
    })();

    RefDesignProtocol.ResultHeader = (function() {

        /**
         * Properties of a ResultHeader.
         * @memberof RefDesignProtocol
         * @interface IResultHeader
         * @property {number|null} [reqId] ResultHeader reqId
         * @property {boolean|null} [status] ResultHeader status
         * @property {string|null} [reason] ResultHeader reason
         */

        /**
         * Constructs a new ResultHeader.
         * @memberof RefDesignProtocol
         * @classdesc Represents a ResultHeader.
         * @implements IResultHeader
         * @constructor
         * @param {RefDesignProtocol.IResultHeader=} [properties] Properties to set
         */
        function ResultHeader(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ResultHeader reqId.
         * @member {number} reqId
         * @memberof RefDesignProtocol.ResultHeader
         * @instance
         */
        ResultHeader.prototype.reqId = 0;

        /**
         * ResultHeader status.
         * @member {boolean} status
         * @memberof RefDesignProtocol.ResultHeader
         * @instance
         */
        ResultHeader.prototype.status = false;

        /**
         * ResultHeader reason.
         * @member {string} reason
         * @memberof RefDesignProtocol.ResultHeader
         * @instance
         */
        ResultHeader.prototype.reason = "";

        /**
         * Creates a new ResultHeader instance using the specified properties.
         * @function create
         * @memberof RefDesignProtocol.ResultHeader
         * @static
         * @param {RefDesignProtocol.IResultHeader=} [properties] Properties to set
         * @returns {RefDesignProtocol.ResultHeader} ResultHeader instance
         */
        ResultHeader.create = function create(properties) {
            return new ResultHeader(properties);
        };

        /**
         * Encodes the specified ResultHeader message. Does not implicitly {@link RefDesignProtocol.ResultHeader.verify|verify} messages.
         * @function encode
         * @memberof RefDesignProtocol.ResultHeader
         * @static
         * @param {RefDesignProtocol.IResultHeader} message ResultHeader message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ResultHeader.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.reqId != null && Object.hasOwnProperty.call(message, "reqId"))
                writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.reqId);
            if (message.status != null && Object.hasOwnProperty.call(message, "status"))
                writer.uint32(/* id 2, wireType 0 =*/16).bool(message.status);
            if (message.reason != null && Object.hasOwnProperty.call(message, "reason"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.reason);
            return writer;
        };

        /**
         * Encodes the specified ResultHeader message, length delimited. Does not implicitly {@link RefDesignProtocol.ResultHeader.verify|verify} messages.
         * @function encodeDelimited
         * @memberof RefDesignProtocol.ResultHeader
         * @static
         * @param {RefDesignProtocol.IResultHeader} message ResultHeader message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ResultHeader.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a ResultHeader message from the specified reader or buffer.
         * @function decode
         * @memberof RefDesignProtocol.ResultHeader
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {RefDesignProtocol.ResultHeader} ResultHeader
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ResultHeader.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.RefDesignProtocol.ResultHeader();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.reqId = reader.uint32();
                        break;
                    }
                case 2: {
                        message.status = reader.bool();
                        break;
                    }
                case 3: {
                        message.reason = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a ResultHeader message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof RefDesignProtocol.ResultHeader
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {RefDesignProtocol.ResultHeader} ResultHeader
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ResultHeader.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a ResultHeader message.
         * @function verify
         * @memberof RefDesignProtocol.ResultHeader
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ResultHeader.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.reqId != null && message.hasOwnProperty("reqId"))
                if (!$util.isInteger(message.reqId))
                    return "reqId: integer expected";
            if (message.status != null && message.hasOwnProperty("status"))
                if (typeof message.status !== "boolean")
                    return "status: boolean expected";
            if (message.reason != null && message.hasOwnProperty("reason"))
                if (!$util.isString(message.reason))
                    return "reason: string expected";
            return null;
        };

        /**
         * Creates a ResultHeader message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof RefDesignProtocol.ResultHeader
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {RefDesignProtocol.ResultHeader} ResultHeader
         */
        ResultHeader.fromObject = function fromObject(object) {
            if (object instanceof $root.RefDesignProtocol.ResultHeader)
                return object;
            let message = new $root.RefDesignProtocol.ResultHeader();
            if (object.reqId != null)
                message.reqId = object.reqId >>> 0;
            if (object.status != null)
                message.status = Boolean(object.status);
            if (object.reason != null)
                message.reason = String(object.reason);
            return message;
        };

        /**
         * Creates a plain object from a ResultHeader message. Also converts values to other types if specified.
         * @function toObject
         * @memberof RefDesignProtocol.ResultHeader
         * @static
         * @param {RefDesignProtocol.ResultHeader} message ResultHeader
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ResultHeader.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.reqId = 0;
                object.status = false;
                object.reason = "";
            }
            if (message.reqId != null && message.hasOwnProperty("reqId"))
                object.reqId = message.reqId;
            if (message.status != null && message.hasOwnProperty("status"))
                object.status = message.status;
            if (message.reason != null && message.hasOwnProperty("reason"))
                object.reason = message.reason;
            return object;
        };

        /**
         * Converts this ResultHeader to JSON.
         * @function toJSON
         * @memberof RefDesignProtocol.ResultHeader
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ResultHeader.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for ResultHeader
         * @function getTypeUrl
         * @memberof RefDesignProtocol.ResultHeader
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        ResultHeader.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/RefDesignProtocol.ResultHeader";
        };

        return ResultHeader;
    })();

    RefDesignProtocol.ResultChannel = (function() {

        /**
         * Properties of a ResultChannel.
         * @memberof RefDesignProtocol
         * @interface IResultChannel
         * @property {RefDesignProtocol.IResultHeader|null} [header] ResultChannel header
         * @property {RefDesignProtocol.IVersionResult|null} [version] ResultChannel version
         * @property {RefDesignProtocol.IConfigResult|null} [config] ResultChannel config
         * @property {RefDesignProtocol.ICapabilitiesResult|null} [capabilities] ResultChannel capabilities
         * @property {RefDesignProtocol.ISingleShotResult|null} [singleShot] ResultChannel singleShot
         * @property {RefDesignProtocol.IMemoryInfoResult|null} [memoryInfo] ResultChannel memoryInfo
         * @property {RefDesignProtocol.IReadMemoryResult|null} [memoryData] ResultChannel memoryData
         * @property {RefDesignProtocol.IExtensionResult|null} [extension] ResultChannel extension
         * @property {RefDesignProtocol.ICalibPeakMaskResult|null} [calibPeakMask] ResultChannel calibPeakMask
         * @property {RefDesignProtocol.ICalibExposureResult|null} [calibExpo] ResultChannel calibExpo
         * @property {RefDesignProtocol.IMinMaxPeaksResult|null} [minMaxPeaks] ResultChannel minMaxPeaks
         * @property {RefDesignProtocol.IMziStaticContrastResult|null} [mziStaticContrast] ResultChannel mziStaticContrast
         */

        /**
         * Constructs a new ResultChannel.
         * @memberof RefDesignProtocol
         * @classdesc Represents a ResultChannel.
         * @implements IResultChannel
         * @constructor
         * @param {RefDesignProtocol.IResultChannel=} [properties] Properties to set
         */
        function ResultChannel(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ResultChannel header.
         * @member {RefDesignProtocol.IResultHeader|null|undefined} header
         * @memberof RefDesignProtocol.ResultChannel
         * @instance
         */
        ResultChannel.prototype.header = null;

        /**
         * ResultChannel version.
         * @member {RefDesignProtocol.IVersionResult|null|undefined} version
         * @memberof RefDesignProtocol.ResultChannel
         * @instance
         */
        ResultChannel.prototype.version = null;

        /**
         * ResultChannel config.
         * @member {RefDesignProtocol.IConfigResult|null|undefined} config
         * @memberof RefDesignProtocol.ResultChannel
         * @instance
         */
        ResultChannel.prototype.config = null;

        /**
         * ResultChannel capabilities.
         * @member {RefDesignProtocol.ICapabilitiesResult|null|undefined} capabilities
         * @memberof RefDesignProtocol.ResultChannel
         * @instance
         */
        ResultChannel.prototype.capabilities = null;

        /**
         * ResultChannel singleShot.
         * @member {RefDesignProtocol.ISingleShotResult|null|undefined} singleShot
         * @memberof RefDesignProtocol.ResultChannel
         * @instance
         */
        ResultChannel.prototype.singleShot = null;

        /**
         * ResultChannel memoryInfo.
         * @member {RefDesignProtocol.IMemoryInfoResult|null|undefined} memoryInfo
         * @memberof RefDesignProtocol.ResultChannel
         * @instance
         */
        ResultChannel.prototype.memoryInfo = null;

        /**
         * ResultChannel memoryData.
         * @member {RefDesignProtocol.IReadMemoryResult|null|undefined} memoryData
         * @memberof RefDesignProtocol.ResultChannel
         * @instance
         */
        ResultChannel.prototype.memoryData = null;

        /**
         * ResultChannel extension.
         * @member {RefDesignProtocol.IExtensionResult|null|undefined} extension
         * @memberof RefDesignProtocol.ResultChannel
         * @instance
         */
        ResultChannel.prototype.extension = null;

        /**
         * ResultChannel calibPeakMask.
         * @member {RefDesignProtocol.ICalibPeakMaskResult|null|undefined} calibPeakMask
         * @memberof RefDesignProtocol.ResultChannel
         * @instance
         */
        ResultChannel.prototype.calibPeakMask = null;

        /**
         * ResultChannel calibExpo.
         * @member {RefDesignProtocol.ICalibExposureResult|null|undefined} calibExpo
         * @memberof RefDesignProtocol.ResultChannel
         * @instance
         */
        ResultChannel.prototype.calibExpo = null;

        /**
         * ResultChannel minMaxPeaks.
         * @member {RefDesignProtocol.IMinMaxPeaksResult|null|undefined} minMaxPeaks
         * @memberof RefDesignProtocol.ResultChannel
         * @instance
         */
        ResultChannel.prototype.minMaxPeaks = null;

        /**
         * ResultChannel mziStaticContrast.
         * @member {RefDesignProtocol.IMziStaticContrastResult|null|undefined} mziStaticContrast
         * @memberof RefDesignProtocol.ResultChannel
         * @instance
         */
        ResultChannel.prototype.mziStaticContrast = null;

        // OneOf field names bound to virtual getters and setters
        let $oneOfFields;

        /**
         * ResultChannel payload.
         * @member {"version"|"config"|"capabilities"|"singleShot"|"memoryInfo"|"memoryData"|"extension"|"calibPeakMask"|"calibExpo"|"minMaxPeaks"|"mziStaticContrast"|undefined} payload
         * @memberof RefDesignProtocol.ResultChannel
         * @instance
         */
        Object.defineProperty(ResultChannel.prototype, "payload", {
            get: $util.oneOfGetter($oneOfFields = ["version", "config", "capabilities", "singleShot", "memoryInfo", "memoryData", "extension", "calibPeakMask", "calibExpo", "minMaxPeaks", "mziStaticContrast"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Creates a new ResultChannel instance using the specified properties.
         * @function create
         * @memberof RefDesignProtocol.ResultChannel
         * @static
         * @param {RefDesignProtocol.IResultChannel=} [properties] Properties to set
         * @returns {RefDesignProtocol.ResultChannel} ResultChannel instance
         */
        ResultChannel.create = function create(properties) {
            return new ResultChannel(properties);
        };

        /**
         * Encodes the specified ResultChannel message. Does not implicitly {@link RefDesignProtocol.ResultChannel.verify|verify} messages.
         * @function encode
         * @memberof RefDesignProtocol.ResultChannel
         * @static
         * @param {RefDesignProtocol.IResultChannel} message ResultChannel message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ResultChannel.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.header != null && Object.hasOwnProperty.call(message, "header"))
                $root.RefDesignProtocol.ResultHeader.encode(message.header, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.version != null && Object.hasOwnProperty.call(message, "version"))
                $root.RefDesignProtocol.VersionResult.encode(message.version, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            if (message.config != null && Object.hasOwnProperty.call(message, "config"))
                $root.RefDesignProtocol.ConfigResult.encode(message.config, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            if (message.capabilities != null && Object.hasOwnProperty.call(message, "capabilities"))
                $root.RefDesignProtocol.CapabilitiesResult.encode(message.capabilities, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
            if (message.singleShot != null && Object.hasOwnProperty.call(message, "singleShot"))
                $root.RefDesignProtocol.SingleShotResult.encode(message.singleShot, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
            if (message.memoryInfo != null && Object.hasOwnProperty.call(message, "memoryInfo"))
                $root.RefDesignProtocol.MemoryInfoResult.encode(message.memoryInfo, writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
            if (message.memoryData != null && Object.hasOwnProperty.call(message, "memoryData"))
                $root.RefDesignProtocol.ReadMemoryResult.encode(message.memoryData, writer.uint32(/* id 7, wireType 2 =*/58).fork()).ldelim();
            if (message.extension != null && Object.hasOwnProperty.call(message, "extension"))
                $root.RefDesignProtocol.ExtensionResult.encode(message.extension, writer.uint32(/* id 8, wireType 2 =*/66).fork()).ldelim();
            if (message.calibPeakMask != null && Object.hasOwnProperty.call(message, "calibPeakMask"))
                $root.RefDesignProtocol.CalibPeakMaskResult.encode(message.calibPeakMask, writer.uint32(/* id 9, wireType 2 =*/74).fork()).ldelim();
            if (message.calibExpo != null && Object.hasOwnProperty.call(message, "calibExpo"))
                $root.RefDesignProtocol.CalibExposureResult.encode(message.calibExpo, writer.uint32(/* id 10, wireType 2 =*/82).fork()).ldelim();
            if (message.minMaxPeaks != null && Object.hasOwnProperty.call(message, "minMaxPeaks"))
                $root.RefDesignProtocol.MinMaxPeaksResult.encode(message.minMaxPeaks, writer.uint32(/* id 11, wireType 2 =*/90).fork()).ldelim();
            if (message.mziStaticContrast != null && Object.hasOwnProperty.call(message, "mziStaticContrast"))
                $root.RefDesignProtocol.MziStaticContrastResult.encode(message.mziStaticContrast, writer.uint32(/* id 12, wireType 2 =*/98).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified ResultChannel message, length delimited. Does not implicitly {@link RefDesignProtocol.ResultChannel.verify|verify} messages.
         * @function encodeDelimited
         * @memberof RefDesignProtocol.ResultChannel
         * @static
         * @param {RefDesignProtocol.IResultChannel} message ResultChannel message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ResultChannel.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a ResultChannel message from the specified reader or buffer.
         * @function decode
         * @memberof RefDesignProtocol.ResultChannel
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {RefDesignProtocol.ResultChannel} ResultChannel
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ResultChannel.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.RefDesignProtocol.ResultChannel();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.header = $root.RefDesignProtocol.ResultHeader.decode(reader, reader.uint32());
                        break;
                    }
                case 2: {
                        message.version = $root.RefDesignProtocol.VersionResult.decode(reader, reader.uint32());
                        break;
                    }
                case 3: {
                        message.config = $root.RefDesignProtocol.ConfigResult.decode(reader, reader.uint32());
                        break;
                    }
                case 4: {
                        message.capabilities = $root.RefDesignProtocol.CapabilitiesResult.decode(reader, reader.uint32());
                        break;
                    }
                case 5: {
                        message.singleShot = $root.RefDesignProtocol.SingleShotResult.decode(reader, reader.uint32());
                        break;
                    }
                case 6: {
                        message.memoryInfo = $root.RefDesignProtocol.MemoryInfoResult.decode(reader, reader.uint32());
                        break;
                    }
                case 7: {
                        message.memoryData = $root.RefDesignProtocol.ReadMemoryResult.decode(reader, reader.uint32());
                        break;
                    }
                case 8: {
                        message.extension = $root.RefDesignProtocol.ExtensionResult.decode(reader, reader.uint32());
                        break;
                    }
                case 9: {
                        message.calibPeakMask = $root.RefDesignProtocol.CalibPeakMaskResult.decode(reader, reader.uint32());
                        break;
                    }
                case 10: {
                        message.calibExpo = $root.RefDesignProtocol.CalibExposureResult.decode(reader, reader.uint32());
                        break;
                    }
                case 11: {
                        message.minMaxPeaks = $root.RefDesignProtocol.MinMaxPeaksResult.decode(reader, reader.uint32());
                        break;
                    }
                case 12: {
                        message.mziStaticContrast = $root.RefDesignProtocol.MziStaticContrastResult.decode(reader, reader.uint32());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a ResultChannel message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof RefDesignProtocol.ResultChannel
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {RefDesignProtocol.ResultChannel} ResultChannel
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ResultChannel.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a ResultChannel message.
         * @function verify
         * @memberof RefDesignProtocol.ResultChannel
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ResultChannel.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            let properties = {};
            if (message.header != null && message.hasOwnProperty("header")) {
                let error = $root.RefDesignProtocol.ResultHeader.verify(message.header);
                if (error)
                    return "header." + error;
            }
            if (message.version != null && message.hasOwnProperty("version")) {
                properties.payload = 1;
                {
                    let error = $root.RefDesignProtocol.VersionResult.verify(message.version);
                    if (error)
                        return "version." + error;
                }
            }
            if (message.config != null && message.hasOwnProperty("config")) {
                if (properties.payload === 1)
                    return "payload: multiple values";
                properties.payload = 1;
                {
                    let error = $root.RefDesignProtocol.ConfigResult.verify(message.config);
                    if (error)
                        return "config." + error;
                }
            }
            if (message.capabilities != null && message.hasOwnProperty("capabilities")) {
                if (properties.payload === 1)
                    return "payload: multiple values";
                properties.payload = 1;
                {
                    let error = $root.RefDesignProtocol.CapabilitiesResult.verify(message.capabilities);
                    if (error)
                        return "capabilities." + error;
                }
            }
            if (message.singleShot != null && message.hasOwnProperty("singleShot")) {
                if (properties.payload === 1)
                    return "payload: multiple values";
                properties.payload = 1;
                {
                    let error = $root.RefDesignProtocol.SingleShotResult.verify(message.singleShot);
                    if (error)
                        return "singleShot." + error;
                }
            }
            if (message.memoryInfo != null && message.hasOwnProperty("memoryInfo")) {
                if (properties.payload === 1)
                    return "payload: multiple values";
                properties.payload = 1;
                {
                    let error = $root.RefDesignProtocol.MemoryInfoResult.verify(message.memoryInfo);
                    if (error)
                        return "memoryInfo." + error;
                }
            }
            if (message.memoryData != null && message.hasOwnProperty("memoryData")) {
                if (properties.payload === 1)
                    return "payload: multiple values";
                properties.payload = 1;
                {
                    let error = $root.RefDesignProtocol.ReadMemoryResult.verify(message.memoryData);
                    if (error)
                        return "memoryData." + error;
                }
            }
            if (message.extension != null && message.hasOwnProperty("extension")) {
                if (properties.payload === 1)
                    return "payload: multiple values";
                properties.payload = 1;
                {
                    let error = $root.RefDesignProtocol.ExtensionResult.verify(message.extension);
                    if (error)
                        return "extension." + error;
                }
            }
            if (message.calibPeakMask != null && message.hasOwnProperty("calibPeakMask")) {
                if (properties.payload === 1)
                    return "payload: multiple values";
                properties.payload = 1;
                {
                    let error = $root.RefDesignProtocol.CalibPeakMaskResult.verify(message.calibPeakMask);
                    if (error)
                        return "calibPeakMask." + error;
                }
            }
            if (message.calibExpo != null && message.hasOwnProperty("calibExpo")) {
                if (properties.payload === 1)
                    return "payload: multiple values";
                properties.payload = 1;
                {
                    let error = $root.RefDesignProtocol.CalibExposureResult.verify(message.calibExpo);
                    if (error)
                        return "calibExpo." + error;
                }
            }
            if (message.minMaxPeaks != null && message.hasOwnProperty("minMaxPeaks")) {
                if (properties.payload === 1)
                    return "payload: multiple values";
                properties.payload = 1;
                {
                    let error = $root.RefDesignProtocol.MinMaxPeaksResult.verify(message.minMaxPeaks);
                    if (error)
                        return "minMaxPeaks." + error;
                }
            }
            if (message.mziStaticContrast != null && message.hasOwnProperty("mziStaticContrast")) {
                if (properties.payload === 1)
                    return "payload: multiple values";
                properties.payload = 1;
                {
                    let error = $root.RefDesignProtocol.MziStaticContrastResult.verify(message.mziStaticContrast);
                    if (error)
                        return "mziStaticContrast." + error;
                }
            }
            return null;
        };

        /**
         * Creates a ResultChannel message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof RefDesignProtocol.ResultChannel
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {RefDesignProtocol.ResultChannel} ResultChannel
         */
        ResultChannel.fromObject = function fromObject(object) {
            if (object instanceof $root.RefDesignProtocol.ResultChannel)
                return object;
            let message = new $root.RefDesignProtocol.ResultChannel();
            if (object.header != null) {
                if (typeof object.header !== "object")
                    throw TypeError(".RefDesignProtocol.ResultChannel.header: object expected");
                message.header = $root.RefDesignProtocol.ResultHeader.fromObject(object.header);
            }
            if (object.version != null) {
                if (typeof object.version !== "object")
                    throw TypeError(".RefDesignProtocol.ResultChannel.version: object expected");
                message.version = $root.RefDesignProtocol.VersionResult.fromObject(object.version);
            }
            if (object.config != null) {
                if (typeof object.config !== "object")
                    throw TypeError(".RefDesignProtocol.ResultChannel.config: object expected");
                message.config = $root.RefDesignProtocol.ConfigResult.fromObject(object.config);
            }
            if (object.capabilities != null) {
                if (typeof object.capabilities !== "object")
                    throw TypeError(".RefDesignProtocol.ResultChannel.capabilities: object expected");
                message.capabilities = $root.RefDesignProtocol.CapabilitiesResult.fromObject(object.capabilities);
            }
            if (object.singleShot != null) {
                if (typeof object.singleShot !== "object")
                    throw TypeError(".RefDesignProtocol.ResultChannel.singleShot: object expected");
                message.singleShot = $root.RefDesignProtocol.SingleShotResult.fromObject(object.singleShot);
            }
            if (object.memoryInfo != null) {
                if (typeof object.memoryInfo !== "object")
                    throw TypeError(".RefDesignProtocol.ResultChannel.memoryInfo: object expected");
                message.memoryInfo = $root.RefDesignProtocol.MemoryInfoResult.fromObject(object.memoryInfo);
            }
            if (object.memoryData != null) {
                if (typeof object.memoryData !== "object")
                    throw TypeError(".RefDesignProtocol.ResultChannel.memoryData: object expected");
                message.memoryData = $root.RefDesignProtocol.ReadMemoryResult.fromObject(object.memoryData);
            }
            if (object.extension != null) {
                if (typeof object.extension !== "object")
                    throw TypeError(".RefDesignProtocol.ResultChannel.extension: object expected");
                message.extension = $root.RefDesignProtocol.ExtensionResult.fromObject(object.extension);
            }
            if (object.calibPeakMask != null) {
                if (typeof object.calibPeakMask !== "object")
                    throw TypeError(".RefDesignProtocol.ResultChannel.calibPeakMask: object expected");
                message.calibPeakMask = $root.RefDesignProtocol.CalibPeakMaskResult.fromObject(object.calibPeakMask);
            }
            if (object.calibExpo != null) {
                if (typeof object.calibExpo !== "object")
                    throw TypeError(".RefDesignProtocol.ResultChannel.calibExpo: object expected");
                message.calibExpo = $root.RefDesignProtocol.CalibExposureResult.fromObject(object.calibExpo);
            }
            if (object.minMaxPeaks != null) {
                if (typeof object.minMaxPeaks !== "object")
                    throw TypeError(".RefDesignProtocol.ResultChannel.minMaxPeaks: object expected");
                message.minMaxPeaks = $root.RefDesignProtocol.MinMaxPeaksResult.fromObject(object.minMaxPeaks);
            }
            if (object.mziStaticContrast != null) {
                if (typeof object.mziStaticContrast !== "object")
                    throw TypeError(".RefDesignProtocol.ResultChannel.mziStaticContrast: object expected");
                message.mziStaticContrast = $root.RefDesignProtocol.MziStaticContrastResult.fromObject(object.mziStaticContrast);
            }
            return message;
        };

        /**
         * Creates a plain object from a ResultChannel message. Also converts values to other types if specified.
         * @function toObject
         * @memberof RefDesignProtocol.ResultChannel
         * @static
         * @param {RefDesignProtocol.ResultChannel} message ResultChannel
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ResultChannel.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                object.header = null;
            if (message.header != null && message.hasOwnProperty("header"))
                object.header = $root.RefDesignProtocol.ResultHeader.toObject(message.header, options);
            if (message.version != null && message.hasOwnProperty("version")) {
                object.version = $root.RefDesignProtocol.VersionResult.toObject(message.version, options);
                if (options.oneofs)
                    object.payload = "version";
            }
            if (message.config != null && message.hasOwnProperty("config")) {
                object.config = $root.RefDesignProtocol.ConfigResult.toObject(message.config, options);
                if (options.oneofs)
                    object.payload = "config";
            }
            if (message.capabilities != null && message.hasOwnProperty("capabilities")) {
                object.capabilities = $root.RefDesignProtocol.CapabilitiesResult.toObject(message.capabilities, options);
                if (options.oneofs)
                    object.payload = "capabilities";
            }
            if (message.singleShot != null && message.hasOwnProperty("singleShot")) {
                object.singleShot = $root.RefDesignProtocol.SingleShotResult.toObject(message.singleShot, options);
                if (options.oneofs)
                    object.payload = "singleShot";
            }
            if (message.memoryInfo != null && message.hasOwnProperty("memoryInfo")) {
                object.memoryInfo = $root.RefDesignProtocol.MemoryInfoResult.toObject(message.memoryInfo, options);
                if (options.oneofs)
                    object.payload = "memoryInfo";
            }
            if (message.memoryData != null && message.hasOwnProperty("memoryData")) {
                object.memoryData = $root.RefDesignProtocol.ReadMemoryResult.toObject(message.memoryData, options);
                if (options.oneofs)
                    object.payload = "memoryData";
            }
            if (message.extension != null && message.hasOwnProperty("extension")) {
                object.extension = $root.RefDesignProtocol.ExtensionResult.toObject(message.extension, options);
                if (options.oneofs)
                    object.payload = "extension";
            }
            if (message.calibPeakMask != null && message.hasOwnProperty("calibPeakMask")) {
                object.calibPeakMask = $root.RefDesignProtocol.CalibPeakMaskResult.toObject(message.calibPeakMask, options);
                if (options.oneofs)
                    object.payload = "calibPeakMask";
            }
            if (message.calibExpo != null && message.hasOwnProperty("calibExpo")) {
                object.calibExpo = $root.RefDesignProtocol.CalibExposureResult.toObject(message.calibExpo, options);
                if (options.oneofs)
                    object.payload = "calibExpo";
            }
            if (message.minMaxPeaks != null && message.hasOwnProperty("minMaxPeaks")) {
                object.minMaxPeaks = $root.RefDesignProtocol.MinMaxPeaksResult.toObject(message.minMaxPeaks, options);
                if (options.oneofs)
                    object.payload = "minMaxPeaks";
            }
            if (message.mziStaticContrast != null && message.hasOwnProperty("mziStaticContrast")) {
                object.mziStaticContrast = $root.RefDesignProtocol.MziStaticContrastResult.toObject(message.mziStaticContrast, options);
                if (options.oneofs)
                    object.payload = "mziStaticContrast";
            }
            return object;
        };

        /**
         * Converts this ResultChannel to JSON.
         * @function toJSON
         * @memberof RefDesignProtocol.ResultChannel
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ResultChannel.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for ResultChannel
         * @function getTypeUrl
         * @memberof RefDesignProtocol.ResultChannel
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        ResultChannel.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/RefDesignProtocol.ResultChannel";
        };

        return ResultChannel;
    })();

    return RefDesignProtocol;
})();

/**
 * FieldType enum.
 * @exports FieldType
 * @enum {number}
 * @property {number} FT_DEFAULT=0 FT_DEFAULT value
 * @property {number} FT_CALLBACK=1 FT_CALLBACK value
 * @property {number} FT_POINTER=4 FT_POINTER value
 * @property {number} FT_STATIC=2 FT_STATIC value
 * @property {number} FT_IGNORE=3 FT_IGNORE value
 * @property {number} FT_INLINE=5 FT_INLINE value
 */
export const FieldType = $root.FieldType = (() => {
    const valuesById = {}, values = Object.create(valuesById);
    values[valuesById[0] = "FT_DEFAULT"] = 0;
    values[valuesById[1] = "FT_CALLBACK"] = 1;
    values[valuesById[4] = "FT_POINTER"] = 4;
    values[valuesById[2] = "FT_STATIC"] = 2;
    values[valuesById[3] = "FT_IGNORE"] = 3;
    values[valuesById[5] = "FT_INLINE"] = 5;
    return values;
})();

/**
 * IntSize enum.
 * @exports IntSize
 * @enum {number}
 * @property {number} IS_DEFAULT=0 IS_DEFAULT value
 * @property {number} IS_8=8 IS_8 value
 * @property {number} IS_16=16 IS_16 value
 * @property {number} IS_32=32 IS_32 value
 * @property {number} IS_64=64 IS_64 value
 */
export const IntSize = $root.IntSize = (() => {
    const valuesById = {}, values = Object.create(valuesById);
    values[valuesById[0] = "IS_DEFAULT"] = 0;
    values[valuesById[8] = "IS_8"] = 8;
    values[valuesById[16] = "IS_16"] = 16;
    values[valuesById[32] = "IS_32"] = 32;
    values[valuesById[64] = "IS_64"] = 64;
    return values;
})();

/**
 * TypenameMangling enum.
 * @exports TypenameMangling
 * @enum {number}
 * @property {number} M_NONE=0 M_NONE value
 * @property {number} M_STRIP_PACKAGE=1 M_STRIP_PACKAGE value
 * @property {number} M_FLATTEN=2 M_FLATTEN value
 */
export const TypenameMangling = $root.TypenameMangling = (() => {
    const valuesById = {}, values = Object.create(valuesById);
    values[valuesById[0] = "M_NONE"] = 0;
    values[valuesById[1] = "M_STRIP_PACKAGE"] = 1;
    values[valuesById[2] = "M_FLATTEN"] = 2;
    return values;
})();

export const NanoPBOptions = $root.NanoPBOptions = (() => {

    /**
     * Properties of a NanoPBOptions.
     * @exports INanoPBOptions
     * @interface INanoPBOptions
     * @property {number|null} [maxSize] NanoPBOptions maxSize
     * @property {number|null} [maxLength] NanoPBOptions maxLength
     * @property {number|null} [maxCount] NanoPBOptions maxCount
     * @property {IntSize|null} [intSize] NanoPBOptions intSize
     * @property {FieldType|null} [type] NanoPBOptions type
     * @property {boolean|null} [longNames] NanoPBOptions longNames
     * @property {boolean|null} [packedStruct] NanoPBOptions packedStruct
     * @property {boolean|null} [packedEnum] NanoPBOptions packedEnum
     * @property {boolean|null} [skipMessage] NanoPBOptions skipMessage
     * @property {boolean|null} [noUnions] NanoPBOptions noUnions
     * @property {number|null} [msgid] NanoPBOptions msgid
     * @property {boolean|null} [anonymousOneof] NanoPBOptions anonymousOneof
     * @property {boolean|null} [proto3] NanoPBOptions proto3
     * @property {boolean|null} [enumToString] NanoPBOptions enumToString
     * @property {boolean|null} [fixedLength] NanoPBOptions fixedLength
     * @property {boolean|null} [fixedCount] NanoPBOptions fixedCount
     * @property {TypenameMangling|null} [mangleNames] NanoPBOptions mangleNames
     */

    /**
     * Constructs a new NanoPBOptions.
     * @exports NanoPBOptions
     * @classdesc Represents a NanoPBOptions.
     * @implements INanoPBOptions
     * @constructor
     * @param {INanoPBOptions=} [properties] Properties to set
     */
    function NanoPBOptions(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * NanoPBOptions maxSize.
     * @member {number} maxSize
     * @memberof NanoPBOptions
     * @instance
     */
    NanoPBOptions.prototype.maxSize = 0;

    /**
     * NanoPBOptions maxLength.
     * @member {number} maxLength
     * @memberof NanoPBOptions
     * @instance
     */
    NanoPBOptions.prototype.maxLength = 0;

    /**
     * NanoPBOptions maxCount.
     * @member {number} maxCount
     * @memberof NanoPBOptions
     * @instance
     */
    NanoPBOptions.prototype.maxCount = 0;

    /**
     * NanoPBOptions intSize.
     * @member {IntSize} intSize
     * @memberof NanoPBOptions
     * @instance
     */
    NanoPBOptions.prototype.intSize = 0;

    /**
     * NanoPBOptions type.
     * @member {FieldType} type
     * @memberof NanoPBOptions
     * @instance
     */
    NanoPBOptions.prototype.type = 0;

    /**
     * NanoPBOptions longNames.
     * @member {boolean} longNames
     * @memberof NanoPBOptions
     * @instance
     */
    NanoPBOptions.prototype.longNames = true;

    /**
     * NanoPBOptions packedStruct.
     * @member {boolean} packedStruct
     * @memberof NanoPBOptions
     * @instance
     */
    NanoPBOptions.prototype.packedStruct = false;

    /**
     * NanoPBOptions packedEnum.
     * @member {boolean} packedEnum
     * @memberof NanoPBOptions
     * @instance
     */
    NanoPBOptions.prototype.packedEnum = false;

    /**
     * NanoPBOptions skipMessage.
     * @member {boolean} skipMessage
     * @memberof NanoPBOptions
     * @instance
     */
    NanoPBOptions.prototype.skipMessage = false;

    /**
     * NanoPBOptions noUnions.
     * @member {boolean} noUnions
     * @memberof NanoPBOptions
     * @instance
     */
    NanoPBOptions.prototype.noUnions = false;

    /**
     * NanoPBOptions msgid.
     * @member {number} msgid
     * @memberof NanoPBOptions
     * @instance
     */
    NanoPBOptions.prototype.msgid = 0;

    /**
     * NanoPBOptions anonymousOneof.
     * @member {boolean} anonymousOneof
     * @memberof NanoPBOptions
     * @instance
     */
    NanoPBOptions.prototype.anonymousOneof = false;

    /**
     * NanoPBOptions proto3.
     * @member {boolean} proto3
     * @memberof NanoPBOptions
     * @instance
     */
    NanoPBOptions.prototype.proto3 = false;

    /**
     * NanoPBOptions enumToString.
     * @member {boolean} enumToString
     * @memberof NanoPBOptions
     * @instance
     */
    NanoPBOptions.prototype.enumToString = false;

    /**
     * NanoPBOptions fixedLength.
     * @member {boolean} fixedLength
     * @memberof NanoPBOptions
     * @instance
     */
    NanoPBOptions.prototype.fixedLength = false;

    /**
     * NanoPBOptions fixedCount.
     * @member {boolean} fixedCount
     * @memberof NanoPBOptions
     * @instance
     */
    NanoPBOptions.prototype.fixedCount = false;

    /**
     * NanoPBOptions mangleNames.
     * @member {TypenameMangling} mangleNames
     * @memberof NanoPBOptions
     * @instance
     */
    NanoPBOptions.prototype.mangleNames = 0;

    /**
     * Creates a new NanoPBOptions instance using the specified properties.
     * @function create
     * @memberof NanoPBOptions
     * @static
     * @param {INanoPBOptions=} [properties] Properties to set
     * @returns {NanoPBOptions} NanoPBOptions instance
     */
    NanoPBOptions.create = function create(properties) {
        return new NanoPBOptions(properties);
    };

    /**
     * Encodes the specified NanoPBOptions message. Does not implicitly {@link NanoPBOptions.verify|verify} messages.
     * @function encode
     * @memberof NanoPBOptions
     * @static
     * @param {INanoPBOptions} message NanoPBOptions message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    NanoPBOptions.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.maxSize != null && Object.hasOwnProperty.call(message, "maxSize"))
            writer.uint32(/* id 1, wireType 0 =*/8).int32(message.maxSize);
        if (message.maxCount != null && Object.hasOwnProperty.call(message, "maxCount"))
            writer.uint32(/* id 2, wireType 0 =*/16).int32(message.maxCount);
        if (message.type != null && Object.hasOwnProperty.call(message, "type"))
            writer.uint32(/* id 3, wireType 0 =*/24).int32(message.type);
        if (message.longNames != null && Object.hasOwnProperty.call(message, "longNames"))
            writer.uint32(/* id 4, wireType 0 =*/32).bool(message.longNames);
        if (message.packedStruct != null && Object.hasOwnProperty.call(message, "packedStruct"))
            writer.uint32(/* id 5, wireType 0 =*/40).bool(message.packedStruct);
        if (message.skipMessage != null && Object.hasOwnProperty.call(message, "skipMessage"))
            writer.uint32(/* id 6, wireType 0 =*/48).bool(message.skipMessage);
        if (message.intSize != null && Object.hasOwnProperty.call(message, "intSize"))
            writer.uint32(/* id 7, wireType 0 =*/56).int32(message.intSize);
        if (message.noUnions != null && Object.hasOwnProperty.call(message, "noUnions"))
            writer.uint32(/* id 8, wireType 0 =*/64).bool(message.noUnions);
        if (message.msgid != null && Object.hasOwnProperty.call(message, "msgid"))
            writer.uint32(/* id 9, wireType 0 =*/72).uint32(message.msgid);
        if (message.packedEnum != null && Object.hasOwnProperty.call(message, "packedEnum"))
            writer.uint32(/* id 10, wireType 0 =*/80).bool(message.packedEnum);
        if (message.anonymousOneof != null && Object.hasOwnProperty.call(message, "anonymousOneof"))
            writer.uint32(/* id 11, wireType 0 =*/88).bool(message.anonymousOneof);
        if (message.proto3 != null && Object.hasOwnProperty.call(message, "proto3"))
            writer.uint32(/* id 12, wireType 0 =*/96).bool(message.proto3);
        if (message.enumToString != null && Object.hasOwnProperty.call(message, "enumToString"))
            writer.uint32(/* id 13, wireType 0 =*/104).bool(message.enumToString);
        if (message.maxLength != null && Object.hasOwnProperty.call(message, "maxLength"))
            writer.uint32(/* id 14, wireType 0 =*/112).int32(message.maxLength);
        if (message.fixedLength != null && Object.hasOwnProperty.call(message, "fixedLength"))
            writer.uint32(/* id 15, wireType 0 =*/120).bool(message.fixedLength);
        if (message.fixedCount != null && Object.hasOwnProperty.call(message, "fixedCount"))
            writer.uint32(/* id 16, wireType 0 =*/128).bool(message.fixedCount);
        if (message.mangleNames != null && Object.hasOwnProperty.call(message, "mangleNames"))
            writer.uint32(/* id 17, wireType 0 =*/136).int32(message.mangleNames);
        return writer;
    };

    /**
     * Encodes the specified NanoPBOptions message, length delimited. Does not implicitly {@link NanoPBOptions.verify|verify} messages.
     * @function encodeDelimited
     * @memberof NanoPBOptions
     * @static
     * @param {INanoPBOptions} message NanoPBOptions message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    NanoPBOptions.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a NanoPBOptions message from the specified reader or buffer.
     * @function decode
     * @memberof NanoPBOptions
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {NanoPBOptions} NanoPBOptions
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    NanoPBOptions.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.NanoPBOptions();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.maxSize = reader.int32();
                    break;
                }
            case 14: {
                    message.maxLength = reader.int32();
                    break;
                }
            case 2: {
                    message.maxCount = reader.int32();
                    break;
                }
            case 7: {
                    message.intSize = reader.int32();
                    break;
                }
            case 3: {
                    message.type = reader.int32();
                    break;
                }
            case 4: {
                    message.longNames = reader.bool();
                    break;
                }
            case 5: {
                    message.packedStruct = reader.bool();
                    break;
                }
            case 10: {
                    message.packedEnum = reader.bool();
                    break;
                }
            case 6: {
                    message.skipMessage = reader.bool();
                    break;
                }
            case 8: {
                    message.noUnions = reader.bool();
                    break;
                }
            case 9: {
                    message.msgid = reader.uint32();
                    break;
                }
            case 11: {
                    message.anonymousOneof = reader.bool();
                    break;
                }
            case 12: {
                    message.proto3 = reader.bool();
                    break;
                }
            case 13: {
                    message.enumToString = reader.bool();
                    break;
                }
            case 15: {
                    message.fixedLength = reader.bool();
                    break;
                }
            case 16: {
                    message.fixedCount = reader.bool();
                    break;
                }
            case 17: {
                    message.mangleNames = reader.int32();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a NanoPBOptions message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof NanoPBOptions
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {NanoPBOptions} NanoPBOptions
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    NanoPBOptions.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a NanoPBOptions message.
     * @function verify
     * @memberof NanoPBOptions
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    NanoPBOptions.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.maxSize != null && message.hasOwnProperty("maxSize"))
            if (!$util.isInteger(message.maxSize))
                return "maxSize: integer expected";
        if (message.maxLength != null && message.hasOwnProperty("maxLength"))
            if (!$util.isInteger(message.maxLength))
                return "maxLength: integer expected";
        if (message.maxCount != null && message.hasOwnProperty("maxCount"))
            if (!$util.isInteger(message.maxCount))
                return "maxCount: integer expected";
        if (message.intSize != null && message.hasOwnProperty("intSize"))
            switch (message.intSize) {
            default:
                return "intSize: enum value expected";
            case 0:
            case 8:
            case 16:
            case 32:
            case 64:
                break;
            }
        if (message.type != null && message.hasOwnProperty("type"))
            switch (message.type) {
            default:
                return "type: enum value expected";
            case 0:
            case 1:
            case 4:
            case 2:
            case 3:
            case 5:
                break;
            }
        if (message.longNames != null && message.hasOwnProperty("longNames"))
            if (typeof message.longNames !== "boolean")
                return "longNames: boolean expected";
        if (message.packedStruct != null && message.hasOwnProperty("packedStruct"))
            if (typeof message.packedStruct !== "boolean")
                return "packedStruct: boolean expected";
        if (message.packedEnum != null && message.hasOwnProperty("packedEnum"))
            if (typeof message.packedEnum !== "boolean")
                return "packedEnum: boolean expected";
        if (message.skipMessage != null && message.hasOwnProperty("skipMessage"))
            if (typeof message.skipMessage !== "boolean")
                return "skipMessage: boolean expected";
        if (message.noUnions != null && message.hasOwnProperty("noUnions"))
            if (typeof message.noUnions !== "boolean")
                return "noUnions: boolean expected";
        if (message.msgid != null && message.hasOwnProperty("msgid"))
            if (!$util.isInteger(message.msgid))
                return "msgid: integer expected";
        if (message.anonymousOneof != null && message.hasOwnProperty("anonymousOneof"))
            if (typeof message.anonymousOneof !== "boolean")
                return "anonymousOneof: boolean expected";
        if (message.proto3 != null && message.hasOwnProperty("proto3"))
            if (typeof message.proto3 !== "boolean")
                return "proto3: boolean expected";
        if (message.enumToString != null && message.hasOwnProperty("enumToString"))
            if (typeof message.enumToString !== "boolean")
                return "enumToString: boolean expected";
        if (message.fixedLength != null && message.hasOwnProperty("fixedLength"))
            if (typeof message.fixedLength !== "boolean")
                return "fixedLength: boolean expected";
        if (message.fixedCount != null && message.hasOwnProperty("fixedCount"))
            if (typeof message.fixedCount !== "boolean")
                return "fixedCount: boolean expected";
        if (message.mangleNames != null && message.hasOwnProperty("mangleNames"))
            switch (message.mangleNames) {
            default:
                return "mangleNames: enum value expected";
            case 0:
            case 1:
            case 2:
                break;
            }
        return null;
    };

    /**
     * Creates a NanoPBOptions message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof NanoPBOptions
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {NanoPBOptions} NanoPBOptions
     */
    NanoPBOptions.fromObject = function fromObject(object) {
        if (object instanceof $root.NanoPBOptions)
            return object;
        let message = new $root.NanoPBOptions();
        if (object.maxSize != null)
            message.maxSize = object.maxSize | 0;
        if (object.maxLength != null)
            message.maxLength = object.maxLength | 0;
        if (object.maxCount != null)
            message.maxCount = object.maxCount | 0;
        switch (object.intSize) {
        default:
            if (typeof object.intSize === "number") {
                message.intSize = object.intSize;
                break;
            }
            break;
        case "IS_DEFAULT":
        case 0:
            message.intSize = 0;
            break;
        case "IS_8":
        case 8:
            message.intSize = 8;
            break;
        case "IS_16":
        case 16:
            message.intSize = 16;
            break;
        case "IS_32":
        case 32:
            message.intSize = 32;
            break;
        case "IS_64":
        case 64:
            message.intSize = 64;
            break;
        }
        switch (object.type) {
        default:
            if (typeof object.type === "number") {
                message.type = object.type;
                break;
            }
            break;
        case "FT_DEFAULT":
        case 0:
            message.type = 0;
            break;
        case "FT_CALLBACK":
        case 1:
            message.type = 1;
            break;
        case "FT_POINTER":
        case 4:
            message.type = 4;
            break;
        case "FT_STATIC":
        case 2:
            message.type = 2;
            break;
        case "FT_IGNORE":
        case 3:
            message.type = 3;
            break;
        case "FT_INLINE":
        case 5:
            message.type = 5;
            break;
        }
        if (object.longNames != null)
            message.longNames = Boolean(object.longNames);
        if (object.packedStruct != null)
            message.packedStruct = Boolean(object.packedStruct);
        if (object.packedEnum != null)
            message.packedEnum = Boolean(object.packedEnum);
        if (object.skipMessage != null)
            message.skipMessage = Boolean(object.skipMessage);
        if (object.noUnions != null)
            message.noUnions = Boolean(object.noUnions);
        if (object.msgid != null)
            message.msgid = object.msgid >>> 0;
        if (object.anonymousOneof != null)
            message.anonymousOneof = Boolean(object.anonymousOneof);
        if (object.proto3 != null)
            message.proto3 = Boolean(object.proto3);
        if (object.enumToString != null)
            message.enumToString = Boolean(object.enumToString);
        if (object.fixedLength != null)
            message.fixedLength = Boolean(object.fixedLength);
        if (object.fixedCount != null)
            message.fixedCount = Boolean(object.fixedCount);
        switch (object.mangleNames) {
        default:
            if (typeof object.mangleNames === "number") {
                message.mangleNames = object.mangleNames;
                break;
            }
            break;
        case "M_NONE":
        case 0:
            message.mangleNames = 0;
            break;
        case "M_STRIP_PACKAGE":
        case 1:
            message.mangleNames = 1;
            break;
        case "M_FLATTEN":
        case 2:
            message.mangleNames = 2;
            break;
        }
        return message;
    };

    /**
     * Creates a plain object from a NanoPBOptions message. Also converts values to other types if specified.
     * @function toObject
     * @memberof NanoPBOptions
     * @static
     * @param {NanoPBOptions} message NanoPBOptions
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    NanoPBOptions.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.defaults) {
            object.maxSize = 0;
            object.maxCount = 0;
            object.type = options.enums === String ? "FT_DEFAULT" : 0;
            object.longNames = true;
            object.packedStruct = false;
            object.skipMessage = false;
            object.intSize = options.enums === String ? "IS_DEFAULT" : 0;
            object.noUnions = false;
            object.msgid = 0;
            object.packedEnum = false;
            object.anonymousOneof = false;
            object.proto3 = false;
            object.enumToString = false;
            object.maxLength = 0;
            object.fixedLength = false;
            object.fixedCount = false;
            object.mangleNames = options.enums === String ? "M_NONE" : 0;
        }
        if (message.maxSize != null && message.hasOwnProperty("maxSize"))
            object.maxSize = message.maxSize;
        if (message.maxCount != null && message.hasOwnProperty("maxCount"))
            object.maxCount = message.maxCount;
        if (message.type != null && message.hasOwnProperty("type"))
            object.type = options.enums === String ? $root.FieldType[message.type] === undefined ? message.type : $root.FieldType[message.type] : message.type;
        if (message.longNames != null && message.hasOwnProperty("longNames"))
            object.longNames = message.longNames;
        if (message.packedStruct != null && message.hasOwnProperty("packedStruct"))
            object.packedStruct = message.packedStruct;
        if (message.skipMessage != null && message.hasOwnProperty("skipMessage"))
            object.skipMessage = message.skipMessage;
        if (message.intSize != null && message.hasOwnProperty("intSize"))
            object.intSize = options.enums === String ? $root.IntSize[message.intSize] === undefined ? message.intSize : $root.IntSize[message.intSize] : message.intSize;
        if (message.noUnions != null && message.hasOwnProperty("noUnions"))
            object.noUnions = message.noUnions;
        if (message.msgid != null && message.hasOwnProperty("msgid"))
            object.msgid = message.msgid;
        if (message.packedEnum != null && message.hasOwnProperty("packedEnum"))
            object.packedEnum = message.packedEnum;
        if (message.anonymousOneof != null && message.hasOwnProperty("anonymousOneof"))
            object.anonymousOneof = message.anonymousOneof;
        if (message.proto3 != null && message.hasOwnProperty("proto3"))
            object.proto3 = message.proto3;
        if (message.enumToString != null && message.hasOwnProperty("enumToString"))
            object.enumToString = message.enumToString;
        if (message.maxLength != null && message.hasOwnProperty("maxLength"))
            object.maxLength = message.maxLength;
        if (message.fixedLength != null && message.hasOwnProperty("fixedLength"))
            object.fixedLength = message.fixedLength;
        if (message.fixedCount != null && message.hasOwnProperty("fixedCount"))
            object.fixedCount = message.fixedCount;
        if (message.mangleNames != null && message.hasOwnProperty("mangleNames"))
            object.mangleNames = options.enums === String ? $root.TypenameMangling[message.mangleNames] === undefined ? message.mangleNames : $root.TypenameMangling[message.mangleNames] : message.mangleNames;
        return object;
    };

    /**
     * Converts this NanoPBOptions to JSON.
     * @function toJSON
     * @memberof NanoPBOptions
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    NanoPBOptions.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for NanoPBOptions
     * @function getTypeUrl
     * @memberof NanoPBOptions
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    NanoPBOptions.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/NanoPBOptions";
    };

    return NanoPBOptions;
})();

export const google = $root.google = (() => {

    /**
     * Namespace google.
     * @exports google
     * @namespace
     */
    const google = {};

    google.protobuf = (function() {

        /**
         * Namespace protobuf.
         * @memberof google
         * @namespace
         */
        const protobuf = {};

        protobuf.FileDescriptorSet = (function() {

            /**
             * Properties of a FileDescriptorSet.
             * @memberof google.protobuf
             * @interface IFileDescriptorSet
             * @property {Array.<google.protobuf.IFileDescriptorProto>|null} [file] FileDescriptorSet file
             */

            /**
             * Constructs a new FileDescriptorSet.
             * @memberof google.protobuf
             * @classdesc Represents a FileDescriptorSet.
             * @implements IFileDescriptorSet
             * @constructor
             * @param {google.protobuf.IFileDescriptorSet=} [properties] Properties to set
             */
            function FileDescriptorSet(properties) {
                this.file = [];
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * FileDescriptorSet file.
             * @member {Array.<google.protobuf.IFileDescriptorProto>} file
             * @memberof google.protobuf.FileDescriptorSet
             * @instance
             */
            FileDescriptorSet.prototype.file = $util.emptyArray;

            /**
             * Creates a new FileDescriptorSet instance using the specified properties.
             * @function create
             * @memberof google.protobuf.FileDescriptorSet
             * @static
             * @param {google.protobuf.IFileDescriptorSet=} [properties] Properties to set
             * @returns {google.protobuf.FileDescriptorSet} FileDescriptorSet instance
             */
            FileDescriptorSet.create = function create(properties) {
                return new FileDescriptorSet(properties);
            };

            /**
             * Encodes the specified FileDescriptorSet message. Does not implicitly {@link google.protobuf.FileDescriptorSet.verify|verify} messages.
             * @function encode
             * @memberof google.protobuf.FileDescriptorSet
             * @static
             * @param {google.protobuf.IFileDescriptorSet} message FileDescriptorSet message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            FileDescriptorSet.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.file != null && message.file.length)
                    for (let i = 0; i < message.file.length; ++i)
                        $root.google.protobuf.FileDescriptorProto.encode(message.file[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                return writer;
            };

            /**
             * Encodes the specified FileDescriptorSet message, length delimited. Does not implicitly {@link google.protobuf.FileDescriptorSet.verify|verify} messages.
             * @function encodeDelimited
             * @memberof google.protobuf.FileDescriptorSet
             * @static
             * @param {google.protobuf.IFileDescriptorSet} message FileDescriptorSet message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            FileDescriptorSet.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a FileDescriptorSet message from the specified reader or buffer.
             * @function decode
             * @memberof google.protobuf.FileDescriptorSet
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {google.protobuf.FileDescriptorSet} FileDescriptorSet
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            FileDescriptorSet.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.google.protobuf.FileDescriptorSet();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            if (!(message.file && message.file.length))
                                message.file = [];
                            message.file.push($root.google.protobuf.FileDescriptorProto.decode(reader, reader.uint32()));
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a FileDescriptorSet message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof google.protobuf.FileDescriptorSet
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {google.protobuf.FileDescriptorSet} FileDescriptorSet
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            FileDescriptorSet.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a FileDescriptorSet message.
             * @function verify
             * @memberof google.protobuf.FileDescriptorSet
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            FileDescriptorSet.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.file != null && message.hasOwnProperty("file")) {
                    if (!Array.isArray(message.file))
                        return "file: array expected";
                    for (let i = 0; i < message.file.length; ++i) {
                        let error = $root.google.protobuf.FileDescriptorProto.verify(message.file[i]);
                        if (error)
                            return "file." + error;
                    }
                }
                return null;
            };

            /**
             * Creates a FileDescriptorSet message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof google.protobuf.FileDescriptorSet
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {google.protobuf.FileDescriptorSet} FileDescriptorSet
             */
            FileDescriptorSet.fromObject = function fromObject(object) {
                if (object instanceof $root.google.protobuf.FileDescriptorSet)
                    return object;
                let message = new $root.google.protobuf.FileDescriptorSet();
                if (object.file) {
                    if (!Array.isArray(object.file))
                        throw TypeError(".google.protobuf.FileDescriptorSet.file: array expected");
                    message.file = [];
                    for (let i = 0; i < object.file.length; ++i) {
                        if (typeof object.file[i] !== "object")
                            throw TypeError(".google.protobuf.FileDescriptorSet.file: object expected");
                        message.file[i] = $root.google.protobuf.FileDescriptorProto.fromObject(object.file[i]);
                    }
                }
                return message;
            };

            /**
             * Creates a plain object from a FileDescriptorSet message. Also converts values to other types if specified.
             * @function toObject
             * @memberof google.protobuf.FileDescriptorSet
             * @static
             * @param {google.protobuf.FileDescriptorSet} message FileDescriptorSet
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            FileDescriptorSet.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.arrays || options.defaults)
                    object.file = [];
                if (message.file && message.file.length) {
                    object.file = [];
                    for (let j = 0; j < message.file.length; ++j)
                        object.file[j] = $root.google.protobuf.FileDescriptorProto.toObject(message.file[j], options);
                }
                return object;
            };

            /**
             * Converts this FileDescriptorSet to JSON.
             * @function toJSON
             * @memberof google.protobuf.FileDescriptorSet
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            FileDescriptorSet.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for FileDescriptorSet
             * @function getTypeUrl
             * @memberof google.protobuf.FileDescriptorSet
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            FileDescriptorSet.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/google.protobuf.FileDescriptorSet";
            };

            return FileDescriptorSet;
        })();

        protobuf.FileDescriptorProto = (function() {

            /**
             * Properties of a FileDescriptorProto.
             * @memberof google.protobuf
             * @interface IFileDescriptorProto
             * @property {string|null} [name] FileDescriptorProto name
             * @property {string|null} ["package"] FileDescriptorProto package
             * @property {Array.<string>|null} [dependency] FileDescriptorProto dependency
             * @property {Array.<number>|null} [publicDependency] FileDescriptorProto publicDependency
             * @property {Array.<number>|null} [weakDependency] FileDescriptorProto weakDependency
             * @property {Array.<google.protobuf.IDescriptorProto>|null} [messageType] FileDescriptorProto messageType
             * @property {Array.<google.protobuf.IEnumDescriptorProto>|null} [enumType] FileDescriptorProto enumType
             * @property {Array.<google.protobuf.IServiceDescriptorProto>|null} [service] FileDescriptorProto service
             * @property {Array.<google.protobuf.IFieldDescriptorProto>|null} [extension] FileDescriptorProto extension
             * @property {google.protobuf.IFileOptions|null} [options] FileDescriptorProto options
             * @property {google.protobuf.ISourceCodeInfo|null} [sourceCodeInfo] FileDescriptorProto sourceCodeInfo
             * @property {string|null} [syntax] FileDescriptorProto syntax
             */

            /**
             * Constructs a new FileDescriptorProto.
             * @memberof google.protobuf
             * @classdesc Represents a FileDescriptorProto.
             * @implements IFileDescriptorProto
             * @constructor
             * @param {google.protobuf.IFileDescriptorProto=} [properties] Properties to set
             */
            function FileDescriptorProto(properties) {
                this.dependency = [];
                this.publicDependency = [];
                this.weakDependency = [];
                this.messageType = [];
                this.enumType = [];
                this.service = [];
                this.extension = [];
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * FileDescriptorProto name.
             * @member {string} name
             * @memberof google.protobuf.FileDescriptorProto
             * @instance
             */
            FileDescriptorProto.prototype.name = "";

            /**
             * FileDescriptorProto package.
             * @member {string} package
             * @memberof google.protobuf.FileDescriptorProto
             * @instance
             */
            FileDescriptorProto.prototype["package"] = "";

            /**
             * FileDescriptorProto dependency.
             * @member {Array.<string>} dependency
             * @memberof google.protobuf.FileDescriptorProto
             * @instance
             */
            FileDescriptorProto.prototype.dependency = $util.emptyArray;

            /**
             * FileDescriptorProto publicDependency.
             * @member {Array.<number>} publicDependency
             * @memberof google.protobuf.FileDescriptorProto
             * @instance
             */
            FileDescriptorProto.prototype.publicDependency = $util.emptyArray;

            /**
             * FileDescriptorProto weakDependency.
             * @member {Array.<number>} weakDependency
             * @memberof google.protobuf.FileDescriptorProto
             * @instance
             */
            FileDescriptorProto.prototype.weakDependency = $util.emptyArray;

            /**
             * FileDescriptorProto messageType.
             * @member {Array.<google.protobuf.IDescriptorProto>} messageType
             * @memberof google.protobuf.FileDescriptorProto
             * @instance
             */
            FileDescriptorProto.prototype.messageType = $util.emptyArray;

            /**
             * FileDescriptorProto enumType.
             * @member {Array.<google.protobuf.IEnumDescriptorProto>} enumType
             * @memberof google.protobuf.FileDescriptorProto
             * @instance
             */
            FileDescriptorProto.prototype.enumType = $util.emptyArray;

            /**
             * FileDescriptorProto service.
             * @member {Array.<google.protobuf.IServiceDescriptorProto>} service
             * @memberof google.protobuf.FileDescriptorProto
             * @instance
             */
            FileDescriptorProto.prototype.service = $util.emptyArray;

            /**
             * FileDescriptorProto extension.
             * @member {Array.<google.protobuf.IFieldDescriptorProto>} extension
             * @memberof google.protobuf.FileDescriptorProto
             * @instance
             */
            FileDescriptorProto.prototype.extension = $util.emptyArray;

            /**
             * FileDescriptorProto options.
             * @member {google.protobuf.IFileOptions|null|undefined} options
             * @memberof google.protobuf.FileDescriptorProto
             * @instance
             */
            FileDescriptorProto.prototype.options = null;

            /**
             * FileDescriptorProto sourceCodeInfo.
             * @member {google.protobuf.ISourceCodeInfo|null|undefined} sourceCodeInfo
             * @memberof google.protobuf.FileDescriptorProto
             * @instance
             */
            FileDescriptorProto.prototype.sourceCodeInfo = null;

            /**
             * FileDescriptorProto syntax.
             * @member {string} syntax
             * @memberof google.protobuf.FileDescriptorProto
             * @instance
             */
            FileDescriptorProto.prototype.syntax = "";

            /**
             * Creates a new FileDescriptorProto instance using the specified properties.
             * @function create
             * @memberof google.protobuf.FileDescriptorProto
             * @static
             * @param {google.protobuf.IFileDescriptorProto=} [properties] Properties to set
             * @returns {google.protobuf.FileDescriptorProto} FileDescriptorProto instance
             */
            FileDescriptorProto.create = function create(properties) {
                return new FileDescriptorProto(properties);
            };

            /**
             * Encodes the specified FileDescriptorProto message. Does not implicitly {@link google.protobuf.FileDescriptorProto.verify|verify} messages.
             * @function encode
             * @memberof google.protobuf.FileDescriptorProto
             * @static
             * @param {google.protobuf.IFileDescriptorProto} message FileDescriptorProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            FileDescriptorProto.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.name);
                if (message["package"] != null && Object.hasOwnProperty.call(message, "package"))
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message["package"]);
                if (message.dependency != null && message.dependency.length)
                    for (let i = 0; i < message.dependency.length; ++i)
                        writer.uint32(/* id 3, wireType 2 =*/26).string(message.dependency[i]);
                if (message.messageType != null && message.messageType.length)
                    for (let i = 0; i < message.messageType.length; ++i)
                        $root.google.protobuf.DescriptorProto.encode(message.messageType[i], writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
                if (message.enumType != null && message.enumType.length)
                    for (let i = 0; i < message.enumType.length; ++i)
                        $root.google.protobuf.EnumDescriptorProto.encode(message.enumType[i], writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
                if (message.service != null && message.service.length)
                    for (let i = 0; i < message.service.length; ++i)
                        $root.google.protobuf.ServiceDescriptorProto.encode(message.service[i], writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
                if (message.extension != null && message.extension.length)
                    for (let i = 0; i < message.extension.length; ++i)
                        $root.google.protobuf.FieldDescriptorProto.encode(message.extension[i], writer.uint32(/* id 7, wireType 2 =*/58).fork()).ldelim();
                if (message.options != null && Object.hasOwnProperty.call(message, "options"))
                    $root.google.protobuf.FileOptions.encode(message.options, writer.uint32(/* id 8, wireType 2 =*/66).fork()).ldelim();
                if (message.sourceCodeInfo != null && Object.hasOwnProperty.call(message, "sourceCodeInfo"))
                    $root.google.protobuf.SourceCodeInfo.encode(message.sourceCodeInfo, writer.uint32(/* id 9, wireType 2 =*/74).fork()).ldelim();
                if (message.publicDependency != null && message.publicDependency.length)
                    for (let i = 0; i < message.publicDependency.length; ++i)
                        writer.uint32(/* id 10, wireType 0 =*/80).int32(message.publicDependency[i]);
                if (message.weakDependency != null && message.weakDependency.length)
                    for (let i = 0; i < message.weakDependency.length; ++i)
                        writer.uint32(/* id 11, wireType 0 =*/88).int32(message.weakDependency[i]);
                if (message.syntax != null && Object.hasOwnProperty.call(message, "syntax"))
                    writer.uint32(/* id 12, wireType 2 =*/98).string(message.syntax);
                return writer;
            };

            /**
             * Encodes the specified FileDescriptorProto message, length delimited. Does not implicitly {@link google.protobuf.FileDescriptorProto.verify|verify} messages.
             * @function encodeDelimited
             * @memberof google.protobuf.FileDescriptorProto
             * @static
             * @param {google.protobuf.IFileDescriptorProto} message FileDescriptorProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            FileDescriptorProto.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a FileDescriptorProto message from the specified reader or buffer.
             * @function decode
             * @memberof google.protobuf.FileDescriptorProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {google.protobuf.FileDescriptorProto} FileDescriptorProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            FileDescriptorProto.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.google.protobuf.FileDescriptorProto();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.name = reader.string();
                            break;
                        }
                    case 2: {
                            message["package"] = reader.string();
                            break;
                        }
                    case 3: {
                            if (!(message.dependency && message.dependency.length))
                                message.dependency = [];
                            message.dependency.push(reader.string());
                            break;
                        }
                    case 10: {
                            if (!(message.publicDependency && message.publicDependency.length))
                                message.publicDependency = [];
                            if ((tag & 7) === 2) {
                                let end2 = reader.uint32() + reader.pos;
                                while (reader.pos < end2)
                                    message.publicDependency.push(reader.int32());
                            } else
                                message.publicDependency.push(reader.int32());
                            break;
                        }
                    case 11: {
                            if (!(message.weakDependency && message.weakDependency.length))
                                message.weakDependency = [];
                            if ((tag & 7) === 2) {
                                let end2 = reader.uint32() + reader.pos;
                                while (reader.pos < end2)
                                    message.weakDependency.push(reader.int32());
                            } else
                                message.weakDependency.push(reader.int32());
                            break;
                        }
                    case 4: {
                            if (!(message.messageType && message.messageType.length))
                                message.messageType = [];
                            message.messageType.push($root.google.protobuf.DescriptorProto.decode(reader, reader.uint32()));
                            break;
                        }
                    case 5: {
                            if (!(message.enumType && message.enumType.length))
                                message.enumType = [];
                            message.enumType.push($root.google.protobuf.EnumDescriptorProto.decode(reader, reader.uint32()));
                            break;
                        }
                    case 6: {
                            if (!(message.service && message.service.length))
                                message.service = [];
                            message.service.push($root.google.protobuf.ServiceDescriptorProto.decode(reader, reader.uint32()));
                            break;
                        }
                    case 7: {
                            if (!(message.extension && message.extension.length))
                                message.extension = [];
                            message.extension.push($root.google.protobuf.FieldDescriptorProto.decode(reader, reader.uint32()));
                            break;
                        }
                    case 8: {
                            message.options = $root.google.protobuf.FileOptions.decode(reader, reader.uint32());
                            break;
                        }
                    case 9: {
                            message.sourceCodeInfo = $root.google.protobuf.SourceCodeInfo.decode(reader, reader.uint32());
                            break;
                        }
                    case 12: {
                            message.syntax = reader.string();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a FileDescriptorProto message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof google.protobuf.FileDescriptorProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {google.protobuf.FileDescriptorProto} FileDescriptorProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            FileDescriptorProto.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a FileDescriptorProto message.
             * @function verify
             * @memberof google.protobuf.FileDescriptorProto
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            FileDescriptorProto.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.name != null && message.hasOwnProperty("name"))
                    if (!$util.isString(message.name))
                        return "name: string expected";
                if (message["package"] != null && message.hasOwnProperty("package"))
                    if (!$util.isString(message["package"]))
                        return "package: string expected";
                if (message.dependency != null && message.hasOwnProperty("dependency")) {
                    if (!Array.isArray(message.dependency))
                        return "dependency: array expected";
                    for (let i = 0; i < message.dependency.length; ++i)
                        if (!$util.isString(message.dependency[i]))
                            return "dependency: string[] expected";
                }
                if (message.publicDependency != null && message.hasOwnProperty("publicDependency")) {
                    if (!Array.isArray(message.publicDependency))
                        return "publicDependency: array expected";
                    for (let i = 0; i < message.publicDependency.length; ++i)
                        if (!$util.isInteger(message.publicDependency[i]))
                            return "publicDependency: integer[] expected";
                }
                if (message.weakDependency != null && message.hasOwnProperty("weakDependency")) {
                    if (!Array.isArray(message.weakDependency))
                        return "weakDependency: array expected";
                    for (let i = 0; i < message.weakDependency.length; ++i)
                        if (!$util.isInteger(message.weakDependency[i]))
                            return "weakDependency: integer[] expected";
                }
                if (message.messageType != null && message.hasOwnProperty("messageType")) {
                    if (!Array.isArray(message.messageType))
                        return "messageType: array expected";
                    for (let i = 0; i < message.messageType.length; ++i) {
                        let error = $root.google.protobuf.DescriptorProto.verify(message.messageType[i]);
                        if (error)
                            return "messageType." + error;
                    }
                }
                if (message.enumType != null && message.hasOwnProperty("enumType")) {
                    if (!Array.isArray(message.enumType))
                        return "enumType: array expected";
                    for (let i = 0; i < message.enumType.length; ++i) {
                        let error = $root.google.protobuf.EnumDescriptorProto.verify(message.enumType[i]);
                        if (error)
                            return "enumType." + error;
                    }
                }
                if (message.service != null && message.hasOwnProperty("service")) {
                    if (!Array.isArray(message.service))
                        return "service: array expected";
                    for (let i = 0; i < message.service.length; ++i) {
                        let error = $root.google.protobuf.ServiceDescriptorProto.verify(message.service[i]);
                        if (error)
                            return "service." + error;
                    }
                }
                if (message.extension != null && message.hasOwnProperty("extension")) {
                    if (!Array.isArray(message.extension))
                        return "extension: array expected";
                    for (let i = 0; i < message.extension.length; ++i) {
                        let error = $root.google.protobuf.FieldDescriptorProto.verify(message.extension[i]);
                        if (error)
                            return "extension." + error;
                    }
                }
                if (message.options != null && message.hasOwnProperty("options")) {
                    let error = $root.google.protobuf.FileOptions.verify(message.options);
                    if (error)
                        return "options." + error;
                }
                if (message.sourceCodeInfo != null && message.hasOwnProperty("sourceCodeInfo")) {
                    let error = $root.google.protobuf.SourceCodeInfo.verify(message.sourceCodeInfo);
                    if (error)
                        return "sourceCodeInfo." + error;
                }
                if (message.syntax != null && message.hasOwnProperty("syntax"))
                    if (!$util.isString(message.syntax))
                        return "syntax: string expected";
                return null;
            };

            /**
             * Creates a FileDescriptorProto message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof google.protobuf.FileDescriptorProto
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {google.protobuf.FileDescriptorProto} FileDescriptorProto
             */
            FileDescriptorProto.fromObject = function fromObject(object) {
                if (object instanceof $root.google.protobuf.FileDescriptorProto)
                    return object;
                let message = new $root.google.protobuf.FileDescriptorProto();
                if (object.name != null)
                    message.name = String(object.name);
                if (object["package"] != null)
                    message["package"] = String(object["package"]);
                if (object.dependency) {
                    if (!Array.isArray(object.dependency))
                        throw TypeError(".google.protobuf.FileDescriptorProto.dependency: array expected");
                    message.dependency = [];
                    for (let i = 0; i < object.dependency.length; ++i)
                        message.dependency[i] = String(object.dependency[i]);
                }
                if (object.publicDependency) {
                    if (!Array.isArray(object.publicDependency))
                        throw TypeError(".google.protobuf.FileDescriptorProto.publicDependency: array expected");
                    message.publicDependency = [];
                    for (let i = 0; i < object.publicDependency.length; ++i)
                        message.publicDependency[i] = object.publicDependency[i] | 0;
                }
                if (object.weakDependency) {
                    if (!Array.isArray(object.weakDependency))
                        throw TypeError(".google.protobuf.FileDescriptorProto.weakDependency: array expected");
                    message.weakDependency = [];
                    for (let i = 0; i < object.weakDependency.length; ++i)
                        message.weakDependency[i] = object.weakDependency[i] | 0;
                }
                if (object.messageType) {
                    if (!Array.isArray(object.messageType))
                        throw TypeError(".google.protobuf.FileDescriptorProto.messageType: array expected");
                    message.messageType = [];
                    for (let i = 0; i < object.messageType.length; ++i) {
                        if (typeof object.messageType[i] !== "object")
                            throw TypeError(".google.protobuf.FileDescriptorProto.messageType: object expected");
                        message.messageType[i] = $root.google.protobuf.DescriptorProto.fromObject(object.messageType[i]);
                    }
                }
                if (object.enumType) {
                    if (!Array.isArray(object.enumType))
                        throw TypeError(".google.protobuf.FileDescriptorProto.enumType: array expected");
                    message.enumType = [];
                    for (let i = 0; i < object.enumType.length; ++i) {
                        if (typeof object.enumType[i] !== "object")
                            throw TypeError(".google.protobuf.FileDescriptorProto.enumType: object expected");
                        message.enumType[i] = $root.google.protobuf.EnumDescriptorProto.fromObject(object.enumType[i]);
                    }
                }
                if (object.service) {
                    if (!Array.isArray(object.service))
                        throw TypeError(".google.protobuf.FileDescriptorProto.service: array expected");
                    message.service = [];
                    for (let i = 0; i < object.service.length; ++i) {
                        if (typeof object.service[i] !== "object")
                            throw TypeError(".google.protobuf.FileDescriptorProto.service: object expected");
                        message.service[i] = $root.google.protobuf.ServiceDescriptorProto.fromObject(object.service[i]);
                    }
                }
                if (object.extension) {
                    if (!Array.isArray(object.extension))
                        throw TypeError(".google.protobuf.FileDescriptorProto.extension: array expected");
                    message.extension = [];
                    for (let i = 0; i < object.extension.length; ++i) {
                        if (typeof object.extension[i] !== "object")
                            throw TypeError(".google.protobuf.FileDescriptorProto.extension: object expected");
                        message.extension[i] = $root.google.protobuf.FieldDescriptorProto.fromObject(object.extension[i]);
                    }
                }
                if (object.options != null) {
                    if (typeof object.options !== "object")
                        throw TypeError(".google.protobuf.FileDescriptorProto.options: object expected");
                    message.options = $root.google.protobuf.FileOptions.fromObject(object.options);
                }
                if (object.sourceCodeInfo != null) {
                    if (typeof object.sourceCodeInfo !== "object")
                        throw TypeError(".google.protobuf.FileDescriptorProto.sourceCodeInfo: object expected");
                    message.sourceCodeInfo = $root.google.protobuf.SourceCodeInfo.fromObject(object.sourceCodeInfo);
                }
                if (object.syntax != null)
                    message.syntax = String(object.syntax);
                return message;
            };

            /**
             * Creates a plain object from a FileDescriptorProto message. Also converts values to other types if specified.
             * @function toObject
             * @memberof google.protobuf.FileDescriptorProto
             * @static
             * @param {google.protobuf.FileDescriptorProto} message FileDescriptorProto
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            FileDescriptorProto.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.arrays || options.defaults) {
                    object.dependency = [];
                    object.messageType = [];
                    object.enumType = [];
                    object.service = [];
                    object.extension = [];
                    object.publicDependency = [];
                    object.weakDependency = [];
                }
                if (options.defaults) {
                    object.name = "";
                    object["package"] = "";
                    object.options = null;
                    object.sourceCodeInfo = null;
                    object.syntax = "";
                }
                if (message.name != null && message.hasOwnProperty("name"))
                    object.name = message.name;
                if (message["package"] != null && message.hasOwnProperty("package"))
                    object["package"] = message["package"];
                if (message.dependency && message.dependency.length) {
                    object.dependency = [];
                    for (let j = 0; j < message.dependency.length; ++j)
                        object.dependency[j] = message.dependency[j];
                }
                if (message.messageType && message.messageType.length) {
                    object.messageType = [];
                    for (let j = 0; j < message.messageType.length; ++j)
                        object.messageType[j] = $root.google.protobuf.DescriptorProto.toObject(message.messageType[j], options);
                }
                if (message.enumType && message.enumType.length) {
                    object.enumType = [];
                    for (let j = 0; j < message.enumType.length; ++j)
                        object.enumType[j] = $root.google.protobuf.EnumDescriptorProto.toObject(message.enumType[j], options);
                }
                if (message.service && message.service.length) {
                    object.service = [];
                    for (let j = 0; j < message.service.length; ++j)
                        object.service[j] = $root.google.protobuf.ServiceDescriptorProto.toObject(message.service[j], options);
                }
                if (message.extension && message.extension.length) {
                    object.extension = [];
                    for (let j = 0; j < message.extension.length; ++j)
                        object.extension[j] = $root.google.protobuf.FieldDescriptorProto.toObject(message.extension[j], options);
                }
                if (message.options != null && message.hasOwnProperty("options"))
                    object.options = $root.google.protobuf.FileOptions.toObject(message.options, options);
                if (message.sourceCodeInfo != null && message.hasOwnProperty("sourceCodeInfo"))
                    object.sourceCodeInfo = $root.google.protobuf.SourceCodeInfo.toObject(message.sourceCodeInfo, options);
                if (message.publicDependency && message.publicDependency.length) {
                    object.publicDependency = [];
                    for (let j = 0; j < message.publicDependency.length; ++j)
                        object.publicDependency[j] = message.publicDependency[j];
                }
                if (message.weakDependency && message.weakDependency.length) {
                    object.weakDependency = [];
                    for (let j = 0; j < message.weakDependency.length; ++j)
                        object.weakDependency[j] = message.weakDependency[j];
                }
                if (message.syntax != null && message.hasOwnProperty("syntax"))
                    object.syntax = message.syntax;
                return object;
            };

            /**
             * Converts this FileDescriptorProto to JSON.
             * @function toJSON
             * @memberof google.protobuf.FileDescriptorProto
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            FileDescriptorProto.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for FileDescriptorProto
             * @function getTypeUrl
             * @memberof google.protobuf.FileDescriptorProto
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            FileDescriptorProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/google.protobuf.FileDescriptorProto";
            };

            return FileDescriptorProto;
        })();

        protobuf.DescriptorProto = (function() {

            /**
             * Properties of a DescriptorProto.
             * @memberof google.protobuf
             * @interface IDescriptorProto
             * @property {string|null} [name] DescriptorProto name
             * @property {Array.<google.protobuf.IFieldDescriptorProto>|null} [field] DescriptorProto field
             * @property {Array.<google.protobuf.IFieldDescriptorProto>|null} [extension] DescriptorProto extension
             * @property {Array.<google.protobuf.IDescriptorProto>|null} [nestedType] DescriptorProto nestedType
             * @property {Array.<google.protobuf.IEnumDescriptorProto>|null} [enumType] DescriptorProto enumType
             * @property {Array.<google.protobuf.DescriptorProto.IExtensionRange>|null} [extensionRange] DescriptorProto extensionRange
             * @property {Array.<google.protobuf.IOneofDescriptorProto>|null} [oneofDecl] DescriptorProto oneofDecl
             * @property {google.protobuf.IMessageOptions|null} [options] DescriptorProto options
             * @property {Array.<google.protobuf.DescriptorProto.IReservedRange>|null} [reservedRange] DescriptorProto reservedRange
             * @property {Array.<string>|null} [reservedName] DescriptorProto reservedName
             */

            /**
             * Constructs a new DescriptorProto.
             * @memberof google.protobuf
             * @classdesc Represents a DescriptorProto.
             * @implements IDescriptorProto
             * @constructor
             * @param {google.protobuf.IDescriptorProto=} [properties] Properties to set
             */
            function DescriptorProto(properties) {
                this.field = [];
                this.extension = [];
                this.nestedType = [];
                this.enumType = [];
                this.extensionRange = [];
                this.oneofDecl = [];
                this.reservedRange = [];
                this.reservedName = [];
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * DescriptorProto name.
             * @member {string} name
             * @memberof google.protobuf.DescriptorProto
             * @instance
             */
            DescriptorProto.prototype.name = "";

            /**
             * DescriptorProto field.
             * @member {Array.<google.protobuf.IFieldDescriptorProto>} field
             * @memberof google.protobuf.DescriptorProto
             * @instance
             */
            DescriptorProto.prototype.field = $util.emptyArray;

            /**
             * DescriptorProto extension.
             * @member {Array.<google.protobuf.IFieldDescriptorProto>} extension
             * @memberof google.protobuf.DescriptorProto
             * @instance
             */
            DescriptorProto.prototype.extension = $util.emptyArray;

            /**
             * DescriptorProto nestedType.
             * @member {Array.<google.protobuf.IDescriptorProto>} nestedType
             * @memberof google.protobuf.DescriptorProto
             * @instance
             */
            DescriptorProto.prototype.nestedType = $util.emptyArray;

            /**
             * DescriptorProto enumType.
             * @member {Array.<google.protobuf.IEnumDescriptorProto>} enumType
             * @memberof google.protobuf.DescriptorProto
             * @instance
             */
            DescriptorProto.prototype.enumType = $util.emptyArray;

            /**
             * DescriptorProto extensionRange.
             * @member {Array.<google.protobuf.DescriptorProto.IExtensionRange>} extensionRange
             * @memberof google.protobuf.DescriptorProto
             * @instance
             */
            DescriptorProto.prototype.extensionRange = $util.emptyArray;

            /**
             * DescriptorProto oneofDecl.
             * @member {Array.<google.protobuf.IOneofDescriptorProto>} oneofDecl
             * @memberof google.protobuf.DescriptorProto
             * @instance
             */
            DescriptorProto.prototype.oneofDecl = $util.emptyArray;

            /**
             * DescriptorProto options.
             * @member {google.protobuf.IMessageOptions|null|undefined} options
             * @memberof google.protobuf.DescriptorProto
             * @instance
             */
            DescriptorProto.prototype.options = null;

            /**
             * DescriptorProto reservedRange.
             * @member {Array.<google.protobuf.DescriptorProto.IReservedRange>} reservedRange
             * @memberof google.protobuf.DescriptorProto
             * @instance
             */
            DescriptorProto.prototype.reservedRange = $util.emptyArray;

            /**
             * DescriptorProto reservedName.
             * @member {Array.<string>} reservedName
             * @memberof google.protobuf.DescriptorProto
             * @instance
             */
            DescriptorProto.prototype.reservedName = $util.emptyArray;

            /**
             * Creates a new DescriptorProto instance using the specified properties.
             * @function create
             * @memberof google.protobuf.DescriptorProto
             * @static
             * @param {google.protobuf.IDescriptorProto=} [properties] Properties to set
             * @returns {google.protobuf.DescriptorProto} DescriptorProto instance
             */
            DescriptorProto.create = function create(properties) {
                return new DescriptorProto(properties);
            };

            /**
             * Encodes the specified DescriptorProto message. Does not implicitly {@link google.protobuf.DescriptorProto.verify|verify} messages.
             * @function encode
             * @memberof google.protobuf.DescriptorProto
             * @static
             * @param {google.protobuf.IDescriptorProto} message DescriptorProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            DescriptorProto.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.name);
                if (message.field != null && message.field.length)
                    for (let i = 0; i < message.field.length; ++i)
                        $root.google.protobuf.FieldDescriptorProto.encode(message.field[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                if (message.nestedType != null && message.nestedType.length)
                    for (let i = 0; i < message.nestedType.length; ++i)
                        $root.google.protobuf.DescriptorProto.encode(message.nestedType[i], writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                if (message.enumType != null && message.enumType.length)
                    for (let i = 0; i < message.enumType.length; ++i)
                        $root.google.protobuf.EnumDescriptorProto.encode(message.enumType[i], writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
                if (message.extensionRange != null && message.extensionRange.length)
                    for (let i = 0; i < message.extensionRange.length; ++i)
                        $root.google.protobuf.DescriptorProto.ExtensionRange.encode(message.extensionRange[i], writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
                if (message.extension != null && message.extension.length)
                    for (let i = 0; i < message.extension.length; ++i)
                        $root.google.protobuf.FieldDescriptorProto.encode(message.extension[i], writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
                if (message.options != null && Object.hasOwnProperty.call(message, "options"))
                    $root.google.protobuf.MessageOptions.encode(message.options, writer.uint32(/* id 7, wireType 2 =*/58).fork()).ldelim();
                if (message.oneofDecl != null && message.oneofDecl.length)
                    for (let i = 0; i < message.oneofDecl.length; ++i)
                        $root.google.protobuf.OneofDescriptorProto.encode(message.oneofDecl[i], writer.uint32(/* id 8, wireType 2 =*/66).fork()).ldelim();
                if (message.reservedRange != null && message.reservedRange.length)
                    for (let i = 0; i < message.reservedRange.length; ++i)
                        $root.google.protobuf.DescriptorProto.ReservedRange.encode(message.reservedRange[i], writer.uint32(/* id 9, wireType 2 =*/74).fork()).ldelim();
                if (message.reservedName != null && message.reservedName.length)
                    for (let i = 0; i < message.reservedName.length; ++i)
                        writer.uint32(/* id 10, wireType 2 =*/82).string(message.reservedName[i]);
                return writer;
            };

            /**
             * Encodes the specified DescriptorProto message, length delimited. Does not implicitly {@link google.protobuf.DescriptorProto.verify|verify} messages.
             * @function encodeDelimited
             * @memberof google.protobuf.DescriptorProto
             * @static
             * @param {google.protobuf.IDescriptorProto} message DescriptorProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            DescriptorProto.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a DescriptorProto message from the specified reader or buffer.
             * @function decode
             * @memberof google.protobuf.DescriptorProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {google.protobuf.DescriptorProto} DescriptorProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            DescriptorProto.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.google.protobuf.DescriptorProto();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.name = reader.string();
                            break;
                        }
                    case 2: {
                            if (!(message.field && message.field.length))
                                message.field = [];
                            message.field.push($root.google.protobuf.FieldDescriptorProto.decode(reader, reader.uint32()));
                            break;
                        }
                    case 6: {
                            if (!(message.extension && message.extension.length))
                                message.extension = [];
                            message.extension.push($root.google.protobuf.FieldDescriptorProto.decode(reader, reader.uint32()));
                            break;
                        }
                    case 3: {
                            if (!(message.nestedType && message.nestedType.length))
                                message.nestedType = [];
                            message.nestedType.push($root.google.protobuf.DescriptorProto.decode(reader, reader.uint32()));
                            break;
                        }
                    case 4: {
                            if (!(message.enumType && message.enumType.length))
                                message.enumType = [];
                            message.enumType.push($root.google.protobuf.EnumDescriptorProto.decode(reader, reader.uint32()));
                            break;
                        }
                    case 5: {
                            if (!(message.extensionRange && message.extensionRange.length))
                                message.extensionRange = [];
                            message.extensionRange.push($root.google.protobuf.DescriptorProto.ExtensionRange.decode(reader, reader.uint32()));
                            break;
                        }
                    case 8: {
                            if (!(message.oneofDecl && message.oneofDecl.length))
                                message.oneofDecl = [];
                            message.oneofDecl.push($root.google.protobuf.OneofDescriptorProto.decode(reader, reader.uint32()));
                            break;
                        }
                    case 7: {
                            message.options = $root.google.protobuf.MessageOptions.decode(reader, reader.uint32());
                            break;
                        }
                    case 9: {
                            if (!(message.reservedRange && message.reservedRange.length))
                                message.reservedRange = [];
                            message.reservedRange.push($root.google.protobuf.DescriptorProto.ReservedRange.decode(reader, reader.uint32()));
                            break;
                        }
                    case 10: {
                            if (!(message.reservedName && message.reservedName.length))
                                message.reservedName = [];
                            message.reservedName.push(reader.string());
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a DescriptorProto message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof google.protobuf.DescriptorProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {google.protobuf.DescriptorProto} DescriptorProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            DescriptorProto.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a DescriptorProto message.
             * @function verify
             * @memberof google.protobuf.DescriptorProto
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            DescriptorProto.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.name != null && message.hasOwnProperty("name"))
                    if (!$util.isString(message.name))
                        return "name: string expected";
                if (message.field != null && message.hasOwnProperty("field")) {
                    if (!Array.isArray(message.field))
                        return "field: array expected";
                    for (let i = 0; i < message.field.length; ++i) {
                        let error = $root.google.protobuf.FieldDescriptorProto.verify(message.field[i]);
                        if (error)
                            return "field." + error;
                    }
                }
                if (message.extension != null && message.hasOwnProperty("extension")) {
                    if (!Array.isArray(message.extension))
                        return "extension: array expected";
                    for (let i = 0; i < message.extension.length; ++i) {
                        let error = $root.google.protobuf.FieldDescriptorProto.verify(message.extension[i]);
                        if (error)
                            return "extension." + error;
                    }
                }
                if (message.nestedType != null && message.hasOwnProperty("nestedType")) {
                    if (!Array.isArray(message.nestedType))
                        return "nestedType: array expected";
                    for (let i = 0; i < message.nestedType.length; ++i) {
                        let error = $root.google.protobuf.DescriptorProto.verify(message.nestedType[i]);
                        if (error)
                            return "nestedType." + error;
                    }
                }
                if (message.enumType != null && message.hasOwnProperty("enumType")) {
                    if (!Array.isArray(message.enumType))
                        return "enumType: array expected";
                    for (let i = 0; i < message.enumType.length; ++i) {
                        let error = $root.google.protobuf.EnumDescriptorProto.verify(message.enumType[i]);
                        if (error)
                            return "enumType." + error;
                    }
                }
                if (message.extensionRange != null && message.hasOwnProperty("extensionRange")) {
                    if (!Array.isArray(message.extensionRange))
                        return "extensionRange: array expected";
                    for (let i = 0; i < message.extensionRange.length; ++i) {
                        let error = $root.google.protobuf.DescriptorProto.ExtensionRange.verify(message.extensionRange[i]);
                        if (error)
                            return "extensionRange." + error;
                    }
                }
                if (message.oneofDecl != null && message.hasOwnProperty("oneofDecl")) {
                    if (!Array.isArray(message.oneofDecl))
                        return "oneofDecl: array expected";
                    for (let i = 0; i < message.oneofDecl.length; ++i) {
                        let error = $root.google.protobuf.OneofDescriptorProto.verify(message.oneofDecl[i]);
                        if (error)
                            return "oneofDecl." + error;
                    }
                }
                if (message.options != null && message.hasOwnProperty("options")) {
                    let error = $root.google.protobuf.MessageOptions.verify(message.options);
                    if (error)
                        return "options." + error;
                }
                if (message.reservedRange != null && message.hasOwnProperty("reservedRange")) {
                    if (!Array.isArray(message.reservedRange))
                        return "reservedRange: array expected";
                    for (let i = 0; i < message.reservedRange.length; ++i) {
                        let error = $root.google.protobuf.DescriptorProto.ReservedRange.verify(message.reservedRange[i]);
                        if (error)
                            return "reservedRange." + error;
                    }
                }
                if (message.reservedName != null && message.hasOwnProperty("reservedName")) {
                    if (!Array.isArray(message.reservedName))
                        return "reservedName: array expected";
                    for (let i = 0; i < message.reservedName.length; ++i)
                        if (!$util.isString(message.reservedName[i]))
                            return "reservedName: string[] expected";
                }
                return null;
            };

            /**
             * Creates a DescriptorProto message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof google.protobuf.DescriptorProto
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {google.protobuf.DescriptorProto} DescriptorProto
             */
            DescriptorProto.fromObject = function fromObject(object) {
                if (object instanceof $root.google.protobuf.DescriptorProto)
                    return object;
                let message = new $root.google.protobuf.DescriptorProto();
                if (object.name != null)
                    message.name = String(object.name);
                if (object.field) {
                    if (!Array.isArray(object.field))
                        throw TypeError(".google.protobuf.DescriptorProto.field: array expected");
                    message.field = [];
                    for (let i = 0; i < object.field.length; ++i) {
                        if (typeof object.field[i] !== "object")
                            throw TypeError(".google.protobuf.DescriptorProto.field: object expected");
                        message.field[i] = $root.google.protobuf.FieldDescriptorProto.fromObject(object.field[i]);
                    }
                }
                if (object.extension) {
                    if (!Array.isArray(object.extension))
                        throw TypeError(".google.protobuf.DescriptorProto.extension: array expected");
                    message.extension = [];
                    for (let i = 0; i < object.extension.length; ++i) {
                        if (typeof object.extension[i] !== "object")
                            throw TypeError(".google.protobuf.DescriptorProto.extension: object expected");
                        message.extension[i] = $root.google.protobuf.FieldDescriptorProto.fromObject(object.extension[i]);
                    }
                }
                if (object.nestedType) {
                    if (!Array.isArray(object.nestedType))
                        throw TypeError(".google.protobuf.DescriptorProto.nestedType: array expected");
                    message.nestedType = [];
                    for (let i = 0; i < object.nestedType.length; ++i) {
                        if (typeof object.nestedType[i] !== "object")
                            throw TypeError(".google.protobuf.DescriptorProto.nestedType: object expected");
                        message.nestedType[i] = $root.google.protobuf.DescriptorProto.fromObject(object.nestedType[i]);
                    }
                }
                if (object.enumType) {
                    if (!Array.isArray(object.enumType))
                        throw TypeError(".google.protobuf.DescriptorProto.enumType: array expected");
                    message.enumType = [];
                    for (let i = 0; i < object.enumType.length; ++i) {
                        if (typeof object.enumType[i] !== "object")
                            throw TypeError(".google.protobuf.DescriptorProto.enumType: object expected");
                        message.enumType[i] = $root.google.protobuf.EnumDescriptorProto.fromObject(object.enumType[i]);
                    }
                }
                if (object.extensionRange) {
                    if (!Array.isArray(object.extensionRange))
                        throw TypeError(".google.protobuf.DescriptorProto.extensionRange: array expected");
                    message.extensionRange = [];
                    for (let i = 0; i < object.extensionRange.length; ++i) {
                        if (typeof object.extensionRange[i] !== "object")
                            throw TypeError(".google.protobuf.DescriptorProto.extensionRange: object expected");
                        message.extensionRange[i] = $root.google.protobuf.DescriptorProto.ExtensionRange.fromObject(object.extensionRange[i]);
                    }
                }
                if (object.oneofDecl) {
                    if (!Array.isArray(object.oneofDecl))
                        throw TypeError(".google.protobuf.DescriptorProto.oneofDecl: array expected");
                    message.oneofDecl = [];
                    for (let i = 0; i < object.oneofDecl.length; ++i) {
                        if (typeof object.oneofDecl[i] !== "object")
                            throw TypeError(".google.protobuf.DescriptorProto.oneofDecl: object expected");
                        message.oneofDecl[i] = $root.google.protobuf.OneofDescriptorProto.fromObject(object.oneofDecl[i]);
                    }
                }
                if (object.options != null) {
                    if (typeof object.options !== "object")
                        throw TypeError(".google.protobuf.DescriptorProto.options: object expected");
                    message.options = $root.google.protobuf.MessageOptions.fromObject(object.options);
                }
                if (object.reservedRange) {
                    if (!Array.isArray(object.reservedRange))
                        throw TypeError(".google.protobuf.DescriptorProto.reservedRange: array expected");
                    message.reservedRange = [];
                    for (let i = 0; i < object.reservedRange.length; ++i) {
                        if (typeof object.reservedRange[i] !== "object")
                            throw TypeError(".google.protobuf.DescriptorProto.reservedRange: object expected");
                        message.reservedRange[i] = $root.google.protobuf.DescriptorProto.ReservedRange.fromObject(object.reservedRange[i]);
                    }
                }
                if (object.reservedName) {
                    if (!Array.isArray(object.reservedName))
                        throw TypeError(".google.protobuf.DescriptorProto.reservedName: array expected");
                    message.reservedName = [];
                    for (let i = 0; i < object.reservedName.length; ++i)
                        message.reservedName[i] = String(object.reservedName[i]);
                }
                return message;
            };

            /**
             * Creates a plain object from a DescriptorProto message. Also converts values to other types if specified.
             * @function toObject
             * @memberof google.protobuf.DescriptorProto
             * @static
             * @param {google.protobuf.DescriptorProto} message DescriptorProto
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            DescriptorProto.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.arrays || options.defaults) {
                    object.field = [];
                    object.nestedType = [];
                    object.enumType = [];
                    object.extensionRange = [];
                    object.extension = [];
                    object.oneofDecl = [];
                    object.reservedRange = [];
                    object.reservedName = [];
                }
                if (options.defaults) {
                    object.name = "";
                    object.options = null;
                }
                if (message.name != null && message.hasOwnProperty("name"))
                    object.name = message.name;
                if (message.field && message.field.length) {
                    object.field = [];
                    for (let j = 0; j < message.field.length; ++j)
                        object.field[j] = $root.google.protobuf.FieldDescriptorProto.toObject(message.field[j], options);
                }
                if (message.nestedType && message.nestedType.length) {
                    object.nestedType = [];
                    for (let j = 0; j < message.nestedType.length; ++j)
                        object.nestedType[j] = $root.google.protobuf.DescriptorProto.toObject(message.nestedType[j], options);
                }
                if (message.enumType && message.enumType.length) {
                    object.enumType = [];
                    for (let j = 0; j < message.enumType.length; ++j)
                        object.enumType[j] = $root.google.protobuf.EnumDescriptorProto.toObject(message.enumType[j], options);
                }
                if (message.extensionRange && message.extensionRange.length) {
                    object.extensionRange = [];
                    for (let j = 0; j < message.extensionRange.length; ++j)
                        object.extensionRange[j] = $root.google.protobuf.DescriptorProto.ExtensionRange.toObject(message.extensionRange[j], options);
                }
                if (message.extension && message.extension.length) {
                    object.extension = [];
                    for (let j = 0; j < message.extension.length; ++j)
                        object.extension[j] = $root.google.protobuf.FieldDescriptorProto.toObject(message.extension[j], options);
                }
                if (message.options != null && message.hasOwnProperty("options"))
                    object.options = $root.google.protobuf.MessageOptions.toObject(message.options, options);
                if (message.oneofDecl && message.oneofDecl.length) {
                    object.oneofDecl = [];
                    for (let j = 0; j < message.oneofDecl.length; ++j)
                        object.oneofDecl[j] = $root.google.protobuf.OneofDescriptorProto.toObject(message.oneofDecl[j], options);
                }
                if (message.reservedRange && message.reservedRange.length) {
                    object.reservedRange = [];
                    for (let j = 0; j < message.reservedRange.length; ++j)
                        object.reservedRange[j] = $root.google.protobuf.DescriptorProto.ReservedRange.toObject(message.reservedRange[j], options);
                }
                if (message.reservedName && message.reservedName.length) {
                    object.reservedName = [];
                    for (let j = 0; j < message.reservedName.length; ++j)
                        object.reservedName[j] = message.reservedName[j];
                }
                return object;
            };

            /**
             * Converts this DescriptorProto to JSON.
             * @function toJSON
             * @memberof google.protobuf.DescriptorProto
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            DescriptorProto.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for DescriptorProto
             * @function getTypeUrl
             * @memberof google.protobuf.DescriptorProto
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            DescriptorProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/google.protobuf.DescriptorProto";
            };

            DescriptorProto.ExtensionRange = (function() {

                /**
                 * Properties of an ExtensionRange.
                 * @memberof google.protobuf.DescriptorProto
                 * @interface IExtensionRange
                 * @property {number|null} [start] ExtensionRange start
                 * @property {number|null} [end] ExtensionRange end
                 */

                /**
                 * Constructs a new ExtensionRange.
                 * @memberof google.protobuf.DescriptorProto
                 * @classdesc Represents an ExtensionRange.
                 * @implements IExtensionRange
                 * @constructor
                 * @param {google.protobuf.DescriptorProto.IExtensionRange=} [properties] Properties to set
                 */
                function ExtensionRange(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ExtensionRange start.
                 * @member {number} start
                 * @memberof google.protobuf.DescriptorProto.ExtensionRange
                 * @instance
                 */
                ExtensionRange.prototype.start = 0;

                /**
                 * ExtensionRange end.
                 * @member {number} end
                 * @memberof google.protobuf.DescriptorProto.ExtensionRange
                 * @instance
                 */
                ExtensionRange.prototype.end = 0;

                /**
                 * Creates a new ExtensionRange instance using the specified properties.
                 * @function create
                 * @memberof google.protobuf.DescriptorProto.ExtensionRange
                 * @static
                 * @param {google.protobuf.DescriptorProto.IExtensionRange=} [properties] Properties to set
                 * @returns {google.protobuf.DescriptorProto.ExtensionRange} ExtensionRange instance
                 */
                ExtensionRange.create = function create(properties) {
                    return new ExtensionRange(properties);
                };

                /**
                 * Encodes the specified ExtensionRange message. Does not implicitly {@link google.protobuf.DescriptorProto.ExtensionRange.verify|verify} messages.
                 * @function encode
                 * @memberof google.protobuf.DescriptorProto.ExtensionRange
                 * @static
                 * @param {google.protobuf.DescriptorProto.IExtensionRange} message ExtensionRange message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ExtensionRange.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.start != null && Object.hasOwnProperty.call(message, "start"))
                        writer.uint32(/* id 1, wireType 0 =*/8).int32(message.start);
                    if (message.end != null && Object.hasOwnProperty.call(message, "end"))
                        writer.uint32(/* id 2, wireType 0 =*/16).int32(message.end);
                    return writer;
                };

                /**
                 * Encodes the specified ExtensionRange message, length delimited. Does not implicitly {@link google.protobuf.DescriptorProto.ExtensionRange.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof google.protobuf.DescriptorProto.ExtensionRange
                 * @static
                 * @param {google.protobuf.DescriptorProto.IExtensionRange} message ExtensionRange message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ExtensionRange.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes an ExtensionRange message from the specified reader or buffer.
                 * @function decode
                 * @memberof google.protobuf.DescriptorProto.ExtensionRange
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {google.protobuf.DescriptorProto.ExtensionRange} ExtensionRange
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ExtensionRange.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    let end = length === undefined ? reader.len : reader.pos + length, message = new $root.google.protobuf.DescriptorProto.ExtensionRange();
                    while (reader.pos < end) {
                        let tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.start = reader.int32();
                                break;
                            }
                        case 2: {
                                message.end = reader.int32();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes an ExtensionRange message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof google.protobuf.DescriptorProto.ExtensionRange
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {google.protobuf.DescriptorProto.ExtensionRange} ExtensionRange
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ExtensionRange.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies an ExtensionRange message.
                 * @function verify
                 * @memberof google.protobuf.DescriptorProto.ExtensionRange
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ExtensionRange.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.start != null && message.hasOwnProperty("start"))
                        if (!$util.isInteger(message.start))
                            return "start: integer expected";
                    if (message.end != null && message.hasOwnProperty("end"))
                        if (!$util.isInteger(message.end))
                            return "end: integer expected";
                    return null;
                };

                /**
                 * Creates an ExtensionRange message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof google.protobuf.DescriptorProto.ExtensionRange
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {google.protobuf.DescriptorProto.ExtensionRange} ExtensionRange
                 */
                ExtensionRange.fromObject = function fromObject(object) {
                    if (object instanceof $root.google.protobuf.DescriptorProto.ExtensionRange)
                        return object;
                    let message = new $root.google.protobuf.DescriptorProto.ExtensionRange();
                    if (object.start != null)
                        message.start = object.start | 0;
                    if (object.end != null)
                        message.end = object.end | 0;
                    return message;
                };

                /**
                 * Creates a plain object from an ExtensionRange message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof google.protobuf.DescriptorProto.ExtensionRange
                 * @static
                 * @param {google.protobuf.DescriptorProto.ExtensionRange} message ExtensionRange
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ExtensionRange.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults) {
                        object.start = 0;
                        object.end = 0;
                    }
                    if (message.start != null && message.hasOwnProperty("start"))
                        object.start = message.start;
                    if (message.end != null && message.hasOwnProperty("end"))
                        object.end = message.end;
                    return object;
                };

                /**
                 * Converts this ExtensionRange to JSON.
                 * @function toJSON
                 * @memberof google.protobuf.DescriptorProto.ExtensionRange
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ExtensionRange.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for ExtensionRange
                 * @function getTypeUrl
                 * @memberof google.protobuf.DescriptorProto.ExtensionRange
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                ExtensionRange.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/google.protobuf.DescriptorProto.ExtensionRange";
                };

                return ExtensionRange;
            })();

            DescriptorProto.ReservedRange = (function() {

                /**
                 * Properties of a ReservedRange.
                 * @memberof google.protobuf.DescriptorProto
                 * @interface IReservedRange
                 * @property {number|null} [start] ReservedRange start
                 * @property {number|null} [end] ReservedRange end
                 */

                /**
                 * Constructs a new ReservedRange.
                 * @memberof google.protobuf.DescriptorProto
                 * @classdesc Represents a ReservedRange.
                 * @implements IReservedRange
                 * @constructor
                 * @param {google.protobuf.DescriptorProto.IReservedRange=} [properties] Properties to set
                 */
                function ReservedRange(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ReservedRange start.
                 * @member {number} start
                 * @memberof google.protobuf.DescriptorProto.ReservedRange
                 * @instance
                 */
                ReservedRange.prototype.start = 0;

                /**
                 * ReservedRange end.
                 * @member {number} end
                 * @memberof google.protobuf.DescriptorProto.ReservedRange
                 * @instance
                 */
                ReservedRange.prototype.end = 0;

                /**
                 * Creates a new ReservedRange instance using the specified properties.
                 * @function create
                 * @memberof google.protobuf.DescriptorProto.ReservedRange
                 * @static
                 * @param {google.protobuf.DescriptorProto.IReservedRange=} [properties] Properties to set
                 * @returns {google.protobuf.DescriptorProto.ReservedRange} ReservedRange instance
                 */
                ReservedRange.create = function create(properties) {
                    return new ReservedRange(properties);
                };

                /**
                 * Encodes the specified ReservedRange message. Does not implicitly {@link google.protobuf.DescriptorProto.ReservedRange.verify|verify} messages.
                 * @function encode
                 * @memberof google.protobuf.DescriptorProto.ReservedRange
                 * @static
                 * @param {google.protobuf.DescriptorProto.IReservedRange} message ReservedRange message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ReservedRange.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.start != null && Object.hasOwnProperty.call(message, "start"))
                        writer.uint32(/* id 1, wireType 0 =*/8).int32(message.start);
                    if (message.end != null && Object.hasOwnProperty.call(message, "end"))
                        writer.uint32(/* id 2, wireType 0 =*/16).int32(message.end);
                    return writer;
                };

                /**
                 * Encodes the specified ReservedRange message, length delimited. Does not implicitly {@link google.protobuf.DescriptorProto.ReservedRange.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof google.protobuf.DescriptorProto.ReservedRange
                 * @static
                 * @param {google.protobuf.DescriptorProto.IReservedRange} message ReservedRange message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ReservedRange.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a ReservedRange message from the specified reader or buffer.
                 * @function decode
                 * @memberof google.protobuf.DescriptorProto.ReservedRange
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {google.protobuf.DescriptorProto.ReservedRange} ReservedRange
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ReservedRange.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    let end = length === undefined ? reader.len : reader.pos + length, message = new $root.google.protobuf.DescriptorProto.ReservedRange();
                    while (reader.pos < end) {
                        let tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.start = reader.int32();
                                break;
                            }
                        case 2: {
                                message.end = reader.int32();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a ReservedRange message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof google.protobuf.DescriptorProto.ReservedRange
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {google.protobuf.DescriptorProto.ReservedRange} ReservedRange
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ReservedRange.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a ReservedRange message.
                 * @function verify
                 * @memberof google.protobuf.DescriptorProto.ReservedRange
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ReservedRange.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.start != null && message.hasOwnProperty("start"))
                        if (!$util.isInteger(message.start))
                            return "start: integer expected";
                    if (message.end != null && message.hasOwnProperty("end"))
                        if (!$util.isInteger(message.end))
                            return "end: integer expected";
                    return null;
                };

                /**
                 * Creates a ReservedRange message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof google.protobuf.DescriptorProto.ReservedRange
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {google.protobuf.DescriptorProto.ReservedRange} ReservedRange
                 */
                ReservedRange.fromObject = function fromObject(object) {
                    if (object instanceof $root.google.protobuf.DescriptorProto.ReservedRange)
                        return object;
                    let message = new $root.google.protobuf.DescriptorProto.ReservedRange();
                    if (object.start != null)
                        message.start = object.start | 0;
                    if (object.end != null)
                        message.end = object.end | 0;
                    return message;
                };

                /**
                 * Creates a plain object from a ReservedRange message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof google.protobuf.DescriptorProto.ReservedRange
                 * @static
                 * @param {google.protobuf.DescriptorProto.ReservedRange} message ReservedRange
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ReservedRange.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults) {
                        object.start = 0;
                        object.end = 0;
                    }
                    if (message.start != null && message.hasOwnProperty("start"))
                        object.start = message.start;
                    if (message.end != null && message.hasOwnProperty("end"))
                        object.end = message.end;
                    return object;
                };

                /**
                 * Converts this ReservedRange to JSON.
                 * @function toJSON
                 * @memberof google.protobuf.DescriptorProto.ReservedRange
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ReservedRange.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for ReservedRange
                 * @function getTypeUrl
                 * @memberof google.protobuf.DescriptorProto.ReservedRange
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                ReservedRange.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/google.protobuf.DescriptorProto.ReservedRange";
                };

                return ReservedRange;
            })();

            return DescriptorProto;
        })();

        protobuf.FieldDescriptorProto = (function() {

            /**
             * Properties of a FieldDescriptorProto.
             * @memberof google.protobuf
             * @interface IFieldDescriptorProto
             * @property {string|null} [name] FieldDescriptorProto name
             * @property {number|null} [number] FieldDescriptorProto number
             * @property {google.protobuf.FieldDescriptorProto.Label|null} [label] FieldDescriptorProto label
             * @property {google.protobuf.FieldDescriptorProto.Type|null} [type] FieldDescriptorProto type
             * @property {string|null} [typeName] FieldDescriptorProto typeName
             * @property {string|null} [extendee] FieldDescriptorProto extendee
             * @property {string|null} [defaultValue] FieldDescriptorProto defaultValue
             * @property {number|null} [oneofIndex] FieldDescriptorProto oneofIndex
             * @property {string|null} [jsonName] FieldDescriptorProto jsonName
             * @property {google.protobuf.IFieldOptions|null} [options] FieldDescriptorProto options
             */

            /**
             * Constructs a new FieldDescriptorProto.
             * @memberof google.protobuf
             * @classdesc Represents a FieldDescriptorProto.
             * @implements IFieldDescriptorProto
             * @constructor
             * @param {google.protobuf.IFieldDescriptorProto=} [properties] Properties to set
             */
            function FieldDescriptorProto(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * FieldDescriptorProto name.
             * @member {string} name
             * @memberof google.protobuf.FieldDescriptorProto
             * @instance
             */
            FieldDescriptorProto.prototype.name = "";

            /**
             * FieldDescriptorProto number.
             * @member {number} number
             * @memberof google.protobuf.FieldDescriptorProto
             * @instance
             */
            FieldDescriptorProto.prototype.number = 0;

            /**
             * FieldDescriptorProto label.
             * @member {google.protobuf.FieldDescriptorProto.Label} label
             * @memberof google.protobuf.FieldDescriptorProto
             * @instance
             */
            FieldDescriptorProto.prototype.label = 1;

            /**
             * FieldDescriptorProto type.
             * @member {google.protobuf.FieldDescriptorProto.Type} type
             * @memberof google.protobuf.FieldDescriptorProto
             * @instance
             */
            FieldDescriptorProto.prototype.type = 1;

            /**
             * FieldDescriptorProto typeName.
             * @member {string} typeName
             * @memberof google.protobuf.FieldDescriptorProto
             * @instance
             */
            FieldDescriptorProto.prototype.typeName = "";

            /**
             * FieldDescriptorProto extendee.
             * @member {string} extendee
             * @memberof google.protobuf.FieldDescriptorProto
             * @instance
             */
            FieldDescriptorProto.prototype.extendee = "";

            /**
             * FieldDescriptorProto defaultValue.
             * @member {string} defaultValue
             * @memberof google.protobuf.FieldDescriptorProto
             * @instance
             */
            FieldDescriptorProto.prototype.defaultValue = "";

            /**
             * FieldDescriptorProto oneofIndex.
             * @member {number} oneofIndex
             * @memberof google.protobuf.FieldDescriptorProto
             * @instance
             */
            FieldDescriptorProto.prototype.oneofIndex = 0;

            /**
             * FieldDescriptorProto jsonName.
             * @member {string} jsonName
             * @memberof google.protobuf.FieldDescriptorProto
             * @instance
             */
            FieldDescriptorProto.prototype.jsonName = "";

            /**
             * FieldDescriptorProto options.
             * @member {google.protobuf.IFieldOptions|null|undefined} options
             * @memberof google.protobuf.FieldDescriptorProto
             * @instance
             */
            FieldDescriptorProto.prototype.options = null;

            /**
             * Creates a new FieldDescriptorProto instance using the specified properties.
             * @function create
             * @memberof google.protobuf.FieldDescriptorProto
             * @static
             * @param {google.protobuf.IFieldDescriptorProto=} [properties] Properties to set
             * @returns {google.protobuf.FieldDescriptorProto} FieldDescriptorProto instance
             */
            FieldDescriptorProto.create = function create(properties) {
                return new FieldDescriptorProto(properties);
            };

            /**
             * Encodes the specified FieldDescriptorProto message. Does not implicitly {@link google.protobuf.FieldDescriptorProto.verify|verify} messages.
             * @function encode
             * @memberof google.protobuf.FieldDescriptorProto
             * @static
             * @param {google.protobuf.IFieldDescriptorProto} message FieldDescriptorProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            FieldDescriptorProto.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.name);
                if (message.extendee != null && Object.hasOwnProperty.call(message, "extendee"))
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.extendee);
                if (message.number != null && Object.hasOwnProperty.call(message, "number"))
                    writer.uint32(/* id 3, wireType 0 =*/24).int32(message.number);
                if (message.label != null && Object.hasOwnProperty.call(message, "label"))
                    writer.uint32(/* id 4, wireType 0 =*/32).int32(message.label);
                if (message.type != null && Object.hasOwnProperty.call(message, "type"))
                    writer.uint32(/* id 5, wireType 0 =*/40).int32(message.type);
                if (message.typeName != null && Object.hasOwnProperty.call(message, "typeName"))
                    writer.uint32(/* id 6, wireType 2 =*/50).string(message.typeName);
                if (message.defaultValue != null && Object.hasOwnProperty.call(message, "defaultValue"))
                    writer.uint32(/* id 7, wireType 2 =*/58).string(message.defaultValue);
                if (message.options != null && Object.hasOwnProperty.call(message, "options"))
                    $root.google.protobuf.FieldOptions.encode(message.options, writer.uint32(/* id 8, wireType 2 =*/66).fork()).ldelim();
                if (message.oneofIndex != null && Object.hasOwnProperty.call(message, "oneofIndex"))
                    writer.uint32(/* id 9, wireType 0 =*/72).int32(message.oneofIndex);
                if (message.jsonName != null && Object.hasOwnProperty.call(message, "jsonName"))
                    writer.uint32(/* id 10, wireType 2 =*/82).string(message.jsonName);
                return writer;
            };

            /**
             * Encodes the specified FieldDescriptorProto message, length delimited. Does not implicitly {@link google.protobuf.FieldDescriptorProto.verify|verify} messages.
             * @function encodeDelimited
             * @memberof google.protobuf.FieldDescriptorProto
             * @static
             * @param {google.protobuf.IFieldDescriptorProto} message FieldDescriptorProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            FieldDescriptorProto.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a FieldDescriptorProto message from the specified reader or buffer.
             * @function decode
             * @memberof google.protobuf.FieldDescriptorProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {google.protobuf.FieldDescriptorProto} FieldDescriptorProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            FieldDescriptorProto.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.google.protobuf.FieldDescriptorProto();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.name = reader.string();
                            break;
                        }
                    case 3: {
                            message.number = reader.int32();
                            break;
                        }
                    case 4: {
                            message.label = reader.int32();
                            break;
                        }
                    case 5: {
                            message.type = reader.int32();
                            break;
                        }
                    case 6: {
                            message.typeName = reader.string();
                            break;
                        }
                    case 2: {
                            message.extendee = reader.string();
                            break;
                        }
                    case 7: {
                            message.defaultValue = reader.string();
                            break;
                        }
                    case 9: {
                            message.oneofIndex = reader.int32();
                            break;
                        }
                    case 10: {
                            message.jsonName = reader.string();
                            break;
                        }
                    case 8: {
                            message.options = $root.google.protobuf.FieldOptions.decode(reader, reader.uint32());
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a FieldDescriptorProto message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof google.protobuf.FieldDescriptorProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {google.protobuf.FieldDescriptorProto} FieldDescriptorProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            FieldDescriptorProto.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a FieldDescriptorProto message.
             * @function verify
             * @memberof google.protobuf.FieldDescriptorProto
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            FieldDescriptorProto.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.name != null && message.hasOwnProperty("name"))
                    if (!$util.isString(message.name))
                        return "name: string expected";
                if (message.number != null && message.hasOwnProperty("number"))
                    if (!$util.isInteger(message.number))
                        return "number: integer expected";
                if (message.label != null && message.hasOwnProperty("label"))
                    switch (message.label) {
                    default:
                        return "label: enum value expected";
                    case 1:
                    case 2:
                    case 3:
                        break;
                    }
                if (message.type != null && message.hasOwnProperty("type"))
                    switch (message.type) {
                    default:
                        return "type: enum value expected";
                    case 1:
                    case 2:
                    case 3:
                    case 4:
                    case 5:
                    case 6:
                    case 7:
                    case 8:
                    case 9:
                    case 10:
                    case 11:
                    case 12:
                    case 13:
                    case 14:
                    case 15:
                    case 16:
                    case 17:
                    case 18:
                        break;
                    }
                if (message.typeName != null && message.hasOwnProperty("typeName"))
                    if (!$util.isString(message.typeName))
                        return "typeName: string expected";
                if (message.extendee != null && message.hasOwnProperty("extendee"))
                    if (!$util.isString(message.extendee))
                        return "extendee: string expected";
                if (message.defaultValue != null && message.hasOwnProperty("defaultValue"))
                    if (!$util.isString(message.defaultValue))
                        return "defaultValue: string expected";
                if (message.oneofIndex != null && message.hasOwnProperty("oneofIndex"))
                    if (!$util.isInteger(message.oneofIndex))
                        return "oneofIndex: integer expected";
                if (message.jsonName != null && message.hasOwnProperty("jsonName"))
                    if (!$util.isString(message.jsonName))
                        return "jsonName: string expected";
                if (message.options != null && message.hasOwnProperty("options")) {
                    let error = $root.google.protobuf.FieldOptions.verify(message.options);
                    if (error)
                        return "options." + error;
                }
                return null;
            };

            /**
             * Creates a FieldDescriptorProto message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof google.protobuf.FieldDescriptorProto
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {google.protobuf.FieldDescriptorProto} FieldDescriptorProto
             */
            FieldDescriptorProto.fromObject = function fromObject(object) {
                if (object instanceof $root.google.protobuf.FieldDescriptorProto)
                    return object;
                let message = new $root.google.protobuf.FieldDescriptorProto();
                if (object.name != null)
                    message.name = String(object.name);
                if (object.number != null)
                    message.number = object.number | 0;
                switch (object.label) {
                default:
                    if (typeof object.label === "number") {
                        message.label = object.label;
                        break;
                    }
                    break;
                case "LABEL_OPTIONAL":
                case 1:
                    message.label = 1;
                    break;
                case "LABEL_REQUIRED":
                case 2:
                    message.label = 2;
                    break;
                case "LABEL_REPEATED":
                case 3:
                    message.label = 3;
                    break;
                }
                switch (object.type) {
                default:
                    if (typeof object.type === "number") {
                        message.type = object.type;
                        break;
                    }
                    break;
                case "TYPE_DOUBLE":
                case 1:
                    message.type = 1;
                    break;
                case "TYPE_FLOAT":
                case 2:
                    message.type = 2;
                    break;
                case "TYPE_INT64":
                case 3:
                    message.type = 3;
                    break;
                case "TYPE_UINT64":
                case 4:
                    message.type = 4;
                    break;
                case "TYPE_INT32":
                case 5:
                    message.type = 5;
                    break;
                case "TYPE_FIXED64":
                case 6:
                    message.type = 6;
                    break;
                case "TYPE_FIXED32":
                case 7:
                    message.type = 7;
                    break;
                case "TYPE_BOOL":
                case 8:
                    message.type = 8;
                    break;
                case "TYPE_STRING":
                case 9:
                    message.type = 9;
                    break;
                case "TYPE_GROUP":
                case 10:
                    message.type = 10;
                    break;
                case "TYPE_MESSAGE":
                case 11:
                    message.type = 11;
                    break;
                case "TYPE_BYTES":
                case 12:
                    message.type = 12;
                    break;
                case "TYPE_UINT32":
                case 13:
                    message.type = 13;
                    break;
                case "TYPE_ENUM":
                case 14:
                    message.type = 14;
                    break;
                case "TYPE_SFIXED32":
                case 15:
                    message.type = 15;
                    break;
                case "TYPE_SFIXED64":
                case 16:
                    message.type = 16;
                    break;
                case "TYPE_SINT32":
                case 17:
                    message.type = 17;
                    break;
                case "TYPE_SINT64":
                case 18:
                    message.type = 18;
                    break;
                }
                if (object.typeName != null)
                    message.typeName = String(object.typeName);
                if (object.extendee != null)
                    message.extendee = String(object.extendee);
                if (object.defaultValue != null)
                    message.defaultValue = String(object.defaultValue);
                if (object.oneofIndex != null)
                    message.oneofIndex = object.oneofIndex | 0;
                if (object.jsonName != null)
                    message.jsonName = String(object.jsonName);
                if (object.options != null) {
                    if (typeof object.options !== "object")
                        throw TypeError(".google.protobuf.FieldDescriptorProto.options: object expected");
                    message.options = $root.google.protobuf.FieldOptions.fromObject(object.options);
                }
                return message;
            };

            /**
             * Creates a plain object from a FieldDescriptorProto message. Also converts values to other types if specified.
             * @function toObject
             * @memberof google.protobuf.FieldDescriptorProto
             * @static
             * @param {google.protobuf.FieldDescriptorProto} message FieldDescriptorProto
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            FieldDescriptorProto.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults) {
                    object.name = "";
                    object.extendee = "";
                    object.number = 0;
                    object.label = options.enums === String ? "LABEL_OPTIONAL" : 1;
                    object.type = options.enums === String ? "TYPE_DOUBLE" : 1;
                    object.typeName = "";
                    object.defaultValue = "";
                    object.options = null;
                    object.oneofIndex = 0;
                    object.jsonName = "";
                }
                if (message.name != null && message.hasOwnProperty("name"))
                    object.name = message.name;
                if (message.extendee != null && message.hasOwnProperty("extendee"))
                    object.extendee = message.extendee;
                if (message.number != null && message.hasOwnProperty("number"))
                    object.number = message.number;
                if (message.label != null && message.hasOwnProperty("label"))
                    object.label = options.enums === String ? $root.google.protobuf.FieldDescriptorProto.Label[message.label] === undefined ? message.label : $root.google.protobuf.FieldDescriptorProto.Label[message.label] : message.label;
                if (message.type != null && message.hasOwnProperty("type"))
                    object.type = options.enums === String ? $root.google.protobuf.FieldDescriptorProto.Type[message.type] === undefined ? message.type : $root.google.protobuf.FieldDescriptorProto.Type[message.type] : message.type;
                if (message.typeName != null && message.hasOwnProperty("typeName"))
                    object.typeName = message.typeName;
                if (message.defaultValue != null && message.hasOwnProperty("defaultValue"))
                    object.defaultValue = message.defaultValue;
                if (message.options != null && message.hasOwnProperty("options"))
                    object.options = $root.google.protobuf.FieldOptions.toObject(message.options, options);
                if (message.oneofIndex != null && message.hasOwnProperty("oneofIndex"))
                    object.oneofIndex = message.oneofIndex;
                if (message.jsonName != null && message.hasOwnProperty("jsonName"))
                    object.jsonName = message.jsonName;
                return object;
            };

            /**
             * Converts this FieldDescriptorProto to JSON.
             * @function toJSON
             * @memberof google.protobuf.FieldDescriptorProto
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            FieldDescriptorProto.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for FieldDescriptorProto
             * @function getTypeUrl
             * @memberof google.protobuf.FieldDescriptorProto
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            FieldDescriptorProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/google.protobuf.FieldDescriptorProto";
            };

            /**
             * Type enum.
             * @name google.protobuf.FieldDescriptorProto.Type
             * @enum {number}
             * @property {number} TYPE_DOUBLE=1 TYPE_DOUBLE value
             * @property {number} TYPE_FLOAT=2 TYPE_FLOAT value
             * @property {number} TYPE_INT64=3 TYPE_INT64 value
             * @property {number} TYPE_UINT64=4 TYPE_UINT64 value
             * @property {number} TYPE_INT32=5 TYPE_INT32 value
             * @property {number} TYPE_FIXED64=6 TYPE_FIXED64 value
             * @property {number} TYPE_FIXED32=7 TYPE_FIXED32 value
             * @property {number} TYPE_BOOL=8 TYPE_BOOL value
             * @property {number} TYPE_STRING=9 TYPE_STRING value
             * @property {number} TYPE_GROUP=10 TYPE_GROUP value
             * @property {number} TYPE_MESSAGE=11 TYPE_MESSAGE value
             * @property {number} TYPE_BYTES=12 TYPE_BYTES value
             * @property {number} TYPE_UINT32=13 TYPE_UINT32 value
             * @property {number} TYPE_ENUM=14 TYPE_ENUM value
             * @property {number} TYPE_SFIXED32=15 TYPE_SFIXED32 value
             * @property {number} TYPE_SFIXED64=16 TYPE_SFIXED64 value
             * @property {number} TYPE_SINT32=17 TYPE_SINT32 value
             * @property {number} TYPE_SINT64=18 TYPE_SINT64 value
             */
            FieldDescriptorProto.Type = (function() {
                const valuesById = {}, values = Object.create(valuesById);
                values[valuesById[1] = "TYPE_DOUBLE"] = 1;
                values[valuesById[2] = "TYPE_FLOAT"] = 2;
                values[valuesById[3] = "TYPE_INT64"] = 3;
                values[valuesById[4] = "TYPE_UINT64"] = 4;
                values[valuesById[5] = "TYPE_INT32"] = 5;
                values[valuesById[6] = "TYPE_FIXED64"] = 6;
                values[valuesById[7] = "TYPE_FIXED32"] = 7;
                values[valuesById[8] = "TYPE_BOOL"] = 8;
                values[valuesById[9] = "TYPE_STRING"] = 9;
                values[valuesById[10] = "TYPE_GROUP"] = 10;
                values[valuesById[11] = "TYPE_MESSAGE"] = 11;
                values[valuesById[12] = "TYPE_BYTES"] = 12;
                values[valuesById[13] = "TYPE_UINT32"] = 13;
                values[valuesById[14] = "TYPE_ENUM"] = 14;
                values[valuesById[15] = "TYPE_SFIXED32"] = 15;
                values[valuesById[16] = "TYPE_SFIXED64"] = 16;
                values[valuesById[17] = "TYPE_SINT32"] = 17;
                values[valuesById[18] = "TYPE_SINT64"] = 18;
                return values;
            })();

            /**
             * Label enum.
             * @name google.protobuf.FieldDescriptorProto.Label
             * @enum {number}
             * @property {number} LABEL_OPTIONAL=1 LABEL_OPTIONAL value
             * @property {number} LABEL_REQUIRED=2 LABEL_REQUIRED value
             * @property {number} LABEL_REPEATED=3 LABEL_REPEATED value
             */
            FieldDescriptorProto.Label = (function() {
                const valuesById = {}, values = Object.create(valuesById);
                values[valuesById[1] = "LABEL_OPTIONAL"] = 1;
                values[valuesById[2] = "LABEL_REQUIRED"] = 2;
                values[valuesById[3] = "LABEL_REPEATED"] = 3;
                return values;
            })();

            return FieldDescriptorProto;
        })();

        protobuf.OneofDescriptorProto = (function() {

            /**
             * Properties of an OneofDescriptorProto.
             * @memberof google.protobuf
             * @interface IOneofDescriptorProto
             * @property {string|null} [name] OneofDescriptorProto name
             * @property {google.protobuf.IOneofOptions|null} [options] OneofDescriptorProto options
             */

            /**
             * Constructs a new OneofDescriptorProto.
             * @memberof google.protobuf
             * @classdesc Represents an OneofDescriptorProto.
             * @implements IOneofDescriptorProto
             * @constructor
             * @param {google.protobuf.IOneofDescriptorProto=} [properties] Properties to set
             */
            function OneofDescriptorProto(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * OneofDescriptorProto name.
             * @member {string} name
             * @memberof google.protobuf.OneofDescriptorProto
             * @instance
             */
            OneofDescriptorProto.prototype.name = "";

            /**
             * OneofDescriptorProto options.
             * @member {google.protobuf.IOneofOptions|null|undefined} options
             * @memberof google.protobuf.OneofDescriptorProto
             * @instance
             */
            OneofDescriptorProto.prototype.options = null;

            /**
             * Creates a new OneofDescriptorProto instance using the specified properties.
             * @function create
             * @memberof google.protobuf.OneofDescriptorProto
             * @static
             * @param {google.protobuf.IOneofDescriptorProto=} [properties] Properties to set
             * @returns {google.protobuf.OneofDescriptorProto} OneofDescriptorProto instance
             */
            OneofDescriptorProto.create = function create(properties) {
                return new OneofDescriptorProto(properties);
            };

            /**
             * Encodes the specified OneofDescriptorProto message. Does not implicitly {@link google.protobuf.OneofDescriptorProto.verify|verify} messages.
             * @function encode
             * @memberof google.protobuf.OneofDescriptorProto
             * @static
             * @param {google.protobuf.IOneofDescriptorProto} message OneofDescriptorProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            OneofDescriptorProto.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.name);
                if (message.options != null && Object.hasOwnProperty.call(message, "options"))
                    $root.google.protobuf.OneofOptions.encode(message.options, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                return writer;
            };

            /**
             * Encodes the specified OneofDescriptorProto message, length delimited. Does not implicitly {@link google.protobuf.OneofDescriptorProto.verify|verify} messages.
             * @function encodeDelimited
             * @memberof google.protobuf.OneofDescriptorProto
             * @static
             * @param {google.protobuf.IOneofDescriptorProto} message OneofDescriptorProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            OneofDescriptorProto.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes an OneofDescriptorProto message from the specified reader or buffer.
             * @function decode
             * @memberof google.protobuf.OneofDescriptorProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {google.protobuf.OneofDescriptorProto} OneofDescriptorProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            OneofDescriptorProto.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.google.protobuf.OneofDescriptorProto();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.name = reader.string();
                            break;
                        }
                    case 2: {
                            message.options = $root.google.protobuf.OneofOptions.decode(reader, reader.uint32());
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes an OneofDescriptorProto message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof google.protobuf.OneofDescriptorProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {google.protobuf.OneofDescriptorProto} OneofDescriptorProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            OneofDescriptorProto.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies an OneofDescriptorProto message.
             * @function verify
             * @memberof google.protobuf.OneofDescriptorProto
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            OneofDescriptorProto.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.name != null && message.hasOwnProperty("name"))
                    if (!$util.isString(message.name))
                        return "name: string expected";
                if (message.options != null && message.hasOwnProperty("options")) {
                    let error = $root.google.protobuf.OneofOptions.verify(message.options);
                    if (error)
                        return "options." + error;
                }
                return null;
            };

            /**
             * Creates an OneofDescriptorProto message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof google.protobuf.OneofDescriptorProto
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {google.protobuf.OneofDescriptorProto} OneofDescriptorProto
             */
            OneofDescriptorProto.fromObject = function fromObject(object) {
                if (object instanceof $root.google.protobuf.OneofDescriptorProto)
                    return object;
                let message = new $root.google.protobuf.OneofDescriptorProto();
                if (object.name != null)
                    message.name = String(object.name);
                if (object.options != null) {
                    if (typeof object.options !== "object")
                        throw TypeError(".google.protobuf.OneofDescriptorProto.options: object expected");
                    message.options = $root.google.protobuf.OneofOptions.fromObject(object.options);
                }
                return message;
            };

            /**
             * Creates a plain object from an OneofDescriptorProto message. Also converts values to other types if specified.
             * @function toObject
             * @memberof google.protobuf.OneofDescriptorProto
             * @static
             * @param {google.protobuf.OneofDescriptorProto} message OneofDescriptorProto
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            OneofDescriptorProto.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults) {
                    object.name = "";
                    object.options = null;
                }
                if (message.name != null && message.hasOwnProperty("name"))
                    object.name = message.name;
                if (message.options != null && message.hasOwnProperty("options"))
                    object.options = $root.google.protobuf.OneofOptions.toObject(message.options, options);
                return object;
            };

            /**
             * Converts this OneofDescriptorProto to JSON.
             * @function toJSON
             * @memberof google.protobuf.OneofDescriptorProto
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            OneofDescriptorProto.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for OneofDescriptorProto
             * @function getTypeUrl
             * @memberof google.protobuf.OneofDescriptorProto
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            OneofDescriptorProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/google.protobuf.OneofDescriptorProto";
            };

            return OneofDescriptorProto;
        })();

        protobuf.EnumDescriptorProto = (function() {

            /**
             * Properties of an EnumDescriptorProto.
             * @memberof google.protobuf
             * @interface IEnumDescriptorProto
             * @property {string|null} [name] EnumDescriptorProto name
             * @property {Array.<google.protobuf.IEnumValueDescriptorProto>|null} [value] EnumDescriptorProto value
             * @property {google.protobuf.IEnumOptions|null} [options] EnumDescriptorProto options
             */

            /**
             * Constructs a new EnumDescriptorProto.
             * @memberof google.protobuf
             * @classdesc Represents an EnumDescriptorProto.
             * @implements IEnumDescriptorProto
             * @constructor
             * @param {google.protobuf.IEnumDescriptorProto=} [properties] Properties to set
             */
            function EnumDescriptorProto(properties) {
                this.value = [];
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * EnumDescriptorProto name.
             * @member {string} name
             * @memberof google.protobuf.EnumDescriptorProto
             * @instance
             */
            EnumDescriptorProto.prototype.name = "";

            /**
             * EnumDescriptorProto value.
             * @member {Array.<google.protobuf.IEnumValueDescriptorProto>} value
             * @memberof google.protobuf.EnumDescriptorProto
             * @instance
             */
            EnumDescriptorProto.prototype.value = $util.emptyArray;

            /**
             * EnumDescriptorProto options.
             * @member {google.protobuf.IEnumOptions|null|undefined} options
             * @memberof google.protobuf.EnumDescriptorProto
             * @instance
             */
            EnumDescriptorProto.prototype.options = null;

            /**
             * Creates a new EnumDescriptorProto instance using the specified properties.
             * @function create
             * @memberof google.protobuf.EnumDescriptorProto
             * @static
             * @param {google.protobuf.IEnumDescriptorProto=} [properties] Properties to set
             * @returns {google.protobuf.EnumDescriptorProto} EnumDescriptorProto instance
             */
            EnumDescriptorProto.create = function create(properties) {
                return new EnumDescriptorProto(properties);
            };

            /**
             * Encodes the specified EnumDescriptorProto message. Does not implicitly {@link google.protobuf.EnumDescriptorProto.verify|verify} messages.
             * @function encode
             * @memberof google.protobuf.EnumDescriptorProto
             * @static
             * @param {google.protobuf.IEnumDescriptorProto} message EnumDescriptorProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            EnumDescriptorProto.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.name);
                if (message.value != null && message.value.length)
                    for (let i = 0; i < message.value.length; ++i)
                        $root.google.protobuf.EnumValueDescriptorProto.encode(message.value[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                if (message.options != null && Object.hasOwnProperty.call(message, "options"))
                    $root.google.protobuf.EnumOptions.encode(message.options, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                return writer;
            };

            /**
             * Encodes the specified EnumDescriptorProto message, length delimited. Does not implicitly {@link google.protobuf.EnumDescriptorProto.verify|verify} messages.
             * @function encodeDelimited
             * @memberof google.protobuf.EnumDescriptorProto
             * @static
             * @param {google.protobuf.IEnumDescriptorProto} message EnumDescriptorProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            EnumDescriptorProto.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes an EnumDescriptorProto message from the specified reader or buffer.
             * @function decode
             * @memberof google.protobuf.EnumDescriptorProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {google.protobuf.EnumDescriptorProto} EnumDescriptorProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            EnumDescriptorProto.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.google.protobuf.EnumDescriptorProto();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.name = reader.string();
                            break;
                        }
                    case 2: {
                            if (!(message.value && message.value.length))
                                message.value = [];
                            message.value.push($root.google.protobuf.EnumValueDescriptorProto.decode(reader, reader.uint32()));
                            break;
                        }
                    case 3: {
                            message.options = $root.google.protobuf.EnumOptions.decode(reader, reader.uint32());
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes an EnumDescriptorProto message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof google.protobuf.EnumDescriptorProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {google.protobuf.EnumDescriptorProto} EnumDescriptorProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            EnumDescriptorProto.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies an EnumDescriptorProto message.
             * @function verify
             * @memberof google.protobuf.EnumDescriptorProto
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            EnumDescriptorProto.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.name != null && message.hasOwnProperty("name"))
                    if (!$util.isString(message.name))
                        return "name: string expected";
                if (message.value != null && message.hasOwnProperty("value")) {
                    if (!Array.isArray(message.value))
                        return "value: array expected";
                    for (let i = 0; i < message.value.length; ++i) {
                        let error = $root.google.protobuf.EnumValueDescriptorProto.verify(message.value[i]);
                        if (error)
                            return "value." + error;
                    }
                }
                if (message.options != null && message.hasOwnProperty("options")) {
                    let error = $root.google.protobuf.EnumOptions.verify(message.options);
                    if (error)
                        return "options." + error;
                }
                return null;
            };

            /**
             * Creates an EnumDescriptorProto message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof google.protobuf.EnumDescriptorProto
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {google.protobuf.EnumDescriptorProto} EnumDescriptorProto
             */
            EnumDescriptorProto.fromObject = function fromObject(object) {
                if (object instanceof $root.google.protobuf.EnumDescriptorProto)
                    return object;
                let message = new $root.google.protobuf.EnumDescriptorProto();
                if (object.name != null)
                    message.name = String(object.name);
                if (object.value) {
                    if (!Array.isArray(object.value))
                        throw TypeError(".google.protobuf.EnumDescriptorProto.value: array expected");
                    message.value = [];
                    for (let i = 0; i < object.value.length; ++i) {
                        if (typeof object.value[i] !== "object")
                            throw TypeError(".google.protobuf.EnumDescriptorProto.value: object expected");
                        message.value[i] = $root.google.protobuf.EnumValueDescriptorProto.fromObject(object.value[i]);
                    }
                }
                if (object.options != null) {
                    if (typeof object.options !== "object")
                        throw TypeError(".google.protobuf.EnumDescriptorProto.options: object expected");
                    message.options = $root.google.protobuf.EnumOptions.fromObject(object.options);
                }
                return message;
            };

            /**
             * Creates a plain object from an EnumDescriptorProto message. Also converts values to other types if specified.
             * @function toObject
             * @memberof google.protobuf.EnumDescriptorProto
             * @static
             * @param {google.protobuf.EnumDescriptorProto} message EnumDescriptorProto
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            EnumDescriptorProto.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.arrays || options.defaults)
                    object.value = [];
                if (options.defaults) {
                    object.name = "";
                    object.options = null;
                }
                if (message.name != null && message.hasOwnProperty("name"))
                    object.name = message.name;
                if (message.value && message.value.length) {
                    object.value = [];
                    for (let j = 0; j < message.value.length; ++j)
                        object.value[j] = $root.google.protobuf.EnumValueDescriptorProto.toObject(message.value[j], options);
                }
                if (message.options != null && message.hasOwnProperty("options"))
                    object.options = $root.google.protobuf.EnumOptions.toObject(message.options, options);
                return object;
            };

            /**
             * Converts this EnumDescriptorProto to JSON.
             * @function toJSON
             * @memberof google.protobuf.EnumDescriptorProto
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            EnumDescriptorProto.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for EnumDescriptorProto
             * @function getTypeUrl
             * @memberof google.protobuf.EnumDescriptorProto
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            EnumDescriptorProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/google.protobuf.EnumDescriptorProto";
            };

            return EnumDescriptorProto;
        })();

        protobuf.EnumValueDescriptorProto = (function() {

            /**
             * Properties of an EnumValueDescriptorProto.
             * @memberof google.protobuf
             * @interface IEnumValueDescriptorProto
             * @property {string|null} [name] EnumValueDescriptorProto name
             * @property {number|null} [number] EnumValueDescriptorProto number
             * @property {google.protobuf.IEnumValueOptions|null} [options] EnumValueDescriptorProto options
             */

            /**
             * Constructs a new EnumValueDescriptorProto.
             * @memberof google.protobuf
             * @classdesc Represents an EnumValueDescriptorProto.
             * @implements IEnumValueDescriptorProto
             * @constructor
             * @param {google.protobuf.IEnumValueDescriptorProto=} [properties] Properties to set
             */
            function EnumValueDescriptorProto(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * EnumValueDescriptorProto name.
             * @member {string} name
             * @memberof google.protobuf.EnumValueDescriptorProto
             * @instance
             */
            EnumValueDescriptorProto.prototype.name = "";

            /**
             * EnumValueDescriptorProto number.
             * @member {number} number
             * @memberof google.protobuf.EnumValueDescriptorProto
             * @instance
             */
            EnumValueDescriptorProto.prototype.number = 0;

            /**
             * EnumValueDescriptorProto options.
             * @member {google.protobuf.IEnumValueOptions|null|undefined} options
             * @memberof google.protobuf.EnumValueDescriptorProto
             * @instance
             */
            EnumValueDescriptorProto.prototype.options = null;

            /**
             * Creates a new EnumValueDescriptorProto instance using the specified properties.
             * @function create
             * @memberof google.protobuf.EnumValueDescriptorProto
             * @static
             * @param {google.protobuf.IEnumValueDescriptorProto=} [properties] Properties to set
             * @returns {google.protobuf.EnumValueDescriptorProto} EnumValueDescriptorProto instance
             */
            EnumValueDescriptorProto.create = function create(properties) {
                return new EnumValueDescriptorProto(properties);
            };

            /**
             * Encodes the specified EnumValueDescriptorProto message. Does not implicitly {@link google.protobuf.EnumValueDescriptorProto.verify|verify} messages.
             * @function encode
             * @memberof google.protobuf.EnumValueDescriptorProto
             * @static
             * @param {google.protobuf.IEnumValueDescriptorProto} message EnumValueDescriptorProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            EnumValueDescriptorProto.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.name);
                if (message.number != null && Object.hasOwnProperty.call(message, "number"))
                    writer.uint32(/* id 2, wireType 0 =*/16).int32(message.number);
                if (message.options != null && Object.hasOwnProperty.call(message, "options"))
                    $root.google.protobuf.EnumValueOptions.encode(message.options, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                return writer;
            };

            /**
             * Encodes the specified EnumValueDescriptorProto message, length delimited. Does not implicitly {@link google.protobuf.EnumValueDescriptorProto.verify|verify} messages.
             * @function encodeDelimited
             * @memberof google.protobuf.EnumValueDescriptorProto
             * @static
             * @param {google.protobuf.IEnumValueDescriptorProto} message EnumValueDescriptorProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            EnumValueDescriptorProto.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes an EnumValueDescriptorProto message from the specified reader or buffer.
             * @function decode
             * @memberof google.protobuf.EnumValueDescriptorProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {google.protobuf.EnumValueDescriptorProto} EnumValueDescriptorProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            EnumValueDescriptorProto.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.google.protobuf.EnumValueDescriptorProto();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.name = reader.string();
                            break;
                        }
                    case 2: {
                            message.number = reader.int32();
                            break;
                        }
                    case 3: {
                            message.options = $root.google.protobuf.EnumValueOptions.decode(reader, reader.uint32());
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes an EnumValueDescriptorProto message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof google.protobuf.EnumValueDescriptorProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {google.protobuf.EnumValueDescriptorProto} EnumValueDescriptorProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            EnumValueDescriptorProto.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies an EnumValueDescriptorProto message.
             * @function verify
             * @memberof google.protobuf.EnumValueDescriptorProto
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            EnumValueDescriptorProto.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.name != null && message.hasOwnProperty("name"))
                    if (!$util.isString(message.name))
                        return "name: string expected";
                if (message.number != null && message.hasOwnProperty("number"))
                    if (!$util.isInteger(message.number))
                        return "number: integer expected";
                if (message.options != null && message.hasOwnProperty("options")) {
                    let error = $root.google.protobuf.EnumValueOptions.verify(message.options);
                    if (error)
                        return "options." + error;
                }
                return null;
            };

            /**
             * Creates an EnumValueDescriptorProto message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof google.protobuf.EnumValueDescriptorProto
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {google.protobuf.EnumValueDescriptorProto} EnumValueDescriptorProto
             */
            EnumValueDescriptorProto.fromObject = function fromObject(object) {
                if (object instanceof $root.google.protobuf.EnumValueDescriptorProto)
                    return object;
                let message = new $root.google.protobuf.EnumValueDescriptorProto();
                if (object.name != null)
                    message.name = String(object.name);
                if (object.number != null)
                    message.number = object.number | 0;
                if (object.options != null) {
                    if (typeof object.options !== "object")
                        throw TypeError(".google.protobuf.EnumValueDescriptorProto.options: object expected");
                    message.options = $root.google.protobuf.EnumValueOptions.fromObject(object.options);
                }
                return message;
            };

            /**
             * Creates a plain object from an EnumValueDescriptorProto message. Also converts values to other types if specified.
             * @function toObject
             * @memberof google.protobuf.EnumValueDescriptorProto
             * @static
             * @param {google.protobuf.EnumValueDescriptorProto} message EnumValueDescriptorProto
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            EnumValueDescriptorProto.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults) {
                    object.name = "";
                    object.number = 0;
                    object.options = null;
                }
                if (message.name != null && message.hasOwnProperty("name"))
                    object.name = message.name;
                if (message.number != null && message.hasOwnProperty("number"))
                    object.number = message.number;
                if (message.options != null && message.hasOwnProperty("options"))
                    object.options = $root.google.protobuf.EnumValueOptions.toObject(message.options, options);
                return object;
            };

            /**
             * Converts this EnumValueDescriptorProto to JSON.
             * @function toJSON
             * @memberof google.protobuf.EnumValueDescriptorProto
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            EnumValueDescriptorProto.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for EnumValueDescriptorProto
             * @function getTypeUrl
             * @memberof google.protobuf.EnumValueDescriptorProto
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            EnumValueDescriptorProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/google.protobuf.EnumValueDescriptorProto";
            };

            return EnumValueDescriptorProto;
        })();

        protobuf.ServiceDescriptorProto = (function() {

            /**
             * Properties of a ServiceDescriptorProto.
             * @memberof google.protobuf
             * @interface IServiceDescriptorProto
             * @property {string|null} [name] ServiceDescriptorProto name
             * @property {Array.<google.protobuf.IMethodDescriptorProto>|null} [method] ServiceDescriptorProto method
             * @property {google.protobuf.IServiceOptions|null} [options] ServiceDescriptorProto options
             */

            /**
             * Constructs a new ServiceDescriptorProto.
             * @memberof google.protobuf
             * @classdesc Represents a ServiceDescriptorProto.
             * @implements IServiceDescriptorProto
             * @constructor
             * @param {google.protobuf.IServiceDescriptorProto=} [properties] Properties to set
             */
            function ServiceDescriptorProto(properties) {
                this.method = [];
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * ServiceDescriptorProto name.
             * @member {string} name
             * @memberof google.protobuf.ServiceDescriptorProto
             * @instance
             */
            ServiceDescriptorProto.prototype.name = "";

            /**
             * ServiceDescriptorProto method.
             * @member {Array.<google.protobuf.IMethodDescriptorProto>} method
             * @memberof google.protobuf.ServiceDescriptorProto
             * @instance
             */
            ServiceDescriptorProto.prototype.method = $util.emptyArray;

            /**
             * ServiceDescriptorProto options.
             * @member {google.protobuf.IServiceOptions|null|undefined} options
             * @memberof google.protobuf.ServiceDescriptorProto
             * @instance
             */
            ServiceDescriptorProto.prototype.options = null;

            /**
             * Creates a new ServiceDescriptorProto instance using the specified properties.
             * @function create
             * @memberof google.protobuf.ServiceDescriptorProto
             * @static
             * @param {google.protobuf.IServiceDescriptorProto=} [properties] Properties to set
             * @returns {google.protobuf.ServiceDescriptorProto} ServiceDescriptorProto instance
             */
            ServiceDescriptorProto.create = function create(properties) {
                return new ServiceDescriptorProto(properties);
            };

            /**
             * Encodes the specified ServiceDescriptorProto message. Does not implicitly {@link google.protobuf.ServiceDescriptorProto.verify|verify} messages.
             * @function encode
             * @memberof google.protobuf.ServiceDescriptorProto
             * @static
             * @param {google.protobuf.IServiceDescriptorProto} message ServiceDescriptorProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ServiceDescriptorProto.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.name);
                if (message.method != null && message.method.length)
                    for (let i = 0; i < message.method.length; ++i)
                        $root.google.protobuf.MethodDescriptorProto.encode(message.method[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                if (message.options != null && Object.hasOwnProperty.call(message, "options"))
                    $root.google.protobuf.ServiceOptions.encode(message.options, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                return writer;
            };

            /**
             * Encodes the specified ServiceDescriptorProto message, length delimited. Does not implicitly {@link google.protobuf.ServiceDescriptorProto.verify|verify} messages.
             * @function encodeDelimited
             * @memberof google.protobuf.ServiceDescriptorProto
             * @static
             * @param {google.protobuf.IServiceDescriptorProto} message ServiceDescriptorProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ServiceDescriptorProto.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a ServiceDescriptorProto message from the specified reader or buffer.
             * @function decode
             * @memberof google.protobuf.ServiceDescriptorProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {google.protobuf.ServiceDescriptorProto} ServiceDescriptorProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ServiceDescriptorProto.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.google.protobuf.ServiceDescriptorProto();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.name = reader.string();
                            break;
                        }
                    case 2: {
                            if (!(message.method && message.method.length))
                                message.method = [];
                            message.method.push($root.google.protobuf.MethodDescriptorProto.decode(reader, reader.uint32()));
                            break;
                        }
                    case 3: {
                            message.options = $root.google.protobuf.ServiceOptions.decode(reader, reader.uint32());
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a ServiceDescriptorProto message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof google.protobuf.ServiceDescriptorProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {google.protobuf.ServiceDescriptorProto} ServiceDescriptorProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ServiceDescriptorProto.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a ServiceDescriptorProto message.
             * @function verify
             * @memberof google.protobuf.ServiceDescriptorProto
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            ServiceDescriptorProto.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.name != null && message.hasOwnProperty("name"))
                    if (!$util.isString(message.name))
                        return "name: string expected";
                if (message.method != null && message.hasOwnProperty("method")) {
                    if (!Array.isArray(message.method))
                        return "method: array expected";
                    for (let i = 0; i < message.method.length; ++i) {
                        let error = $root.google.protobuf.MethodDescriptorProto.verify(message.method[i]);
                        if (error)
                            return "method." + error;
                    }
                }
                if (message.options != null && message.hasOwnProperty("options")) {
                    let error = $root.google.protobuf.ServiceOptions.verify(message.options);
                    if (error)
                        return "options." + error;
                }
                return null;
            };

            /**
             * Creates a ServiceDescriptorProto message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof google.protobuf.ServiceDescriptorProto
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {google.protobuf.ServiceDescriptorProto} ServiceDescriptorProto
             */
            ServiceDescriptorProto.fromObject = function fromObject(object) {
                if (object instanceof $root.google.protobuf.ServiceDescriptorProto)
                    return object;
                let message = new $root.google.protobuf.ServiceDescriptorProto();
                if (object.name != null)
                    message.name = String(object.name);
                if (object.method) {
                    if (!Array.isArray(object.method))
                        throw TypeError(".google.protobuf.ServiceDescriptorProto.method: array expected");
                    message.method = [];
                    for (let i = 0; i < object.method.length; ++i) {
                        if (typeof object.method[i] !== "object")
                            throw TypeError(".google.protobuf.ServiceDescriptorProto.method: object expected");
                        message.method[i] = $root.google.protobuf.MethodDescriptorProto.fromObject(object.method[i]);
                    }
                }
                if (object.options != null) {
                    if (typeof object.options !== "object")
                        throw TypeError(".google.protobuf.ServiceDescriptorProto.options: object expected");
                    message.options = $root.google.protobuf.ServiceOptions.fromObject(object.options);
                }
                return message;
            };

            /**
             * Creates a plain object from a ServiceDescriptorProto message. Also converts values to other types if specified.
             * @function toObject
             * @memberof google.protobuf.ServiceDescriptorProto
             * @static
             * @param {google.protobuf.ServiceDescriptorProto} message ServiceDescriptorProto
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            ServiceDescriptorProto.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.arrays || options.defaults)
                    object.method = [];
                if (options.defaults) {
                    object.name = "";
                    object.options = null;
                }
                if (message.name != null && message.hasOwnProperty("name"))
                    object.name = message.name;
                if (message.method && message.method.length) {
                    object.method = [];
                    for (let j = 0; j < message.method.length; ++j)
                        object.method[j] = $root.google.protobuf.MethodDescriptorProto.toObject(message.method[j], options);
                }
                if (message.options != null && message.hasOwnProperty("options"))
                    object.options = $root.google.protobuf.ServiceOptions.toObject(message.options, options);
                return object;
            };

            /**
             * Converts this ServiceDescriptorProto to JSON.
             * @function toJSON
             * @memberof google.protobuf.ServiceDescriptorProto
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            ServiceDescriptorProto.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for ServiceDescriptorProto
             * @function getTypeUrl
             * @memberof google.protobuf.ServiceDescriptorProto
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            ServiceDescriptorProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/google.protobuf.ServiceDescriptorProto";
            };

            return ServiceDescriptorProto;
        })();

        protobuf.MethodDescriptorProto = (function() {

            /**
             * Properties of a MethodDescriptorProto.
             * @memberof google.protobuf
             * @interface IMethodDescriptorProto
             * @property {string|null} [name] MethodDescriptorProto name
             * @property {string|null} [inputType] MethodDescriptorProto inputType
             * @property {string|null} [outputType] MethodDescriptorProto outputType
             * @property {google.protobuf.IMethodOptions|null} [options] MethodDescriptorProto options
             * @property {boolean|null} [clientStreaming] MethodDescriptorProto clientStreaming
             * @property {boolean|null} [serverStreaming] MethodDescriptorProto serverStreaming
             */

            /**
             * Constructs a new MethodDescriptorProto.
             * @memberof google.protobuf
             * @classdesc Represents a MethodDescriptorProto.
             * @implements IMethodDescriptorProto
             * @constructor
             * @param {google.protobuf.IMethodDescriptorProto=} [properties] Properties to set
             */
            function MethodDescriptorProto(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * MethodDescriptorProto name.
             * @member {string} name
             * @memberof google.protobuf.MethodDescriptorProto
             * @instance
             */
            MethodDescriptorProto.prototype.name = "";

            /**
             * MethodDescriptorProto inputType.
             * @member {string} inputType
             * @memberof google.protobuf.MethodDescriptorProto
             * @instance
             */
            MethodDescriptorProto.prototype.inputType = "";

            /**
             * MethodDescriptorProto outputType.
             * @member {string} outputType
             * @memberof google.protobuf.MethodDescriptorProto
             * @instance
             */
            MethodDescriptorProto.prototype.outputType = "";

            /**
             * MethodDescriptorProto options.
             * @member {google.protobuf.IMethodOptions|null|undefined} options
             * @memberof google.protobuf.MethodDescriptorProto
             * @instance
             */
            MethodDescriptorProto.prototype.options = null;

            /**
             * MethodDescriptorProto clientStreaming.
             * @member {boolean} clientStreaming
             * @memberof google.protobuf.MethodDescriptorProto
             * @instance
             */
            MethodDescriptorProto.prototype.clientStreaming = false;

            /**
             * MethodDescriptorProto serverStreaming.
             * @member {boolean} serverStreaming
             * @memberof google.protobuf.MethodDescriptorProto
             * @instance
             */
            MethodDescriptorProto.prototype.serverStreaming = false;

            /**
             * Creates a new MethodDescriptorProto instance using the specified properties.
             * @function create
             * @memberof google.protobuf.MethodDescriptorProto
             * @static
             * @param {google.protobuf.IMethodDescriptorProto=} [properties] Properties to set
             * @returns {google.protobuf.MethodDescriptorProto} MethodDescriptorProto instance
             */
            MethodDescriptorProto.create = function create(properties) {
                return new MethodDescriptorProto(properties);
            };

            /**
             * Encodes the specified MethodDescriptorProto message. Does not implicitly {@link google.protobuf.MethodDescriptorProto.verify|verify} messages.
             * @function encode
             * @memberof google.protobuf.MethodDescriptorProto
             * @static
             * @param {google.protobuf.IMethodDescriptorProto} message MethodDescriptorProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            MethodDescriptorProto.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.name);
                if (message.inputType != null && Object.hasOwnProperty.call(message, "inputType"))
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.inputType);
                if (message.outputType != null && Object.hasOwnProperty.call(message, "outputType"))
                    writer.uint32(/* id 3, wireType 2 =*/26).string(message.outputType);
                if (message.options != null && Object.hasOwnProperty.call(message, "options"))
                    $root.google.protobuf.MethodOptions.encode(message.options, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
                if (message.clientStreaming != null && Object.hasOwnProperty.call(message, "clientStreaming"))
                    writer.uint32(/* id 5, wireType 0 =*/40).bool(message.clientStreaming);
                if (message.serverStreaming != null && Object.hasOwnProperty.call(message, "serverStreaming"))
                    writer.uint32(/* id 6, wireType 0 =*/48).bool(message.serverStreaming);
                return writer;
            };

            /**
             * Encodes the specified MethodDescriptorProto message, length delimited. Does not implicitly {@link google.protobuf.MethodDescriptorProto.verify|verify} messages.
             * @function encodeDelimited
             * @memberof google.protobuf.MethodDescriptorProto
             * @static
             * @param {google.protobuf.IMethodDescriptorProto} message MethodDescriptorProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            MethodDescriptorProto.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a MethodDescriptorProto message from the specified reader or buffer.
             * @function decode
             * @memberof google.protobuf.MethodDescriptorProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {google.protobuf.MethodDescriptorProto} MethodDescriptorProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            MethodDescriptorProto.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.google.protobuf.MethodDescriptorProto();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.name = reader.string();
                            break;
                        }
                    case 2: {
                            message.inputType = reader.string();
                            break;
                        }
                    case 3: {
                            message.outputType = reader.string();
                            break;
                        }
                    case 4: {
                            message.options = $root.google.protobuf.MethodOptions.decode(reader, reader.uint32());
                            break;
                        }
                    case 5: {
                            message.clientStreaming = reader.bool();
                            break;
                        }
                    case 6: {
                            message.serverStreaming = reader.bool();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a MethodDescriptorProto message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof google.protobuf.MethodDescriptorProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {google.protobuf.MethodDescriptorProto} MethodDescriptorProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            MethodDescriptorProto.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a MethodDescriptorProto message.
             * @function verify
             * @memberof google.protobuf.MethodDescriptorProto
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            MethodDescriptorProto.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.name != null && message.hasOwnProperty("name"))
                    if (!$util.isString(message.name))
                        return "name: string expected";
                if (message.inputType != null && message.hasOwnProperty("inputType"))
                    if (!$util.isString(message.inputType))
                        return "inputType: string expected";
                if (message.outputType != null && message.hasOwnProperty("outputType"))
                    if (!$util.isString(message.outputType))
                        return "outputType: string expected";
                if (message.options != null && message.hasOwnProperty("options")) {
                    let error = $root.google.protobuf.MethodOptions.verify(message.options);
                    if (error)
                        return "options." + error;
                }
                if (message.clientStreaming != null && message.hasOwnProperty("clientStreaming"))
                    if (typeof message.clientStreaming !== "boolean")
                        return "clientStreaming: boolean expected";
                if (message.serverStreaming != null && message.hasOwnProperty("serverStreaming"))
                    if (typeof message.serverStreaming !== "boolean")
                        return "serverStreaming: boolean expected";
                return null;
            };

            /**
             * Creates a MethodDescriptorProto message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof google.protobuf.MethodDescriptorProto
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {google.protobuf.MethodDescriptorProto} MethodDescriptorProto
             */
            MethodDescriptorProto.fromObject = function fromObject(object) {
                if (object instanceof $root.google.protobuf.MethodDescriptorProto)
                    return object;
                let message = new $root.google.protobuf.MethodDescriptorProto();
                if (object.name != null)
                    message.name = String(object.name);
                if (object.inputType != null)
                    message.inputType = String(object.inputType);
                if (object.outputType != null)
                    message.outputType = String(object.outputType);
                if (object.options != null) {
                    if (typeof object.options !== "object")
                        throw TypeError(".google.protobuf.MethodDescriptorProto.options: object expected");
                    message.options = $root.google.protobuf.MethodOptions.fromObject(object.options);
                }
                if (object.clientStreaming != null)
                    message.clientStreaming = Boolean(object.clientStreaming);
                if (object.serverStreaming != null)
                    message.serverStreaming = Boolean(object.serverStreaming);
                return message;
            };

            /**
             * Creates a plain object from a MethodDescriptorProto message. Also converts values to other types if specified.
             * @function toObject
             * @memberof google.protobuf.MethodDescriptorProto
             * @static
             * @param {google.protobuf.MethodDescriptorProto} message MethodDescriptorProto
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            MethodDescriptorProto.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults) {
                    object.name = "";
                    object.inputType = "";
                    object.outputType = "";
                    object.options = null;
                    object.clientStreaming = false;
                    object.serverStreaming = false;
                }
                if (message.name != null && message.hasOwnProperty("name"))
                    object.name = message.name;
                if (message.inputType != null && message.hasOwnProperty("inputType"))
                    object.inputType = message.inputType;
                if (message.outputType != null && message.hasOwnProperty("outputType"))
                    object.outputType = message.outputType;
                if (message.options != null && message.hasOwnProperty("options"))
                    object.options = $root.google.protobuf.MethodOptions.toObject(message.options, options);
                if (message.clientStreaming != null && message.hasOwnProperty("clientStreaming"))
                    object.clientStreaming = message.clientStreaming;
                if (message.serverStreaming != null && message.hasOwnProperty("serverStreaming"))
                    object.serverStreaming = message.serverStreaming;
                return object;
            };

            /**
             * Converts this MethodDescriptorProto to JSON.
             * @function toJSON
             * @memberof google.protobuf.MethodDescriptorProto
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            MethodDescriptorProto.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for MethodDescriptorProto
             * @function getTypeUrl
             * @memberof google.protobuf.MethodDescriptorProto
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            MethodDescriptorProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/google.protobuf.MethodDescriptorProto";
            };

            return MethodDescriptorProto;
        })();

        protobuf.FileOptions = (function() {

            /**
             * Properties of a FileOptions.
             * @memberof google.protobuf
             * @interface IFileOptions
             * @property {string|null} [javaPackage] FileOptions javaPackage
             * @property {string|null} [javaOuterClassname] FileOptions javaOuterClassname
             * @property {boolean|null} [javaMultipleFiles] FileOptions javaMultipleFiles
             * @property {boolean|null} [javaGenerateEqualsAndHash] FileOptions javaGenerateEqualsAndHash
             * @property {boolean|null} [javaStringCheckUtf8] FileOptions javaStringCheckUtf8
             * @property {google.protobuf.FileOptions.OptimizeMode|null} [optimizeFor] FileOptions optimizeFor
             * @property {string|null} [goPackage] FileOptions goPackage
             * @property {boolean|null} [ccGenericServices] FileOptions ccGenericServices
             * @property {boolean|null} [javaGenericServices] FileOptions javaGenericServices
             * @property {boolean|null} [pyGenericServices] FileOptions pyGenericServices
             * @property {boolean|null} [deprecated] FileOptions deprecated
             * @property {boolean|null} [ccEnableArenas] FileOptions ccEnableArenas
             * @property {string|null} [objcClassPrefix] FileOptions objcClassPrefix
             * @property {string|null} [csharpNamespace] FileOptions csharpNamespace
             * @property {Array.<google.protobuf.IUninterpretedOption>|null} [uninterpretedOption] FileOptions uninterpretedOption
             * @property {INanoPBOptions|null} [".nanopbFileopt"] FileOptions .nanopbFileopt
             */

            /**
             * Constructs a new FileOptions.
             * @memberof google.protobuf
             * @classdesc Represents a FileOptions.
             * @implements IFileOptions
             * @constructor
             * @param {google.protobuf.IFileOptions=} [properties] Properties to set
             */
            function FileOptions(properties) {
                this.uninterpretedOption = [];
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * FileOptions javaPackage.
             * @member {string} javaPackage
             * @memberof google.protobuf.FileOptions
             * @instance
             */
            FileOptions.prototype.javaPackage = "";

            /**
             * FileOptions javaOuterClassname.
             * @member {string} javaOuterClassname
             * @memberof google.protobuf.FileOptions
             * @instance
             */
            FileOptions.prototype.javaOuterClassname = "";

            /**
             * FileOptions javaMultipleFiles.
             * @member {boolean} javaMultipleFiles
             * @memberof google.protobuf.FileOptions
             * @instance
             */
            FileOptions.prototype.javaMultipleFiles = false;

            /**
             * FileOptions javaGenerateEqualsAndHash.
             * @member {boolean} javaGenerateEqualsAndHash
             * @memberof google.protobuf.FileOptions
             * @instance
             */
            FileOptions.prototype.javaGenerateEqualsAndHash = false;

            /**
             * FileOptions javaStringCheckUtf8.
             * @member {boolean} javaStringCheckUtf8
             * @memberof google.protobuf.FileOptions
             * @instance
             */
            FileOptions.prototype.javaStringCheckUtf8 = false;

            /**
             * FileOptions optimizeFor.
             * @member {google.protobuf.FileOptions.OptimizeMode} optimizeFor
             * @memberof google.protobuf.FileOptions
             * @instance
             */
            FileOptions.prototype.optimizeFor = 1;

            /**
             * FileOptions goPackage.
             * @member {string} goPackage
             * @memberof google.protobuf.FileOptions
             * @instance
             */
            FileOptions.prototype.goPackage = "";

            /**
             * FileOptions ccGenericServices.
             * @member {boolean} ccGenericServices
             * @memberof google.protobuf.FileOptions
             * @instance
             */
            FileOptions.prototype.ccGenericServices = false;

            /**
             * FileOptions javaGenericServices.
             * @member {boolean} javaGenericServices
             * @memberof google.protobuf.FileOptions
             * @instance
             */
            FileOptions.prototype.javaGenericServices = false;

            /**
             * FileOptions pyGenericServices.
             * @member {boolean} pyGenericServices
             * @memberof google.protobuf.FileOptions
             * @instance
             */
            FileOptions.prototype.pyGenericServices = false;

            /**
             * FileOptions deprecated.
             * @member {boolean} deprecated
             * @memberof google.protobuf.FileOptions
             * @instance
             */
            FileOptions.prototype.deprecated = false;

            /**
             * FileOptions ccEnableArenas.
             * @member {boolean} ccEnableArenas
             * @memberof google.protobuf.FileOptions
             * @instance
             */
            FileOptions.prototype.ccEnableArenas = false;

            /**
             * FileOptions objcClassPrefix.
             * @member {string} objcClassPrefix
             * @memberof google.protobuf.FileOptions
             * @instance
             */
            FileOptions.prototype.objcClassPrefix = "";

            /**
             * FileOptions csharpNamespace.
             * @member {string} csharpNamespace
             * @memberof google.protobuf.FileOptions
             * @instance
             */
            FileOptions.prototype.csharpNamespace = "";

            /**
             * FileOptions uninterpretedOption.
             * @member {Array.<google.protobuf.IUninterpretedOption>} uninterpretedOption
             * @memberof google.protobuf.FileOptions
             * @instance
             */
            FileOptions.prototype.uninterpretedOption = $util.emptyArray;

            /**
             * FileOptions .nanopbFileopt.
             * @member {INanoPBOptions|null|undefined} .nanopbFileopt
             * @memberof google.protobuf.FileOptions
             * @instance
             */
            FileOptions.prototype[".nanopbFileopt"] = null;

            /**
             * Creates a new FileOptions instance using the specified properties.
             * @function create
             * @memberof google.protobuf.FileOptions
             * @static
             * @param {google.protobuf.IFileOptions=} [properties] Properties to set
             * @returns {google.protobuf.FileOptions} FileOptions instance
             */
            FileOptions.create = function create(properties) {
                return new FileOptions(properties);
            };

            /**
             * Encodes the specified FileOptions message. Does not implicitly {@link google.protobuf.FileOptions.verify|verify} messages.
             * @function encode
             * @memberof google.protobuf.FileOptions
             * @static
             * @param {google.protobuf.IFileOptions} message FileOptions message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            FileOptions.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.javaPackage != null && Object.hasOwnProperty.call(message, "javaPackage"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.javaPackage);
                if (message.javaOuterClassname != null && Object.hasOwnProperty.call(message, "javaOuterClassname"))
                    writer.uint32(/* id 8, wireType 2 =*/66).string(message.javaOuterClassname);
                if (message.optimizeFor != null && Object.hasOwnProperty.call(message, "optimizeFor"))
                    writer.uint32(/* id 9, wireType 0 =*/72).int32(message.optimizeFor);
                if (message.javaMultipleFiles != null && Object.hasOwnProperty.call(message, "javaMultipleFiles"))
                    writer.uint32(/* id 10, wireType 0 =*/80).bool(message.javaMultipleFiles);
                if (message.goPackage != null && Object.hasOwnProperty.call(message, "goPackage"))
                    writer.uint32(/* id 11, wireType 2 =*/90).string(message.goPackage);
                if (message.ccGenericServices != null && Object.hasOwnProperty.call(message, "ccGenericServices"))
                    writer.uint32(/* id 16, wireType 0 =*/128).bool(message.ccGenericServices);
                if (message.javaGenericServices != null && Object.hasOwnProperty.call(message, "javaGenericServices"))
                    writer.uint32(/* id 17, wireType 0 =*/136).bool(message.javaGenericServices);
                if (message.pyGenericServices != null && Object.hasOwnProperty.call(message, "pyGenericServices"))
                    writer.uint32(/* id 18, wireType 0 =*/144).bool(message.pyGenericServices);
                if (message.javaGenerateEqualsAndHash != null && Object.hasOwnProperty.call(message, "javaGenerateEqualsAndHash"))
                    writer.uint32(/* id 20, wireType 0 =*/160).bool(message.javaGenerateEqualsAndHash);
                if (message.deprecated != null && Object.hasOwnProperty.call(message, "deprecated"))
                    writer.uint32(/* id 23, wireType 0 =*/184).bool(message.deprecated);
                if (message.javaStringCheckUtf8 != null && Object.hasOwnProperty.call(message, "javaStringCheckUtf8"))
                    writer.uint32(/* id 27, wireType 0 =*/216).bool(message.javaStringCheckUtf8);
                if (message.ccEnableArenas != null && Object.hasOwnProperty.call(message, "ccEnableArenas"))
                    writer.uint32(/* id 31, wireType 0 =*/248).bool(message.ccEnableArenas);
                if (message.objcClassPrefix != null && Object.hasOwnProperty.call(message, "objcClassPrefix"))
                    writer.uint32(/* id 36, wireType 2 =*/290).string(message.objcClassPrefix);
                if (message.csharpNamespace != null && Object.hasOwnProperty.call(message, "csharpNamespace"))
                    writer.uint32(/* id 37, wireType 2 =*/298).string(message.csharpNamespace);
                if (message.uninterpretedOption != null && message.uninterpretedOption.length)
                    for (let i = 0; i < message.uninterpretedOption.length; ++i)
                        $root.google.protobuf.UninterpretedOption.encode(message.uninterpretedOption[i], writer.uint32(/* id 999, wireType 2 =*/7994).fork()).ldelim();
                if (message[".nanopbFileopt"] != null && Object.hasOwnProperty.call(message, ".nanopbFileopt"))
                    $root.NanoPBOptions.encode(message[".nanopbFileopt"], writer.uint32(/* id 1010, wireType 2 =*/8082).fork()).ldelim();
                return writer;
            };

            /**
             * Encodes the specified FileOptions message, length delimited. Does not implicitly {@link google.protobuf.FileOptions.verify|verify} messages.
             * @function encodeDelimited
             * @memberof google.protobuf.FileOptions
             * @static
             * @param {google.protobuf.IFileOptions} message FileOptions message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            FileOptions.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a FileOptions message from the specified reader or buffer.
             * @function decode
             * @memberof google.protobuf.FileOptions
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {google.protobuf.FileOptions} FileOptions
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            FileOptions.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.google.protobuf.FileOptions();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.javaPackage = reader.string();
                            break;
                        }
                    case 8: {
                            message.javaOuterClassname = reader.string();
                            break;
                        }
                    case 10: {
                            message.javaMultipleFiles = reader.bool();
                            break;
                        }
                    case 20: {
                            message.javaGenerateEqualsAndHash = reader.bool();
                            break;
                        }
                    case 27: {
                            message.javaStringCheckUtf8 = reader.bool();
                            break;
                        }
                    case 9: {
                            message.optimizeFor = reader.int32();
                            break;
                        }
                    case 11: {
                            message.goPackage = reader.string();
                            break;
                        }
                    case 16: {
                            message.ccGenericServices = reader.bool();
                            break;
                        }
                    case 17: {
                            message.javaGenericServices = reader.bool();
                            break;
                        }
                    case 18: {
                            message.pyGenericServices = reader.bool();
                            break;
                        }
                    case 23: {
                            message.deprecated = reader.bool();
                            break;
                        }
                    case 31: {
                            message.ccEnableArenas = reader.bool();
                            break;
                        }
                    case 36: {
                            message.objcClassPrefix = reader.string();
                            break;
                        }
                    case 37: {
                            message.csharpNamespace = reader.string();
                            break;
                        }
                    case 999: {
                            if (!(message.uninterpretedOption && message.uninterpretedOption.length))
                                message.uninterpretedOption = [];
                            message.uninterpretedOption.push($root.google.protobuf.UninterpretedOption.decode(reader, reader.uint32()));
                            break;
                        }
                    case 1010: {
                            message[".nanopbFileopt"] = $root.NanoPBOptions.decode(reader, reader.uint32());
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a FileOptions message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof google.protobuf.FileOptions
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {google.protobuf.FileOptions} FileOptions
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            FileOptions.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a FileOptions message.
             * @function verify
             * @memberof google.protobuf.FileOptions
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            FileOptions.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.javaPackage != null && message.hasOwnProperty("javaPackage"))
                    if (!$util.isString(message.javaPackage))
                        return "javaPackage: string expected";
                if (message.javaOuterClassname != null && message.hasOwnProperty("javaOuterClassname"))
                    if (!$util.isString(message.javaOuterClassname))
                        return "javaOuterClassname: string expected";
                if (message.javaMultipleFiles != null && message.hasOwnProperty("javaMultipleFiles"))
                    if (typeof message.javaMultipleFiles !== "boolean")
                        return "javaMultipleFiles: boolean expected";
                if (message.javaGenerateEqualsAndHash != null && message.hasOwnProperty("javaGenerateEqualsAndHash"))
                    if (typeof message.javaGenerateEqualsAndHash !== "boolean")
                        return "javaGenerateEqualsAndHash: boolean expected";
                if (message.javaStringCheckUtf8 != null && message.hasOwnProperty("javaStringCheckUtf8"))
                    if (typeof message.javaStringCheckUtf8 !== "boolean")
                        return "javaStringCheckUtf8: boolean expected";
                if (message.optimizeFor != null && message.hasOwnProperty("optimizeFor"))
                    switch (message.optimizeFor) {
                    default:
                        return "optimizeFor: enum value expected";
                    case 1:
                    case 2:
                    case 3:
                        break;
                    }
                if (message.goPackage != null && message.hasOwnProperty("goPackage"))
                    if (!$util.isString(message.goPackage))
                        return "goPackage: string expected";
                if (message.ccGenericServices != null && message.hasOwnProperty("ccGenericServices"))
                    if (typeof message.ccGenericServices !== "boolean")
                        return "ccGenericServices: boolean expected";
                if (message.javaGenericServices != null && message.hasOwnProperty("javaGenericServices"))
                    if (typeof message.javaGenericServices !== "boolean")
                        return "javaGenericServices: boolean expected";
                if (message.pyGenericServices != null && message.hasOwnProperty("pyGenericServices"))
                    if (typeof message.pyGenericServices !== "boolean")
                        return "pyGenericServices: boolean expected";
                if (message.deprecated != null && message.hasOwnProperty("deprecated"))
                    if (typeof message.deprecated !== "boolean")
                        return "deprecated: boolean expected";
                if (message.ccEnableArenas != null && message.hasOwnProperty("ccEnableArenas"))
                    if (typeof message.ccEnableArenas !== "boolean")
                        return "ccEnableArenas: boolean expected";
                if (message.objcClassPrefix != null && message.hasOwnProperty("objcClassPrefix"))
                    if (!$util.isString(message.objcClassPrefix))
                        return "objcClassPrefix: string expected";
                if (message.csharpNamespace != null && message.hasOwnProperty("csharpNamespace"))
                    if (!$util.isString(message.csharpNamespace))
                        return "csharpNamespace: string expected";
                if (message.uninterpretedOption != null && message.hasOwnProperty("uninterpretedOption")) {
                    if (!Array.isArray(message.uninterpretedOption))
                        return "uninterpretedOption: array expected";
                    for (let i = 0; i < message.uninterpretedOption.length; ++i) {
                        let error = $root.google.protobuf.UninterpretedOption.verify(message.uninterpretedOption[i]);
                        if (error)
                            return "uninterpretedOption." + error;
                    }
                }
                if (message[".nanopbFileopt"] != null && message.hasOwnProperty(".nanopbFileopt")) {
                    let error = $root.NanoPBOptions.verify(message[".nanopbFileopt"]);
                    if (error)
                        return ".nanopbFileopt." + error;
                }
                return null;
            };

            /**
             * Creates a FileOptions message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof google.protobuf.FileOptions
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {google.protobuf.FileOptions} FileOptions
             */
            FileOptions.fromObject = function fromObject(object) {
                if (object instanceof $root.google.protobuf.FileOptions)
                    return object;
                let message = new $root.google.protobuf.FileOptions();
                if (object.javaPackage != null)
                    message.javaPackage = String(object.javaPackage);
                if (object.javaOuterClassname != null)
                    message.javaOuterClassname = String(object.javaOuterClassname);
                if (object.javaMultipleFiles != null)
                    message.javaMultipleFiles = Boolean(object.javaMultipleFiles);
                if (object.javaGenerateEqualsAndHash != null)
                    message.javaGenerateEqualsAndHash = Boolean(object.javaGenerateEqualsAndHash);
                if (object.javaStringCheckUtf8 != null)
                    message.javaStringCheckUtf8 = Boolean(object.javaStringCheckUtf8);
                switch (object.optimizeFor) {
                default:
                    if (typeof object.optimizeFor === "number") {
                        message.optimizeFor = object.optimizeFor;
                        break;
                    }
                    break;
                case "SPEED":
                case 1:
                    message.optimizeFor = 1;
                    break;
                case "CODE_SIZE":
                case 2:
                    message.optimizeFor = 2;
                    break;
                case "LITE_RUNTIME":
                case 3:
                    message.optimizeFor = 3;
                    break;
                }
                if (object.goPackage != null)
                    message.goPackage = String(object.goPackage);
                if (object.ccGenericServices != null)
                    message.ccGenericServices = Boolean(object.ccGenericServices);
                if (object.javaGenericServices != null)
                    message.javaGenericServices = Boolean(object.javaGenericServices);
                if (object.pyGenericServices != null)
                    message.pyGenericServices = Boolean(object.pyGenericServices);
                if (object.deprecated != null)
                    message.deprecated = Boolean(object.deprecated);
                if (object.ccEnableArenas != null)
                    message.ccEnableArenas = Boolean(object.ccEnableArenas);
                if (object.objcClassPrefix != null)
                    message.objcClassPrefix = String(object.objcClassPrefix);
                if (object.csharpNamespace != null)
                    message.csharpNamespace = String(object.csharpNamespace);
                if (object.uninterpretedOption) {
                    if (!Array.isArray(object.uninterpretedOption))
                        throw TypeError(".google.protobuf.FileOptions.uninterpretedOption: array expected");
                    message.uninterpretedOption = [];
                    for (let i = 0; i < object.uninterpretedOption.length; ++i) {
                        if (typeof object.uninterpretedOption[i] !== "object")
                            throw TypeError(".google.protobuf.FileOptions.uninterpretedOption: object expected");
                        message.uninterpretedOption[i] = $root.google.protobuf.UninterpretedOption.fromObject(object.uninterpretedOption[i]);
                    }
                }
                if (object[".nanopbFileopt"] != null) {
                    if (typeof object[".nanopbFileopt"] !== "object")
                        throw TypeError(".google.protobuf.FileOptions..nanopbFileopt: object expected");
                    message[".nanopbFileopt"] = $root.NanoPBOptions.fromObject(object[".nanopbFileopt"]);
                }
                return message;
            };

            /**
             * Creates a plain object from a FileOptions message. Also converts values to other types if specified.
             * @function toObject
             * @memberof google.protobuf.FileOptions
             * @static
             * @param {google.protobuf.FileOptions} message FileOptions
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            FileOptions.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.arrays || options.defaults)
                    object.uninterpretedOption = [];
                if (options.defaults) {
                    object.javaPackage = "";
                    object.javaOuterClassname = "";
                    object.optimizeFor = options.enums === String ? "SPEED" : 1;
                    object.javaMultipleFiles = false;
                    object.goPackage = "";
                    object.ccGenericServices = false;
                    object.javaGenericServices = false;
                    object.pyGenericServices = false;
                    object.javaGenerateEqualsAndHash = false;
                    object.deprecated = false;
                    object.javaStringCheckUtf8 = false;
                    object.ccEnableArenas = false;
                    object.objcClassPrefix = "";
                    object.csharpNamespace = "";
                    object[".nanopbFileopt"] = null;
                }
                if (message.javaPackage != null && message.hasOwnProperty("javaPackage"))
                    object.javaPackage = message.javaPackage;
                if (message.javaOuterClassname != null && message.hasOwnProperty("javaOuterClassname"))
                    object.javaOuterClassname = message.javaOuterClassname;
                if (message.optimizeFor != null && message.hasOwnProperty("optimizeFor"))
                    object.optimizeFor = options.enums === String ? $root.google.protobuf.FileOptions.OptimizeMode[message.optimizeFor] === undefined ? message.optimizeFor : $root.google.protobuf.FileOptions.OptimizeMode[message.optimizeFor] : message.optimizeFor;
                if (message.javaMultipleFiles != null && message.hasOwnProperty("javaMultipleFiles"))
                    object.javaMultipleFiles = message.javaMultipleFiles;
                if (message.goPackage != null && message.hasOwnProperty("goPackage"))
                    object.goPackage = message.goPackage;
                if (message.ccGenericServices != null && message.hasOwnProperty("ccGenericServices"))
                    object.ccGenericServices = message.ccGenericServices;
                if (message.javaGenericServices != null && message.hasOwnProperty("javaGenericServices"))
                    object.javaGenericServices = message.javaGenericServices;
                if (message.pyGenericServices != null && message.hasOwnProperty("pyGenericServices"))
                    object.pyGenericServices = message.pyGenericServices;
                if (message.javaGenerateEqualsAndHash != null && message.hasOwnProperty("javaGenerateEqualsAndHash"))
                    object.javaGenerateEqualsAndHash = message.javaGenerateEqualsAndHash;
                if (message.deprecated != null && message.hasOwnProperty("deprecated"))
                    object.deprecated = message.deprecated;
                if (message.javaStringCheckUtf8 != null && message.hasOwnProperty("javaStringCheckUtf8"))
                    object.javaStringCheckUtf8 = message.javaStringCheckUtf8;
                if (message.ccEnableArenas != null && message.hasOwnProperty("ccEnableArenas"))
                    object.ccEnableArenas = message.ccEnableArenas;
                if (message.objcClassPrefix != null && message.hasOwnProperty("objcClassPrefix"))
                    object.objcClassPrefix = message.objcClassPrefix;
                if (message.csharpNamespace != null && message.hasOwnProperty("csharpNamespace"))
                    object.csharpNamespace = message.csharpNamespace;
                if (message.uninterpretedOption && message.uninterpretedOption.length) {
                    object.uninterpretedOption = [];
                    for (let j = 0; j < message.uninterpretedOption.length; ++j)
                        object.uninterpretedOption[j] = $root.google.protobuf.UninterpretedOption.toObject(message.uninterpretedOption[j], options);
                }
                if (message[".nanopbFileopt"] != null && message.hasOwnProperty(".nanopbFileopt"))
                    object[".nanopbFileopt"] = $root.NanoPBOptions.toObject(message[".nanopbFileopt"], options);
                return object;
            };

            /**
             * Converts this FileOptions to JSON.
             * @function toJSON
             * @memberof google.protobuf.FileOptions
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            FileOptions.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for FileOptions
             * @function getTypeUrl
             * @memberof google.protobuf.FileOptions
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            FileOptions.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/google.protobuf.FileOptions";
            };

            /**
             * OptimizeMode enum.
             * @name google.protobuf.FileOptions.OptimizeMode
             * @enum {number}
             * @property {number} SPEED=1 SPEED value
             * @property {number} CODE_SIZE=2 CODE_SIZE value
             * @property {number} LITE_RUNTIME=3 LITE_RUNTIME value
             */
            FileOptions.OptimizeMode = (function() {
                const valuesById = {}, values = Object.create(valuesById);
                values[valuesById[1] = "SPEED"] = 1;
                values[valuesById[2] = "CODE_SIZE"] = 2;
                values[valuesById[3] = "LITE_RUNTIME"] = 3;
                return values;
            })();

            return FileOptions;
        })();

        protobuf.MessageOptions = (function() {

            /**
             * Properties of a MessageOptions.
             * @memberof google.protobuf
             * @interface IMessageOptions
             * @property {boolean|null} [messageSetWireFormat] MessageOptions messageSetWireFormat
             * @property {boolean|null} [noStandardDescriptorAccessor] MessageOptions noStandardDescriptorAccessor
             * @property {boolean|null} [deprecated] MessageOptions deprecated
             * @property {boolean|null} [mapEntry] MessageOptions mapEntry
             * @property {Array.<google.protobuf.IUninterpretedOption>|null} [uninterpretedOption] MessageOptions uninterpretedOption
             * @property {INanoPBOptions|null} [".nanopbMsgopt"] MessageOptions .nanopbMsgopt
             */

            /**
             * Constructs a new MessageOptions.
             * @memberof google.protobuf
             * @classdesc Represents a MessageOptions.
             * @implements IMessageOptions
             * @constructor
             * @param {google.protobuf.IMessageOptions=} [properties] Properties to set
             */
            function MessageOptions(properties) {
                this.uninterpretedOption = [];
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * MessageOptions messageSetWireFormat.
             * @member {boolean} messageSetWireFormat
             * @memberof google.protobuf.MessageOptions
             * @instance
             */
            MessageOptions.prototype.messageSetWireFormat = false;

            /**
             * MessageOptions noStandardDescriptorAccessor.
             * @member {boolean} noStandardDescriptorAccessor
             * @memberof google.protobuf.MessageOptions
             * @instance
             */
            MessageOptions.prototype.noStandardDescriptorAccessor = false;

            /**
             * MessageOptions deprecated.
             * @member {boolean} deprecated
             * @memberof google.protobuf.MessageOptions
             * @instance
             */
            MessageOptions.prototype.deprecated = false;

            /**
             * MessageOptions mapEntry.
             * @member {boolean} mapEntry
             * @memberof google.protobuf.MessageOptions
             * @instance
             */
            MessageOptions.prototype.mapEntry = false;

            /**
             * MessageOptions uninterpretedOption.
             * @member {Array.<google.protobuf.IUninterpretedOption>} uninterpretedOption
             * @memberof google.protobuf.MessageOptions
             * @instance
             */
            MessageOptions.prototype.uninterpretedOption = $util.emptyArray;

            /**
             * MessageOptions .nanopbMsgopt.
             * @member {INanoPBOptions|null|undefined} .nanopbMsgopt
             * @memberof google.protobuf.MessageOptions
             * @instance
             */
            MessageOptions.prototype[".nanopbMsgopt"] = null;

            /**
             * Creates a new MessageOptions instance using the specified properties.
             * @function create
             * @memberof google.protobuf.MessageOptions
             * @static
             * @param {google.protobuf.IMessageOptions=} [properties] Properties to set
             * @returns {google.protobuf.MessageOptions} MessageOptions instance
             */
            MessageOptions.create = function create(properties) {
                return new MessageOptions(properties);
            };

            /**
             * Encodes the specified MessageOptions message. Does not implicitly {@link google.protobuf.MessageOptions.verify|verify} messages.
             * @function encode
             * @memberof google.protobuf.MessageOptions
             * @static
             * @param {google.protobuf.IMessageOptions} message MessageOptions message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            MessageOptions.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.messageSetWireFormat != null && Object.hasOwnProperty.call(message, "messageSetWireFormat"))
                    writer.uint32(/* id 1, wireType 0 =*/8).bool(message.messageSetWireFormat);
                if (message.noStandardDescriptorAccessor != null && Object.hasOwnProperty.call(message, "noStandardDescriptorAccessor"))
                    writer.uint32(/* id 2, wireType 0 =*/16).bool(message.noStandardDescriptorAccessor);
                if (message.deprecated != null && Object.hasOwnProperty.call(message, "deprecated"))
                    writer.uint32(/* id 3, wireType 0 =*/24).bool(message.deprecated);
                if (message.mapEntry != null && Object.hasOwnProperty.call(message, "mapEntry"))
                    writer.uint32(/* id 7, wireType 0 =*/56).bool(message.mapEntry);
                if (message.uninterpretedOption != null && message.uninterpretedOption.length)
                    for (let i = 0; i < message.uninterpretedOption.length; ++i)
                        $root.google.protobuf.UninterpretedOption.encode(message.uninterpretedOption[i], writer.uint32(/* id 999, wireType 2 =*/7994).fork()).ldelim();
                if (message[".nanopbMsgopt"] != null && Object.hasOwnProperty.call(message, ".nanopbMsgopt"))
                    $root.NanoPBOptions.encode(message[".nanopbMsgopt"], writer.uint32(/* id 1010, wireType 2 =*/8082).fork()).ldelim();
                return writer;
            };

            /**
             * Encodes the specified MessageOptions message, length delimited. Does not implicitly {@link google.protobuf.MessageOptions.verify|verify} messages.
             * @function encodeDelimited
             * @memberof google.protobuf.MessageOptions
             * @static
             * @param {google.protobuf.IMessageOptions} message MessageOptions message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            MessageOptions.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a MessageOptions message from the specified reader or buffer.
             * @function decode
             * @memberof google.protobuf.MessageOptions
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {google.protobuf.MessageOptions} MessageOptions
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            MessageOptions.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.google.protobuf.MessageOptions();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.messageSetWireFormat = reader.bool();
                            break;
                        }
                    case 2: {
                            message.noStandardDescriptorAccessor = reader.bool();
                            break;
                        }
                    case 3: {
                            message.deprecated = reader.bool();
                            break;
                        }
                    case 7: {
                            message.mapEntry = reader.bool();
                            break;
                        }
                    case 999: {
                            if (!(message.uninterpretedOption && message.uninterpretedOption.length))
                                message.uninterpretedOption = [];
                            message.uninterpretedOption.push($root.google.protobuf.UninterpretedOption.decode(reader, reader.uint32()));
                            break;
                        }
                    case 1010: {
                            message[".nanopbMsgopt"] = $root.NanoPBOptions.decode(reader, reader.uint32());
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a MessageOptions message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof google.protobuf.MessageOptions
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {google.protobuf.MessageOptions} MessageOptions
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            MessageOptions.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a MessageOptions message.
             * @function verify
             * @memberof google.protobuf.MessageOptions
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            MessageOptions.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.messageSetWireFormat != null && message.hasOwnProperty("messageSetWireFormat"))
                    if (typeof message.messageSetWireFormat !== "boolean")
                        return "messageSetWireFormat: boolean expected";
                if (message.noStandardDescriptorAccessor != null && message.hasOwnProperty("noStandardDescriptorAccessor"))
                    if (typeof message.noStandardDescriptorAccessor !== "boolean")
                        return "noStandardDescriptorAccessor: boolean expected";
                if (message.deprecated != null && message.hasOwnProperty("deprecated"))
                    if (typeof message.deprecated !== "boolean")
                        return "deprecated: boolean expected";
                if (message.mapEntry != null && message.hasOwnProperty("mapEntry"))
                    if (typeof message.mapEntry !== "boolean")
                        return "mapEntry: boolean expected";
                if (message.uninterpretedOption != null && message.hasOwnProperty("uninterpretedOption")) {
                    if (!Array.isArray(message.uninterpretedOption))
                        return "uninterpretedOption: array expected";
                    for (let i = 0; i < message.uninterpretedOption.length; ++i) {
                        let error = $root.google.protobuf.UninterpretedOption.verify(message.uninterpretedOption[i]);
                        if (error)
                            return "uninterpretedOption." + error;
                    }
                }
                if (message[".nanopbMsgopt"] != null && message.hasOwnProperty(".nanopbMsgopt")) {
                    let error = $root.NanoPBOptions.verify(message[".nanopbMsgopt"]);
                    if (error)
                        return ".nanopbMsgopt." + error;
                }
                return null;
            };

            /**
             * Creates a MessageOptions message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof google.protobuf.MessageOptions
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {google.protobuf.MessageOptions} MessageOptions
             */
            MessageOptions.fromObject = function fromObject(object) {
                if (object instanceof $root.google.protobuf.MessageOptions)
                    return object;
                let message = new $root.google.protobuf.MessageOptions();
                if (object.messageSetWireFormat != null)
                    message.messageSetWireFormat = Boolean(object.messageSetWireFormat);
                if (object.noStandardDescriptorAccessor != null)
                    message.noStandardDescriptorAccessor = Boolean(object.noStandardDescriptorAccessor);
                if (object.deprecated != null)
                    message.deprecated = Boolean(object.deprecated);
                if (object.mapEntry != null)
                    message.mapEntry = Boolean(object.mapEntry);
                if (object.uninterpretedOption) {
                    if (!Array.isArray(object.uninterpretedOption))
                        throw TypeError(".google.protobuf.MessageOptions.uninterpretedOption: array expected");
                    message.uninterpretedOption = [];
                    for (let i = 0; i < object.uninterpretedOption.length; ++i) {
                        if (typeof object.uninterpretedOption[i] !== "object")
                            throw TypeError(".google.protobuf.MessageOptions.uninterpretedOption: object expected");
                        message.uninterpretedOption[i] = $root.google.protobuf.UninterpretedOption.fromObject(object.uninterpretedOption[i]);
                    }
                }
                if (object[".nanopbMsgopt"] != null) {
                    if (typeof object[".nanopbMsgopt"] !== "object")
                        throw TypeError(".google.protobuf.MessageOptions..nanopbMsgopt: object expected");
                    message[".nanopbMsgopt"] = $root.NanoPBOptions.fromObject(object[".nanopbMsgopt"]);
                }
                return message;
            };

            /**
             * Creates a plain object from a MessageOptions message. Also converts values to other types if specified.
             * @function toObject
             * @memberof google.protobuf.MessageOptions
             * @static
             * @param {google.protobuf.MessageOptions} message MessageOptions
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            MessageOptions.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.arrays || options.defaults)
                    object.uninterpretedOption = [];
                if (options.defaults) {
                    object.messageSetWireFormat = false;
                    object.noStandardDescriptorAccessor = false;
                    object.deprecated = false;
                    object.mapEntry = false;
                    object[".nanopbMsgopt"] = null;
                }
                if (message.messageSetWireFormat != null && message.hasOwnProperty("messageSetWireFormat"))
                    object.messageSetWireFormat = message.messageSetWireFormat;
                if (message.noStandardDescriptorAccessor != null && message.hasOwnProperty("noStandardDescriptorAccessor"))
                    object.noStandardDescriptorAccessor = message.noStandardDescriptorAccessor;
                if (message.deprecated != null && message.hasOwnProperty("deprecated"))
                    object.deprecated = message.deprecated;
                if (message.mapEntry != null && message.hasOwnProperty("mapEntry"))
                    object.mapEntry = message.mapEntry;
                if (message.uninterpretedOption && message.uninterpretedOption.length) {
                    object.uninterpretedOption = [];
                    for (let j = 0; j < message.uninterpretedOption.length; ++j)
                        object.uninterpretedOption[j] = $root.google.protobuf.UninterpretedOption.toObject(message.uninterpretedOption[j], options);
                }
                if (message[".nanopbMsgopt"] != null && message.hasOwnProperty(".nanopbMsgopt"))
                    object[".nanopbMsgopt"] = $root.NanoPBOptions.toObject(message[".nanopbMsgopt"], options);
                return object;
            };

            /**
             * Converts this MessageOptions to JSON.
             * @function toJSON
             * @memberof google.protobuf.MessageOptions
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            MessageOptions.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for MessageOptions
             * @function getTypeUrl
             * @memberof google.protobuf.MessageOptions
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            MessageOptions.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/google.protobuf.MessageOptions";
            };

            return MessageOptions;
        })();

        protobuf.FieldOptions = (function() {

            /**
             * Properties of a FieldOptions.
             * @memberof google.protobuf
             * @interface IFieldOptions
             * @property {google.protobuf.FieldOptions.CType|null} [ctype] FieldOptions ctype
             * @property {boolean|null} [packed] FieldOptions packed
             * @property {google.protobuf.FieldOptions.JSType|null} [jstype] FieldOptions jstype
             * @property {boolean|null} [lazy] FieldOptions lazy
             * @property {boolean|null} [deprecated] FieldOptions deprecated
             * @property {boolean|null} [weak] FieldOptions weak
             * @property {Array.<google.protobuf.IUninterpretedOption>|null} [uninterpretedOption] FieldOptions uninterpretedOption
             * @property {INanoPBOptions|null} [".nanopb"] FieldOptions .nanopb
             */

            /**
             * Constructs a new FieldOptions.
             * @memberof google.protobuf
             * @classdesc Represents a FieldOptions.
             * @implements IFieldOptions
             * @constructor
             * @param {google.protobuf.IFieldOptions=} [properties] Properties to set
             */
            function FieldOptions(properties) {
                this.uninterpretedOption = [];
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * FieldOptions ctype.
             * @member {google.protobuf.FieldOptions.CType} ctype
             * @memberof google.protobuf.FieldOptions
             * @instance
             */
            FieldOptions.prototype.ctype = 0;

            /**
             * FieldOptions packed.
             * @member {boolean} packed
             * @memberof google.protobuf.FieldOptions
             * @instance
             */
            FieldOptions.prototype.packed = false;

            /**
             * FieldOptions jstype.
             * @member {google.protobuf.FieldOptions.JSType} jstype
             * @memberof google.protobuf.FieldOptions
             * @instance
             */
            FieldOptions.prototype.jstype = 0;

            /**
             * FieldOptions lazy.
             * @member {boolean} lazy
             * @memberof google.protobuf.FieldOptions
             * @instance
             */
            FieldOptions.prototype.lazy = false;

            /**
             * FieldOptions deprecated.
             * @member {boolean} deprecated
             * @memberof google.protobuf.FieldOptions
             * @instance
             */
            FieldOptions.prototype.deprecated = false;

            /**
             * FieldOptions weak.
             * @member {boolean} weak
             * @memberof google.protobuf.FieldOptions
             * @instance
             */
            FieldOptions.prototype.weak = false;

            /**
             * FieldOptions uninterpretedOption.
             * @member {Array.<google.protobuf.IUninterpretedOption>} uninterpretedOption
             * @memberof google.protobuf.FieldOptions
             * @instance
             */
            FieldOptions.prototype.uninterpretedOption = $util.emptyArray;

            /**
             * FieldOptions .nanopb.
             * @member {INanoPBOptions|null|undefined} .nanopb
             * @memberof google.protobuf.FieldOptions
             * @instance
             */
            FieldOptions.prototype[".nanopb"] = null;

            /**
             * Creates a new FieldOptions instance using the specified properties.
             * @function create
             * @memberof google.protobuf.FieldOptions
             * @static
             * @param {google.protobuf.IFieldOptions=} [properties] Properties to set
             * @returns {google.protobuf.FieldOptions} FieldOptions instance
             */
            FieldOptions.create = function create(properties) {
                return new FieldOptions(properties);
            };

            /**
             * Encodes the specified FieldOptions message. Does not implicitly {@link google.protobuf.FieldOptions.verify|verify} messages.
             * @function encode
             * @memberof google.protobuf.FieldOptions
             * @static
             * @param {google.protobuf.IFieldOptions} message FieldOptions message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            FieldOptions.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.ctype != null && Object.hasOwnProperty.call(message, "ctype"))
                    writer.uint32(/* id 1, wireType 0 =*/8).int32(message.ctype);
                if (message.packed != null && Object.hasOwnProperty.call(message, "packed"))
                    writer.uint32(/* id 2, wireType 0 =*/16).bool(message.packed);
                if (message.deprecated != null && Object.hasOwnProperty.call(message, "deprecated"))
                    writer.uint32(/* id 3, wireType 0 =*/24).bool(message.deprecated);
                if (message.lazy != null && Object.hasOwnProperty.call(message, "lazy"))
                    writer.uint32(/* id 5, wireType 0 =*/40).bool(message.lazy);
                if (message.jstype != null && Object.hasOwnProperty.call(message, "jstype"))
                    writer.uint32(/* id 6, wireType 0 =*/48).int32(message.jstype);
                if (message.weak != null && Object.hasOwnProperty.call(message, "weak"))
                    writer.uint32(/* id 10, wireType 0 =*/80).bool(message.weak);
                if (message.uninterpretedOption != null && message.uninterpretedOption.length)
                    for (let i = 0; i < message.uninterpretedOption.length; ++i)
                        $root.google.protobuf.UninterpretedOption.encode(message.uninterpretedOption[i], writer.uint32(/* id 999, wireType 2 =*/7994).fork()).ldelim();
                if (message[".nanopb"] != null && Object.hasOwnProperty.call(message, ".nanopb"))
                    $root.NanoPBOptions.encode(message[".nanopb"], writer.uint32(/* id 1010, wireType 2 =*/8082).fork()).ldelim();
                return writer;
            };

            /**
             * Encodes the specified FieldOptions message, length delimited. Does not implicitly {@link google.protobuf.FieldOptions.verify|verify} messages.
             * @function encodeDelimited
             * @memberof google.protobuf.FieldOptions
             * @static
             * @param {google.protobuf.IFieldOptions} message FieldOptions message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            FieldOptions.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a FieldOptions message from the specified reader or buffer.
             * @function decode
             * @memberof google.protobuf.FieldOptions
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {google.protobuf.FieldOptions} FieldOptions
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            FieldOptions.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.google.protobuf.FieldOptions();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.ctype = reader.int32();
                            break;
                        }
                    case 2: {
                            message.packed = reader.bool();
                            break;
                        }
                    case 6: {
                            message.jstype = reader.int32();
                            break;
                        }
                    case 5: {
                            message.lazy = reader.bool();
                            break;
                        }
                    case 3: {
                            message.deprecated = reader.bool();
                            break;
                        }
                    case 10: {
                            message.weak = reader.bool();
                            break;
                        }
                    case 999: {
                            if (!(message.uninterpretedOption && message.uninterpretedOption.length))
                                message.uninterpretedOption = [];
                            message.uninterpretedOption.push($root.google.protobuf.UninterpretedOption.decode(reader, reader.uint32()));
                            break;
                        }
                    case 1010: {
                            message[".nanopb"] = $root.NanoPBOptions.decode(reader, reader.uint32());
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a FieldOptions message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof google.protobuf.FieldOptions
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {google.protobuf.FieldOptions} FieldOptions
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            FieldOptions.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a FieldOptions message.
             * @function verify
             * @memberof google.protobuf.FieldOptions
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            FieldOptions.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.ctype != null && message.hasOwnProperty("ctype"))
                    switch (message.ctype) {
                    default:
                        return "ctype: enum value expected";
                    case 0:
                    case 1:
                    case 2:
                        break;
                    }
                if (message.packed != null && message.hasOwnProperty("packed"))
                    if (typeof message.packed !== "boolean")
                        return "packed: boolean expected";
                if (message.jstype != null && message.hasOwnProperty("jstype"))
                    switch (message.jstype) {
                    default:
                        return "jstype: enum value expected";
                    case 0:
                    case 1:
                    case 2:
                        break;
                    }
                if (message.lazy != null && message.hasOwnProperty("lazy"))
                    if (typeof message.lazy !== "boolean")
                        return "lazy: boolean expected";
                if (message.deprecated != null && message.hasOwnProperty("deprecated"))
                    if (typeof message.deprecated !== "boolean")
                        return "deprecated: boolean expected";
                if (message.weak != null && message.hasOwnProperty("weak"))
                    if (typeof message.weak !== "boolean")
                        return "weak: boolean expected";
                if (message.uninterpretedOption != null && message.hasOwnProperty("uninterpretedOption")) {
                    if (!Array.isArray(message.uninterpretedOption))
                        return "uninterpretedOption: array expected";
                    for (let i = 0; i < message.uninterpretedOption.length; ++i) {
                        let error = $root.google.protobuf.UninterpretedOption.verify(message.uninterpretedOption[i]);
                        if (error)
                            return "uninterpretedOption." + error;
                    }
                }
                if (message[".nanopb"] != null && message.hasOwnProperty(".nanopb")) {
                    let error = $root.NanoPBOptions.verify(message[".nanopb"]);
                    if (error)
                        return ".nanopb." + error;
                }
                return null;
            };

            /**
             * Creates a FieldOptions message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof google.protobuf.FieldOptions
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {google.protobuf.FieldOptions} FieldOptions
             */
            FieldOptions.fromObject = function fromObject(object) {
                if (object instanceof $root.google.protobuf.FieldOptions)
                    return object;
                let message = new $root.google.protobuf.FieldOptions();
                switch (object.ctype) {
                default:
                    if (typeof object.ctype === "number") {
                        message.ctype = object.ctype;
                        break;
                    }
                    break;
                case "STRING":
                case 0:
                    message.ctype = 0;
                    break;
                case "CORD":
                case 1:
                    message.ctype = 1;
                    break;
                case "STRING_PIECE":
                case 2:
                    message.ctype = 2;
                    break;
                }
                if (object.packed != null)
                    message.packed = Boolean(object.packed);
                switch (object.jstype) {
                default:
                    if (typeof object.jstype === "number") {
                        message.jstype = object.jstype;
                        break;
                    }
                    break;
                case "JS_NORMAL":
                case 0:
                    message.jstype = 0;
                    break;
                case "JS_STRING":
                case 1:
                    message.jstype = 1;
                    break;
                case "JS_NUMBER":
                case 2:
                    message.jstype = 2;
                    break;
                }
                if (object.lazy != null)
                    message.lazy = Boolean(object.lazy);
                if (object.deprecated != null)
                    message.deprecated = Boolean(object.deprecated);
                if (object.weak != null)
                    message.weak = Boolean(object.weak);
                if (object.uninterpretedOption) {
                    if (!Array.isArray(object.uninterpretedOption))
                        throw TypeError(".google.protobuf.FieldOptions.uninterpretedOption: array expected");
                    message.uninterpretedOption = [];
                    for (let i = 0; i < object.uninterpretedOption.length; ++i) {
                        if (typeof object.uninterpretedOption[i] !== "object")
                            throw TypeError(".google.protobuf.FieldOptions.uninterpretedOption: object expected");
                        message.uninterpretedOption[i] = $root.google.protobuf.UninterpretedOption.fromObject(object.uninterpretedOption[i]);
                    }
                }
                if (object[".nanopb"] != null) {
                    if (typeof object[".nanopb"] !== "object")
                        throw TypeError(".google.protobuf.FieldOptions..nanopb: object expected");
                    message[".nanopb"] = $root.NanoPBOptions.fromObject(object[".nanopb"]);
                }
                return message;
            };

            /**
             * Creates a plain object from a FieldOptions message. Also converts values to other types if specified.
             * @function toObject
             * @memberof google.protobuf.FieldOptions
             * @static
             * @param {google.protobuf.FieldOptions} message FieldOptions
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            FieldOptions.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.arrays || options.defaults)
                    object.uninterpretedOption = [];
                if (options.defaults) {
                    object.ctype = options.enums === String ? "STRING" : 0;
                    object.packed = false;
                    object.deprecated = false;
                    object.lazy = false;
                    object.jstype = options.enums === String ? "JS_NORMAL" : 0;
                    object.weak = false;
                    object[".nanopb"] = null;
                }
                if (message.ctype != null && message.hasOwnProperty("ctype"))
                    object.ctype = options.enums === String ? $root.google.protobuf.FieldOptions.CType[message.ctype] === undefined ? message.ctype : $root.google.protobuf.FieldOptions.CType[message.ctype] : message.ctype;
                if (message.packed != null && message.hasOwnProperty("packed"))
                    object.packed = message.packed;
                if (message.deprecated != null && message.hasOwnProperty("deprecated"))
                    object.deprecated = message.deprecated;
                if (message.lazy != null && message.hasOwnProperty("lazy"))
                    object.lazy = message.lazy;
                if (message.jstype != null && message.hasOwnProperty("jstype"))
                    object.jstype = options.enums === String ? $root.google.protobuf.FieldOptions.JSType[message.jstype] === undefined ? message.jstype : $root.google.protobuf.FieldOptions.JSType[message.jstype] : message.jstype;
                if (message.weak != null && message.hasOwnProperty("weak"))
                    object.weak = message.weak;
                if (message.uninterpretedOption && message.uninterpretedOption.length) {
                    object.uninterpretedOption = [];
                    for (let j = 0; j < message.uninterpretedOption.length; ++j)
                        object.uninterpretedOption[j] = $root.google.protobuf.UninterpretedOption.toObject(message.uninterpretedOption[j], options);
                }
                if (message[".nanopb"] != null && message.hasOwnProperty(".nanopb"))
                    object[".nanopb"] = $root.NanoPBOptions.toObject(message[".nanopb"], options);
                return object;
            };

            /**
             * Converts this FieldOptions to JSON.
             * @function toJSON
             * @memberof google.protobuf.FieldOptions
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            FieldOptions.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for FieldOptions
             * @function getTypeUrl
             * @memberof google.protobuf.FieldOptions
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            FieldOptions.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/google.protobuf.FieldOptions";
            };

            /**
             * CType enum.
             * @name google.protobuf.FieldOptions.CType
             * @enum {number}
             * @property {number} STRING=0 STRING value
             * @property {number} CORD=1 CORD value
             * @property {number} STRING_PIECE=2 STRING_PIECE value
             */
            FieldOptions.CType = (function() {
                const valuesById = {}, values = Object.create(valuesById);
                values[valuesById[0] = "STRING"] = 0;
                values[valuesById[1] = "CORD"] = 1;
                values[valuesById[2] = "STRING_PIECE"] = 2;
                return values;
            })();

            /**
             * JSType enum.
             * @name google.protobuf.FieldOptions.JSType
             * @enum {number}
             * @property {number} JS_NORMAL=0 JS_NORMAL value
             * @property {number} JS_STRING=1 JS_STRING value
             * @property {number} JS_NUMBER=2 JS_NUMBER value
             */
            FieldOptions.JSType = (function() {
                const valuesById = {}, values = Object.create(valuesById);
                values[valuesById[0] = "JS_NORMAL"] = 0;
                values[valuesById[1] = "JS_STRING"] = 1;
                values[valuesById[2] = "JS_NUMBER"] = 2;
                return values;
            })();

            return FieldOptions;
        })();

        protobuf.OneofOptions = (function() {

            /**
             * Properties of an OneofOptions.
             * @memberof google.protobuf
             * @interface IOneofOptions
             * @property {Array.<google.protobuf.IUninterpretedOption>|null} [uninterpretedOption] OneofOptions uninterpretedOption
             */

            /**
             * Constructs a new OneofOptions.
             * @memberof google.protobuf
             * @classdesc Represents an OneofOptions.
             * @implements IOneofOptions
             * @constructor
             * @param {google.protobuf.IOneofOptions=} [properties] Properties to set
             */
            function OneofOptions(properties) {
                this.uninterpretedOption = [];
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * OneofOptions uninterpretedOption.
             * @member {Array.<google.protobuf.IUninterpretedOption>} uninterpretedOption
             * @memberof google.protobuf.OneofOptions
             * @instance
             */
            OneofOptions.prototype.uninterpretedOption = $util.emptyArray;

            /**
             * Creates a new OneofOptions instance using the specified properties.
             * @function create
             * @memberof google.protobuf.OneofOptions
             * @static
             * @param {google.protobuf.IOneofOptions=} [properties] Properties to set
             * @returns {google.protobuf.OneofOptions} OneofOptions instance
             */
            OneofOptions.create = function create(properties) {
                return new OneofOptions(properties);
            };

            /**
             * Encodes the specified OneofOptions message. Does not implicitly {@link google.protobuf.OneofOptions.verify|verify} messages.
             * @function encode
             * @memberof google.protobuf.OneofOptions
             * @static
             * @param {google.protobuf.IOneofOptions} message OneofOptions message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            OneofOptions.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.uninterpretedOption != null && message.uninterpretedOption.length)
                    for (let i = 0; i < message.uninterpretedOption.length; ++i)
                        $root.google.protobuf.UninterpretedOption.encode(message.uninterpretedOption[i], writer.uint32(/* id 999, wireType 2 =*/7994).fork()).ldelim();
                return writer;
            };

            /**
             * Encodes the specified OneofOptions message, length delimited. Does not implicitly {@link google.protobuf.OneofOptions.verify|verify} messages.
             * @function encodeDelimited
             * @memberof google.protobuf.OneofOptions
             * @static
             * @param {google.protobuf.IOneofOptions} message OneofOptions message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            OneofOptions.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes an OneofOptions message from the specified reader or buffer.
             * @function decode
             * @memberof google.protobuf.OneofOptions
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {google.protobuf.OneofOptions} OneofOptions
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            OneofOptions.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.google.protobuf.OneofOptions();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 999: {
                            if (!(message.uninterpretedOption && message.uninterpretedOption.length))
                                message.uninterpretedOption = [];
                            message.uninterpretedOption.push($root.google.protobuf.UninterpretedOption.decode(reader, reader.uint32()));
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes an OneofOptions message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof google.protobuf.OneofOptions
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {google.protobuf.OneofOptions} OneofOptions
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            OneofOptions.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies an OneofOptions message.
             * @function verify
             * @memberof google.protobuf.OneofOptions
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            OneofOptions.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.uninterpretedOption != null && message.hasOwnProperty("uninterpretedOption")) {
                    if (!Array.isArray(message.uninterpretedOption))
                        return "uninterpretedOption: array expected";
                    for (let i = 0; i < message.uninterpretedOption.length; ++i) {
                        let error = $root.google.protobuf.UninterpretedOption.verify(message.uninterpretedOption[i]);
                        if (error)
                            return "uninterpretedOption." + error;
                    }
                }
                return null;
            };

            /**
             * Creates an OneofOptions message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof google.protobuf.OneofOptions
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {google.protobuf.OneofOptions} OneofOptions
             */
            OneofOptions.fromObject = function fromObject(object) {
                if (object instanceof $root.google.protobuf.OneofOptions)
                    return object;
                let message = new $root.google.protobuf.OneofOptions();
                if (object.uninterpretedOption) {
                    if (!Array.isArray(object.uninterpretedOption))
                        throw TypeError(".google.protobuf.OneofOptions.uninterpretedOption: array expected");
                    message.uninterpretedOption = [];
                    for (let i = 0; i < object.uninterpretedOption.length; ++i) {
                        if (typeof object.uninterpretedOption[i] !== "object")
                            throw TypeError(".google.protobuf.OneofOptions.uninterpretedOption: object expected");
                        message.uninterpretedOption[i] = $root.google.protobuf.UninterpretedOption.fromObject(object.uninterpretedOption[i]);
                    }
                }
                return message;
            };

            /**
             * Creates a plain object from an OneofOptions message. Also converts values to other types if specified.
             * @function toObject
             * @memberof google.protobuf.OneofOptions
             * @static
             * @param {google.protobuf.OneofOptions} message OneofOptions
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            OneofOptions.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.arrays || options.defaults)
                    object.uninterpretedOption = [];
                if (message.uninterpretedOption && message.uninterpretedOption.length) {
                    object.uninterpretedOption = [];
                    for (let j = 0; j < message.uninterpretedOption.length; ++j)
                        object.uninterpretedOption[j] = $root.google.protobuf.UninterpretedOption.toObject(message.uninterpretedOption[j], options);
                }
                return object;
            };

            /**
             * Converts this OneofOptions to JSON.
             * @function toJSON
             * @memberof google.protobuf.OneofOptions
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            OneofOptions.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for OneofOptions
             * @function getTypeUrl
             * @memberof google.protobuf.OneofOptions
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            OneofOptions.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/google.protobuf.OneofOptions";
            };

            return OneofOptions;
        })();

        protobuf.EnumOptions = (function() {

            /**
             * Properties of an EnumOptions.
             * @memberof google.protobuf
             * @interface IEnumOptions
             * @property {boolean|null} [allowAlias] EnumOptions allowAlias
             * @property {boolean|null} [deprecated] EnumOptions deprecated
             * @property {Array.<google.protobuf.IUninterpretedOption>|null} [uninterpretedOption] EnumOptions uninterpretedOption
             * @property {INanoPBOptions|null} [".nanopbEnumopt"] EnumOptions .nanopbEnumopt
             */

            /**
             * Constructs a new EnumOptions.
             * @memberof google.protobuf
             * @classdesc Represents an EnumOptions.
             * @implements IEnumOptions
             * @constructor
             * @param {google.protobuf.IEnumOptions=} [properties] Properties to set
             */
            function EnumOptions(properties) {
                this.uninterpretedOption = [];
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * EnumOptions allowAlias.
             * @member {boolean} allowAlias
             * @memberof google.protobuf.EnumOptions
             * @instance
             */
            EnumOptions.prototype.allowAlias = false;

            /**
             * EnumOptions deprecated.
             * @member {boolean} deprecated
             * @memberof google.protobuf.EnumOptions
             * @instance
             */
            EnumOptions.prototype.deprecated = false;

            /**
             * EnumOptions uninterpretedOption.
             * @member {Array.<google.protobuf.IUninterpretedOption>} uninterpretedOption
             * @memberof google.protobuf.EnumOptions
             * @instance
             */
            EnumOptions.prototype.uninterpretedOption = $util.emptyArray;

            /**
             * EnumOptions .nanopbEnumopt.
             * @member {INanoPBOptions|null|undefined} .nanopbEnumopt
             * @memberof google.protobuf.EnumOptions
             * @instance
             */
            EnumOptions.prototype[".nanopbEnumopt"] = null;

            /**
             * Creates a new EnumOptions instance using the specified properties.
             * @function create
             * @memberof google.protobuf.EnumOptions
             * @static
             * @param {google.protobuf.IEnumOptions=} [properties] Properties to set
             * @returns {google.protobuf.EnumOptions} EnumOptions instance
             */
            EnumOptions.create = function create(properties) {
                return new EnumOptions(properties);
            };

            /**
             * Encodes the specified EnumOptions message. Does not implicitly {@link google.protobuf.EnumOptions.verify|verify} messages.
             * @function encode
             * @memberof google.protobuf.EnumOptions
             * @static
             * @param {google.protobuf.IEnumOptions} message EnumOptions message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            EnumOptions.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.allowAlias != null && Object.hasOwnProperty.call(message, "allowAlias"))
                    writer.uint32(/* id 2, wireType 0 =*/16).bool(message.allowAlias);
                if (message.deprecated != null && Object.hasOwnProperty.call(message, "deprecated"))
                    writer.uint32(/* id 3, wireType 0 =*/24).bool(message.deprecated);
                if (message.uninterpretedOption != null && message.uninterpretedOption.length)
                    for (let i = 0; i < message.uninterpretedOption.length; ++i)
                        $root.google.protobuf.UninterpretedOption.encode(message.uninterpretedOption[i], writer.uint32(/* id 999, wireType 2 =*/7994).fork()).ldelim();
                if (message[".nanopbEnumopt"] != null && Object.hasOwnProperty.call(message, ".nanopbEnumopt"))
                    $root.NanoPBOptions.encode(message[".nanopbEnumopt"], writer.uint32(/* id 1010, wireType 2 =*/8082).fork()).ldelim();
                return writer;
            };

            /**
             * Encodes the specified EnumOptions message, length delimited. Does not implicitly {@link google.protobuf.EnumOptions.verify|verify} messages.
             * @function encodeDelimited
             * @memberof google.protobuf.EnumOptions
             * @static
             * @param {google.protobuf.IEnumOptions} message EnumOptions message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            EnumOptions.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes an EnumOptions message from the specified reader or buffer.
             * @function decode
             * @memberof google.protobuf.EnumOptions
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {google.protobuf.EnumOptions} EnumOptions
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            EnumOptions.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.google.protobuf.EnumOptions();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 2: {
                            message.allowAlias = reader.bool();
                            break;
                        }
                    case 3: {
                            message.deprecated = reader.bool();
                            break;
                        }
                    case 999: {
                            if (!(message.uninterpretedOption && message.uninterpretedOption.length))
                                message.uninterpretedOption = [];
                            message.uninterpretedOption.push($root.google.protobuf.UninterpretedOption.decode(reader, reader.uint32()));
                            break;
                        }
                    case 1010: {
                            message[".nanopbEnumopt"] = $root.NanoPBOptions.decode(reader, reader.uint32());
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes an EnumOptions message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof google.protobuf.EnumOptions
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {google.protobuf.EnumOptions} EnumOptions
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            EnumOptions.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies an EnumOptions message.
             * @function verify
             * @memberof google.protobuf.EnumOptions
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            EnumOptions.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.allowAlias != null && message.hasOwnProperty("allowAlias"))
                    if (typeof message.allowAlias !== "boolean")
                        return "allowAlias: boolean expected";
                if (message.deprecated != null && message.hasOwnProperty("deprecated"))
                    if (typeof message.deprecated !== "boolean")
                        return "deprecated: boolean expected";
                if (message.uninterpretedOption != null && message.hasOwnProperty("uninterpretedOption")) {
                    if (!Array.isArray(message.uninterpretedOption))
                        return "uninterpretedOption: array expected";
                    for (let i = 0; i < message.uninterpretedOption.length; ++i) {
                        let error = $root.google.protobuf.UninterpretedOption.verify(message.uninterpretedOption[i]);
                        if (error)
                            return "uninterpretedOption." + error;
                    }
                }
                if (message[".nanopbEnumopt"] != null && message.hasOwnProperty(".nanopbEnumopt")) {
                    let error = $root.NanoPBOptions.verify(message[".nanopbEnumopt"]);
                    if (error)
                        return ".nanopbEnumopt." + error;
                }
                return null;
            };

            /**
             * Creates an EnumOptions message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof google.protobuf.EnumOptions
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {google.protobuf.EnumOptions} EnumOptions
             */
            EnumOptions.fromObject = function fromObject(object) {
                if (object instanceof $root.google.protobuf.EnumOptions)
                    return object;
                let message = new $root.google.protobuf.EnumOptions();
                if (object.allowAlias != null)
                    message.allowAlias = Boolean(object.allowAlias);
                if (object.deprecated != null)
                    message.deprecated = Boolean(object.deprecated);
                if (object.uninterpretedOption) {
                    if (!Array.isArray(object.uninterpretedOption))
                        throw TypeError(".google.protobuf.EnumOptions.uninterpretedOption: array expected");
                    message.uninterpretedOption = [];
                    for (let i = 0; i < object.uninterpretedOption.length; ++i) {
                        if (typeof object.uninterpretedOption[i] !== "object")
                            throw TypeError(".google.protobuf.EnumOptions.uninterpretedOption: object expected");
                        message.uninterpretedOption[i] = $root.google.protobuf.UninterpretedOption.fromObject(object.uninterpretedOption[i]);
                    }
                }
                if (object[".nanopbEnumopt"] != null) {
                    if (typeof object[".nanopbEnumopt"] !== "object")
                        throw TypeError(".google.protobuf.EnumOptions..nanopbEnumopt: object expected");
                    message[".nanopbEnumopt"] = $root.NanoPBOptions.fromObject(object[".nanopbEnumopt"]);
                }
                return message;
            };

            /**
             * Creates a plain object from an EnumOptions message. Also converts values to other types if specified.
             * @function toObject
             * @memberof google.protobuf.EnumOptions
             * @static
             * @param {google.protobuf.EnumOptions} message EnumOptions
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            EnumOptions.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.arrays || options.defaults)
                    object.uninterpretedOption = [];
                if (options.defaults) {
                    object.allowAlias = false;
                    object.deprecated = false;
                    object[".nanopbEnumopt"] = null;
                }
                if (message.allowAlias != null && message.hasOwnProperty("allowAlias"))
                    object.allowAlias = message.allowAlias;
                if (message.deprecated != null && message.hasOwnProperty("deprecated"))
                    object.deprecated = message.deprecated;
                if (message.uninterpretedOption && message.uninterpretedOption.length) {
                    object.uninterpretedOption = [];
                    for (let j = 0; j < message.uninterpretedOption.length; ++j)
                        object.uninterpretedOption[j] = $root.google.protobuf.UninterpretedOption.toObject(message.uninterpretedOption[j], options);
                }
                if (message[".nanopbEnumopt"] != null && message.hasOwnProperty(".nanopbEnumopt"))
                    object[".nanopbEnumopt"] = $root.NanoPBOptions.toObject(message[".nanopbEnumopt"], options);
                return object;
            };

            /**
             * Converts this EnumOptions to JSON.
             * @function toJSON
             * @memberof google.protobuf.EnumOptions
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            EnumOptions.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for EnumOptions
             * @function getTypeUrl
             * @memberof google.protobuf.EnumOptions
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            EnumOptions.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/google.protobuf.EnumOptions";
            };

            return EnumOptions;
        })();

        protobuf.EnumValueOptions = (function() {

            /**
             * Properties of an EnumValueOptions.
             * @memberof google.protobuf
             * @interface IEnumValueOptions
             * @property {boolean|null} [deprecated] EnumValueOptions deprecated
             * @property {Array.<google.protobuf.IUninterpretedOption>|null} [uninterpretedOption] EnumValueOptions uninterpretedOption
             */

            /**
             * Constructs a new EnumValueOptions.
             * @memberof google.protobuf
             * @classdesc Represents an EnumValueOptions.
             * @implements IEnumValueOptions
             * @constructor
             * @param {google.protobuf.IEnumValueOptions=} [properties] Properties to set
             */
            function EnumValueOptions(properties) {
                this.uninterpretedOption = [];
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * EnumValueOptions deprecated.
             * @member {boolean} deprecated
             * @memberof google.protobuf.EnumValueOptions
             * @instance
             */
            EnumValueOptions.prototype.deprecated = false;

            /**
             * EnumValueOptions uninterpretedOption.
             * @member {Array.<google.protobuf.IUninterpretedOption>} uninterpretedOption
             * @memberof google.protobuf.EnumValueOptions
             * @instance
             */
            EnumValueOptions.prototype.uninterpretedOption = $util.emptyArray;

            /**
             * Creates a new EnumValueOptions instance using the specified properties.
             * @function create
             * @memberof google.protobuf.EnumValueOptions
             * @static
             * @param {google.protobuf.IEnumValueOptions=} [properties] Properties to set
             * @returns {google.protobuf.EnumValueOptions} EnumValueOptions instance
             */
            EnumValueOptions.create = function create(properties) {
                return new EnumValueOptions(properties);
            };

            /**
             * Encodes the specified EnumValueOptions message. Does not implicitly {@link google.protobuf.EnumValueOptions.verify|verify} messages.
             * @function encode
             * @memberof google.protobuf.EnumValueOptions
             * @static
             * @param {google.protobuf.IEnumValueOptions} message EnumValueOptions message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            EnumValueOptions.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.deprecated != null && Object.hasOwnProperty.call(message, "deprecated"))
                    writer.uint32(/* id 1, wireType 0 =*/8).bool(message.deprecated);
                if (message.uninterpretedOption != null && message.uninterpretedOption.length)
                    for (let i = 0; i < message.uninterpretedOption.length; ++i)
                        $root.google.protobuf.UninterpretedOption.encode(message.uninterpretedOption[i], writer.uint32(/* id 999, wireType 2 =*/7994).fork()).ldelim();
                return writer;
            };

            /**
             * Encodes the specified EnumValueOptions message, length delimited. Does not implicitly {@link google.protobuf.EnumValueOptions.verify|verify} messages.
             * @function encodeDelimited
             * @memberof google.protobuf.EnumValueOptions
             * @static
             * @param {google.protobuf.IEnumValueOptions} message EnumValueOptions message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            EnumValueOptions.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes an EnumValueOptions message from the specified reader or buffer.
             * @function decode
             * @memberof google.protobuf.EnumValueOptions
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {google.protobuf.EnumValueOptions} EnumValueOptions
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            EnumValueOptions.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.google.protobuf.EnumValueOptions();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.deprecated = reader.bool();
                            break;
                        }
                    case 999: {
                            if (!(message.uninterpretedOption && message.uninterpretedOption.length))
                                message.uninterpretedOption = [];
                            message.uninterpretedOption.push($root.google.protobuf.UninterpretedOption.decode(reader, reader.uint32()));
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes an EnumValueOptions message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof google.protobuf.EnumValueOptions
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {google.protobuf.EnumValueOptions} EnumValueOptions
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            EnumValueOptions.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies an EnumValueOptions message.
             * @function verify
             * @memberof google.protobuf.EnumValueOptions
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            EnumValueOptions.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.deprecated != null && message.hasOwnProperty("deprecated"))
                    if (typeof message.deprecated !== "boolean")
                        return "deprecated: boolean expected";
                if (message.uninterpretedOption != null && message.hasOwnProperty("uninterpretedOption")) {
                    if (!Array.isArray(message.uninterpretedOption))
                        return "uninterpretedOption: array expected";
                    for (let i = 0; i < message.uninterpretedOption.length; ++i) {
                        let error = $root.google.protobuf.UninterpretedOption.verify(message.uninterpretedOption[i]);
                        if (error)
                            return "uninterpretedOption." + error;
                    }
                }
                return null;
            };

            /**
             * Creates an EnumValueOptions message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof google.protobuf.EnumValueOptions
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {google.protobuf.EnumValueOptions} EnumValueOptions
             */
            EnumValueOptions.fromObject = function fromObject(object) {
                if (object instanceof $root.google.protobuf.EnumValueOptions)
                    return object;
                let message = new $root.google.protobuf.EnumValueOptions();
                if (object.deprecated != null)
                    message.deprecated = Boolean(object.deprecated);
                if (object.uninterpretedOption) {
                    if (!Array.isArray(object.uninterpretedOption))
                        throw TypeError(".google.protobuf.EnumValueOptions.uninterpretedOption: array expected");
                    message.uninterpretedOption = [];
                    for (let i = 0; i < object.uninterpretedOption.length; ++i) {
                        if (typeof object.uninterpretedOption[i] !== "object")
                            throw TypeError(".google.protobuf.EnumValueOptions.uninterpretedOption: object expected");
                        message.uninterpretedOption[i] = $root.google.protobuf.UninterpretedOption.fromObject(object.uninterpretedOption[i]);
                    }
                }
                return message;
            };

            /**
             * Creates a plain object from an EnumValueOptions message. Also converts values to other types if specified.
             * @function toObject
             * @memberof google.protobuf.EnumValueOptions
             * @static
             * @param {google.protobuf.EnumValueOptions} message EnumValueOptions
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            EnumValueOptions.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.arrays || options.defaults)
                    object.uninterpretedOption = [];
                if (options.defaults)
                    object.deprecated = false;
                if (message.deprecated != null && message.hasOwnProperty("deprecated"))
                    object.deprecated = message.deprecated;
                if (message.uninterpretedOption && message.uninterpretedOption.length) {
                    object.uninterpretedOption = [];
                    for (let j = 0; j < message.uninterpretedOption.length; ++j)
                        object.uninterpretedOption[j] = $root.google.protobuf.UninterpretedOption.toObject(message.uninterpretedOption[j], options);
                }
                return object;
            };

            /**
             * Converts this EnumValueOptions to JSON.
             * @function toJSON
             * @memberof google.protobuf.EnumValueOptions
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            EnumValueOptions.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for EnumValueOptions
             * @function getTypeUrl
             * @memberof google.protobuf.EnumValueOptions
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            EnumValueOptions.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/google.protobuf.EnumValueOptions";
            };

            return EnumValueOptions;
        })();

        protobuf.ServiceOptions = (function() {

            /**
             * Properties of a ServiceOptions.
             * @memberof google.protobuf
             * @interface IServiceOptions
             * @property {boolean|null} [deprecated] ServiceOptions deprecated
             * @property {Array.<google.protobuf.IUninterpretedOption>|null} [uninterpretedOption] ServiceOptions uninterpretedOption
             */

            /**
             * Constructs a new ServiceOptions.
             * @memberof google.protobuf
             * @classdesc Represents a ServiceOptions.
             * @implements IServiceOptions
             * @constructor
             * @param {google.protobuf.IServiceOptions=} [properties] Properties to set
             */
            function ServiceOptions(properties) {
                this.uninterpretedOption = [];
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * ServiceOptions deprecated.
             * @member {boolean} deprecated
             * @memberof google.protobuf.ServiceOptions
             * @instance
             */
            ServiceOptions.prototype.deprecated = false;

            /**
             * ServiceOptions uninterpretedOption.
             * @member {Array.<google.protobuf.IUninterpretedOption>} uninterpretedOption
             * @memberof google.protobuf.ServiceOptions
             * @instance
             */
            ServiceOptions.prototype.uninterpretedOption = $util.emptyArray;

            /**
             * Creates a new ServiceOptions instance using the specified properties.
             * @function create
             * @memberof google.protobuf.ServiceOptions
             * @static
             * @param {google.protobuf.IServiceOptions=} [properties] Properties to set
             * @returns {google.protobuf.ServiceOptions} ServiceOptions instance
             */
            ServiceOptions.create = function create(properties) {
                return new ServiceOptions(properties);
            };

            /**
             * Encodes the specified ServiceOptions message. Does not implicitly {@link google.protobuf.ServiceOptions.verify|verify} messages.
             * @function encode
             * @memberof google.protobuf.ServiceOptions
             * @static
             * @param {google.protobuf.IServiceOptions} message ServiceOptions message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ServiceOptions.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.deprecated != null && Object.hasOwnProperty.call(message, "deprecated"))
                    writer.uint32(/* id 33, wireType 0 =*/264).bool(message.deprecated);
                if (message.uninterpretedOption != null && message.uninterpretedOption.length)
                    for (let i = 0; i < message.uninterpretedOption.length; ++i)
                        $root.google.protobuf.UninterpretedOption.encode(message.uninterpretedOption[i], writer.uint32(/* id 999, wireType 2 =*/7994).fork()).ldelim();
                return writer;
            };

            /**
             * Encodes the specified ServiceOptions message, length delimited. Does not implicitly {@link google.protobuf.ServiceOptions.verify|verify} messages.
             * @function encodeDelimited
             * @memberof google.protobuf.ServiceOptions
             * @static
             * @param {google.protobuf.IServiceOptions} message ServiceOptions message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ServiceOptions.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a ServiceOptions message from the specified reader or buffer.
             * @function decode
             * @memberof google.protobuf.ServiceOptions
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {google.protobuf.ServiceOptions} ServiceOptions
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ServiceOptions.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.google.protobuf.ServiceOptions();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 33: {
                            message.deprecated = reader.bool();
                            break;
                        }
                    case 999: {
                            if (!(message.uninterpretedOption && message.uninterpretedOption.length))
                                message.uninterpretedOption = [];
                            message.uninterpretedOption.push($root.google.protobuf.UninterpretedOption.decode(reader, reader.uint32()));
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a ServiceOptions message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof google.protobuf.ServiceOptions
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {google.protobuf.ServiceOptions} ServiceOptions
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ServiceOptions.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a ServiceOptions message.
             * @function verify
             * @memberof google.protobuf.ServiceOptions
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            ServiceOptions.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.deprecated != null && message.hasOwnProperty("deprecated"))
                    if (typeof message.deprecated !== "boolean")
                        return "deprecated: boolean expected";
                if (message.uninterpretedOption != null && message.hasOwnProperty("uninterpretedOption")) {
                    if (!Array.isArray(message.uninterpretedOption))
                        return "uninterpretedOption: array expected";
                    for (let i = 0; i < message.uninterpretedOption.length; ++i) {
                        let error = $root.google.protobuf.UninterpretedOption.verify(message.uninterpretedOption[i]);
                        if (error)
                            return "uninterpretedOption." + error;
                    }
                }
                return null;
            };

            /**
             * Creates a ServiceOptions message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof google.protobuf.ServiceOptions
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {google.protobuf.ServiceOptions} ServiceOptions
             */
            ServiceOptions.fromObject = function fromObject(object) {
                if (object instanceof $root.google.protobuf.ServiceOptions)
                    return object;
                let message = new $root.google.protobuf.ServiceOptions();
                if (object.deprecated != null)
                    message.deprecated = Boolean(object.deprecated);
                if (object.uninterpretedOption) {
                    if (!Array.isArray(object.uninterpretedOption))
                        throw TypeError(".google.protobuf.ServiceOptions.uninterpretedOption: array expected");
                    message.uninterpretedOption = [];
                    for (let i = 0; i < object.uninterpretedOption.length; ++i) {
                        if (typeof object.uninterpretedOption[i] !== "object")
                            throw TypeError(".google.protobuf.ServiceOptions.uninterpretedOption: object expected");
                        message.uninterpretedOption[i] = $root.google.protobuf.UninterpretedOption.fromObject(object.uninterpretedOption[i]);
                    }
                }
                return message;
            };

            /**
             * Creates a plain object from a ServiceOptions message. Also converts values to other types if specified.
             * @function toObject
             * @memberof google.protobuf.ServiceOptions
             * @static
             * @param {google.protobuf.ServiceOptions} message ServiceOptions
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            ServiceOptions.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.arrays || options.defaults)
                    object.uninterpretedOption = [];
                if (options.defaults)
                    object.deprecated = false;
                if (message.deprecated != null && message.hasOwnProperty("deprecated"))
                    object.deprecated = message.deprecated;
                if (message.uninterpretedOption && message.uninterpretedOption.length) {
                    object.uninterpretedOption = [];
                    for (let j = 0; j < message.uninterpretedOption.length; ++j)
                        object.uninterpretedOption[j] = $root.google.protobuf.UninterpretedOption.toObject(message.uninterpretedOption[j], options);
                }
                return object;
            };

            /**
             * Converts this ServiceOptions to JSON.
             * @function toJSON
             * @memberof google.protobuf.ServiceOptions
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            ServiceOptions.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for ServiceOptions
             * @function getTypeUrl
             * @memberof google.protobuf.ServiceOptions
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            ServiceOptions.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/google.protobuf.ServiceOptions";
            };

            return ServiceOptions;
        })();

        protobuf.MethodOptions = (function() {

            /**
             * Properties of a MethodOptions.
             * @memberof google.protobuf
             * @interface IMethodOptions
             * @property {boolean|null} [deprecated] MethodOptions deprecated
             * @property {Array.<google.protobuf.IUninterpretedOption>|null} [uninterpretedOption] MethodOptions uninterpretedOption
             */

            /**
             * Constructs a new MethodOptions.
             * @memberof google.protobuf
             * @classdesc Represents a MethodOptions.
             * @implements IMethodOptions
             * @constructor
             * @param {google.protobuf.IMethodOptions=} [properties] Properties to set
             */
            function MethodOptions(properties) {
                this.uninterpretedOption = [];
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * MethodOptions deprecated.
             * @member {boolean} deprecated
             * @memberof google.protobuf.MethodOptions
             * @instance
             */
            MethodOptions.prototype.deprecated = false;

            /**
             * MethodOptions uninterpretedOption.
             * @member {Array.<google.protobuf.IUninterpretedOption>} uninterpretedOption
             * @memberof google.protobuf.MethodOptions
             * @instance
             */
            MethodOptions.prototype.uninterpretedOption = $util.emptyArray;

            /**
             * Creates a new MethodOptions instance using the specified properties.
             * @function create
             * @memberof google.protobuf.MethodOptions
             * @static
             * @param {google.protobuf.IMethodOptions=} [properties] Properties to set
             * @returns {google.protobuf.MethodOptions} MethodOptions instance
             */
            MethodOptions.create = function create(properties) {
                return new MethodOptions(properties);
            };

            /**
             * Encodes the specified MethodOptions message. Does not implicitly {@link google.protobuf.MethodOptions.verify|verify} messages.
             * @function encode
             * @memberof google.protobuf.MethodOptions
             * @static
             * @param {google.protobuf.IMethodOptions} message MethodOptions message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            MethodOptions.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.deprecated != null && Object.hasOwnProperty.call(message, "deprecated"))
                    writer.uint32(/* id 33, wireType 0 =*/264).bool(message.deprecated);
                if (message.uninterpretedOption != null && message.uninterpretedOption.length)
                    for (let i = 0; i < message.uninterpretedOption.length; ++i)
                        $root.google.protobuf.UninterpretedOption.encode(message.uninterpretedOption[i], writer.uint32(/* id 999, wireType 2 =*/7994).fork()).ldelim();
                return writer;
            };

            /**
             * Encodes the specified MethodOptions message, length delimited. Does not implicitly {@link google.protobuf.MethodOptions.verify|verify} messages.
             * @function encodeDelimited
             * @memberof google.protobuf.MethodOptions
             * @static
             * @param {google.protobuf.IMethodOptions} message MethodOptions message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            MethodOptions.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a MethodOptions message from the specified reader or buffer.
             * @function decode
             * @memberof google.protobuf.MethodOptions
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {google.protobuf.MethodOptions} MethodOptions
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            MethodOptions.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.google.protobuf.MethodOptions();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 33: {
                            message.deprecated = reader.bool();
                            break;
                        }
                    case 999: {
                            if (!(message.uninterpretedOption && message.uninterpretedOption.length))
                                message.uninterpretedOption = [];
                            message.uninterpretedOption.push($root.google.protobuf.UninterpretedOption.decode(reader, reader.uint32()));
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a MethodOptions message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof google.protobuf.MethodOptions
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {google.protobuf.MethodOptions} MethodOptions
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            MethodOptions.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a MethodOptions message.
             * @function verify
             * @memberof google.protobuf.MethodOptions
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            MethodOptions.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.deprecated != null && message.hasOwnProperty("deprecated"))
                    if (typeof message.deprecated !== "boolean")
                        return "deprecated: boolean expected";
                if (message.uninterpretedOption != null && message.hasOwnProperty("uninterpretedOption")) {
                    if (!Array.isArray(message.uninterpretedOption))
                        return "uninterpretedOption: array expected";
                    for (let i = 0; i < message.uninterpretedOption.length; ++i) {
                        let error = $root.google.protobuf.UninterpretedOption.verify(message.uninterpretedOption[i]);
                        if (error)
                            return "uninterpretedOption." + error;
                    }
                }
                return null;
            };

            /**
             * Creates a MethodOptions message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof google.protobuf.MethodOptions
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {google.protobuf.MethodOptions} MethodOptions
             */
            MethodOptions.fromObject = function fromObject(object) {
                if (object instanceof $root.google.protobuf.MethodOptions)
                    return object;
                let message = new $root.google.protobuf.MethodOptions();
                if (object.deprecated != null)
                    message.deprecated = Boolean(object.deprecated);
                if (object.uninterpretedOption) {
                    if (!Array.isArray(object.uninterpretedOption))
                        throw TypeError(".google.protobuf.MethodOptions.uninterpretedOption: array expected");
                    message.uninterpretedOption = [];
                    for (let i = 0; i < object.uninterpretedOption.length; ++i) {
                        if (typeof object.uninterpretedOption[i] !== "object")
                            throw TypeError(".google.protobuf.MethodOptions.uninterpretedOption: object expected");
                        message.uninterpretedOption[i] = $root.google.protobuf.UninterpretedOption.fromObject(object.uninterpretedOption[i]);
                    }
                }
                return message;
            };

            /**
             * Creates a plain object from a MethodOptions message. Also converts values to other types if specified.
             * @function toObject
             * @memberof google.protobuf.MethodOptions
             * @static
             * @param {google.protobuf.MethodOptions} message MethodOptions
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            MethodOptions.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.arrays || options.defaults)
                    object.uninterpretedOption = [];
                if (options.defaults)
                    object.deprecated = false;
                if (message.deprecated != null && message.hasOwnProperty("deprecated"))
                    object.deprecated = message.deprecated;
                if (message.uninterpretedOption && message.uninterpretedOption.length) {
                    object.uninterpretedOption = [];
                    for (let j = 0; j < message.uninterpretedOption.length; ++j)
                        object.uninterpretedOption[j] = $root.google.protobuf.UninterpretedOption.toObject(message.uninterpretedOption[j], options);
                }
                return object;
            };

            /**
             * Converts this MethodOptions to JSON.
             * @function toJSON
             * @memberof google.protobuf.MethodOptions
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            MethodOptions.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for MethodOptions
             * @function getTypeUrl
             * @memberof google.protobuf.MethodOptions
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            MethodOptions.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/google.protobuf.MethodOptions";
            };

            return MethodOptions;
        })();

        protobuf.UninterpretedOption = (function() {

            /**
             * Properties of an UninterpretedOption.
             * @memberof google.protobuf
             * @interface IUninterpretedOption
             * @property {Array.<google.protobuf.UninterpretedOption.INamePart>|null} [name] UninterpretedOption name
             * @property {string|null} [identifierValue] UninterpretedOption identifierValue
             * @property {number|Long|null} [positiveIntValue] UninterpretedOption positiveIntValue
             * @property {number|Long|null} [negativeIntValue] UninterpretedOption negativeIntValue
             * @property {number|null} [doubleValue] UninterpretedOption doubleValue
             * @property {Uint8Array|null} [stringValue] UninterpretedOption stringValue
             * @property {string|null} [aggregateValue] UninterpretedOption aggregateValue
             */

            /**
             * Constructs a new UninterpretedOption.
             * @memberof google.protobuf
             * @classdesc Represents an UninterpretedOption.
             * @implements IUninterpretedOption
             * @constructor
             * @param {google.protobuf.IUninterpretedOption=} [properties] Properties to set
             */
            function UninterpretedOption(properties) {
                this.name = [];
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * UninterpretedOption name.
             * @member {Array.<google.protobuf.UninterpretedOption.INamePart>} name
             * @memberof google.protobuf.UninterpretedOption
             * @instance
             */
            UninterpretedOption.prototype.name = $util.emptyArray;

            /**
             * UninterpretedOption identifierValue.
             * @member {string} identifierValue
             * @memberof google.protobuf.UninterpretedOption
             * @instance
             */
            UninterpretedOption.prototype.identifierValue = "";

            /**
             * UninterpretedOption positiveIntValue.
             * @member {number|Long} positiveIntValue
             * @memberof google.protobuf.UninterpretedOption
             * @instance
             */
            UninterpretedOption.prototype.positiveIntValue = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

            /**
             * UninterpretedOption negativeIntValue.
             * @member {number|Long} negativeIntValue
             * @memberof google.protobuf.UninterpretedOption
             * @instance
             */
            UninterpretedOption.prototype.negativeIntValue = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

            /**
             * UninterpretedOption doubleValue.
             * @member {number} doubleValue
             * @memberof google.protobuf.UninterpretedOption
             * @instance
             */
            UninterpretedOption.prototype.doubleValue = 0;

            /**
             * UninterpretedOption stringValue.
             * @member {Uint8Array} stringValue
             * @memberof google.protobuf.UninterpretedOption
             * @instance
             */
            UninterpretedOption.prototype.stringValue = $util.newBuffer([]);

            /**
             * UninterpretedOption aggregateValue.
             * @member {string} aggregateValue
             * @memberof google.protobuf.UninterpretedOption
             * @instance
             */
            UninterpretedOption.prototype.aggregateValue = "";

            /**
             * Creates a new UninterpretedOption instance using the specified properties.
             * @function create
             * @memberof google.protobuf.UninterpretedOption
             * @static
             * @param {google.protobuf.IUninterpretedOption=} [properties] Properties to set
             * @returns {google.protobuf.UninterpretedOption} UninterpretedOption instance
             */
            UninterpretedOption.create = function create(properties) {
                return new UninterpretedOption(properties);
            };

            /**
             * Encodes the specified UninterpretedOption message. Does not implicitly {@link google.protobuf.UninterpretedOption.verify|verify} messages.
             * @function encode
             * @memberof google.protobuf.UninterpretedOption
             * @static
             * @param {google.protobuf.IUninterpretedOption} message UninterpretedOption message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            UninterpretedOption.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.name != null && message.name.length)
                    for (let i = 0; i < message.name.length; ++i)
                        $root.google.protobuf.UninterpretedOption.NamePart.encode(message.name[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                if (message.identifierValue != null && Object.hasOwnProperty.call(message, "identifierValue"))
                    writer.uint32(/* id 3, wireType 2 =*/26).string(message.identifierValue);
                if (message.positiveIntValue != null && Object.hasOwnProperty.call(message, "positiveIntValue"))
                    writer.uint32(/* id 4, wireType 0 =*/32).uint64(message.positiveIntValue);
                if (message.negativeIntValue != null && Object.hasOwnProperty.call(message, "negativeIntValue"))
                    writer.uint32(/* id 5, wireType 0 =*/40).int64(message.negativeIntValue);
                if (message.doubleValue != null && Object.hasOwnProperty.call(message, "doubleValue"))
                    writer.uint32(/* id 6, wireType 1 =*/49).double(message.doubleValue);
                if (message.stringValue != null && Object.hasOwnProperty.call(message, "stringValue"))
                    writer.uint32(/* id 7, wireType 2 =*/58).bytes(message.stringValue);
                if (message.aggregateValue != null && Object.hasOwnProperty.call(message, "aggregateValue"))
                    writer.uint32(/* id 8, wireType 2 =*/66).string(message.aggregateValue);
                return writer;
            };

            /**
             * Encodes the specified UninterpretedOption message, length delimited. Does not implicitly {@link google.protobuf.UninterpretedOption.verify|verify} messages.
             * @function encodeDelimited
             * @memberof google.protobuf.UninterpretedOption
             * @static
             * @param {google.protobuf.IUninterpretedOption} message UninterpretedOption message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            UninterpretedOption.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes an UninterpretedOption message from the specified reader or buffer.
             * @function decode
             * @memberof google.protobuf.UninterpretedOption
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {google.protobuf.UninterpretedOption} UninterpretedOption
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            UninterpretedOption.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.google.protobuf.UninterpretedOption();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 2: {
                            if (!(message.name && message.name.length))
                                message.name = [];
                            message.name.push($root.google.protobuf.UninterpretedOption.NamePart.decode(reader, reader.uint32()));
                            break;
                        }
                    case 3: {
                            message.identifierValue = reader.string();
                            break;
                        }
                    case 4: {
                            message.positiveIntValue = reader.uint64();
                            break;
                        }
                    case 5: {
                            message.negativeIntValue = reader.int64();
                            break;
                        }
                    case 6: {
                            message.doubleValue = reader.double();
                            break;
                        }
                    case 7: {
                            message.stringValue = reader.bytes();
                            break;
                        }
                    case 8: {
                            message.aggregateValue = reader.string();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes an UninterpretedOption message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof google.protobuf.UninterpretedOption
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {google.protobuf.UninterpretedOption} UninterpretedOption
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            UninterpretedOption.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies an UninterpretedOption message.
             * @function verify
             * @memberof google.protobuf.UninterpretedOption
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            UninterpretedOption.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.name != null && message.hasOwnProperty("name")) {
                    if (!Array.isArray(message.name))
                        return "name: array expected";
                    for (let i = 0; i < message.name.length; ++i) {
                        let error = $root.google.protobuf.UninterpretedOption.NamePart.verify(message.name[i]);
                        if (error)
                            return "name." + error;
                    }
                }
                if (message.identifierValue != null && message.hasOwnProperty("identifierValue"))
                    if (!$util.isString(message.identifierValue))
                        return "identifierValue: string expected";
                if (message.positiveIntValue != null && message.hasOwnProperty("positiveIntValue"))
                    if (!$util.isInteger(message.positiveIntValue) && !(message.positiveIntValue && $util.isInteger(message.positiveIntValue.low) && $util.isInteger(message.positiveIntValue.high)))
                        return "positiveIntValue: integer|Long expected";
                if (message.negativeIntValue != null && message.hasOwnProperty("negativeIntValue"))
                    if (!$util.isInteger(message.negativeIntValue) && !(message.negativeIntValue && $util.isInteger(message.negativeIntValue.low) && $util.isInteger(message.negativeIntValue.high)))
                        return "negativeIntValue: integer|Long expected";
                if (message.doubleValue != null && message.hasOwnProperty("doubleValue"))
                    if (typeof message.doubleValue !== "number")
                        return "doubleValue: number expected";
                if (message.stringValue != null && message.hasOwnProperty("stringValue"))
                    if (!(message.stringValue && typeof message.stringValue.length === "number" || $util.isString(message.stringValue)))
                        return "stringValue: buffer expected";
                if (message.aggregateValue != null && message.hasOwnProperty("aggregateValue"))
                    if (!$util.isString(message.aggregateValue))
                        return "aggregateValue: string expected";
                return null;
            };

            /**
             * Creates an UninterpretedOption message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof google.protobuf.UninterpretedOption
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {google.protobuf.UninterpretedOption} UninterpretedOption
             */
            UninterpretedOption.fromObject = function fromObject(object) {
                if (object instanceof $root.google.protobuf.UninterpretedOption)
                    return object;
                let message = new $root.google.protobuf.UninterpretedOption();
                if (object.name) {
                    if (!Array.isArray(object.name))
                        throw TypeError(".google.protobuf.UninterpretedOption.name: array expected");
                    message.name = [];
                    for (let i = 0; i < object.name.length; ++i) {
                        if (typeof object.name[i] !== "object")
                            throw TypeError(".google.protobuf.UninterpretedOption.name: object expected");
                        message.name[i] = $root.google.protobuf.UninterpretedOption.NamePart.fromObject(object.name[i]);
                    }
                }
                if (object.identifierValue != null)
                    message.identifierValue = String(object.identifierValue);
                if (object.positiveIntValue != null)
                    if ($util.Long)
                        (message.positiveIntValue = $util.Long.fromValue(object.positiveIntValue)).unsigned = true;
                    else if (typeof object.positiveIntValue === "string")
                        message.positiveIntValue = parseInt(object.positiveIntValue, 10);
                    else if (typeof object.positiveIntValue === "number")
                        message.positiveIntValue = object.positiveIntValue;
                    else if (typeof object.positiveIntValue === "object")
                        message.positiveIntValue = new $util.LongBits(object.positiveIntValue.low >>> 0, object.positiveIntValue.high >>> 0).toNumber(true);
                if (object.negativeIntValue != null)
                    if ($util.Long)
                        (message.negativeIntValue = $util.Long.fromValue(object.negativeIntValue)).unsigned = false;
                    else if (typeof object.negativeIntValue === "string")
                        message.negativeIntValue = parseInt(object.negativeIntValue, 10);
                    else if (typeof object.negativeIntValue === "number")
                        message.negativeIntValue = object.negativeIntValue;
                    else if (typeof object.negativeIntValue === "object")
                        message.negativeIntValue = new $util.LongBits(object.negativeIntValue.low >>> 0, object.negativeIntValue.high >>> 0).toNumber();
                if (object.doubleValue != null)
                    message.doubleValue = Number(object.doubleValue);
                if (object.stringValue != null)
                    if (typeof object.stringValue === "string")
                        $util.base64.decode(object.stringValue, message.stringValue = $util.newBuffer($util.base64.length(object.stringValue)), 0);
                    else if (object.stringValue.length >= 0)
                        message.stringValue = object.stringValue;
                if (object.aggregateValue != null)
                    message.aggregateValue = String(object.aggregateValue);
                return message;
            };

            /**
             * Creates a plain object from an UninterpretedOption message. Also converts values to other types if specified.
             * @function toObject
             * @memberof google.protobuf.UninterpretedOption
             * @static
             * @param {google.protobuf.UninterpretedOption} message UninterpretedOption
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            UninterpretedOption.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.arrays || options.defaults)
                    object.name = [];
                if (options.defaults) {
                    object.identifierValue = "";
                    if ($util.Long) {
                        let long = new $util.Long(0, 0, true);
                        object.positiveIntValue = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.positiveIntValue = options.longs === String ? "0" : 0;
                    if ($util.Long) {
                        let long = new $util.Long(0, 0, false);
                        object.negativeIntValue = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.negativeIntValue = options.longs === String ? "0" : 0;
                    object.doubleValue = 0;
                    if (options.bytes === String)
                        object.stringValue = "";
                    else {
                        object.stringValue = [];
                        if (options.bytes !== Array)
                            object.stringValue = $util.newBuffer(object.stringValue);
                    }
                    object.aggregateValue = "";
                }
                if (message.name && message.name.length) {
                    object.name = [];
                    for (let j = 0; j < message.name.length; ++j)
                        object.name[j] = $root.google.protobuf.UninterpretedOption.NamePart.toObject(message.name[j], options);
                }
                if (message.identifierValue != null && message.hasOwnProperty("identifierValue"))
                    object.identifierValue = message.identifierValue;
                if (message.positiveIntValue != null && message.hasOwnProperty("positiveIntValue"))
                    if (typeof message.positiveIntValue === "number")
                        object.positiveIntValue = options.longs === String ? String(message.positiveIntValue) : message.positiveIntValue;
                    else
                        object.positiveIntValue = options.longs === String ? $util.Long.prototype.toString.call(message.positiveIntValue) : options.longs === Number ? new $util.LongBits(message.positiveIntValue.low >>> 0, message.positiveIntValue.high >>> 0).toNumber(true) : message.positiveIntValue;
                if (message.negativeIntValue != null && message.hasOwnProperty("negativeIntValue"))
                    if (typeof message.negativeIntValue === "number")
                        object.negativeIntValue = options.longs === String ? String(message.negativeIntValue) : message.negativeIntValue;
                    else
                        object.negativeIntValue = options.longs === String ? $util.Long.prototype.toString.call(message.negativeIntValue) : options.longs === Number ? new $util.LongBits(message.negativeIntValue.low >>> 0, message.negativeIntValue.high >>> 0).toNumber() : message.negativeIntValue;
                if (message.doubleValue != null && message.hasOwnProperty("doubleValue"))
                    object.doubleValue = options.json && !isFinite(message.doubleValue) ? String(message.doubleValue) : message.doubleValue;
                if (message.stringValue != null && message.hasOwnProperty("stringValue"))
                    object.stringValue = options.bytes === String ? $util.base64.encode(message.stringValue, 0, message.stringValue.length) : options.bytes === Array ? Array.prototype.slice.call(message.stringValue) : message.stringValue;
                if (message.aggregateValue != null && message.hasOwnProperty("aggregateValue"))
                    object.aggregateValue = message.aggregateValue;
                return object;
            };

            /**
             * Converts this UninterpretedOption to JSON.
             * @function toJSON
             * @memberof google.protobuf.UninterpretedOption
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            UninterpretedOption.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for UninterpretedOption
             * @function getTypeUrl
             * @memberof google.protobuf.UninterpretedOption
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            UninterpretedOption.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/google.protobuf.UninterpretedOption";
            };

            UninterpretedOption.NamePart = (function() {

                /**
                 * Properties of a NamePart.
                 * @memberof google.protobuf.UninterpretedOption
                 * @interface INamePart
                 * @property {string} namePart NamePart namePart
                 * @property {boolean} isExtension NamePart isExtension
                 */

                /**
                 * Constructs a new NamePart.
                 * @memberof google.protobuf.UninterpretedOption
                 * @classdesc Represents a NamePart.
                 * @implements INamePart
                 * @constructor
                 * @param {google.protobuf.UninterpretedOption.INamePart=} [properties] Properties to set
                 */
                function NamePart(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * NamePart namePart.
                 * @member {string} namePart
                 * @memberof google.protobuf.UninterpretedOption.NamePart
                 * @instance
                 */
                NamePart.prototype.namePart = "";

                /**
                 * NamePart isExtension.
                 * @member {boolean} isExtension
                 * @memberof google.protobuf.UninterpretedOption.NamePart
                 * @instance
                 */
                NamePart.prototype.isExtension = false;

                /**
                 * Creates a new NamePart instance using the specified properties.
                 * @function create
                 * @memberof google.protobuf.UninterpretedOption.NamePart
                 * @static
                 * @param {google.protobuf.UninterpretedOption.INamePart=} [properties] Properties to set
                 * @returns {google.protobuf.UninterpretedOption.NamePart} NamePart instance
                 */
                NamePart.create = function create(properties) {
                    return new NamePart(properties);
                };

                /**
                 * Encodes the specified NamePart message. Does not implicitly {@link google.protobuf.UninterpretedOption.NamePart.verify|verify} messages.
                 * @function encode
                 * @memberof google.protobuf.UninterpretedOption.NamePart
                 * @static
                 * @param {google.protobuf.UninterpretedOption.INamePart} message NamePart message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                NamePart.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.namePart);
                    writer.uint32(/* id 2, wireType 0 =*/16).bool(message.isExtension);
                    return writer;
                };

                /**
                 * Encodes the specified NamePart message, length delimited. Does not implicitly {@link google.protobuf.UninterpretedOption.NamePart.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof google.protobuf.UninterpretedOption.NamePart
                 * @static
                 * @param {google.protobuf.UninterpretedOption.INamePart} message NamePart message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                NamePart.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a NamePart message from the specified reader or buffer.
                 * @function decode
                 * @memberof google.protobuf.UninterpretedOption.NamePart
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {google.protobuf.UninterpretedOption.NamePart} NamePart
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                NamePart.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    let end = length === undefined ? reader.len : reader.pos + length, message = new $root.google.protobuf.UninterpretedOption.NamePart();
                    while (reader.pos < end) {
                        let tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.namePart = reader.string();
                                break;
                            }
                        case 2: {
                                message.isExtension = reader.bool();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    if (!message.hasOwnProperty("namePart"))
                        throw $util.ProtocolError("missing required 'namePart'", { instance: message });
                    if (!message.hasOwnProperty("isExtension"))
                        throw $util.ProtocolError("missing required 'isExtension'", { instance: message });
                    return message;
                };

                /**
                 * Decodes a NamePart message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof google.protobuf.UninterpretedOption.NamePart
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {google.protobuf.UninterpretedOption.NamePart} NamePart
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                NamePart.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a NamePart message.
                 * @function verify
                 * @memberof google.protobuf.UninterpretedOption.NamePart
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                NamePart.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (!$util.isString(message.namePart))
                        return "namePart: string expected";
                    if (typeof message.isExtension !== "boolean")
                        return "isExtension: boolean expected";
                    return null;
                };

                /**
                 * Creates a NamePart message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof google.protobuf.UninterpretedOption.NamePart
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {google.protobuf.UninterpretedOption.NamePart} NamePart
                 */
                NamePart.fromObject = function fromObject(object) {
                    if (object instanceof $root.google.protobuf.UninterpretedOption.NamePart)
                        return object;
                    let message = new $root.google.protobuf.UninterpretedOption.NamePart();
                    if (object.namePart != null)
                        message.namePart = String(object.namePart);
                    if (object.isExtension != null)
                        message.isExtension = Boolean(object.isExtension);
                    return message;
                };

                /**
                 * Creates a plain object from a NamePart message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof google.protobuf.UninterpretedOption.NamePart
                 * @static
                 * @param {google.protobuf.UninterpretedOption.NamePart} message NamePart
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                NamePart.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults) {
                        object.namePart = "";
                        object.isExtension = false;
                    }
                    if (message.namePart != null && message.hasOwnProperty("namePart"))
                        object.namePart = message.namePart;
                    if (message.isExtension != null && message.hasOwnProperty("isExtension"))
                        object.isExtension = message.isExtension;
                    return object;
                };

                /**
                 * Converts this NamePart to JSON.
                 * @function toJSON
                 * @memberof google.protobuf.UninterpretedOption.NamePart
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                NamePart.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for NamePart
                 * @function getTypeUrl
                 * @memberof google.protobuf.UninterpretedOption.NamePart
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                NamePart.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/google.protobuf.UninterpretedOption.NamePart";
                };

                return NamePart;
            })();

            return UninterpretedOption;
        })();

        protobuf.SourceCodeInfo = (function() {

            /**
             * Properties of a SourceCodeInfo.
             * @memberof google.protobuf
             * @interface ISourceCodeInfo
             * @property {Array.<google.protobuf.SourceCodeInfo.ILocation>|null} [location] SourceCodeInfo location
             */

            /**
             * Constructs a new SourceCodeInfo.
             * @memberof google.protobuf
             * @classdesc Represents a SourceCodeInfo.
             * @implements ISourceCodeInfo
             * @constructor
             * @param {google.protobuf.ISourceCodeInfo=} [properties] Properties to set
             */
            function SourceCodeInfo(properties) {
                this.location = [];
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * SourceCodeInfo location.
             * @member {Array.<google.protobuf.SourceCodeInfo.ILocation>} location
             * @memberof google.protobuf.SourceCodeInfo
             * @instance
             */
            SourceCodeInfo.prototype.location = $util.emptyArray;

            /**
             * Creates a new SourceCodeInfo instance using the specified properties.
             * @function create
             * @memberof google.protobuf.SourceCodeInfo
             * @static
             * @param {google.protobuf.ISourceCodeInfo=} [properties] Properties to set
             * @returns {google.protobuf.SourceCodeInfo} SourceCodeInfo instance
             */
            SourceCodeInfo.create = function create(properties) {
                return new SourceCodeInfo(properties);
            };

            /**
             * Encodes the specified SourceCodeInfo message. Does not implicitly {@link google.protobuf.SourceCodeInfo.verify|verify} messages.
             * @function encode
             * @memberof google.protobuf.SourceCodeInfo
             * @static
             * @param {google.protobuf.ISourceCodeInfo} message SourceCodeInfo message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            SourceCodeInfo.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.location != null && message.location.length)
                    for (let i = 0; i < message.location.length; ++i)
                        $root.google.protobuf.SourceCodeInfo.Location.encode(message.location[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                return writer;
            };

            /**
             * Encodes the specified SourceCodeInfo message, length delimited. Does not implicitly {@link google.protobuf.SourceCodeInfo.verify|verify} messages.
             * @function encodeDelimited
             * @memberof google.protobuf.SourceCodeInfo
             * @static
             * @param {google.protobuf.ISourceCodeInfo} message SourceCodeInfo message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            SourceCodeInfo.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a SourceCodeInfo message from the specified reader or buffer.
             * @function decode
             * @memberof google.protobuf.SourceCodeInfo
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {google.protobuf.SourceCodeInfo} SourceCodeInfo
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            SourceCodeInfo.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.google.protobuf.SourceCodeInfo();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            if (!(message.location && message.location.length))
                                message.location = [];
                            message.location.push($root.google.protobuf.SourceCodeInfo.Location.decode(reader, reader.uint32()));
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a SourceCodeInfo message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof google.protobuf.SourceCodeInfo
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {google.protobuf.SourceCodeInfo} SourceCodeInfo
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            SourceCodeInfo.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a SourceCodeInfo message.
             * @function verify
             * @memberof google.protobuf.SourceCodeInfo
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            SourceCodeInfo.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.location != null && message.hasOwnProperty("location")) {
                    if (!Array.isArray(message.location))
                        return "location: array expected";
                    for (let i = 0; i < message.location.length; ++i) {
                        let error = $root.google.protobuf.SourceCodeInfo.Location.verify(message.location[i]);
                        if (error)
                            return "location." + error;
                    }
                }
                return null;
            };

            /**
             * Creates a SourceCodeInfo message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof google.protobuf.SourceCodeInfo
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {google.protobuf.SourceCodeInfo} SourceCodeInfo
             */
            SourceCodeInfo.fromObject = function fromObject(object) {
                if (object instanceof $root.google.protobuf.SourceCodeInfo)
                    return object;
                let message = new $root.google.protobuf.SourceCodeInfo();
                if (object.location) {
                    if (!Array.isArray(object.location))
                        throw TypeError(".google.protobuf.SourceCodeInfo.location: array expected");
                    message.location = [];
                    for (let i = 0; i < object.location.length; ++i) {
                        if (typeof object.location[i] !== "object")
                            throw TypeError(".google.protobuf.SourceCodeInfo.location: object expected");
                        message.location[i] = $root.google.protobuf.SourceCodeInfo.Location.fromObject(object.location[i]);
                    }
                }
                return message;
            };

            /**
             * Creates a plain object from a SourceCodeInfo message. Also converts values to other types if specified.
             * @function toObject
             * @memberof google.protobuf.SourceCodeInfo
             * @static
             * @param {google.protobuf.SourceCodeInfo} message SourceCodeInfo
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            SourceCodeInfo.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.arrays || options.defaults)
                    object.location = [];
                if (message.location && message.location.length) {
                    object.location = [];
                    for (let j = 0; j < message.location.length; ++j)
                        object.location[j] = $root.google.protobuf.SourceCodeInfo.Location.toObject(message.location[j], options);
                }
                return object;
            };

            /**
             * Converts this SourceCodeInfo to JSON.
             * @function toJSON
             * @memberof google.protobuf.SourceCodeInfo
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            SourceCodeInfo.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for SourceCodeInfo
             * @function getTypeUrl
             * @memberof google.protobuf.SourceCodeInfo
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            SourceCodeInfo.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/google.protobuf.SourceCodeInfo";
            };

            SourceCodeInfo.Location = (function() {

                /**
                 * Properties of a Location.
                 * @memberof google.protobuf.SourceCodeInfo
                 * @interface ILocation
                 * @property {Array.<number>|null} [path] Location path
                 * @property {Array.<number>|null} [span] Location span
                 * @property {string|null} [leadingComments] Location leadingComments
                 * @property {string|null} [trailingComments] Location trailingComments
                 * @property {Array.<string>|null} [leadingDetachedComments] Location leadingDetachedComments
                 */

                /**
                 * Constructs a new Location.
                 * @memberof google.protobuf.SourceCodeInfo
                 * @classdesc Represents a Location.
                 * @implements ILocation
                 * @constructor
                 * @param {google.protobuf.SourceCodeInfo.ILocation=} [properties] Properties to set
                 */
                function Location(properties) {
                    this.path = [];
                    this.span = [];
                    this.leadingDetachedComments = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Location path.
                 * @member {Array.<number>} path
                 * @memberof google.protobuf.SourceCodeInfo.Location
                 * @instance
                 */
                Location.prototype.path = $util.emptyArray;

                /**
                 * Location span.
                 * @member {Array.<number>} span
                 * @memberof google.protobuf.SourceCodeInfo.Location
                 * @instance
                 */
                Location.prototype.span = $util.emptyArray;

                /**
                 * Location leadingComments.
                 * @member {string} leadingComments
                 * @memberof google.protobuf.SourceCodeInfo.Location
                 * @instance
                 */
                Location.prototype.leadingComments = "";

                /**
                 * Location trailingComments.
                 * @member {string} trailingComments
                 * @memberof google.protobuf.SourceCodeInfo.Location
                 * @instance
                 */
                Location.prototype.trailingComments = "";

                /**
                 * Location leadingDetachedComments.
                 * @member {Array.<string>} leadingDetachedComments
                 * @memberof google.protobuf.SourceCodeInfo.Location
                 * @instance
                 */
                Location.prototype.leadingDetachedComments = $util.emptyArray;

                /**
                 * Creates a new Location instance using the specified properties.
                 * @function create
                 * @memberof google.protobuf.SourceCodeInfo.Location
                 * @static
                 * @param {google.protobuf.SourceCodeInfo.ILocation=} [properties] Properties to set
                 * @returns {google.protobuf.SourceCodeInfo.Location} Location instance
                 */
                Location.create = function create(properties) {
                    return new Location(properties);
                };

                /**
                 * Encodes the specified Location message. Does not implicitly {@link google.protobuf.SourceCodeInfo.Location.verify|verify} messages.
                 * @function encode
                 * @memberof google.protobuf.SourceCodeInfo.Location
                 * @static
                 * @param {google.protobuf.SourceCodeInfo.ILocation} message Location message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Location.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.path != null && message.path.length) {
                        writer.uint32(/* id 1, wireType 2 =*/10).fork();
                        for (let i = 0; i < message.path.length; ++i)
                            writer.int32(message.path[i]);
                        writer.ldelim();
                    }
                    if (message.span != null && message.span.length) {
                        writer.uint32(/* id 2, wireType 2 =*/18).fork();
                        for (let i = 0; i < message.span.length; ++i)
                            writer.int32(message.span[i]);
                        writer.ldelim();
                    }
                    if (message.leadingComments != null && Object.hasOwnProperty.call(message, "leadingComments"))
                        writer.uint32(/* id 3, wireType 2 =*/26).string(message.leadingComments);
                    if (message.trailingComments != null && Object.hasOwnProperty.call(message, "trailingComments"))
                        writer.uint32(/* id 4, wireType 2 =*/34).string(message.trailingComments);
                    if (message.leadingDetachedComments != null && message.leadingDetachedComments.length)
                        for (let i = 0; i < message.leadingDetachedComments.length; ++i)
                            writer.uint32(/* id 6, wireType 2 =*/50).string(message.leadingDetachedComments[i]);
                    return writer;
                };

                /**
                 * Encodes the specified Location message, length delimited. Does not implicitly {@link google.protobuf.SourceCodeInfo.Location.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof google.protobuf.SourceCodeInfo.Location
                 * @static
                 * @param {google.protobuf.SourceCodeInfo.ILocation} message Location message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Location.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a Location message from the specified reader or buffer.
                 * @function decode
                 * @memberof google.protobuf.SourceCodeInfo.Location
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {google.protobuf.SourceCodeInfo.Location} Location
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Location.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    let end = length === undefined ? reader.len : reader.pos + length, message = new $root.google.protobuf.SourceCodeInfo.Location();
                    while (reader.pos < end) {
                        let tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                if (!(message.path && message.path.length))
                                    message.path = [];
                                if ((tag & 7) === 2) {
                                    let end2 = reader.uint32() + reader.pos;
                                    while (reader.pos < end2)
                                        message.path.push(reader.int32());
                                } else
                                    message.path.push(reader.int32());
                                break;
                            }
                        case 2: {
                                if (!(message.span && message.span.length))
                                    message.span = [];
                                if ((tag & 7) === 2) {
                                    let end2 = reader.uint32() + reader.pos;
                                    while (reader.pos < end2)
                                        message.span.push(reader.int32());
                                } else
                                    message.span.push(reader.int32());
                                break;
                            }
                        case 3: {
                                message.leadingComments = reader.string();
                                break;
                            }
                        case 4: {
                                message.trailingComments = reader.string();
                                break;
                            }
                        case 6: {
                                if (!(message.leadingDetachedComments && message.leadingDetachedComments.length))
                                    message.leadingDetachedComments = [];
                                message.leadingDetachedComments.push(reader.string());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a Location message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof google.protobuf.SourceCodeInfo.Location
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {google.protobuf.SourceCodeInfo.Location} Location
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Location.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a Location message.
                 * @function verify
                 * @memberof google.protobuf.SourceCodeInfo.Location
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Location.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.path != null && message.hasOwnProperty("path")) {
                        if (!Array.isArray(message.path))
                            return "path: array expected";
                        for (let i = 0; i < message.path.length; ++i)
                            if (!$util.isInteger(message.path[i]))
                                return "path: integer[] expected";
                    }
                    if (message.span != null && message.hasOwnProperty("span")) {
                        if (!Array.isArray(message.span))
                            return "span: array expected";
                        for (let i = 0; i < message.span.length; ++i)
                            if (!$util.isInteger(message.span[i]))
                                return "span: integer[] expected";
                    }
                    if (message.leadingComments != null && message.hasOwnProperty("leadingComments"))
                        if (!$util.isString(message.leadingComments))
                            return "leadingComments: string expected";
                    if (message.trailingComments != null && message.hasOwnProperty("trailingComments"))
                        if (!$util.isString(message.trailingComments))
                            return "trailingComments: string expected";
                    if (message.leadingDetachedComments != null && message.hasOwnProperty("leadingDetachedComments")) {
                        if (!Array.isArray(message.leadingDetachedComments))
                            return "leadingDetachedComments: array expected";
                        for (let i = 0; i < message.leadingDetachedComments.length; ++i)
                            if (!$util.isString(message.leadingDetachedComments[i]))
                                return "leadingDetachedComments: string[] expected";
                    }
                    return null;
                };

                /**
                 * Creates a Location message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof google.protobuf.SourceCodeInfo.Location
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {google.protobuf.SourceCodeInfo.Location} Location
                 */
                Location.fromObject = function fromObject(object) {
                    if (object instanceof $root.google.protobuf.SourceCodeInfo.Location)
                        return object;
                    let message = new $root.google.protobuf.SourceCodeInfo.Location();
                    if (object.path) {
                        if (!Array.isArray(object.path))
                            throw TypeError(".google.protobuf.SourceCodeInfo.Location.path: array expected");
                        message.path = [];
                        for (let i = 0; i < object.path.length; ++i)
                            message.path[i] = object.path[i] | 0;
                    }
                    if (object.span) {
                        if (!Array.isArray(object.span))
                            throw TypeError(".google.protobuf.SourceCodeInfo.Location.span: array expected");
                        message.span = [];
                        for (let i = 0; i < object.span.length; ++i)
                            message.span[i] = object.span[i] | 0;
                    }
                    if (object.leadingComments != null)
                        message.leadingComments = String(object.leadingComments);
                    if (object.trailingComments != null)
                        message.trailingComments = String(object.trailingComments);
                    if (object.leadingDetachedComments) {
                        if (!Array.isArray(object.leadingDetachedComments))
                            throw TypeError(".google.protobuf.SourceCodeInfo.Location.leadingDetachedComments: array expected");
                        message.leadingDetachedComments = [];
                        for (let i = 0; i < object.leadingDetachedComments.length; ++i)
                            message.leadingDetachedComments[i] = String(object.leadingDetachedComments[i]);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a Location message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof google.protobuf.SourceCodeInfo.Location
                 * @static
                 * @param {google.protobuf.SourceCodeInfo.Location} message Location
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Location.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults) {
                        object.path = [];
                        object.span = [];
                        object.leadingDetachedComments = [];
                    }
                    if (options.defaults) {
                        object.leadingComments = "";
                        object.trailingComments = "";
                    }
                    if (message.path && message.path.length) {
                        object.path = [];
                        for (let j = 0; j < message.path.length; ++j)
                            object.path[j] = message.path[j];
                    }
                    if (message.span && message.span.length) {
                        object.span = [];
                        for (let j = 0; j < message.span.length; ++j)
                            object.span[j] = message.span[j];
                    }
                    if (message.leadingComments != null && message.hasOwnProperty("leadingComments"))
                        object.leadingComments = message.leadingComments;
                    if (message.trailingComments != null && message.hasOwnProperty("trailingComments"))
                        object.trailingComments = message.trailingComments;
                    if (message.leadingDetachedComments && message.leadingDetachedComments.length) {
                        object.leadingDetachedComments = [];
                        for (let j = 0; j < message.leadingDetachedComments.length; ++j)
                            object.leadingDetachedComments[j] = message.leadingDetachedComments[j];
                    }
                    return object;
                };

                /**
                 * Converts this Location to JSON.
                 * @function toJSON
                 * @memberof google.protobuf.SourceCodeInfo.Location
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Location.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for Location
                 * @function getTypeUrl
                 * @memberof google.protobuf.SourceCodeInfo.Location
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                Location.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/google.protobuf.SourceCodeInfo.Location";
                };

                return Location;
            })();

            return SourceCodeInfo;
        })();

        protobuf.GeneratedCodeInfo = (function() {

            /**
             * Properties of a GeneratedCodeInfo.
             * @memberof google.protobuf
             * @interface IGeneratedCodeInfo
             * @property {Array.<google.protobuf.GeneratedCodeInfo.IAnnotation>|null} [annotation] GeneratedCodeInfo annotation
             */

            /**
             * Constructs a new GeneratedCodeInfo.
             * @memberof google.protobuf
             * @classdesc Represents a GeneratedCodeInfo.
             * @implements IGeneratedCodeInfo
             * @constructor
             * @param {google.protobuf.IGeneratedCodeInfo=} [properties] Properties to set
             */
            function GeneratedCodeInfo(properties) {
                this.annotation = [];
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * GeneratedCodeInfo annotation.
             * @member {Array.<google.protobuf.GeneratedCodeInfo.IAnnotation>} annotation
             * @memberof google.protobuf.GeneratedCodeInfo
             * @instance
             */
            GeneratedCodeInfo.prototype.annotation = $util.emptyArray;

            /**
             * Creates a new GeneratedCodeInfo instance using the specified properties.
             * @function create
             * @memberof google.protobuf.GeneratedCodeInfo
             * @static
             * @param {google.protobuf.IGeneratedCodeInfo=} [properties] Properties to set
             * @returns {google.protobuf.GeneratedCodeInfo} GeneratedCodeInfo instance
             */
            GeneratedCodeInfo.create = function create(properties) {
                return new GeneratedCodeInfo(properties);
            };

            /**
             * Encodes the specified GeneratedCodeInfo message. Does not implicitly {@link google.protobuf.GeneratedCodeInfo.verify|verify} messages.
             * @function encode
             * @memberof google.protobuf.GeneratedCodeInfo
             * @static
             * @param {google.protobuf.IGeneratedCodeInfo} message GeneratedCodeInfo message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            GeneratedCodeInfo.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.annotation != null && message.annotation.length)
                    for (let i = 0; i < message.annotation.length; ++i)
                        $root.google.protobuf.GeneratedCodeInfo.Annotation.encode(message.annotation[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                return writer;
            };

            /**
             * Encodes the specified GeneratedCodeInfo message, length delimited. Does not implicitly {@link google.protobuf.GeneratedCodeInfo.verify|verify} messages.
             * @function encodeDelimited
             * @memberof google.protobuf.GeneratedCodeInfo
             * @static
             * @param {google.protobuf.IGeneratedCodeInfo} message GeneratedCodeInfo message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            GeneratedCodeInfo.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a GeneratedCodeInfo message from the specified reader or buffer.
             * @function decode
             * @memberof google.protobuf.GeneratedCodeInfo
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {google.protobuf.GeneratedCodeInfo} GeneratedCodeInfo
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            GeneratedCodeInfo.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.google.protobuf.GeneratedCodeInfo();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            if (!(message.annotation && message.annotation.length))
                                message.annotation = [];
                            message.annotation.push($root.google.protobuf.GeneratedCodeInfo.Annotation.decode(reader, reader.uint32()));
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a GeneratedCodeInfo message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof google.protobuf.GeneratedCodeInfo
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {google.protobuf.GeneratedCodeInfo} GeneratedCodeInfo
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            GeneratedCodeInfo.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a GeneratedCodeInfo message.
             * @function verify
             * @memberof google.protobuf.GeneratedCodeInfo
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            GeneratedCodeInfo.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.annotation != null && message.hasOwnProperty("annotation")) {
                    if (!Array.isArray(message.annotation))
                        return "annotation: array expected";
                    for (let i = 0; i < message.annotation.length; ++i) {
                        let error = $root.google.protobuf.GeneratedCodeInfo.Annotation.verify(message.annotation[i]);
                        if (error)
                            return "annotation." + error;
                    }
                }
                return null;
            };

            /**
             * Creates a GeneratedCodeInfo message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof google.protobuf.GeneratedCodeInfo
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {google.protobuf.GeneratedCodeInfo} GeneratedCodeInfo
             */
            GeneratedCodeInfo.fromObject = function fromObject(object) {
                if (object instanceof $root.google.protobuf.GeneratedCodeInfo)
                    return object;
                let message = new $root.google.protobuf.GeneratedCodeInfo();
                if (object.annotation) {
                    if (!Array.isArray(object.annotation))
                        throw TypeError(".google.protobuf.GeneratedCodeInfo.annotation: array expected");
                    message.annotation = [];
                    for (let i = 0; i < object.annotation.length; ++i) {
                        if (typeof object.annotation[i] !== "object")
                            throw TypeError(".google.protobuf.GeneratedCodeInfo.annotation: object expected");
                        message.annotation[i] = $root.google.protobuf.GeneratedCodeInfo.Annotation.fromObject(object.annotation[i]);
                    }
                }
                return message;
            };

            /**
             * Creates a plain object from a GeneratedCodeInfo message. Also converts values to other types if specified.
             * @function toObject
             * @memberof google.protobuf.GeneratedCodeInfo
             * @static
             * @param {google.protobuf.GeneratedCodeInfo} message GeneratedCodeInfo
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            GeneratedCodeInfo.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.arrays || options.defaults)
                    object.annotation = [];
                if (message.annotation && message.annotation.length) {
                    object.annotation = [];
                    for (let j = 0; j < message.annotation.length; ++j)
                        object.annotation[j] = $root.google.protobuf.GeneratedCodeInfo.Annotation.toObject(message.annotation[j], options);
                }
                return object;
            };

            /**
             * Converts this GeneratedCodeInfo to JSON.
             * @function toJSON
             * @memberof google.protobuf.GeneratedCodeInfo
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            GeneratedCodeInfo.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for GeneratedCodeInfo
             * @function getTypeUrl
             * @memberof google.protobuf.GeneratedCodeInfo
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            GeneratedCodeInfo.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/google.protobuf.GeneratedCodeInfo";
            };

            GeneratedCodeInfo.Annotation = (function() {

                /**
                 * Properties of an Annotation.
                 * @memberof google.protobuf.GeneratedCodeInfo
                 * @interface IAnnotation
                 * @property {Array.<number>|null} [path] Annotation path
                 * @property {string|null} [sourceFile] Annotation sourceFile
                 * @property {number|null} [begin] Annotation begin
                 * @property {number|null} [end] Annotation end
                 */

                /**
                 * Constructs a new Annotation.
                 * @memberof google.protobuf.GeneratedCodeInfo
                 * @classdesc Represents an Annotation.
                 * @implements IAnnotation
                 * @constructor
                 * @param {google.protobuf.GeneratedCodeInfo.IAnnotation=} [properties] Properties to set
                 */
                function Annotation(properties) {
                    this.path = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Annotation path.
                 * @member {Array.<number>} path
                 * @memberof google.protobuf.GeneratedCodeInfo.Annotation
                 * @instance
                 */
                Annotation.prototype.path = $util.emptyArray;

                /**
                 * Annotation sourceFile.
                 * @member {string} sourceFile
                 * @memberof google.protobuf.GeneratedCodeInfo.Annotation
                 * @instance
                 */
                Annotation.prototype.sourceFile = "";

                /**
                 * Annotation begin.
                 * @member {number} begin
                 * @memberof google.protobuf.GeneratedCodeInfo.Annotation
                 * @instance
                 */
                Annotation.prototype.begin = 0;

                /**
                 * Annotation end.
                 * @member {number} end
                 * @memberof google.protobuf.GeneratedCodeInfo.Annotation
                 * @instance
                 */
                Annotation.prototype.end = 0;

                /**
                 * Creates a new Annotation instance using the specified properties.
                 * @function create
                 * @memberof google.protobuf.GeneratedCodeInfo.Annotation
                 * @static
                 * @param {google.protobuf.GeneratedCodeInfo.IAnnotation=} [properties] Properties to set
                 * @returns {google.protobuf.GeneratedCodeInfo.Annotation} Annotation instance
                 */
                Annotation.create = function create(properties) {
                    return new Annotation(properties);
                };

                /**
                 * Encodes the specified Annotation message. Does not implicitly {@link google.protobuf.GeneratedCodeInfo.Annotation.verify|verify} messages.
                 * @function encode
                 * @memberof google.protobuf.GeneratedCodeInfo.Annotation
                 * @static
                 * @param {google.protobuf.GeneratedCodeInfo.IAnnotation} message Annotation message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Annotation.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.path != null && message.path.length) {
                        writer.uint32(/* id 1, wireType 2 =*/10).fork();
                        for (let i = 0; i < message.path.length; ++i)
                            writer.int32(message.path[i]);
                        writer.ldelim();
                    }
                    if (message.sourceFile != null && Object.hasOwnProperty.call(message, "sourceFile"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.sourceFile);
                    if (message.begin != null && Object.hasOwnProperty.call(message, "begin"))
                        writer.uint32(/* id 3, wireType 0 =*/24).int32(message.begin);
                    if (message.end != null && Object.hasOwnProperty.call(message, "end"))
                        writer.uint32(/* id 4, wireType 0 =*/32).int32(message.end);
                    return writer;
                };

                /**
                 * Encodes the specified Annotation message, length delimited. Does not implicitly {@link google.protobuf.GeneratedCodeInfo.Annotation.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof google.protobuf.GeneratedCodeInfo.Annotation
                 * @static
                 * @param {google.protobuf.GeneratedCodeInfo.IAnnotation} message Annotation message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Annotation.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes an Annotation message from the specified reader or buffer.
                 * @function decode
                 * @memberof google.protobuf.GeneratedCodeInfo.Annotation
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {google.protobuf.GeneratedCodeInfo.Annotation} Annotation
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Annotation.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    let end = length === undefined ? reader.len : reader.pos + length, message = new $root.google.protobuf.GeneratedCodeInfo.Annotation();
                    while (reader.pos < end) {
                        let tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                if (!(message.path && message.path.length))
                                    message.path = [];
                                if ((tag & 7) === 2) {
                                    let end2 = reader.uint32() + reader.pos;
                                    while (reader.pos < end2)
                                        message.path.push(reader.int32());
                                } else
                                    message.path.push(reader.int32());
                                break;
                            }
                        case 2: {
                                message.sourceFile = reader.string();
                                break;
                            }
                        case 3: {
                                message.begin = reader.int32();
                                break;
                            }
                        case 4: {
                                message.end = reader.int32();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes an Annotation message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof google.protobuf.GeneratedCodeInfo.Annotation
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {google.protobuf.GeneratedCodeInfo.Annotation} Annotation
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Annotation.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies an Annotation message.
                 * @function verify
                 * @memberof google.protobuf.GeneratedCodeInfo.Annotation
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Annotation.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.path != null && message.hasOwnProperty("path")) {
                        if (!Array.isArray(message.path))
                            return "path: array expected";
                        for (let i = 0; i < message.path.length; ++i)
                            if (!$util.isInteger(message.path[i]))
                                return "path: integer[] expected";
                    }
                    if (message.sourceFile != null && message.hasOwnProperty("sourceFile"))
                        if (!$util.isString(message.sourceFile))
                            return "sourceFile: string expected";
                    if (message.begin != null && message.hasOwnProperty("begin"))
                        if (!$util.isInteger(message.begin))
                            return "begin: integer expected";
                    if (message.end != null && message.hasOwnProperty("end"))
                        if (!$util.isInteger(message.end))
                            return "end: integer expected";
                    return null;
                };

                /**
                 * Creates an Annotation message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof google.protobuf.GeneratedCodeInfo.Annotation
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {google.protobuf.GeneratedCodeInfo.Annotation} Annotation
                 */
                Annotation.fromObject = function fromObject(object) {
                    if (object instanceof $root.google.protobuf.GeneratedCodeInfo.Annotation)
                        return object;
                    let message = new $root.google.protobuf.GeneratedCodeInfo.Annotation();
                    if (object.path) {
                        if (!Array.isArray(object.path))
                            throw TypeError(".google.protobuf.GeneratedCodeInfo.Annotation.path: array expected");
                        message.path = [];
                        for (let i = 0; i < object.path.length; ++i)
                            message.path[i] = object.path[i] | 0;
                    }
                    if (object.sourceFile != null)
                        message.sourceFile = String(object.sourceFile);
                    if (object.begin != null)
                        message.begin = object.begin | 0;
                    if (object.end != null)
                        message.end = object.end | 0;
                    return message;
                };

                /**
                 * Creates a plain object from an Annotation message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof google.protobuf.GeneratedCodeInfo.Annotation
                 * @static
                 * @param {google.protobuf.GeneratedCodeInfo.Annotation} message Annotation
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Annotation.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults)
                        object.path = [];
                    if (options.defaults) {
                        object.sourceFile = "";
                        object.begin = 0;
                        object.end = 0;
                    }
                    if (message.path && message.path.length) {
                        object.path = [];
                        for (let j = 0; j < message.path.length; ++j)
                            object.path[j] = message.path[j];
                    }
                    if (message.sourceFile != null && message.hasOwnProperty("sourceFile"))
                        object.sourceFile = message.sourceFile;
                    if (message.begin != null && message.hasOwnProperty("begin"))
                        object.begin = message.begin;
                    if (message.end != null && message.hasOwnProperty("end"))
                        object.end = message.end;
                    return object;
                };

                /**
                 * Converts this Annotation to JSON.
                 * @function toJSON
                 * @memberof google.protobuf.GeneratedCodeInfo.Annotation
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Annotation.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for Annotation
                 * @function getTypeUrl
                 * @memberof google.protobuf.GeneratedCodeInfo.Annotation
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                Annotation.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/google.protobuf.GeneratedCodeInfo.Annotation";
                };

                return Annotation;
            })();

            return GeneratedCodeInfo;
        })();

        return protobuf;
    })();

    return google;
})();

export { $root as default };
