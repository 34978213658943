import { Button, Col, Row, Slider, Spin, Switch } from "antd";
import { FC, useEffect, useRef, useState } from "react";
import { IMAGE_HEIGHT, IMAGE_WIDTH } from "../constants";
import { requestPort } from "../serial/serial";
import { thimphuGetMemInfos, thimphuGetPlatformId, thimphuReadMemory, thimphuSetHIH, thimphuSetPump } from "../serial/thimphu";
import { refKitProtocolCalibratePeakMask, refkitDFUGetVersion, refkitDFUGoapp, refkitProtocolCalibrateExposure, refkitProtocolGetAlgoLogs, refkitProtocolGetCapabilities, refkitProtocolGetConfig, refkitProtocolGetMemoryInfoCoreSensor, refkitProtocolGetMemoryInfoImage, refkitProtocolGetMemoryInfoMain, refkitProtocolGetPeakExtractPixelsMap, refkitProtocolReadMemoryCoreSensor, refkitProtocolReadMemoryImage, refkitProtocolSetConfig, refkitProtocolSetHIH, refkitProtocolSetMMI, refkitProtocolSetPumpPower, refkitProtocolSetVCSEL, refkitProtocolSingleShot } from "../serial/refkit";
import { FlexCol, FlexRow, Paper } from "../common";
import { JsonView, defaultStyles } from "react-json-view-lite";
import 'react-json-view-lite/dist/index.css';
import { useMessageContext } from "../reducers/messageContext";
import { convertHumidity, convertTemperature, navigatorSupportsSerial, readVarInt } from "../serial/utils";
import { DeleteOutlined } from "@ant-design/icons";
import { RefDesignProtocol } from "../../ref_design_protocol";
import { FspMetadata } from "../../fsp_metadata";
import { thimphuProtocol } from "../../thimphu_protocol";
import { REFKIT_SERIAL_FILTER } from "../serial/constants";
import { WebserialNotSupportedWidget } from "../widgets/webserialNotSupportedWidget";

const RefkitDebugWidget: FC = () => {

    const [ jsonData, setJsonData ] = useState< Object | any[]>({})
    const [ isLoading, setIsLoading ] = useState<boolean>(false)
    const [ vcselState, setVcselState ] = useState<boolean>(true)
    const [ hihState, setHihState ] = useState<boolean>(true)
    const [ mmiState, setMmiState ] = useState<boolean>(false)
    const [ config, setConfig ] = useState<RefDesignProtocol.IConfigData | null>(null)

    const [ isCoreSensorMemoryDone, setIsCoreSensorMemoryDone ] = useState<boolean>(true)

    const { refkitMessages, refkitPort, consumeRefkitMessage } = useMessageContext();

    const sendAndDebugCommand = async (
        func: (port: SerialPort) => Promise<void>
    ) => {
        if (refkitPort === undefined || refkitPort.writable === null) {
            console.log("no refkit port to write to")
            return
        }
        setIsLoading(true)
        void await func(refkitPort)
    }

    useEffect(() => {
        for (let message of refkitMessages) {
            // console.log("message: ", message)
            if (!message.message.result) {
                // Skip non-result messages e.g. events
                continue
            }
            if (
                message.message.result.memoryData ||
                message.message.result.singleShot
            ) {
                // Skip blob messages: memory data
                // They are too big to be displayed
                continue 
            }
            if (message.message.result.header?.reason?.includes("vcsel")) {
                if (message.message.result.header.reason.includes("On")) {
                    setVcselState(true)
                } else if (message.message.result.header.reason.includes("Off")) {
                    setVcselState(false)
                } else {
                    console.log("unexpected vcsel state: ", message.message.result.header.reason)
                }
            }
            if (message.message.result.config && message.message.result.config.config) {
                setConfig(message.message.result.config.config)
            }
            setJsonData(message.message)
            consumeRefkitMessage(message.id)
            setIsLoading(false)
        }
        
    }, [refkitMessages])

    if (!navigatorSupportsSerial()) {
        return <WebserialNotSupportedWidget/>
    }

    return <Paper style={{
        width: '100%'
    }}>
        <FlexCol>

            <Button type='primary' onClick={async () => {
                try{
                    await requestPort([REFKIT_SERIAL_FILTER])
                } catch {}
            }}>
                Request REFKIT
            </Button>
            <Button type='primary' onClick={async () => {
                void await sendAndDebugCommand(refkitDFUGetVersion)
            }}>
                GetVersion (DFU & App)
            </Button>
            <Button type='primary' onClick={async () => {
                void await sendAndDebugCommand(refkitDFUGoapp)
            }}>
                DFU Goapp
            </Button>
            <Button type='primary' onClick={async () => {
                void await sendAndDebugCommand(refkitProtocolGetConfig)
            }}>
                GetConfig
            </Button>
            <Button disabled={config === null} type='primary' onClick={async () => {
                void await sendAndDebugCommand(async (port) => {
                    if (config === null) {
                        console.log("no config to set")
                        return
                    }
                    if (!config.peakMasks || !config.peakPhasisCalib) {
                        console.log("cannot set config without peakMasks or peakPhasisCalib")
                        return
                    }
                    for (let peakMask of config.peakMasks) {
                        peakMask.radius = 4
                    }
                    // remove peak phasis calib,
                    // otherwise refkit cannot digest payload too big
                    config.peakPhasisCalib = undefined
                    console.log('setting config: ', config)
                    return await refkitProtocolSetConfig(port, config)
                })
            }}>
                SetConfig
            </Button>
            <Button type='primary' onClick={async () => {
                void await sendAndDebugCommand(refkitProtocolGetCapabilities)
            }}>
                GetCapabilities
            </Button>
            <Button type='primary' onClick={async () => {
                void await sendAndDebugCommand(refkitProtocolCalibrateExposure)
            }}>
                Calibrate Exposure
            </Button>
            <Button type='primary' onClick={async () => {
                void await sendAndDebugCommand(refKitProtocolCalibratePeakMask)
            }}>
                Calibrate Peak Mask
            </Button>
            <Button type='primary' onClick={async () => {
                void await sendAndDebugCommand(refkitProtocolGetAlgoLogs)
            }}>
                Get Algo Logs
            </Button>
            <Button type='primary' onClick={async () => {
                void await sendAndDebugCommand(refkitProtocolGetPeakExtractPixelsMap)
            }}>
                Get Peak Etract Pixels Map
            </Button>
            <FlexRow style={{
                justifyContent: "end"
            }}>
                VCSEL: 
                <Switch checked={vcselState} onChange={async (checked) => {
                    void await sendAndDebugCommand(async (port) => {
                        return await refkitProtocolSetVCSEL(port, checked)
                    })
                }}/>
            </FlexRow>
            <FlexRow style={{
                justifyContent: "end"
            }}>
                <MMIListenerWidget/>
                MMI: 
                <Switch checked={mmiState} onChange={async (checked) => {
                    void await sendAndDebugCommand(async (port) => {
                        void await refkitProtocolSetMMI(port, checked)
                        setMmiState(checked)
                    })
                }}/>
            </FlexRow>
            <FlexRow style={{
                alignItems: "center"
            }}>
                <RefkitPumpControlWidget/>
            </FlexRow>
            <FlexRow style={{
                justifyContent: "start",
                alignItems: "center"
            }}>
                HIH: 
                <RefkitHIHListenerWidget/>
                <Switch checked={hihState} onChange={async (checked) => {
                    setHihState(checked)
                    void await sendAndDebugCommand(async (port) => {
                        return await refkitProtocolSetHIH(port, checked)
                    })
                }}/>
            </FlexRow>
            <Button type='primary' onClick={async () => {
                void await sendAndDebugCommand(refkitProtocolSingleShot)
            }}>
                Single Shot
            </Button>
            <Button type='primary' onClick={async () => {
                void await sendAndDebugCommand(async (port) => {
                    setIsCoreSensorMemoryDone(false)
                    return await refkitProtocolReadMemoryCoreSensor(port, 0)
                })
            }}>
                Read CS Memory
            </Button>
            <FlexRow style={{
                width: '100%',
                justifyContent: "space-between"
            }}>
                <Button type='primary' onClick={async () => {
                    void await sendAndDebugCommand(refkitProtocolGetMemoryInfoImage)
                }}>
                    MemInfo: Image
                </Button>
                <Button type='primary' onClick={async () => {
                    void await sendAndDebugCommand(refkitProtocolGetMemoryInfoCoreSensor)
                }}>
                    MemInfo: CS
                </Button>
                <Button type='primary' onClick={async () => {
                    void await sendAndDebugCommand(refkitProtocolGetMemoryInfoMain)
                }}>
                    MemInfo: Main
                </Button>
            </FlexRow>
            {isLoading? <Spin/> : <JsonView data={jsonData} style={defaultStyles} shouldInitiallyExpand={(level, value, field) => {
                if (
                    field === "peakPhasisCalib" || 
                    field === "peakMasks"
                ) {
                    return false
                }
                return true
            }}/>}
            <FlexRow style={{
                justifyContent: "end"
            }}>
                <SingleShotListenerWidget/>
            </FlexRow>
            <FlexRow>
                <CoreSensorMetadataListenerWidget
                    isDone={isCoreSensorMemoryDone}
                    setIsDone={setIsCoreSensorMemoryDone}
                />
            </FlexRow>
            <FlexRow style={{
                justifyContent: "end"
            }}>
                
            </FlexRow>
            <FlexRow style={{
                justifyContent: "end"
            }}>
                <Button icon={<DeleteOutlined/>} type="primary" danger onClick={() => {
                    for (let message of refkitMessages) {
                        consumeRefkitMessage(message.id)
                    }
                }}/>
            </FlexRow>
        </FlexCol>
    </Paper>
}

const ThimphuDebugWidget: FC = () => {

    const [ jsonData, setJsonData ] = useState< Object | any[]>({})
    const [ isLoading, setIsLoading ] = useState<boolean>(false)
    // const [ hihState, setHihState ] = useState<boolean>(true)

    const [ isMemoryReadDone, setIsMemoryReadDone ] = useState<boolean>(true)
    const { thimphuMessages, consumeThimphuMessage, thimphuPort } = useMessageContext();

    const sendAndDebugCommand = async (
        func: (port: SerialPort) => Promise<void>
    ) => {
        if (thimphuPort === undefined) {
            console.log("no thimphu port in state")
            return
        }
        setIsLoading(true)
        void await func(thimphuPort)
    }

    useEffect(() => {
        for (let message of thimphuMessages) {
            if (message.message.data ||
                message.message.readMem) {
                    continue
            }
            console.log("thimphu generic message", message)
            setJsonData(message.message)
            consumeThimphuMessage(message.id)
            setIsLoading(false)
        }
    }, [thimphuMessages])

    return <Paper style={{
        width: '100%'
    }}>
        <FlexCol>
            <Button type='primary' onClick={async () => {
                try {
                    await requestPort([{
                        usbVendorId: 0x2341,
                        usbProductId: 0x8037
                    }])
                } catch {}
            }}>
                Request THIMPHU
            </Button>
            <Button type='primary' onClick={async () => {
                void await sendAndDebugCommand(thimphuGetPlatformId)
            }}>
                GetPlatformId
            </Button>
            <Button type='primary' onClick={async () => {
                void await sendAndDebugCommand(thimphuGetMemInfos)
            }}>
                GetMemInfos
            </Button>
            <Button type='primary' onClick={async () => {
                void await sendAndDebugCommand(async (port) => {
                    setIsMemoryReadDone(false)
                    return await thimphuReadMemory(port, 0)
                })
            }}>
                Read Metadata Memory
            </Button>
            <ThimphuMetadataListenerWidget
                isDone={isMemoryReadDone}
                setIsDone={setIsMemoryReadDone}
                setIsLoading={setIsLoading}
                setJsonData={setJsonData}
            />
            <FlexRow style={{
                justifyContent: "start",
                alignItems: "center"
            }}>
                HIH: 
                {/* <Switch checked={hihState} onChange={async (checked) => {
                    void await sendAndDebugCommand(async (port) => {
                        void await thimphuSetHIH(port, checked)
                        setHihState(checked)
                    })
                }}/> */}
                <ThimphuHIHListenerWidget/>
            </FlexRow>
            <FlexRow style={{
                alignItems: "center"
            }}>
                <ThimphuPumpControlWidget/>
            </FlexRow>
            {isLoading ? <Spin/> : <JsonView data={jsonData} style={defaultStyles}/>}

        </FlexCol>
    </Paper>
}

const ThimphuHIHListenerWidget: FC = () => {
    const { thimphuPort, thimphuMessages, consumeThimphuMessage } = useMessageContext();
    const [ humidityValue, setHumidityValue ] = useState<number>(0)
    const [ temperatureValue, setTemperatureValue ] = useState<number>(0)
    
    useEffect( () => {
        if (thimphuPort === undefined) {
            return
        }
        console.log('starting HIH on mount')
        thimphuSetHIH(thimphuPort, true)
        return () => {
            console.log('stopping HIH on unmount')
            thimphuSetHIH(thimphuPort, false)
        }
    }, [ thimphuPort ])

    useEffect( () => {
        for (let message of thimphuMessages) {
            if (
                message.message.data &&
                message.message.data.temperatureHih6120
            ) {
                if (message.message.data.temperatureHih6120.rawHumidity) {
                    setHumidityValue(convertHumidity(
                        message.message.data.temperatureHih6120.rawHumidity
                    ))
                }
                if (message.message.data.temperatureHih6120.rawTemperature) {
                    setTemperatureValue(convertTemperature(
                        message.message.data.temperatureHih6120.rawTemperature
                    ))
                }
                consumeThimphuMessage(message.id)
            }
        }
    }, [thimphuMessages])

    return <div>
        <b>Temp:</b> {temperatureValue.toFixed(2)}
        <br/>
        <b>Hum:</b> {humidityValue.toFixed(2)}
    </div>
}

const RefkitHIHListenerWidget: FC = () => {
    const { refkitPort, refkitMessages, consumeRefkitMessage } = useMessageContext();
    const [ humidityValue, setHumidityValue ] = useState<number>(0)
    const [ temperatureValue, setTemperatureValue ] = useState<number>(0)
    
    useEffect( () => {
        if (refkitPort === undefined) {
            return
        }
        console.log('starting HIH on mount')
        refkitProtocolSetHIH(refkitPort, true)
        return () => {
            console.log('stopping HIH on unmount')
            refkitProtocolSetHIH(refkitPort, false)
        }
    }, [ refkitPort ])

    useEffect( () => {
        for (let message of refkitMessages) {
            if (
                message.message.event &&
                message.message.event.hih6120
            ) {
                if (message.message.event.hih6120.humidity) {
                    setHumidityValue(message.message.event.hih6120.humidity)
                }
                if (message.message.event.hih6120.temperature) {
                    setTemperatureValue(message.message.event.hih6120.temperature)
                }
                consumeRefkitMessage(message.id)
            }
        }
    }, [ refkitMessages ])

    return <div>
        <b>Temp:</b> {temperatureValue.toFixed(2)}
        <br/>
        <b>Hum:</b> {humidityValue.toFixed(2)}
    </div>
}

const MMIListenerWidget: FC = () => {
    const { refkitMessages, consumeRefkitMessage } = useMessageContext();
    const [ mmiValue, setMmiValue ] = useState<number>(0)
    const [ cnt, setCnt ] = useState<number>(0)

    useEffect( () => {

    }, [])

    useEffect( () => {
        for (let message of refkitMessages) {
            if (
                message.message.event &&
                message.message.event.algo &&
                message.message.event.algo.peaks
            ) {
                setCnt(cnt + 1)
                consumeRefkitMessage(message.id)
                if (cnt % 20 !== 0) {
                    continue
                }
                console.log("algo: ", message.message.event.algo)
                // mean of all peaks
                let sum = 0
                for (let peak of message.message.event.algo.peaks) {
                    sum += peak
                }
                setMmiValue(sum / message.message.event.algo.peaks.length)
                // setMmiValue(message.message.event.algo.peaks[0])
            }
        }
    }, [refkitMessages])

    return <div>
        <b>MMI:</b> {mmiValue.toFixed(2)}
    </div>
}

const SingleShotListenerWidget: FC = () => {

    const { refkitPort, refkitMessages, consumeRefkitMessage } = useMessageContext();
    const [ offset, setOffset ] = useState<number>(0)
    const [ isDone, setIsDone ] = useState<boolean>(true)

    const canvasRef = useRef<HTMLCanvasElement>(null)
    const canvasCtxRef = useRef<CanvasRenderingContext2D | null>(null)

    const imageLength = IMAGE_WIDTH*IMAGE_HEIGHT

    useEffect(() => {
        if (refkitPort === undefined) {
            return
        }
        for (let message of refkitMessages) {
            if (
                message.message.result &&
                message.message.result.singleShot
            ) {
                consumeRefkitMessage(message.id)
                setOffset(0)
                setIsDone(false)
                if (canvasRef.current) {
                    let ctx = canvasRef.current.getContext('2d', {
                        willReadFrequently: true
                    })
                    if (ctx !== null) {
                        ctx.clearRect(0, 0, IMAGE_WIDTH, IMAGE_HEIGHT)
                        canvasCtxRef.current = ctx
                    }
                }
                refkitProtocolReadMemoryImage(refkitPort, 0)
            }
            if (
                !isDone && 
                message.message.result &&
                message.message.result.memoryData
            ) {
                consumeRefkitMessage(message.id)
                let data = message.message.result.memoryData.data
                if (data && data.byteLength > 0) {
                    let newOffset = offset + data.byteLength
                    if (canvasRef.current) {
                        let ctx = canvasCtxRef.current
                        if (ctx === null) {
                            return
                        }
                        let imageData = ctx.getImageData(0, 0, IMAGE_WIDTH, IMAGE_HEIGHT)
                        let clampedData = new Uint8ClampedArray(data.length*4)
                        for (let i = 0; i < data.length; i++) {
                            clampedData.set([data[i], data[i], data[i], 255], i*4)
                        }
                        imageData.data.set(clampedData, offset*4)
                        void ctx.putImageData(imageData, 0, 0)
                        let row = Math.floor(offset / IMAGE_WIDTH)
                        let col = offset % IMAGE_WIDTH
                    }
                    if (newOffset >= imageLength) {
                        setIsDone(true)
                        console.log('image loaded!')
                        return
                    } else {
                        refkitProtocolReadMemoryImage(refkitPort, newOffset)
                        setOffset(newOffset)
                    }
                }
            }
        }
    }, [refkitMessages])

    let p = ((offset) / (imageLength-1024) * 100).toFixed()
    return <div>
        <b>SingleShot:</b> {p}% {!isDone? <Spin size="small"/> : null}
        <br/>
        <canvas ref={canvasRef} width={IMAGE_WIDTH} height={IMAGE_HEIGHT} style={{
            width: '100%'
        }}/>
    </div>
}

const CoreSensorMetadataListenerWidget: FC<{
    isDone: boolean,
    setIsDone: (isDone: boolean) => void
}> = ({
    isDone,
    setIsDone
}) => {
    const { refkitPort, refkitMessages, consumeRefkitMessage } = useMessageContext();
    const [ offset, setOffset ] = useState<number>(0)
    
    const memoryLength = 65536
    const bufferViewRef = useRef<Uint8Array>(new Uint8Array(memoryLength))
    const [ json, setJson ] = useState<Object | null>(null)

    
    useEffect(() => {
        if (refkitPort === undefined) {
            return
        }
        for (let message of refkitMessages) {
            if (
                !isDone && 
                message.message.result &&
                message.message.result.memoryData
            ) {
                if (
                    message.message.result.header &&
                    !message.message.result.header.status
                ) {
                    console.log("error on core sensor memory read:", message.message.result.header.reason)
                    setJson(message.message.result.header)
                    setIsDone(true)
                    return
                }
                console.log("message: ", message)
                consumeRefkitMessage(message.id)
                let data = message.message.result.memoryData.data
                if (data && data.byteLength > 0) {
                    console.log("data: ", data)
                    let newOffset = offset + data.byteLength
                    if (bufferViewRef.current !== null) {
                        bufferViewRef.current.set(data, offset)
                    }
                    console.log("offset: ", offset)
                    if (newOffset >= memoryLength) {
                        console.log("buffer", bufferViewRef.current)
                        setJson(FspMetadata.MetadataSchema.decode(bufferViewRef.current))
                        setIsDone(true)
                        console.log('cs memory loaded!')
                        return
                    } else {
                        refkitProtocolReadMemoryCoreSensor(refkitPort, newOffset)
                        setOffset(newOffset)
                    }
                }
            }
        }
    }, [refkitMessages])

    let p = ((offset) / (memoryLength-1024) * 100).toFixed()
    return <div>
        <b>CS Memory:</b> {p}% {!isDone? <Spin size="small"/> : null}
        <br/>
        {json !== null ? <JsonView data={json} style={defaultStyles}/> : null}
    </div>
}

const ThimphuMetadataListenerWidget: FC<{
    isDone: boolean,
    setIsDone: (isDone: boolean) => void
    setIsLoading: (isLoading: boolean) => void,
    setJsonData: (json: Object | any[]) => void
}> = ({
    isDone,
    setIsDone,
    setIsLoading,
    setJsonData
}) => {
    const { thimphuPort, thimphuMessages, consumeThimphuMessage } = useMessageContext();
    const [ offset, setOffset ] = useState<number>(0)
    const [ totalLength, setTotalLength ] = useState<number>(1016)
    const bufferViewRef = useRef<Uint8Array>(new Uint8Array(totalLength))

    useEffect(() => {
        if (thimphuPort === undefined) {
            return
        }
        if (isDone) {
            return
        }
        for (let message of thimphuMessages) {
            if (
                message.message.readMem
            ) {
                console.log("memory read message: ", message)
                consumeThimphuMessage(message.id)
                if (message.message.readMem.ack &&
                    message.message.readMem.ack.status &&
                    message.message.readMem.ack.status > 0
                ) {
                    console.log("error on thimhu memory read:", thimphuProtocol.AckResult.Status[message.message.readMem.ack.status])
                    setJsonData(message.message.readMem.ack)
                    setIsDone(true)
                    setOffset(0)
                    setIsLoading(false)
                    continue
                }
                let data = message.message.readMem.data
                if (!data || data.byteLength === 0) {
                    continue
                }
                console.log("data: ", data)
                if (bufferViewRef.current !== null) {
                    bufferViewRef.current.set(data, offset)
                }
                let [ msgLen, msgLenVarintLen ] = readVarInt(bufferViewRef.current.slice(1))
                // console.log("varint-encoded msg length: ", msgLen, msgLenVarintLen)
                let newOffset = offset + data.byteLength
                console.log(`offset: ${offset}, newOffset: ${newOffset}, memoryLength: ${totalLength}, msgLen: ${msgLen}, msgLenVarintLen: ${msgLenVarintLen}`)
                if (newOffset >= 1+msgLenVarintLen+msgLen) {
                    let payload = bufferViewRef.current.slice(1+msgLenVarintLen)
                    console.log("payload", payload, payload.byteLength)
                    try {
                        let metadata = FspMetadata.MetadataSchema.decode(payload)
                        console.log('thimphu metadata', metadata)
                        setJsonData(metadata)
                    } catch(e) {
                        console.log("error decoding thimphu metadata: ", e)
                    }
                    setIsDone(true)
                    setIsLoading(false)
                    return
                } else {
                    thimphuReadMemory(thimphuPort, newOffset)
                    setOffset(newOffset)
                }
            }
        }
    }, [thimphuMessages])
    return null
}

const ThimphuPumpControlWidget: FC = () => {
    const { thimphuPort } = useMessageContext();
    return <FlexRow style={{
        width: '100%'
    }}>
        Pump:
        <Slider
        style={{
            width: '100%'
        }}
            min={0}
            max={100}
            defaultValue={20}
            onAfterChange={async (value) => {
                if (thimphuPort === undefined) {
                    return null
                }
                console.log("set pump power to: ", value)
                void await thimphuSetPump(thimphuPort, value)
            }}
        />
    </FlexRow>
}

const RefkitPumpControlWidget: FC = () => {
    const { refkitPort } = useMessageContext();
    return <FlexRow style={{
        width: '100%'
    }}>
        Pump:
        <Slider
        style={{
            width: '100%'
        }}
            min={0}
            max={100}
            defaultValue={20}
            onAfterChange={async (value) => {
                if (refkitPort === undefined) {
                    return null
                }
                console.log("set pump power to: ", value)
                void await refkitProtocolSetPumpPower(refkitPort, value)
            }}
        />
    </FlexRow>
}

export const SerialDebugPage: FC = () => {

    return <>
        <Row gutter={[10, 10]}>
            <Col xs={24} md={12}>
                <RefkitDebugWidget/>
            </Col>
            <Col xs={24} md={12}>
                <ThimphuDebugWidget/>
            </Col>
        </Row>
    </>
}