import { Typography, Row, Col, Select, Button, Alert, Popconfirm, message as antdMessage, Switch, Empty } from "antd";
import { FC, useState, useRef, useEffect } from "react";
import { FspMetadata } from "../../fsp_metadata";
import { FlexCol, FlexRow, Paper } from "../common";
import { useMessageContext } from "../reducers/messageContext";
import { useMetadataContext } from "../reducers/metadataContext";
import { THIMPHU_MEMORY_WRITE_BUFFER } from "../serial/constants";
import { thimphuWriteMemory } from "../serial/thimphu";
import { colorHexToRGBA, ARYBALLE_COLOR_GRAY, ARYBALLE_COLOR_PINK, spotsgrid2dCoordinatesTo1dIndex, DEFAULT_COLOR_FOR_UNKNOWN_PEPTIDE, PEPTIDE_COLOR_MAP_VDW, colorTupleToRGBA, rowIdxToLetter, ARYBALLE_COLOR_YELLOW } from "../utils";
import { loadSdsSerial, loadSpotsGrid1D, saveSdsSerial, saveSpotsGrid1D } from "../localStorage";
import { ArrowRightOutlined } from "@ant-design/icons";


const SPOTFILE_TOKEN = 'glpat-eKRDxZ_cydh_VsgAdBBN';
const SPOTFILE_ENDPOINT = "https://gitlab.com/api/v4/projects/Aryballe%2Fproduction%2Fspotfiles/repository"

const parseSpotsgrid2dFromSpotfile = (spotfile: string): number[][] => {
    const spotFileLines: string[] = spotfile.slice(0, spotfile.length - 1).split('\n');
    return spotFileLines.map((spotFileLine: string) => spotFileLine.split(',').map((spotCode: string) => Number(spotCode)));
};


const fetchSpotfile = async (sdsSerial: string): Promise<string> => {
    const response = await fetch(
        `${SPOTFILE_ENDPOINT}/files/${sdsSerial}.fsp64.spotsgrid.csv/raw?access_token=${SPOTFILE_TOKEN}`
    )
    const spotfile = await response.text()
    return spotfile
}

const _fetchSpotfileListPage = async (page: number): Promise<string[]> => {
    const response = await fetch(
        `${SPOTFILE_ENDPOINT}/tree?access_token=${SPOTFILE_TOKEN}&per_page=100&page=${page}`
    )
    const spotfileList = await response.json()
    return spotfileList
        .filter((spotfile: any) => spotfile.name.endsWith('.fsp64.spotsgrid.csv'))
        .map((spotfile: any) => spotfile.name.split('.')[0])
}

const fetchSpotfileList = async (): Promise<string[]> => {
    let page = 1
    let spotfileList: string[] = []
    while (true) {
        const _spotfileList = await _fetchSpotfileListPage(page)
        if (_spotfileList.length === 0) {
            break
        }
        spotfileList = spotfileList.concat(_spotfileList)
        page++
    }
    return spotfileList
}

const spotsgrid2DTo1D = (spotsgrid2d: number[][]): number[] => {
    let spotsgrid1d: number[] = []
    // From left to right, from bottom to top
    for (let i = spotsgrid2d.length - 1; i >= 0; i--) {
        spotsgrid1d = spotsgrid1d.concat(spotsgrid2d[i])
    }
    return spotsgrid1d
}

const spotsgrid1DTo2D = (spotsgrid1d: number[]): number[][] => {
    let spotsgrid2d: number[][] = []
    // From left to right, from bottom to top
    for (let i = 0; i < spotsgrid1d.length; i++) {
        const row = 15 - Math.floor(i / 4)
        const col = i % 4
        if (!spotsgrid2d[row]) {
            spotsgrid2d[row] = []
        }
        spotsgrid2d[row][col] = spotsgrid1d[i]
    }
    // console.log(spotsgrid1d, spotsgrid2d)
    return spotsgrid2d
}

const getSpotsgridTable = (
    spotsgrid1d: number[],
    useColors: boolean,
    onClick: (idx: number) => void
) => {
    let spotsgrid2d = spotsgrid1DTo2D(spotsgrid1d)
    return <table style={{
        borderCollapse: 'collapse',
        border: '1px solid #666',
    }}>
        <tbody>
            {
                <tr>
                    {[null, 0, 1, 2, 3].map((colTitle) => {
                        return <td style={{
                            textAlign: 'center',
                        }}>
                            {colTitle}
                        </td>
                    })}
                </tr>
            }
            {spotsgrid2d.map((row, rowIdx) => {
                let rowCells = row.map((spotInt, colIdx) => {

                    let spotStr = spotInt.toString()

                    let peptideInt = parseInt(spotStr)
                    if (peptideInt < 0) {
                        peptideInt = -peptideInt
                    }
                    let peptideStr = peptideInt.toString()
                    if (peptideStr.length === 3 && peptideStr[2] === '4') {
                        peptideStr = peptideStr.slice(0, 2)
                    }
                    peptideInt = parseInt(peptideStr)

                    let color = DEFAULT_COLOR_FOR_UNKNOWN_PEPTIDE
                    if (PEPTIDE_COLOR_MAP_VDW[peptideInt] !== undefined) {
                        color = PEPTIDE_COLOR_MAP_VDW[peptideInt]
                    }
                    return <td key={colIdx} style={{
                        textAlign: 'center',
                        cursor: 'pointer',
                        border: '1px solid #666',
                        width: 60,
                        height: 25,
                        backgroundColor: function(){
                            if (spotInt < 0) {
                                return colorTupleToRGBA([255, 0, 0], 0.7)
                            }
                            if (!useColors) {
                                return 'transparent'
                            }
                            if (spotInt > 0) {
                                return colorHexToRGBA(color, 0.2)
                            }
                        }(),
                    }}
                    onClick={() => {
                        let idx = spotsgrid2dCoordinatesTo1dIndex(rowIdx, colIdx)
                        onClick(idx)
                    }}
                    >{peptideInt}</td>
                })
                let rowStr = rowIdxToLetter(rowIdx)
                return <tr key={rowIdx}>
                    <td style={{textAlign: 'center'}}>{rowStr}</td>{rowCells}
                </tr>
            })}
        </tbody>
    </table>
}

const spotsgrids1dAreEqual = (spotsgrid1d1: number[], spotsgrid1d2: number[]): boolean => {
    if (spotsgrid1d1.length !== spotsgrid1d2.length) {
        return false
    }
    for (let i = 0; i < spotsgrid1d1.length; i++) {
        if (spotsgrid1d1[i] !== spotsgrid1d2[i]) {
            return false
        }
    }
    return true
}

export const SpotfileManagementWidget: FC = () => {
    const {
        thimphuFspMetadata,
        setThimphuFspMetadata
    } = useMetadataContext()

    const {
        thimphuPort,
        thimphuMessages,
        consumeThimphuMessage
    } = useMessageContext()

    const [ sdsSerialValue, setSdsSerialValue ] = useState<string | null>(null)
    const [ spotfileNames, setSpotfileNames ] = useState<string[]>([])
    const [ currentSpotsgrid1d, setCurrentSpotsgrid1d ] = useState<number[] | null>(null)

    const [ shouldUseColors, setShouldUseColors ] = useState<boolean>(false)

    const memoryIsWritingRef = useRef<boolean>(false)
    const memoryWriteOffsetRef = useRef<number>(0)
    const memoryWritePayloadRef = useRef<Uint8Array | null>(null)

    const divRef = useRef<HTMLDivElement>(null)

    const fetchAndSetSpotfile = async () => {
        console.log('fetching spotfile', sdsSerialValue)
        if (!sdsSerialValue) {
            return
        }
        const spotfile = await fetchSpotfile(sdsSerialValue)
        if (!spotfile) {
            throw new Error('error fetching spotfile from cloud')
        }
        const spotsgrid2d = parseSpotsgrid2dFromSpotfile(spotfile)
        if (!spotsgrid2d) {
            throw new Error('error parsing spotsgrid from CSV file')
        }
        console.log('spotsgrid 2d', spotsgrid2d)
        if (spotsgrid2d.length !== 16 || spotsgrid2d[0].length !== 4) {
            throw new Error('spotsgrid 2d is not 16x4')
        }
        let spotsgrid1d = spotsgrid2DTo1D(spotsgrid2d)
        setCurrentSpotsgrid1d(spotsgrid1d)                            
    }

    const startWritingMemory = async () => {
        if (!thimphuPort) {
            console.log('thimphu write memory: no port')
            return
        }
        if (!thimphuFspMetadata || !thimphuFspMetadata.calibration) {
            console.log('thimphu write memory: no metadata')
            return
        }
        if (!currentSpotsgrid1d) {
            console.log('thimphu write memory: no spotsgrid')
            return
        }
        let newMetadata = {
            ...thimphuFspMetadata
        }
        newMetadata.calibration!.spotsGrid = currentSpotsgrid1d
        memoryWritePayloadRef.current = FspMetadata.MetadataSchema.encodeDelimited(thimphuFspMetadata).finish()
        memoryIsWritingRef.current = true
        memoryWriteOffsetRef.current = 0
        await thimphuWriteMemory(
            thimphuPort,
            memoryWriteOffsetRef.current,
            memoryWritePayloadRef.current.slice(memoryWriteOffsetRef.current, memoryWriteOffsetRef.current + THIMPHU_MEMORY_WRITE_BUFFER)
        )
    }

    useEffect(() => {
        console.log('spotfile widget: mounting', window.location.hash)
        setTimeout(() => {
            if (divRef.current && window.location.hash === '#spotfile') {
                window.scrollTo({
                    top: divRef.current.getBoundingClientRect().top - 60,
                    behavior: 'smooth'
                })
                divRef.current.style.backgroundColor = colorHexToRGBA(ARYBALLE_COLOR_YELLOW, 1)
                divRef.current.style.padding = '10px'
                setTimeout(() => {
                    divRef.current!.style.backgroundColor = 'transparent'
                    divRef.current!.style.padding = '0px'
                    // window.location.hash = ''
                }, 3000)
            }
        }, 100)
    }, [divRef.current])

    useEffect(() => {
        setSdsSerialValue(loadSdsSerial())
    }, [])

    useEffect(() => {
        fetchSpotfileList()
            .then((spotfileList: string[]) => {
                setSpotfileNames(spotfileList)
            })
            .catch((e: any) => {
                console.log('sds swap: fetch spotfile list error', e)
            })
    }, [])

    useEffect(() => {
        if (!thimphuFspMetadata || !thimphuFspMetadata.calibration || !thimphuFspMetadata.calibration.spotsGrid) {
            console.log('spotfile mgmt widget: no spotsgrid in metadata; will try to load from local storage..')
            let _spotsgrid1d = loadSpotsGrid1D()
            if (_spotsgrid1d) {
                console.log('spotfile mgmt widget: loaded spotsgrid from local storage:', _spotsgrid1d)
                setCurrentSpotsgrid1d(_spotsgrid1d)
            }
        } else {
            let _spotsgrid1d = thimphuFspMetadata.calibration.spotsGrid
            saveSpotsGrid1D(_spotsgrid1d)
            setCurrentSpotsgrid1d(_spotsgrid1d)
        }
    }, [thimphuFspMetadata])

    useEffect(() => {
        if (!memoryIsWritingRef.current) {
            return
        }
        for (let message of thimphuMessages) {
            if (
                message.message.ack
            ) {
                consumeThimphuMessage(message.id)
                console.log("spotfile widget: writing memory ack", message.message)
                if (thimphuPort && memoryWritePayloadRef.current) {
                    memoryWriteOffsetRef.current += THIMPHU_MEMORY_WRITE_BUFFER
                    if (memoryWriteOffsetRef.current >= memoryWritePayloadRef.current.byteLength) {
                        memoryIsWritingRef.current = false
                        memoryWritePayloadRef.current = null
                        memoryWriteOffsetRef.current = 0
                        console.log('spotfile widget: memory successfully written to device')
                        return
                    }
                    let writeSize = THIMPHU_MEMORY_WRITE_BUFFER
                    if (memoryWriteOffsetRef.current + THIMPHU_MEMORY_WRITE_BUFFER > memoryWritePayloadRef.current.byteLength) {
                        writeSize = memoryWritePayloadRef.current.byteLength - memoryWriteOffsetRef.current
                    }
                    thimphuWriteMemory(
                        thimphuPort,
                        memoryWriteOffsetRef.current,
                        memoryWritePayloadRef.current.slice(memoryWriteOffsetRef.current, memoryWriteOffsetRef.current + writeSize)
                    ).then(() => {
                        antdMessage.success("Successfully flashed device with the new spotfile!")
                        console.log('spotfile widget: wrote memory chunk', memoryWriteOffsetRef.current, memoryWriteOffsetRef.current + writeSize)
                    })
                }
            }
        }
    }, [thimphuMessages])

    return <div ref={divRef} style={{
            borderRadius: 10,
        }}>
    <Paper>
        <Typography.Title level={3}>Spotfile</Typography.Title>
        <Row gutter={[10, 10]}>
        {currentSpotsgrid1d ? 
            <>
                <Col xs={24}>
                    <FlexRow style={{
                        alignItems: 'center'
                    }}>
                        Use peptide color scheme: 
                        <Switch size="small" checked={shouldUseColors} onChange={(checked: boolean) => {
                            setShouldUseColors(checked)
                        }}/>
                    </FlexRow>
                </Col>
                <Col xs={24} lg={12}>
                    {getSpotsgridTable(
                        currentSpotsgrid1d,
                        shouldUseColors,
                        (idx: number) => {
                            // Toggle spot by reversing its sign
                            let newSpotsgrid1d = [...currentSpotsgrid1d]
                            newSpotsgrid1d[idx] = -newSpotsgrid1d[idx]
                            // saveSpotsGrid1D(newSpotsgrid1d) // do not save on click, only on "save to cache" or on "commit"
                            setCurrentSpotsgrid1d(newSpotsgrid1d)
                        }
                    )}
                </Col>
            </>
            : <Col xs={12}>
                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={<>
                    Spotfile is not set. Please prepare your <b>SDS S/N</b> and download the spotfile <b>using the section on the right</b> <ArrowRightOutlined />
                </>} />
            </Col>
            }
            <Col xs={24} lg={12} style={{
                borderLeft: '1px solid #ccc',
                paddingLeft: 10
            }}>
                <Row gutter={[5, 5]} justify="end">
                    <Col xs={24}>
                        Swap SDS
                    </Col>
                    <Col xs={24}>
                        <Alert type="info" style={{textAlign: 'left'}} message={<>
                            This sections allows you to update the spotfile in case
                            you changed the SDS (cartridge). This functionality <b>requires internet connection</b>.
                        </>}/>
                    </Col>
                    <Col xs={24}>
                        <Select
                            style={{
                                width: '100%'
                            }}
                            placeholder="Type an SDS S/N and hit 'load'"
                            showSearch
                            value={sdsSerialValue}
                            options={spotfileNames.map((spotfileName: string) => {
                                return {
                                    value: spotfileName,
                                    label: spotfileName
                                }
                            })}
                            onChange={(value: string) => {
                                setSdsSerialValue(value)
                            }}
                            filterOption={(input, option) =>
                                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                            }                      
                        />
                    </Col>
                    <Col xs={12}>
                        <Button block onClick={async () => {
                            if (!sdsSerialValue) {
                                antdMessage.error(`Please select an SDS S/N`)
                                return
                            }
                            try {
                                await fetchAndSetSpotfile()
                                saveSdsSerial(sdsSerialValue)
                                antdMessage.success(`Loaded SDS spotfile: ${sdsSerialValue}`)
                            } catch (e: any) {
                                console.log('sds swap: error', e)
                                antdMessage.error(`Error swapping SDS: ${e.message}`)
                            }
                        }}>
                            Load
                        </Button>
                    </Col>
                    <Col xs={12}>
                        <Button disabled={thimphuFspMetadata === undefined} block danger onClick={() => {
                            if (thimphuFspMetadata && thimphuFspMetadata.calibration && thimphuFspMetadata.calibration.spotsGrid) {
                                setCurrentSpotsgrid1d(thimphuFspMetadata.calibration.spotsGrid)
                                antdMessage.info(`Reverted to the initial spotfile`)
                            }
                        }}>
                            Revert
                        </Button>
                    </Col>
                    <Col xs={12}>
                        <Button disabled={thimphuFspMetadata !== undefined} block onClick={() => {
                            if (currentSpotsgrid1d) {
                                saveSpotsGrid1D(currentSpotsgrid1d)
                                antdMessage.info(`Spotfile is cached in the app!`)
                            }
                        }}>
                            Save to cache
                        </Button>
                    </Col>
                </Row>
                {
                    (
                        currentSpotsgrid1d &&
                        thimphuFspMetadata?.calibration?.spotsGrid &&
                        !spotsgrids1dAreEqual(currentSpotsgrid1d, thimphuFspMetadata.calibration.spotsGrid)
                    ) ?
                        <Alert type="warning" style={{textAlign: 'left'}} message={<>
                            <b>Warning:</b> spotfile is pending changes!
                            <br/><br/>
                            <b>Commit</b> them (save on the device) or revert to the initial state.
                            <br/>
                            <b>Otherwise</b>, the changes will be <b>lost</b>.
                        </>}
                        action={
                            <Popconfirm
                                title='Ary you sure you want to flash the device with this spotfile?'
                                onConfirm={async () => {
                                    if (!thimphuFspMetadata || !thimphuFspMetadata.calibration) {
                                        return
                                    }
                                    thimphuFspMetadata.calibration.spotsGrid = currentSpotsgrid1d
                                    setThimphuFspMetadata({
                                        ...thimphuFspMetadata,
                                    })
                                    saveSpotsGrid1D(currentSpotsgrid1d)
                                    await startWritingMemory()
                                }}
                            >
                                <Button type="primary" >
                                    Commit
                                </Button>
                            </Popconfirm>
                        }
                        />
                    : null
                }
            </Col>
        </Row>
    </Paper>
    </div>
}