import Plot from "react-plotly.js"
import { DEFAULT_PLOTLY_CONFIG, DEFAULT_PLOTLY_LAYOUT } from "../constants"
import { addPointToPcaFigure, getModeledPcaFigure } from "../analysis/figures"
import { FC, useState, useEffect } from "react"
import { ModelType } from "../byteio/model"
import { getColormap } from "../analysis/colormap"
import { QuestionningResult } from "../types"
import { colorTupleToRGBA } from "../utils"

export const ModeledPca: FC<{
    model: ModelType | null
    result: (QuestionningResult | null)
}> = ({
    model,
    result
}) => {
    const [ plotlyData, setPlotlyData ] = useState<Plotly.Data[]>([])
    const [ plotlyLayout, setPlotlyLayout ] = useState<Partial<Plotly.Layout>>({})
    const [ cnt, setCnt ] = useState<number>(0)

    useEffect(() => {
        if (model === null) {
            return
        }
        let {
            data, layout
        } = getModeledPcaFigure(
            model.cmap,
            model.groupedScaledEllipses
        )
        layout = {
            ...DEFAULT_PLOTLY_LAYOUT,
            ...layout,
            ...{
                margin: {
                    t: 0,
                    b: 20,
                    l: 20,
                    r: 20,
                },
                font: {
                    size: 9
                }
            }
        }
        setPlotlyData(data)
        setPlotlyLayout(layout)
    }, [model])

    useEffect(() => {
        if (result === null) {
            setPlotlyData([])
            return
        }
        if (model === null) {
            return
        }
        console.log("modeled pca: results", result)
        let _data: Plotly.Data[] = []
        if (result === null) {
            console.log("modeled pca: result is null")
            return
        }
        let point = result.point;
        let label = result.label;

        let color = 'red';
        if (Object.keys(model.cmap).includes(label)) {
            color = colorTupleToRGBA(model.cmap[label], 1);
        }
        _data.push({
            type: 'scatter',
            name: label,
            hoverinfo: 'name',
            legendgroup: 'test_points',
            x: [point[0]],
            y: [point[1]],
            mode: 'markers',
            marker: {
                color,
                size: 10,
                symbol: 'square'
            }
        })
        setPlotlyData(_data)
        setCnt(cnt + 1)

    }, [result])


    if (model === null) {
        return null
    }   
    return <Plot
        data={plotlyData}
        layout={plotlyLayout}
        config={DEFAULT_PLOTLY_CONFIG}
        style={{
            width: '100%',
            height: '100%'
        }}
        useResizeHandler
    />
}