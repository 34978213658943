import { PartitionValue, RecordValue, getPartition } from "./idb/idb"
import { parse as uuidParse, stringify as uuidStringify } from 'uuid'

const getAPIBaseURL = () => {
    if (window.location.hostname === 'localhost') {
        return 'https://webapp.aryballe.com/api'
        return 'http://localhost:8038'
    }
    return 'https://webapp.aryballe.com/api'
}

export interface ApiErrorType {
    Reason: string 
}

export interface ApiRecord {
    ID: RecordValue['key']
    AbsoluteTimestamp: RecordValue['absoluteTimestamp']

    Name: RecordValue['name']
    Description: RecordValue['description']

    DeviceCommonName: RecordValue['device']['commonName']
    DeviceShellSerial: RecordValue['device']['shellSerial']
    DeviceCoreSensorSerial: RecordValue['device']['coreSensorSerial']
    DeviceFwVersion: RecordValue['device']['fwVersion']
    DeviceHwVersion: RecordValue['device']['hwVersion']
    DeviceCameraExposure: RecordValue['device']['cameraExposure']
    DeviceSpotsgrid: RecordValue['device']['spotsgrid']

    BaselineStart: RecordValue['baselineStart']
    BaselineEnd: RecordValue['baselineEnd']
    AnalyteStart: RecordValue['analyteStart']
    AnalyteEnd: RecordValue['analyteEnd']

    SensogramNFrames?: RecordValue['sensogramNFrames']
}

export interface ApiPartition {
    ID: PartitionValue['key']
    AbsoluteTimestamp: PartitionValue['absoluteTimestamp']

    NFrames: PartitionValue['nFrames']
    NDims: PartitionValue['nDims']

    RealtiveTimestamps: PartitionValue['relativeTimestamps']
    Series: PartitionValue['series']
}

export enum ApiParitionDataType {
    Sensogram = 1,
    Humidity = 2,
    Temperature = 3,
}

export interface ApiDOHSessionResponse {
    SessionID: string
}

export const encodeApiPartition = (partition: PartitionValue, type: ApiParitionDataType): Uint8Array => {
    // encode partition as a byte array
    // key (uuid): 16 bytes
    // type: 1 byte (1 = sensogram, 2 = humidity, 3 = temperature)
    // absoluteTimestamp (unix timestamp): 8 bytes
    // nFrames (uint32): 2 bytes
    // nDims (uint32): 2 bytes
    // relativeTimestamps (array of uint16): 2 * nFrames bytes
    // series (array of fixed12 encoded as uint16): 2 * nFrames * nDims bytes

    // all numbers are big endian

    let totalLength = 16 + 1 + 8 + 2 + 2 + 2 * partition.nFrames + 2 * partition.nFrames * partition.nDims

    const arrayBuffer = new ArrayBuffer(totalLength) // underlying array buffer
    const dataView = new DataView(arrayBuffer) // view of the (shared) array buffer

    // key
    const keyBytes = new Uint8Array(uuidParse(partition.key).buffer)
    for (let i = 0; i < 16; i++) {
        dataView.setUint8(i, keyBytes[i])
    }

    // type
    dataView.setUint8(16, +type)

    //  absolute timestamp
    dataView.setBigUint64(16+1, BigInt(partition.absoluteTimestamp))
    // console.log('encoding partition: absolute timestamp', partition.absoluteTimestamp, dataView.getBigUint64(17))

    // nFrames
    dataView.setUint16(16+1+8, partition.nFrames)

    // nDims
    dataView.setUint16(16+1+8+2, partition.nDims)

    // relative timestamps
    let relativeTimestampsOffset = 16+1+8+2+2
    for (let i = 0; i < partition.relativeTimestamps.length; i++) {
        dataView.setUint8(relativeTimestampsOffset + i, partition.relativeTimestamps[i])
    }

    // series
    let seriesOffset = 16+1+8+2+2+2*partition.nFrames
    for (let i = 0; i < partition.series.length; i++) {
        dataView.setUint8(seriesOffset + i, partition.series[i])
    }

    console.log('encoded partition', arrayBuffer)

    return new Uint8Array(arrayBuffer)
}

export const decodeApiPartition = (arrayBuffer: ArrayBuffer): PartitionValue => {
    let dataView = new DataView(arrayBuffer)

    // key
    let key = uuidStringify(new Uint8Array(arrayBuffer.slice(0, 16)))

    // type
    let type = dataView.getUint8(16)

    // absolute timestamp
    let absoluteTimestamp = Number(dataView.getBigUint64(16+1))

    // nFrames
    let nFrames = dataView.getUint16(16+1+8)

    // nDims
    let nDims = dataView.getUint16(16+1+8+2)

    // relative timestamps
    let relativeTimestampsOffset = 16+1+8+2+2
    let relativeTimestamps = new Uint8Array(arrayBuffer.slice(relativeTimestampsOffset, relativeTimestampsOffset + 2 * nFrames))

    // series
    let seriesOffset = 16+1+8+2+2+2*nFrames
    let series = new Uint8Array(arrayBuffer.slice(seriesOffset, seriesOffset + 2 * nFrames * nDims))

    let partition: PartitionValue = {
        key: key,
        absoluteTimestamp: absoluteTimestamp,
        nFrames: nFrames,
        nDims: nDims,
        relativeTimestamps: relativeTimestamps,
        series: series,
    }
    return partition
}

enum ApiResponseType {
    JSON = 'json',
    ArrayBuffer = 'arraybuffer',
}

export const callAPIEndpoint = async (path: string, method: string, body?: BodyInit, responseType?: ApiResponseType, bearerToken?: string) => {
    const response = await fetch(`${getAPIBaseURL()}${path}`, {
        method: method,
        body: body,
        headers: [
            ['Authorization', `Bearer ${bearerToken}`]
        ]
    })
    if (!response.ok) {
        if (response.status === 500) {
            const errorData: ApiErrorType = await response.json()
            throw new Error(`API call failed: ${response.status} ${response.statusText}: ${errorData.Reason}`)
        }
        throw new Error(`API call failed: ${response.status} ${response.statusText}`)
    }
    switch (responseType) {
        case undefined:
            return await response.json()
        case ApiResponseType.JSON:
            return await response.json()
        case ApiResponseType.ArrayBuffer:
            return await response.arrayBuffer()
    }
}

export const apiPostUser = async (
    id: string,
    email: string,
    firstName: string,
    lastName: string,
    groups: string[]
) => {
    void await callAPIEndpoint('/user', 'POST', JSON.stringify({
        ID: id,
        Email: email,
        FirstName: firstName,
        LastName: lastName,
        Groups: groups.map((groupName) => ({
            Name: groupName
        }))
    }))
}

export const apiPostRecord = async (record: RecordValue, userID: string) => {
    let apiRecord: ApiRecord = {
        ID: record.key,
        AbsoluteTimestamp: record.absoluteTimestamp,

        Name: record.name,
        Description: record.description,

        DeviceCommonName: record.device.commonName,
        DeviceShellSerial: record.device.shellSerial,
        DeviceCoreSensorSerial: record.device.coreSensorSerial,
        DeviceFwVersion: record.device.fwVersion,
        DeviceHwVersion: record.device.hwVersion,
        DeviceCameraExposure: record.device.cameraExposure,
        DeviceSpotsgrid: record.device.spotsgrid,
        
        BaselineStart: record.baselineStart,
        BaselineEnd: record.baselineEnd,
        AnalyteStart: record.analyteStart,
        AnalyteEnd: record.analyteEnd,
    }
    let jsonBody = JSON.stringify(apiRecord)
    void await callAPIEndpoint(`/record?user_id=${userID}`, 'POST', jsonBody)
}

const apiPostPartition = async (partition: PartitionValue, recordID: string, type: ApiParitionDataType) => {
    console.log('posting partition', partition)
    let encodedPartition = encodeApiPartition(partition, type)
    void await callAPIEndpoint(`/partition?record_id=${recordID}`, 'POST', encodedPartition)
}

const apiCheckPartitionExists = async (partitionKey: string) => {
    let partitionKeyExists: {
        Exists: boolean
    } = await callAPIEndpoint(`/partition/exists?partition_id=${partitionKey}`, 'GET')
    return partitionKeyExists.Exists
}

export const apiPostRecordPartitions = async (record: RecordValue) => {
    if (!record.sensogramPartitionKeys) {
        return
    }
    let tasks = []
    for (let partitionKey of record.sensogramPartitionKeys) {
        tasks.push(async () => {
            try {
                if (await apiCheckPartitionExists(partitionKey)) {
                    console.log('partition already exists, skipping', partitionKey)
                    return
                }
                let partition = await getPartition(partitionKey)
                await apiPostPartition(partition, record.key, ApiParitionDataType.Sensogram)
            } catch(e: any) {
                throw new Error(`error posting sensogram partition ${partitionKey}: ${e.message}`)
            }
        })
    }
    try {
        await Promise.all(tasks.map((task) => task()))
    } catch(e: any) {
        console.error('error posting all record partitions', e.message)
    }
}

export const apiGetUserRecordKeys = async (userID: string) => {
    let recordKeys: string[] = await callAPIEndpoint(`/user/record/ids?user_id=${userID}`, 'GET')
    return recordKeys
}

export const apiGetRecord = async (recordKey: string) => {
    let apiRecord: ApiRecord = await callAPIEndpoint(`/record?record_id=${recordKey}`, 'GET')
    return apiRecord
}

const _apiGetRecordPartitionKeys = async (recordKey: string, partitionDataType: ApiParitionDataType) => {
    let partitionKeys: string[] = await callAPIEndpoint(`/record/partition/ids?record_id=${recordKey}&partition_data_type=${partitionDataType}`, 'GET')
    return partitionKeys
}

export const apiGetRecordSensogramPartitionKeys = async (recordKey: string) => {
    return await _apiGetRecordPartitionKeys(recordKey, ApiParitionDataType.Sensogram)
}

export const apiGetRecordPartition = async (partitionKey: string) => {
    let encodedPartition: ArrayBuffer = await callAPIEndpoint(`/partition?partition_id=${partitionKey}`, 'GET', undefined, ApiResponseType.ArrayBuffer)
    return decodeApiPartition(encodedPartition)
}

export const apiInitDOHSession = async (recordKeys: string[], bearerToken?: string): Promise<string> => {
    let recordKeysStr = recordKeys.join(',')
    let sessionResponse: ApiDOHSessionResponse = await callAPIEndpoint(
        `/init/doh/session?record_ids=${recordKeysStr}`,
        'GET',
        undefined,
        ApiResponseType.JSON,
        bearerToken
    )
    return sessionResponse.SessionID
}