
// TS function to convert an integer to a byte array of size 2 (little endian)
export function intToByteArray2(num: number): Uint8Array {
    return Uint8Array.from([num & 0xff, (num >> 8) & 0xff])
}

// TS function to convert a byte array of size 2 (little endian) to an integer
export function byteArrayToInt2(bytes: Uint8Array): number {
    return bytes[0] | (bytes[1] << 8)
}

// TS function to convert an integer to a byte array of size 4 (little endian)
export function intToByteArray4(num: number): Uint8Array {
    return Uint8Array.from([num & 0xff, (num >> 8) & 0xff, (num >> 16) & 0xff, (num >> 24) & 0xff])
}

// TS function to convert a byte array of size 4 (little endian) to an integer
export function byteArrayToInt4(bytes: Uint8Array): number {
    return bytes[0] | (bytes[1] << 8) | (bytes[2] << 16) | (bytes[3] << 24)
}

export const convertHumidity = (raw: number): number => {
    return 100 * raw / 16382
}

export const convertTemperature = (raw: number): number => {
    return (165 * raw / 16382) - 40
}

export const readVarInt = (bufferView: Uint8Array): [number, number] => {
    let value = 0;
    let length = 0;
    let currentByte;
    while (true) {
        currentByte = bufferView[length];
        value |= (currentByte & 0x7F) << (length * 7);
        length += 1;
        if (length > 4) {
            throw new Error('VarInt exceeds allowed bounds.');
        }
        if ((currentByte & 0x80) != 0x80) break;
    }
    return [value, length]
}

export const navigatorSupportsSerial = () => "serial" in navigator