import { base64DecToArr, base64DecToArrURLSafe, base64EncArrURLSafe } from "./base64"
import { blob2vecrecord, bytesToFloat32Array, bytesToFloat32Matrix2d, bytesToUint16Array, float32ArrayToBytes, float32Matrix2dToBytes, uint16ArrayToBytes, vecrecord2blob } from "./binary"

export interface ModelType {
    groupedScaledEllipses: Record<string, number[]>
    pcaEigenvectors: number[][]
    commonSpotsgrid: number[],
    cmap: Record<string, [number, number, number]>
}

const encodePcaEigenvectors = (pcaEigenvectors: number[][]): Uint8Array => {
    return float32Matrix2dToBytes(pcaEigenvectors)
}

const decodePcaEigenvectors = (pcaEigenvectorsBytes: Uint8Array): number[][] => {
    return bytesToFloat32Matrix2d(pcaEigenvectorsBytes, 2)
}

const encodeGroupedScaledEllipses = (groupedScaledEllipses: Record<string, number[]>) => {
    return vecrecord2blob(groupedScaledEllipses)
}

const decodeGroupedScaledEllipses = (groupedScaledEllipsesBytes: Uint8Array) => {
    return blob2vecrecord(groupedScaledEllipsesBytes)
}

const encodeCommonSpotsgrid = (commonSpotsgrid: number[]): Uint8Array => {
    return uint16ArrayToBytes(commonSpotsgrid)
}

const decodeCommonSpotsgrid = (commonSpotsgridBytes: Uint8Array): number[] => {
    return bytesToUint16Array(commonSpotsgridBytes)
}

const encodeCmap = (cmap: Record<string, [number, number, number]>): Uint8Array => {
    return vecrecord2blob(cmap)
}

const decodeCmap = (cmapBytes: Uint8Array): Record<string, [number, number, number]> => {
    let _cmap = blob2vecrecord(cmapBytes)
    let cmap: Record<string, [number, number, number]> = {}
    for (let [key, value] of Object.entries(_cmap)) {
        cmap[key] = [value[0], value[1], value[2]]
    }
    return cmap
}

const encodeModelBytes = (
    model: ModelType
): Uint8Array => {
    let encEll = encodeGroupedScaledEllipses(model.groupedScaledEllipses)
    console.debug('encoding model: encEll', encEll)

    let encSpotsgrid = encodeCommonSpotsgrid(model.commonSpotsgrid)
    console.debug('encoding model: encSpotsgrid', encSpotsgrid)

    let encCmap = encodeCmap(model.cmap)
    console.debug('encoding model: encCmap', encCmap)
    
    let encPca = encodePcaEigenvectors(model.pcaEigenvectors)
    console.debug('encoding model: encPca', encPca)


    let headerLength = 4 // 2 bytes for encEll length, 1 byte for encSpotsgrid length, 1 byte for encCmap length
    let enc = new DataView(new ArrayBuffer(
        headerLength +
        encEll.length +
        encSpotsgrid.length +
        encCmap.length +
        encPca.length
    ))

    enc.setUint16(0, encEll.length) // 2 bytes
    enc.setUint8(2, encSpotsgrid.length) // 1 byte
    enc.setUint8(3, encCmap.length) // 1 byte

    for (let i = 0; i < encEll.length; i++) {
        enc.setUint8(headerLength + i, encEll[i])
    }
    for (let i = 0; i < encSpotsgrid.length; i++) {
        enc.setUint8(headerLength + encEll.length + i, encSpotsgrid[i])
    }
    for (let i = 0; i < encCmap.length; i++) {
        enc.setUint8(headerLength + encEll.length + encSpotsgrid.length + i, encCmap[i])
    }
    for (let i = 0; i < encPca.length; i++) {
        enc.setUint8(headerLength + encEll.length + encSpotsgrid.length + encCmap.length + i, encPca[i])
    }
    console.log('encoded model', enc)
    
    return new Uint8Array(enc.buffer)
}

const decodeModelBytes = (enc: Uint8Array): ModelType => {
    let headerLength = 4
    if (enc.byteLength <= headerLength) {
        throw new Error('invalid model')
    }
    let encEllLength = new DataView(enc.buffer).getUint16(0)
    let encSpotsgridLength = new DataView(enc.buffer).getUint8(2)
    let encCmapLength = new DataView(enc.buffer).getUint8(3)

    let encEll = enc.slice(
        headerLength,
        headerLength + encEllLength)
    console.debug('decoding model: encEll', encEll)

    let encSpotsgrid = enc.slice(
        headerLength + encEllLength,
        headerLength + encEllLength + encSpotsgridLength
    )
    console.debug('decoding model: encSpotsgrid', encSpotsgrid)

    let encCmap = enc.slice(
        headerLength + encEllLength + encSpotsgridLength,
        headerLength + encEllLength + encSpotsgridLength + encCmapLength
    )

    let encPca = enc.slice(
        headerLength + encEllLength + encSpotsgridLength + encCmapLength
    )

    let groupedScaledEllipses = decodeGroupedScaledEllipses(encEll)
    let pcaEigenvectors = decodePcaEigenvectors(encPca)
    let commonSpotsgrid = decodeCommonSpotsgrid(encSpotsgrid)
    let cmap = decodeCmap(encCmap)
    return {
        groupedScaledEllipses,
        pcaEigenvectors,
        commonSpotsgrid,
        cmap
    }
}

export const encodeModelBase64 = (
    model: ModelType
): string => {
    let enc = encodeModelBytes(model)
    return base64EncArrURLSafe(enc)
}

export const decodeModelBase64 = (enc: string) => {
    let encBytes = base64DecToArrURLSafe(enc)
    return decodeModelBytes(encBytes)
}