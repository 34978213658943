
const uint32toBytes = (uint32: number): Uint8Array => {
    let view = new DataView(new ArrayBuffer(4))
    view.setUint32(0, uint32)
    return new Uint8Array(view.buffer)
}

const bytesToUint32 = (bytes: Uint8Array): number => {
    let view = new DataView(bytes.buffer)
    var ret: number = view.getUint32(0)
    return ret
}

const uint16toBytes = (uint16: number): Uint8Array => {
    let view = new DataView(new ArrayBuffer(2))
    view.setUint16(0, uint16)
    return new Uint8Array(view.buffer)
}

const bytesToUint16 = (bytes: Uint8Array): number => {
    let view = new DataView(bytes.buffer)
    var ret: number = view.getUint16(0)
    return ret
}

const uint32ArrayToBytes = (uint32Array: number[]): Uint8Array => {
    let view = new DataView(new ArrayBuffer(uint32Array.length * 4))
    for (let i=0;i<uint32Array.length;i++) {
        view.setUint32(i*4, uint32Array[i])
    }
    return new Uint8Array(view.buffer)
}

const bytesToUint32Array = (bytes: Uint8Array): number[] => {
    let view = new DataView(bytes.buffer)
    let uint32Array: number[] = []
    for (let i=0; i < bytes.length/4; i++) {
        uint32Array.push(view.getUint32(i*4))
    }
    return uint32Array
}

const float32toBytes = (float32: number): Uint8Array => {
    let view = new DataView(new ArrayBuffer(4))
    view.setFloat32(0, float32)
    return new Uint8Array(view.buffer)
}

const bytesToFloat32 = (bytes: Uint8Array): number => {
    let view = new DataView(bytes.buffer)
    var ret: number = view.getFloat32(0)
    return ret
}

export const uint16ArrayToBytes = (uint16Array: number[]): Uint8Array => {
    let view = new DataView(new ArrayBuffer(uint16Array.length * 2))
    for (let i=0;i<uint16Array.length;i++) {
        view.setUint16(i*2, uint16Array[i])
    }
    return new Uint8Array(view.buffer)
}

export const bytesToUint16Array = (bytes: Uint8Array): number[] => {
    let view = new DataView(bytes.buffer)
    let uint16Array: number[] = []
    for (let i=0; i < bytes.length/2; i++) {
        uint16Array.push(view.getUint16(i*2))
    }
    return uint16Array
}

export const float32ArrayToBytes = (float32Array: number[]): Uint8Array => {
    let view = new DataView(new ArrayBuffer(float32Array.length * 4))
    for (let i=0;i<float32Array.length;i++) {
        view.setFloat32(i*4, float32Array[i])
    }
    return new Uint8Array(view.buffer)
}

export const bytesToFloat32Array = (bytes: Uint8Array): number[] => {
    let view = new DataView(bytes.buffer)
    let float32Array: number[] = []
    for (let i=0; i < bytes.length/4; i++) {
        float32Array.push(view.getFloat32(i*4))
    }
    return float32Array
}

export const float32Matrix2dToBytes = (float32Matrix2d: number[][]): Uint8Array => {
    let float32Array = float32Matrix2d.flat()
    return float32ArrayToBytes(float32Array)
}

export const bytesToFloat32Matrix2d = (bytes: Uint8Array, nDims: number): number[][] => {
    let float32Array = bytesToFloat32Array(bytes)
    let float32Matrix2d = []
    for (let i=0; i < float32Array.length/nDims; i++) {
        float32Matrix2d.push(float32Array.slice(i*nDims, (i+1)*nDims))
    }
    return float32Matrix2d
}

const float64ArrayToFixed12Bytes = (float64Array: number[]): Uint8Array => {
    let view = new Uint8Array(float64Array.length * 2)
    for (let i=0;i<float64Array.length;i++) {
        view.set(float64ToFixed12Bytes(float64Array[i]), i*2)
    }
    return new Uint8Array(view.buffer)
}

const fixed12BytesToFloat64Array = (bytes: Uint8Array): number[] => {
    let view = new DataView(bytes.buffer)
    let fixed12Array: number[] = []
    for (let i=0; i < bytes.length/2; i++) {
        fixed12Array.push(
            fixed12BytesToFloat64(new Uint8Array([
                view.getUint8(i*2),
                view.getUint8(i*2+1)
            ])))
    }
    return fixed12Array
}

export const float64Matrix2dToFixed12Bytes = (float64Matrix2d: number[][]): Uint8Array => {
    return float64ArrayToFixed12Bytes(float64Matrix2d.flat())
}

export const bytesToFixed12Matrix2d = (bytes: Uint8Array, nDims: number): number[][] => {
    let fixed12Array = fixed12BytesToFloat64Array(bytes)
    let fixed12Matrix2d = []
    for (let i=0; i < fixed12Array.length/nDims; i++) {
        fixed12Matrix2d.push(
            fixed12Array.slice(i*nDims, (i+1)*nDims)
        )
    }
    return fixed12Matrix2d
}

/*
The float64ToFixed12 function takes a float64 value
and encodes it into a 12-bit representation using a dynamic exponent.
The fixed12ToFloat64 function, on the other hand,
decodes a 12-bit representation back into a float64 value.

Both functions utilize bit manipulation to extract
and set the necessary bits for encoding and decoding.

The float64ToFixed12 function performs the following steps:

    - Determine the maximum value that can be represented in 12 bits (maxSignificant).
    - Determine the absolute value and sign flag of the input float64 value.
    - Check the magnitude of the input value and set the appropriate exponent flag (expFlag) based on the range.
    - Convert the input value to a 12-bit representation based on the determined exponent.
    - Set the sign and exponent flags in the 12-bit representation.
    - Return the encoded 12-bit representation.

The fixed12ToFloat64 function performs the reverse process:

    - Extract the sign flag and exponent flag from the 12-bit representation.
    - Extract the 12-bit value.
    - Determine the exponent based on the exponent flag.
    - Divide the 12-bit value by the exponent to obtain the decoded float64 value.
    - Apply the sign flag to the decoded value if necessary.
    - Return the decoded float64 value.
*/

const float64ToFixed12Bytes = (f64Value: number): Uint8Array => {
    const nDataBits: number = 12;
    const maxSignificant: number = Math.pow(2, nDataBits) - 1; // 4095
    const uf64MaxSignificant: number = maxSignificant;
    
    let ui16Value: number = 0;
    const uf64Value: number = Math.abs(f64Value);
    let signFlag: number = 0;
    if (f64Value < 0) {
        signFlag = 1;
    }
    let expFlag: number = 0;
    if (uf64Value >= uf64MaxSignificant) {
        ui16Value = maxSignificant;
        expFlag = 0;
    } else if (uf64Value >= uf64MaxSignificant / 1e1 && uf64Value < uf64MaxSignificant) {
        ui16Value = Math.floor(uf64Value);
        expFlag = 0;
    } else if (uf64Value >= uf64MaxSignificant / 1e2 && uf64Value < uf64MaxSignificant / 1e1) {
        ui16Value = Math.floor(uf64Value * 1e1);
        expFlag = 1;
    } else if (uf64Value >= uf64MaxSignificant / 1e3 && uf64Value < uf64MaxSignificant / 1e2) {
        ui16Value = Math.floor(uf64Value * 1e2);
        expFlag = 2;
    } else if (uf64Value >= uf64MaxSignificant / 1e4 && uf64Value < uf64MaxSignificant / 1e3) {
        ui16Value = Math.floor(uf64Value * 1e3);
        expFlag = 3;
    } else if (uf64Value >= uf64MaxSignificant / 1e5 && uf64Value < uf64MaxSignificant / 1e4) {
        ui16Value = Math.floor(uf64Value * 1e4);
        expFlag = 4;
    } else if (uf64Value >= uf64MaxSignificant / 1e6 && uf64Value < uf64MaxSignificant / 1e5) {
        ui16Value = Math.floor(uf64Value * 1e5);
        expFlag = 5;
    }
    
    const ui12FlaggedValue: number = (signFlag << 15) | (expFlag << 12) | (ui16Value & maxSignificant);
    
    const uint8Array: Uint8Array = new Uint8Array(2);
    uint8Array[0] = (ui12FlaggedValue >> 8) & 0xff;
    uint8Array[1] = ui12FlaggedValue & 0xff;
    
    // console.log('float64ToFixed12', f64Value, signFlag, expFlag, ui16Value, maxSignificant, '|', ui12FlaggedValue, uint8Array)

    return uint8Array;
}

const fixed12BytesToFloat64 = (uint8Array: Uint8Array): number => {
    const ui12FlaggedValue: number = (uint8Array[0] << 8) | uint8Array[1];
    
    const signFlag: number = (ui12FlaggedValue >> 15) & 0b0000000000000001;
    const expFlag: number = (ui12FlaggedValue >> 12) & 0b0000000000000111;
    const ui12Value: number = ui12FlaggedValue & 0b0000111111111111;
    
    
    let exp: number;
    switch (expFlag) {
        case 0:
        exp = 1;
        break;
        case 1:
        exp = 1e1;
        break;
        case 2:
        exp = 1e2;
        break;
        case 3:
        exp = 1e3;
        break;
        case 4:
        exp = 1e4;
        break;
        case 5:
        exp = 1e5;
        break;
        default:
        exp = 1;
    }
    
    let f64Value: number = ui12Value / exp;
    if (signFlag === 1) {
        f64Value *= -1;
    }

    // console.log('fixed12ToFloat64', uint8Array, signFlag, expFlag, ui12Value, '|', exp, f64Value)
    
    return f64Value;
}

export const vecrecord2blob = (record: Record<string, number[]>) => {
    var ent: {
        klen: number  // key length as number (float64)
        vlen: number  // value length as number (float64)
        bklen: Uint8Array  // encoded key length 16-bit (Uint8[2])
        bvlen: Uint8Array  // encoded value length 16-bit (Uint8[2])
        bk: Uint8Array  // encoded key (Utf-8)
        bv: Uint8Array // encoded value (float32ToBytes)
    }[] = []
    Object.entries(record).forEach(([key, value]) => {
        var bv = float32ArrayToBytes(value)
        var bk = new TextEncoder().encode(key)
        var klen = bk.length 
        var bklen = uint16toBytes(klen)
        var vlen = bv.length
        var bvlen = uint16toBytes(vlen)
        ent.push({
            bklen, bvlen, klen, vlen, bk, bv
        })
    })
    
    var totalLength = ent.reduce((acc, { klen, vlen }) => acc+2+klen+2+vlen, 0)
    var tram = new Uint8Array(totalLength)

    var t = 0
    ent.forEach((ent) => {
        /* For each entity, write
            - key_length (2 bytes)
            - key (key_length bytes)
            - value lenght (2 bytes)
            - value (value_length bytes)
        */
        ent.bklen.forEach(b => {
            tram[t] = b
            t++
        })  // 2 bytes
        ent.bk.forEach(b => {
            tram[t] = b
            t++
        }) // bklen bytes
        ent.bvlen.forEach(b => {
            tram[t] = b
            t++
        })  // 2 bytes
        ent.bv.forEach(b => {
            tram[t] = b
            t++
        })  // bvbytes bytes
    })
    return tram
}

export const blob2vecrecord = (bytes: Uint8Array): Record<string, number[]> => {
    var record: Record<string, number[]> = {}
    var t = 0
    while (true) {
        let bklen = bytes.slice(t, t+2) // Always 2 bytes
        t+=2
        let klen = bytesToUint16(bklen)
        let bk = bytes.slice(t, t+klen)
        t+=klen
        let bvlen = bytes.slice(t, t+2) // Always 2 bytes
        t+=2
        let vlen = bytesToUint16(bvlen)
        let bv = bytes.slice(t, t+vlen)
        t+=vlen

        let k = new TextDecoder().decode(bk)
        let v = bytesToFloat32Array(bv)
        record[k] = v
        if (t >= bytes.length) {
            break
        }
    }
    return record
}