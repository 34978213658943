import { FC, useState, useEffect } from "react";
import { useMessageContext } from "../reducers/messageContext";
import { thimphuSetHIH } from "../serial/thimphu";
import { convertHumidity, convertTemperature } from "../serial/utils";
import { FlexRow } from "../common";
import { Progress, Tooltip } from "antd";

export const ThimphuHIHListenerWidget: FC = () => {
    const { thimphuPort, thimphuMessages, consumeThimphuMessage } = useMessageContext();
    const [ humidityValue, setHumidityValue ] = useState<number>(0)
    const [ temperatureValue, setTemperatureValue ] = useState<number>(0)
    
    useEffect( () => {
        if (thimphuPort === undefined) {
            return
        }
        console.log('starting HIH on mount')
        thimphuSetHIH(thimphuPort, true)
        return () => {
            console.log('stopping HIH on unmount')
            thimphuSetHIH(thimphuPort, false)
        }
    }, [ thimphuPort ])

    useEffect( () => {
        for (let message of thimphuMessages) {
            if (
                message.message.data &&
                message.message.data.temperatureHih6120
            ) {
                if (message.message.data.temperatureHih6120.rawHumidity) {
                    setHumidityValue(convertHumidity(
                        message.message.data.temperatureHih6120.rawHumidity
                    ))
                }
                if (message.message.data.temperatureHih6120.rawTemperature) {
                    setTemperatureValue(convertTemperature(
                        message.message.data.temperatureHih6120.rawTemperature
                    ))
                }
                consumeThimphuMessage(message.id)
            }
        }
    }, [thimphuMessages])

    let s = 80

    if (!thimphuPort) {
        return null
    }

    return <FlexRow style={{
        height: '100%',
        alignItems: 'center',
        marginRight: 10
    }}>
        <Tooltip overlay="Humidity"><Progress
            size={s}
            strokeColor="#fd4282"
            type="circle"
            percent={humidityValue}
            format={percent => `${percent?.toFixed(1)}%`} 
        />
        </Tooltip>
        <Tooltip overlay="Temperature"><Progress
            size={s}
            strokeColor="#4de5db"
            type="circle"
            percent={temperatureValue}
            format={percent => `${percent?.toFixed(1)}°C`}
        />
        </Tooltip>
    </FlexRow>
}