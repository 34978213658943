import { ModelType, decodeModelBase64, encodeModelBase64 } from "./byteio/model"
import { DEFAULT_THIMPHU_PUMP_POWER } from "./serial/constants"

const LOCAL_STORAGE_PUMP_POWER_KEY = 'thimphuPumpPower'
const LOCAL_STORAGE_CURRENT_MODEL_KEY = 'currentModel'
const LOCAL_STORAGE_CURRENT_SDS_SERIAL_KEY = 'currentSdsSerial'
const LOCAL_STORAGE_CURRENT_SPOTSGRID1D_KEY = 'currentSpotsGrid1D'
const LOCAL_STORAGE_CURRENT_ANALYSIS_RECORD_KEYS_KEY = 'currentAnalysisRecordKeys'
const LOCAL_STORAGE_CURRENT_MODEL_SIGMA_X_KEY = 'currentModelSigmaX'
const LOCAL_STORAGE_CURRENT_MODEL_SIGMA_Y_KEY = 'currentModelSigmaY'
const LOCAL_STORAGE_LAST_STANDALONE_APP_NOTIFICATION_SHOWN_TIMESTAMP_KEY = 'lastStandaloneAppNotificationShownTimestamp'
const LOCAL_STORAGE_SYNC_ENABLED_KEY = 'syncEnabled'
// Pump power
export const loadPumpPower = (): number => {
    let pumpPower = DEFAULT_THIMPHU_PUMP_POWER
    let storedPumpPower = window.localStorage.getItem(LOCAL_STORAGE_PUMP_POWER_KEY)
    if (storedPumpPower !== null) {
        pumpPower = parseInt(storedPumpPower)
        if (pumpPower > 100) {
            pumpPower = 100
        }
        if (pumpPower < 0) {
            pumpPower = 0
        }
        if (isNaN(pumpPower)) {
            pumpPower = 20
        }
    }
    return pumpPower
}

export const savePumpPower = (pumpPower: number) => {
    window.localStorage.setItem(LOCAL_STORAGE_PUMP_POWER_KEY, pumpPower.toString())
}

export const deletePumpPower = () => {
    window.localStorage.removeItem(LOCAL_STORAGE_PUMP_POWER_KEY)
}

// Model
export const saveModel = (
    model: ModelType
) => {
    let b64Encoded = encodeModelBase64(model)
    window.localStorage.setItem(LOCAL_STORAGE_CURRENT_MODEL_KEY, b64Encoded)
}

export const  loadModel = () => {
    let b64Encoded = window.localStorage.getItem(LOCAL_STORAGE_CURRENT_MODEL_KEY)
    if (b64Encoded === null) {
        return null
    }
    return decodeModelBase64(b64Encoded)
}

export const deleteModel = () => {
    window.localStorage.removeItem(LOCAL_STORAGE_CURRENT_MODEL_KEY)
}

// SDS serial
export const saveSdsSerial = (sdsSerial: string) => {
    window.localStorage.setItem(LOCAL_STORAGE_CURRENT_SDS_SERIAL_KEY, sdsSerial)
}

export const loadSdsSerial = () => {
    return window.localStorage.getItem(LOCAL_STORAGE_CURRENT_SDS_SERIAL_KEY)
}

export const deleteSdsSerial = () => {
    window.localStorage.removeItem(LOCAL_STORAGE_CURRENT_SDS_SERIAL_KEY)
}

// SpotsGrid1D
export const saveSpotsGrid1D = (spotsGrid1D: number[]) => {
    let spotsGrid1DStr = spotsGrid1D.join(',')
    window.localStorage.setItem(LOCAL_STORAGE_CURRENT_SPOTSGRID1D_KEY, spotsGrid1DStr)
}

export const loadSpotsGrid1D = () => {
    let spotsGrid1DStr = window.localStorage.getItem(LOCAL_STORAGE_CURRENT_SPOTSGRID1D_KEY)
    if (spotsGrid1DStr === null) {
        return null
    }
    return spotsGrid1DStr.split(',').map(x => parseInt(x))
}

export const deleteSpotsGrid1D = () => {
    window.localStorage.removeItem(LOCAL_STORAGE_CURRENT_SPOTSGRID1D_KEY)
}

export const saveLastStandaloneAppNotificationShownTimestamp = (timestamp: number) => {
    window.localStorage.setItem(LOCAL_STORAGE_LAST_STANDALONE_APP_NOTIFICATION_SHOWN_TIMESTAMP_KEY, timestamp.toString())
}

export const loadLastStandaloneAppNotificationShownTimestamp = () => {
    let timestampStr = window.localStorage.getItem(LOCAL_STORAGE_LAST_STANDALONE_APP_NOTIFICATION_SHOWN_TIMESTAMP_KEY)
    if (timestampStr === null) {
        return null
    }
    return parseInt(timestampStr)
}

export const saveAnalysisRecordKeys = (selectedRecordKeys: string[] | null) => {
    if (selectedRecordKeys === null) {
        window.localStorage.removeItem(LOCAL_STORAGE_CURRENT_ANALYSIS_RECORD_KEYS_KEY)
        return
    }
    let selectedRecordKeysStr = selectedRecordKeys.join(',')
    window.localStorage.setItem(LOCAL_STORAGE_CURRENT_ANALYSIS_RECORD_KEYS_KEY, selectedRecordKeysStr)
}

export const loadAnalysisRecordKeys = () => {
    let selectedRecordKeysStr = window.localStorage.getItem(LOCAL_STORAGE_CURRENT_ANALYSIS_RECORD_KEYS_KEY)
    if (selectedRecordKeysStr === null) {
        return null
    }
    return selectedRecordKeysStr.split(',')
}

export const deleteAnalysisRecordKeys = () => {
    window.localStorage.removeItem(LOCAL_STORAGE_CURRENT_ANALYSIS_RECORD_KEYS_KEY)
}

export const saveModelSigmaX = (sigmaX: number) => {
    window.localStorage.setItem(LOCAL_STORAGE_CURRENT_MODEL_SIGMA_X_KEY, sigmaX.toString())
}

export const loadModelSigmaX = () => {
    let sigmaXStr = window.localStorage.getItem(LOCAL_STORAGE_CURRENT_MODEL_SIGMA_X_KEY)
    if (sigmaXStr === null) {
        return null
    }
    return parseFloat(sigmaXStr)
}

export const deleteModelSigmaX = () => {
    window.localStorage.removeItem(LOCAL_STORAGE_CURRENT_MODEL_SIGMA_X_KEY)
}

export const saveModelSigmaY = (sigmaY: number) => {
    if (sigmaY === null) {
        window.localStorage.removeItem(LOCAL_STORAGE_CURRENT_MODEL_SIGMA_Y_KEY)
        return
    }
    window.localStorage.setItem(LOCAL_STORAGE_CURRENT_MODEL_SIGMA_Y_KEY, sigmaY.toString())
}

export const loadModelSigmaY = () => {
    let sigmaYStr = window.localStorage.getItem(LOCAL_STORAGE_CURRENT_MODEL_SIGMA_Y_KEY)
    if (sigmaYStr === null) {
        return null
    }
    return parseFloat(sigmaYStr)
}

export const deleteModelSigmaY = () => {
    window.localStorage.removeItem(LOCAL_STORAGE_CURRENT_MODEL_SIGMA_Y_KEY)
}

export const saveSyncEnabled = (syncEnabled: boolean) => {
    window.localStorage.setItem(LOCAL_STORAGE_SYNC_ENABLED_KEY, syncEnabled.toString())
}

export const loadSyncEnabled = () => {
    let syncEnabledStr = window.localStorage.getItem(LOCAL_STORAGE_SYNC_ENABLED_KEY)
    if (syncEnabledStr === null) {
        return true
    }
    return syncEnabledStr === 'true'
}