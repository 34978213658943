
import { openDB, DBSchema, IDBPDatabase } from 'idb';
import { IDB_DEFAULT_ANALYTE_WINDOW_SIZE, IDB_DEFAULT_BASELINE_WINDOW_SIZE } from '../constants';
import { v4 as uuidv4 } from 'uuid';
import { bytesToFixed12Matrix2d, bytesToUint16Array, float64Matrix2dToFixed12Bytes, uint16ArrayToBytes } from '../byteio/binary';

const NEOSE_WEBAPP_CURRENT_DB_VERSION = 1

export type RecordKey = string
export type PartitionKey = string

export interface DeviceValue {
    commonName?: string,
    shellSerial: string,
    coreSensorSerial: string,
    hwVersion: string,
    fwVersion: string,
    cameraExposure: number,
    spotsgrid: number[],
}

export interface RecordValue {
    key: RecordKey,
    absoluteTimestamp: number,

    name?: string,
    description?: string,

    device: DeviceValue,

    baselineStart?: number,
    baselineEnd?: number,
    analyteStart?: number,
    analyteEnd?: number,

    sensogramPartitionKeys?: PartitionKey[],
    humidityPartitionKeys?: PartitionKey[],
    temperaturePartitionKeys?: PartitionKey[],

    sensogramNFrames?: number,
}

export interface RecordValueWithSensograms extends RecordValue {
    sensogramSeries: number[][],
    sensogramTimestamps: number[],
}

export interface PartitionValue {
    key: PartitionKey,
    nFrames: number,
    nDims: number,
    absoluteTimestamp: number,
    relativeTimestamps: Uint8Array, // uint32[nFrames]
    series: Uint8Array, // float32[nDims][nFrames]
}

export interface NeoseWebappDBv1 extends DBSchema {
    records: {
        key: RecordKey // uuid
        value: RecordValue
    },
    partitions: {
        key: PartitionKey // uuid
        value: PartitionValue
    }
}

export const withIdb = async (): Promise<IDBPDatabase<NeoseWebappDBv1>> => {
    const db = await openDB<NeoseWebappDBv1>('neose-webapp-db', NEOSE_WEBAPP_CURRENT_DB_VERSION, {
        async upgrade(db, oldVersion, newVersion) {
            console.log('upgrading IDB', oldVersion, newVersion)
            try{
                db.createObjectStore('records', {
                    keyPath: 'key',
                    autoIncrement: false 
                })
            } catch(e) {}
            try {
                db.createObjectStore('partitions', { 
                    keyPath: 'key',
                    autoIncrement: false
                })
            } catch(e) {}
        },        
    })
    
    return db
}

export const commitSensogramPartition = async (
    recordKey: string,
    sensogramSeriesNum: number[][],
    absoluteTimestampSeriesNum: number[]
) => {
    let idb = await withIdb()
    if (sensogramSeriesNum.length !== absoluteTimestampSeriesNum.length) {
        throw new Error("could not save partition: series and timestamps have different lengths")
    }
    if (sensogramSeriesNum.length === 0) {
        throw new Error("could not save partition: series and timestamps have length 0")
    }

    let nDims = sensogramSeriesNum[0].length

    let firstTimestamp = absoluteTimestampSeriesNum[0]
    let relativeTimestampsNum = absoluteTimestampSeriesNum.map((ts) => ts - firstTimestamp)

    let relativeTimeseriesBytes = uint16ArrayToBytes(relativeTimestampsNum)
    let sensogramSeriesBytes = float64Matrix2dToFixed12Bytes(sensogramSeriesNum)
    // console.log('idb: save partition: series bytes', sensogramSeriesBytes)

    let tx = idb.transaction(['records', 'partitions'], 'readwrite')
    let partition: PartitionValue = {
        key: uuidv4(),
        nDims: nDims,
        nFrames: relativeTimestampsNum.length,
        absoluteTimestamp: firstTimestamp,
        relativeTimestamps: relativeTimeseriesBytes,
        series: sensogramSeriesBytes
    }
    let partitionKey = await tx.objectStore('partitions').add(partition)
    console.log('idb: save partition: partition key', partitionKey)

    let record = await tx.objectStore('records').get(recordKey)
    if (record === undefined) {
        throw new Error(`idb: could not save partition: could not find record by key: ${recordKey}`)
    }
    if (record.sensogramPartitionKeys === undefined) {
        record.sensogramPartitionKeys = []
    }
    if (record.sensogramNFrames === undefined) {
        record.sensogramNFrames = 0
    }
    record.sensogramNFrames += partition.nFrames
    record.sensogramPartitionKeys.push(partitionKey)
    await tx.objectStore('records').put(record)
}

export const getAllRecords = async (): Promise<RecordValue[]> => {
    let idb = await withIdb()
    let records = await idb.getAll('records')
    return records
}

export const getAllRecordKeys = async (): Promise<RecordKey[]> => {
    let idb = await withIdb()
    let recordKeys = await idb.getAllKeys('records')
    return recordKeys
}

export const getRecords = async (recordKeys: RecordKey[]): Promise<RecordValue[]> => {
    let idb = await withIdb()
    let records: RecordValue[] = []
    for (let recordKey of recordKeys) {
        let record = await idb.get('records', recordKey)
        if (record !== undefined) {
            records.push(record)
        }
    }
    return records
}

export const getFullRecords = async (recordKeys: RecordKey[]): Promise<RecordValueWithSensograms[]> => {
    let records = await getRecords(recordKeys)
    let fullRecords: RecordValueWithSensograms[] = []
    for (let record of records) {
        let fullRecord = await getFullRecord(record.key)
        fullRecords.push(fullRecord)
    }
    return fullRecords
}

export const newRecord = async (
    device: DeviceValue
): Promise<RecordKey> => {
    let idb = await withIdb()
    let record = {
        key: uuidv4(),
        absoluteTimestamp: Date.now(),
        device,
    }
    let recordKey = await idb.add('records', record)
    return recordKey
}

export const getRecord = async (recordKey: RecordKey) => {
    let idb = await withIdb()
    let record = await idb.get('records', recordKey)
    return record
}

export const getFullRecord = async (recordKey: RecordKey): Promise<RecordValueWithSensograms> => {
    let idb = await withIdb()
    let record = await getRecord(recordKey)
    if (record === undefined) {
        throw new Error(`idb: could not fetch record: could not find record by key: ${recordKey}`)
    }
    
    let sensogramSeries: number[][] = []
    let sensogramTimestamps: number[] = []
    if (record.sensogramPartitionKeys) {
        for (let partitionKey of record.sensogramPartitionKeys) {
            let partition = await idb.get('partitions', partitionKey)
            if (!partition) {
                continue
            }
            // console.debug('fetched record partition', partition.key)
            let series = bytesToFixed12Matrix2d(partition.series, partition.nDims)
            sensogramSeries.push(...series)
            let relativeTimestamps = bytesToUint16Array(partition.relativeTimestamps)
            let absoluteTimestamps = []
            for (let relativeTimestamp of relativeTimestamps) {
                absoluteTimestamps.push(relativeTimestamp + partition.absoluteTimestamp)
            }
            sensogramTimestamps.push(...absoluteTimestamps)
        }
    }

    // initialize boundaries if they are not set
    let boundariesAreSet = false
    if (record.baselineStart === undefined || record.baselineEnd === undefined) {
        record.baselineStart = 0
        let baselineEnd = IDB_DEFAULT_BASELINE_WINDOW_SIZE
        if (sensogramTimestamps.length < baselineEnd) {
            baselineEnd = Math.floor(sensogramTimestamps.length / 2) - 1
        }
        record.baselineEnd = baselineEnd
        boundariesAreSet = true
    }
    if (record.analyteStart === undefined || record.analyteEnd === undefined) {
        record.analyteEnd = sensogramTimestamps.length-1
        let analyteStart = record.analyteEnd - IDB_DEFAULT_ANALYTE_WINDOW_SIZE
        if (analyteStart < 0) {
            analyteStart = Math.floor(sensogramTimestamps.length / 2) + 1
        }
        record.analyteStart = analyteStart
        boundariesAreSet = true
    }
    if (boundariesAreSet) {
        await updateRecordBoundaries(
            record.key,
            record.baselineStart,
            record.baselineEnd,
            record.analyteStart,
            record.analyteEnd
        )
    }

    return {
        ...record,
        sensogramSeries,
        sensogramTimestamps
    } 
}

// returns the updated record
export const updateRecordName = async (
    recordKey: RecordKey,
    name: string
): Promise<RecordValue> => {
    let idb = await withIdb()
    let record = await idb.get('records', recordKey)
    if (record === undefined) {
        throw new Error(`idb: could not update record name: could not find record by key: ${recordKey}`)
    }
    record.name = name
    void await idb.put('records', record)
    return record
}

export const updateRecordDescription = async (
    recordKey: RecordKey,
    description: string
): Promise<RecordValue> => {
    let idb = await withIdb()
    let record = await idb.get('records', recordKey)
    if (record === undefined) {
        throw new Error(`idb: could not update record description: could not find record by key: ${recordKey}`)
    }
    record.description = description
    void await idb.put('records', record)
    return record
}

export const updateRecordBoundaries = async (
    recordKey: RecordKey,
    baselineStart?: number,
    baselineEnd?: number,
    analyteStart?: number,
    analyteEnd?: number
) => {
    let idb = await withIdb()
    let record = await idb.get('records', recordKey)
    if (record === undefined) {
        throw new Error(`idb: could not update record boundaries: could not find record by key: ${recordKey}`)
    }
    if (baselineStart !== undefined) {
        record.baselineStart = baselineStart
    }
    if (baselineEnd !== undefined) {
        record.baselineEnd = baselineEnd
    }
    if (analyteStart !== undefined) {
        record.analyteStart = analyteStart
    }
    if (analyteEnd !== undefined) {
        record.analyteEnd = analyteEnd
    }
    
    void await idb.put('records', record)
    return record
}

export const deleteRecord = async (recordKey: RecordKey) => {
    let idb = await withIdb()
    let tx = idb.transaction(['records', 'partitions'], 'readwrite')
    let record = await tx.objectStore('records').get(recordKey)
    if (record === undefined) {
        throw new Error(`idb: could not delete record: could not find record by key: ${recordKey}`)
    }
    if (record.sensogramPartitionKeys !== undefined) {
        for (let partitionKey of record.sensogramPartitionKeys) {
            void await tx.objectStore('partitions').delete(partitionKey)
        }
    }
    await tx.objectStore('records').delete(recordKey)
}

export const getPartition = async (partitionKey: PartitionKey): Promise<PartitionValue> => {
    let idb = await withIdb()
    let partition = await idb.get('partitions', partitionKey)
    if (partition === undefined) {
        throw new Error(`idb: could not fetch partition: could not find partition by key: ${partitionKey}`)
    }
    return partition
}

export const putRecord = async(record: RecordValue) => {
    let idb = await withIdb()
    await idb.put('records', record)
}

export const putPartition = async(partition: PartitionValue) => {
    let idb = await withIdb()
    await idb.put('partitions', partition)
}